import { Tooltip as ReachTooltip } from '@reach/tooltip';
import '@reach/tooltip/styles.css';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';

export const Tooltip = styled(ReachTooltip)<{ autoHeight?: boolean }>`
  z-index: ${globalTheme.zIndices.tooltip};
  display: flex;
  align-items: center;
  height: ${(props) => (props.autoHeight ? 'auto' : '24px')};
  padding: 0 ${globalTheme.space[0]};
  color: ${globalTheme.elements.tooltip.color};
  font-size: ${globalTheme.fontSizes[0]};
  background-color: ${globalTheme.elements.tooltip.backgroundColor};
  border: 0;
  border-radius: 4px;
  box-shadow: 4px 0 16px 0 rgba(0, 0, 0, 0.1);
`;
