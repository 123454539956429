import { rgba } from 'polished';
import { ToastContainer as ReactToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Icon } from '../icon';

export const ToastContainer = styled(ReactToastContainer).attrs({
  position: 'bottom-right',
  hideProgressBar: true,
  newestOnTop: true,
  icon: () => <Icon component='AlertIcon' size={3} />,
  toastClassName: 'cy-toast-message',
})`
  right: ${globalTheme.space[2]};
  bottom: ${globalTheme.space[0]};
  width: 100%;
  max-width: 400px;
  padding: 0;
  border: 0;

  .Toastify__toast {
    --background-color: ${globalTheme.colors.neutral};
    --text-color: ${globalTheme.colors.darkGrey};
    --shadow-color: ${rgba(globalTheme.colors.darkGrey, 0.15)};

    margin-bottom: ${globalTheme.space[1]};
    padding: ${globalTheme.space[2]};
    color: var(--text-color);
    font-weight: ${globalTheme.fontWeights.bold};
    font-size: ${globalTheme.fontSizes[2]};
    font-family: ${globalTheme.fontFamily};
    line-height: ${globalTheme.lineHeights.heading};
    background: var(--background-color);
    border-radius: 20px;
    box-shadow: 0 8px 16px 0 var(--shadow-color);
  }

  .Toastify__toast-body {
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: ${globalTheme.space[1]};
    align-items: center;
    padding: 0;
    color: currentColor;
  }

  .Toastify__toast--info {
    --background-color: ${globalTheme.colors.lightBlue};
    --text-color: ${globalTheme.colors.blue};
    --shadow-color: ${rgba(globalTheme.colors.blue, 0.15)};
  }

  .Toastify__toast--warning {
    --background-color: ${globalTheme.colors.yellow};
    --text-color: ${globalTheme.colors.orange};
    --shadow-color: ${rgba(globalTheme.colors.orange, 0.15)};
  }

  .Toastify__toast--success {
    --background-color: ${globalTheme.colors.lightGreen};
    --text-color: ${globalTheme.colors.green};
    --shadow-color: ${rgba(globalTheme.colors.green, 0.15)};
  }

  .Toastify__toast--error {
    --background-color: ${globalTheme.colors.lightPink};
    --text-color: ${globalTheme.colors.pink};
    --shadow-color: ${rgba(globalTheme.colors.pink, 0.15)};
  }

  .Toastify__close-button {
    display: block;
    align-self: center;
    width: 20px;
    height: 20px;
    padding: 0;
    color: currentColor;
    border: 0;
    opacity: 1;
    margin-inline-start: ${globalTheme.space[2]};

    > svg {
      width: inherit;
      height: inherit;
    }
  }

  .Toastify__toast-icon {
    width: 32px;
    margin: 0;
  }
`;
