import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';
import { IconButton } from '../icon-button';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export interface PdfViewerProps {
  file: File | string;
  displayTextLayer?: boolean;
  displayAnnotationsLayer?: boolean;
}

const Container = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;

  &:hover .pagination {
    bottom: ${globalTheme.space[2]};
    visibility: visible;
    opacity: 1;
  }

  .pdfPage > canvas {
    max-width: 100%;
    height: auto !important;
  }

  .react-pdf__Page__annotations {
    display: none !important;
  }

  .annotationLayer {
    display: none !important;
  }

  @media screen and (max-width: ${breakpoints.medium}) {
    flex-direction: column;

    &:hover .pagination {
      bottom: 0;
    }
  }
`;

const Pagination = styled.div`
  position: absolute;
  bottom: ${globalTheme.space[1]};
  left: 50%;
  display: flex;
  gap: 12px;
  align-items: center;
  height: 32px;
  background-color: ${globalTheme.colors.background};
  border: 1px solid ${globalTheme.colors.neutral};
  border-radius: 16px;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.3);
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  button:hover {
    background-color: ${globalTheme.colors.neutral};
  }

  @media screen and (max-width: ${breakpoints.medium}) {
    position: relative;
    bottom: 0;
    justify-content: space-around;
    width: 150px;
    visibility: visible;
    opacity: 1;
  }
`;

export const PdfViewer: FC<PdfViewerProps> = ({
  file,
  displayTextLayer = true,
  displayAnnotationsLayer = true,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const documentRef = useRef<HTMLDivElement>(null);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const pageBack = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const pageForward = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (pageNumber < (numPages || 1)) {
      setPageNumber(pageNumber + 1);
    }
  };

  useEffect(() => {
    if (!documentRef || !documentRef.current || !numPages) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowLeft':
          pageNumber > 1 && setPageNumber(pageNumber - 1);
          break;
        case 'ArrowRight':
          pageNumber < (numPages || 1) && setPageNumber(pageNumber + 1);
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [documentRef, numPages, pageNumber]);

  return (
    <div>
      <Container ref={documentRef}>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            className={'pdfPage'}
            renderTextLayer={displayTextLayer}
            renderAnnotationLayer={displayAnnotationsLayer}
            pageNumber={pageNumber}
          />
        </Document>
        {numPages && numPages > 1 && (
          <Pagination className='pagination'>
            <IconButton
              icon='ChevronBackIcon'
              size={3}
              onClick={pageBack}
              disabled={pageNumber <= 1}
            />
            {pageNumber} of {numPages}
            <IconButton
              icon='ChevronNextIcon'
              size={3}
              onClick={pageForward}
              disabled={pageNumber === numPages}
            />
          </Pagination>
        )}
      </Container>
    </div>
  );
};
