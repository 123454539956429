import { FC, forwardRef, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';

type SpaceKeys = keyof typeof globalTheme.space;

export interface StackProps extends HTMLAttributes<HTMLDivElement> {
  space: SpaceKeys | SpaceKeys[];
  className?: string;
}

const getSpaces = (props: {
  initial: string;
  small: string;
  medium: string;
  large: string;
  xLarge: string;
}) => {
  return css`
    margin-top: ${props.initial};

    @media screen and (min-width: ${breakpoints.small}) {
      margin-top: ${props.small};
    }

    @media screen and (min-width: ${breakpoints.medium}) {
      margin-top: ${props.medium};
    }

    @media screen and (min-width: ${breakpoints.large}) {
      margin-top: ${props.large};
    }

    @media screen and (min-width: ${breakpoints.xLarge}) {
      margin-top: ${props.xLarge};
    }
  `;
};

const StackWrapper = styled.div`
  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    ${getSpaces}
  }
`;

export const Stack: FC<StackProps> = forwardRef<HTMLDivElement, StackProps>(
  ({ children, space, ...divProps }, ref) => {
    let spaceValues = {
      initial: '0',
      small: '0',
      medium: '0',
      large: '0',
      xLarge: '0',
    };

    // Single value spacing
    if (typeof space === 'number') {
      const value = globalTheme.space[space];
      spaceValues.initial = value;
      spaceValues.small = value;
      spaceValues.medium = value;
      spaceValues.large = value;
      spaceValues.xLarge = value;
    }

    // Responsive spacing
    if (Array.isArray(space)) {
      const values = space.map((space) => globalTheme.space[space]);
      spaceValues.initial = values[0];
      spaceValues.small = values[1] || values[0];
      spaceValues.medium = values[2] || values[1] || values[0];
      spaceValues.large = values[3] || values[2] || values[1] || values[0];
      spaceValues.xLarge =
        values[4] || values[3] || values[2] || values[1] || values[0];
    }

    return (
      <StackWrapper {...spaceValues} {...divProps} ref={ref}>
        {children}
      </StackWrapper>
    );
  }
);
