import googleTagManager from '@analytics/google-tag-manager';
import { originalSourcePlugin } from '@analytics/original-source-plugin';
import { logger } from '@hatchd/utils';
import Analytics from 'analytics';

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

// What type of env are we in?
const IS_DEV_ENV = !GTM_ID || process.env.NODE_ENV === 'development';
const IS_PROD_ENV = process.env.NODE_ENV === 'production' && GTM_ID;

export enum EventName {
  signin = 'signin',
  signout = 'signout',
  createPriceGrid = 'createPriceGrid',
  sendOffer = 'sendOffer',
  sendCounterOffer = 'sendCounterOffer',
  acceptOffer = 'acceptOffer',
}

// dev only plugins
const devOnlyPlugins = [
  {
    name: 'logger',
    page: ({ payload }: any) => {
      logger.log('page', payload);
    },
    track: ({ payload }: any) => {
      logger.log('track', payload);
    },
    identify: ({ payload }: any) => {
      logger.log('identify', payload);
    },
  },
];

// prod only plugins
const prodOnlyPlugins = [
  googleTagManager({
    containerId: GTM_ID,
  }),
];

const analytics = Analytics({
  app: 'agora-livestock',
  plugins: [
    originalSourcePlugin(),
    ...(IS_DEV_ENV ? devOnlyPlugins : []),
    ...(IS_PROD_ENV ? prodOnlyPlugins : []),
  ],
});

// tracking page views
export const pageView = (url: string) => {
  analytics.page({ url: url });
};

// tracking basic or custom events
export const trackEvent = (
  event: EventName,
  payload?: { [key: string]: any }
) => {
  analytics.track(event, payload);
};

// tracking logged in user
export const trackUser = (userId: string, payload?: { [key: string]: any }) => {
  analytics.identify(userId, payload);
};
