declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage(msg: string): void;
    };
  }
}

const POST_MESSAGE_JSON = {
  org: 'complete',
};
export const sendCompleteMessageToApp = () => {
  window.ReactNativeWebView &&
    window.ReactNativeWebView.postMessage(JSON.stringify(POST_MESSAGE_JSON));
};
