import styled, { css, CSSObject } from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';
import { Box, BoxProps } from '../box';

export interface GridProps extends BoxProps {
  columns?: number | CSSObject['gridTemplateColumns'];
  rows?: number | CSSObject['gridTemplateRows'];
  /** Space between items */
  gap?:
    | keyof typeof globalTheme['space']
    | {
        column?: keyof typeof globalTheme['space'];
        row?: keyof typeof globalTheme['space'];
      };
  /** Whether to use auto placement, will override any columns set */
  auto?: boolean | { min?: string; max?: string; type?: 'fill' | 'fit' };
  alignItems?: CSSObject['alignItems'];
  justifyItems?: CSSObject['justifyItems'];
  justifyContent?: CSSObject['justifyContent'];
  /** Stack elements on small screen */
  stackOnSmall?: boolean | keyof typeof breakpoints;
  stackOnMedium?: boolean | keyof typeof breakpoints;
}

function getValues(props: GridProps): CSSObject {
  let gridTemplateColumns = props.columns;
  let gridTemplateRows = props.rows;

  if (typeof props.columns === 'number') {
    gridTemplateColumns = `repeat(${props.columns}, 1fr)`;
  }

  if (typeof props.rows === 'number') {
    gridTemplateRows = `repeat(${props.rows}, 1fr)`;
  }

  if (typeof props.auto === 'boolean') {
    gridTemplateColumns = `repeat(auto-fit, minmax(280px, 1fr))`;
  }

  if (typeof props.auto === 'object') {
    gridTemplateColumns = `repeat(auto-${props.auto.type || 'fit'}, minmax(${
      props.auto.min || '280px'
    }, ${props.auto.max || '1fr'}))`;
  }

  return {
    gridTemplateColumns,
    gridTemplateRows,
    gap:
      props.gap !== undefined && typeof props.gap !== 'object'
        ? globalTheme.space[props.gap]
        : undefined,
    rowGap:
      props.gap !== undefined &&
      typeof props.gap === 'object' &&
      props.gap.row !== undefined
        ? globalTheme.space[props.gap.row]
        : undefined,
    columnGap:
      props.gap !== undefined &&
      typeof props.gap === 'object' &&
      props.gap.column !== undefined
        ? globalTheme.space[props.gap.column]
        : undefined,
    alignItems: props.alignItems,
    justifyItems: props.justifyItems,
    justifyContent: props.justifyContent,
  };
}

export const Grid = styled(Box)<GridProps>`
  display: grid;
  ${getValues}

  ${({ stackOnSmall }) =>
    stackOnSmall &&
    css`
      @media screen and (max-width: ${breakpoints[
          typeof stackOnSmall === 'string' ? stackOnSmall : 'small'
        ]}) {
        grid-template-columns: 1fr;
      }
    `}

  ${({ stackOnMedium }) =>
    stackOnMedium &&
    css`
      @media screen and (max-width: ${breakpoints[
          typeof stackOnMedium === 'string' ? stackOnMedium : 'medium'
        ]}) {
        grid-template-columns: 1fr;
      }
    `}
`;
