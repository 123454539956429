/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { AddressNamed } from '../model';
// @ts-ignore
import { PaginatedAddressNamedList } from '../model';
// @ts-ignore
import { PatchedAddressNamed } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * OrganisationAddressApi - axios parameter creator
 * @export
 */
export const OrganisationAddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates an new address against the Buyers Organisation
         * @summary Create an Address
         * @param {string} buyerOrgId 
         * @param {AddressNamed} addressNamed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgAddressCreate: async (buyerOrgId: string, addressNamed: AddressNamed, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgAddressCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'addressNamed' is not null or undefined
            assertParamExists('orgAddressCreate', 'addressNamed', addressNamed)
            const localVarPath = `/v2/org/{buyer_org_id}/address`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addressNamed, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an Address link from the Buyers Organisation
         * @summary Delete an Address
         * @param {string} addressId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgAddressDestroy: async (addressId: string, buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('orgAddressDestroy', 'addressId', addressId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgAddressDestroy', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/address/{address_id}`
                .replace(`{${"address_id"}}`, encodeURIComponent(String(addressId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a list of all the Addresses associated with the Organisation
         * @param {string} buyerOrgId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgAddressList: async (buyerOrgId: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgAddressList', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/address`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the newly updated Address
         * @summary Update the address details
         * @param {string} addressId 
         * @param {string} buyerOrgId 
         * @param {PatchedAddressNamed} [patchedAddressNamed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgAddressPartialUpdate: async (addressId: string, buyerOrgId: string, patchedAddressNamed?: PatchedAddressNamed, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('orgAddressPartialUpdate', 'addressId', addressId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgAddressPartialUpdate', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/address/{address_id}`
                .replace(`{${"address_id"}}`, encodeURIComponent(String(addressId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedAddressNamed, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of the found Address.
         * @summary Get the details of a single Address
         * @param {string} addressId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgAddressRetrieve: async (addressId: string, buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('orgAddressRetrieve', 'addressId', addressId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgAddressRetrieve', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/address/{address_id}`
                .replace(`{${"address_id"}}`, encodeURIComponent(String(addressId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the newly updated Address
         * @summary Update the address details
         * @param {string} addressId 
         * @param {string} buyerOrgId 
         * @param {AddressNamed} addressNamed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgAddressUpdate: async (addressId: string, buyerOrgId: string, addressNamed: AddressNamed, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('orgAddressUpdate', 'addressId', addressId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgAddressUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'addressNamed' is not null or undefined
            assertParamExists('orgAddressUpdate', 'addressNamed', addressNamed)
            const localVarPath = `/v2/org/{buyer_org_id}/address/{address_id}`
                .replace(`{${"address_id"}}`, encodeURIComponent(String(addressId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addressNamed, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationAddressApi - functional programming interface
 * @export
 */
export const OrganisationAddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationAddressApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates an new address against the Buyers Organisation
         * @summary Create an Address
         * @param {string} buyerOrgId 
         * @param {AddressNamed} addressNamed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgAddressCreate(buyerOrgId: string, addressNamed: AddressNamed, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgAddressCreate(buyerOrgId, addressNamed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an Address link from the Buyers Organisation
         * @summary Delete an Address
         * @param {string} addressId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgAddressDestroy(addressId: string, buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgAddressDestroy(addressId, buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a list of all the Addresses associated with the Organisation
         * @param {string} buyerOrgId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgAddressList(buyerOrgId: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAddressNamedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgAddressList(buyerOrgId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the newly updated Address
         * @summary Update the address details
         * @param {string} addressId 
         * @param {string} buyerOrgId 
         * @param {PatchedAddressNamed} [patchedAddressNamed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgAddressPartialUpdate(addressId: string, buyerOrgId: string, patchedAddressNamed?: PatchedAddressNamed, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressNamed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgAddressPartialUpdate(addressId, buyerOrgId, patchedAddressNamed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of the found Address.
         * @summary Get the details of a single Address
         * @param {string} addressId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgAddressRetrieve(addressId: string, buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressNamed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgAddressRetrieve(addressId, buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the newly updated Address
         * @summary Update the address details
         * @param {string} addressId 
         * @param {string} buyerOrgId 
         * @param {AddressNamed} addressNamed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgAddressUpdate(addressId: string, buyerOrgId: string, addressNamed: AddressNamed, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressNamed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgAddressUpdate(addressId, buyerOrgId, addressNamed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationAddressApi - factory interface
 * @export
 */
export const OrganisationAddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationAddressApiFp(configuration)
    return {
        /**
         * Creates an new address against the Buyers Organisation
         * @summary Create an Address
         * @param {string} buyerOrgId 
         * @param {AddressNamed} addressNamed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgAddressCreate(buyerOrgId: string, addressNamed: AddressNamed, options?: any): AxiosPromise<void> {
            return localVarFp.orgAddressCreate(buyerOrgId, addressNamed, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an Address link from the Buyers Organisation
         * @summary Delete an Address
         * @param {string} addressId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgAddressDestroy(addressId: string, buyerOrgId: string, options?: any): AxiosPromise<void> {
            return localVarFp.orgAddressDestroy(addressId, buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a list of all the Addresses associated with the Organisation
         * @param {string} buyerOrgId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgAddressList(buyerOrgId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedAddressNamedList> {
            return localVarFp.orgAddressList(buyerOrgId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the newly updated Address
         * @summary Update the address details
         * @param {string} addressId 
         * @param {string} buyerOrgId 
         * @param {PatchedAddressNamed} [patchedAddressNamed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgAddressPartialUpdate(addressId: string, buyerOrgId: string, patchedAddressNamed?: PatchedAddressNamed, options?: any): AxiosPromise<AddressNamed> {
            return localVarFp.orgAddressPartialUpdate(addressId, buyerOrgId, patchedAddressNamed, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of the found Address.
         * @summary Get the details of a single Address
         * @param {string} addressId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgAddressRetrieve(addressId: string, buyerOrgId: string, options?: any): AxiosPromise<AddressNamed> {
            return localVarFp.orgAddressRetrieve(addressId, buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the newly updated Address
         * @summary Update the address details
         * @param {string} addressId 
         * @param {string} buyerOrgId 
         * @param {AddressNamed} addressNamed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgAddressUpdate(addressId: string, buyerOrgId: string, addressNamed: AddressNamed, options?: any): AxiosPromise<AddressNamed> {
            return localVarFp.orgAddressUpdate(addressId, buyerOrgId, addressNamed, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgAddressCreate operation in OrganisationAddressApi.
 * @export
 * @interface OrganisationAddressApiOrgAddressCreateRequest
 */
export interface OrganisationAddressApiOrgAddressCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationAddressApiOrgAddressCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {AddressNamed}
     * @memberof OrganisationAddressApiOrgAddressCreate
     */
    readonly addressNamed: AddressNamed
}

/**
 * Request parameters for orgAddressDestroy operation in OrganisationAddressApi.
 * @export
 * @interface OrganisationAddressApiOrgAddressDestroyRequest
 */
export interface OrganisationAddressApiOrgAddressDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationAddressApiOrgAddressDestroy
     */
    readonly addressId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationAddressApiOrgAddressDestroy
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgAddressList operation in OrganisationAddressApi.
 * @export
 * @interface OrganisationAddressApiOrgAddressListRequest
 */
export interface OrganisationAddressApiOrgAddressListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationAddressApiOrgAddressList
     */
    readonly buyerOrgId: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof OrganisationAddressApiOrgAddressList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrganisationAddressApiOrgAddressList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for orgAddressPartialUpdate operation in OrganisationAddressApi.
 * @export
 * @interface OrganisationAddressApiOrgAddressPartialUpdateRequest
 */
export interface OrganisationAddressApiOrgAddressPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationAddressApiOrgAddressPartialUpdate
     */
    readonly addressId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationAddressApiOrgAddressPartialUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {PatchedAddressNamed}
     * @memberof OrganisationAddressApiOrgAddressPartialUpdate
     */
    readonly patchedAddressNamed?: PatchedAddressNamed
}

/**
 * Request parameters for orgAddressRetrieve operation in OrganisationAddressApi.
 * @export
 * @interface OrganisationAddressApiOrgAddressRetrieveRequest
 */
export interface OrganisationAddressApiOrgAddressRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationAddressApiOrgAddressRetrieve
     */
    readonly addressId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationAddressApiOrgAddressRetrieve
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgAddressUpdate operation in OrganisationAddressApi.
 * @export
 * @interface OrganisationAddressApiOrgAddressUpdateRequest
 */
export interface OrganisationAddressApiOrgAddressUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationAddressApiOrgAddressUpdate
     */
    readonly addressId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationAddressApiOrgAddressUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {AddressNamed}
     * @memberof OrganisationAddressApiOrgAddressUpdate
     */
    readonly addressNamed: AddressNamed
}

/**
 * OrganisationAddressApi - object-oriented interface
 * @export
 * @class OrganisationAddressApi
 * @extends {BaseAPI}
 */
export class OrganisationAddressApi extends BaseAPI {
    /**
     * Creates an new address against the Buyers Organisation
     * @summary Create an Address
     * @param {OrganisationAddressApiOrgAddressCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAddressApi
     */
    public orgAddressCreate(requestParameters: OrganisationAddressApiOrgAddressCreateRequest, options?: any) {
        return OrganisationAddressApiFp(this.configuration).orgAddressCreate(requestParameters.buyerOrgId, requestParameters.addressNamed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an Address link from the Buyers Organisation
     * @summary Delete an Address
     * @param {OrganisationAddressApiOrgAddressDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAddressApi
     */
    public orgAddressDestroy(requestParameters: OrganisationAddressApiOrgAddressDestroyRequest, options?: any) {
        return OrganisationAddressApiFp(this.configuration).orgAddressDestroy(requestParameters.addressId, requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a list of all the Addresses associated with the Organisation
     * @param {OrganisationAddressApiOrgAddressListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAddressApi
     */
    public orgAddressList(requestParameters: OrganisationAddressApiOrgAddressListRequest, options?: any) {
        return OrganisationAddressApiFp(this.configuration).orgAddressList(requestParameters.buyerOrgId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the newly updated Address
     * @summary Update the address details
     * @param {OrganisationAddressApiOrgAddressPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAddressApi
     */
    public orgAddressPartialUpdate(requestParameters: OrganisationAddressApiOrgAddressPartialUpdateRequest, options?: any) {
        return OrganisationAddressApiFp(this.configuration).orgAddressPartialUpdate(requestParameters.addressId, requestParameters.buyerOrgId, requestParameters.patchedAddressNamed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of the found Address.
     * @summary Get the details of a single Address
     * @param {OrganisationAddressApiOrgAddressRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAddressApi
     */
    public orgAddressRetrieve(requestParameters: OrganisationAddressApiOrgAddressRetrieveRequest, options?: any) {
        return OrganisationAddressApiFp(this.configuration).orgAddressRetrieve(requestParameters.addressId, requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the newly updated Address
     * @summary Update the address details
     * @param {OrganisationAddressApiOrgAddressUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAddressApi
     */
    public orgAddressUpdate(requestParameters: OrganisationAddressApiOrgAddressUpdateRequest, options?: any) {
        return OrganisationAddressApiFp(this.configuration).orgAddressUpdate(requestParameters.addressId, requestParameters.buyerOrgId, requestParameters.addressNamed, options).then((request) => request(this.axios, this.basePath));
    }
}
