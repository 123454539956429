/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PaginatedSaleyardReportList } from '../model';
// @ts-ignore
import { SaleyardReport } from '../model';
/**
 * SellerSaleyardReportApi - axios parameter creator
 * @export
 */
export const SellerSaleyardReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the list of active saleyard reports.  # Filtering Options  ## Fields - state str: Limit results to saleyard reports with the given state eg. ?state=WA - product str: Limit results to saleyard reports with the given product eg. ?product=CT  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of active saleyard reports
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [product] Internal ID of the product
         * @param {'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA'} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerSaleyardReportList: async (page?: number, pageSize?: number, product?: string, state?: 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/seller/saleyard-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of the found saleyard report.
         * @summary Get the details of a single saleyard report
         * @param {string} saleyardReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerSaleyardReportRetrieve: async (saleyardReportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleyardReportId' is not null or undefined
            assertParamExists('sellerSaleyardReportRetrieve', 'saleyardReportId', saleyardReportId)
            const localVarPath = `/v2/seller/saleyard-report/{saleyard_report_id}`
                .replace(`{${"saleyard_report_id"}}`, encodeURIComponent(String(saleyardReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerSaleyardReportApi - functional programming interface
 * @export
 */
export const SellerSaleyardReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerSaleyardReportApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the list of active saleyard reports.  # Filtering Options  ## Fields - state str: Limit results to saleyard reports with the given state eg. ?state=WA - product str: Limit results to saleyard reports with the given product eg. ?product=CT  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of active saleyard reports
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [product] Internal ID of the product
         * @param {'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA'} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerSaleyardReportList(page?: number, pageSize?: number, product?: string, state?: 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSaleyardReportList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerSaleyardReportList(page, pageSize, product, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of the found saleyard report.
         * @summary Get the details of a single saleyard report
         * @param {string} saleyardReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerSaleyardReportRetrieve(saleyardReportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaleyardReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerSaleyardReportRetrieve(saleyardReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SellerSaleyardReportApi - factory interface
 * @export
 */
export const SellerSaleyardReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerSaleyardReportApiFp(configuration)
    return {
        /**
         * Returns the list of active saleyard reports.  # Filtering Options  ## Fields - state str: Limit results to saleyard reports with the given state eg. ?state=WA - product str: Limit results to saleyard reports with the given product eg. ?product=CT  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of active saleyard reports
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [product] Internal ID of the product
         * @param {'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA'} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerSaleyardReportList(page?: number, pageSize?: number, product?: string, state?: 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA', options?: any): AxiosPromise<PaginatedSaleyardReportList> {
            return localVarFp.sellerSaleyardReportList(page, pageSize, product, state, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of the found saleyard report.
         * @summary Get the details of a single saleyard report
         * @param {string} saleyardReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerSaleyardReportRetrieve(saleyardReportId: string, options?: any): AxiosPromise<SaleyardReport> {
            return localVarFp.sellerSaleyardReportRetrieve(saleyardReportId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sellerSaleyardReportList operation in SellerSaleyardReportApi.
 * @export
 * @interface SellerSaleyardReportApiSellerSaleyardReportListRequest
 */
export interface SellerSaleyardReportApiSellerSaleyardReportListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof SellerSaleyardReportApiSellerSaleyardReportList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SellerSaleyardReportApiSellerSaleyardReportList
     */
    readonly pageSize?: number

    /**
     * Internal ID of the product
     * @type {string}
     * @memberof SellerSaleyardReportApiSellerSaleyardReportList
     */
    readonly product?: string

    /**
     * 
     * @type {'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA'}
     * @memberof SellerSaleyardReportApiSellerSaleyardReportList
     */
    readonly state?: 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA'
}

/**
 * Request parameters for sellerSaleyardReportRetrieve operation in SellerSaleyardReportApi.
 * @export
 * @interface SellerSaleyardReportApiSellerSaleyardReportRetrieveRequest
 */
export interface SellerSaleyardReportApiSellerSaleyardReportRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerSaleyardReportApiSellerSaleyardReportRetrieve
     */
    readonly saleyardReportId: string
}

/**
 * SellerSaleyardReportApi - object-oriented interface
 * @export
 * @class SellerSaleyardReportApi
 * @extends {BaseAPI}
 */
export class SellerSaleyardReportApi extends BaseAPI {
    /**
     * Returns the list of active saleyard reports.  # Filtering Options  ## Fields - state str: Limit results to saleyard reports with the given state eg. ?state=WA - product str: Limit results to saleyard reports with the given product eg. ?product=CT  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
     * @summary Get a list of active saleyard reports
     * @param {SellerSaleyardReportApiSellerSaleyardReportListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerSaleyardReportApi
     */
    public sellerSaleyardReportList(requestParameters: SellerSaleyardReportApiSellerSaleyardReportListRequest = {}, options?: any) {
        return SellerSaleyardReportApiFp(this.configuration).sellerSaleyardReportList(requestParameters.page, requestParameters.pageSize, requestParameters.product, requestParameters.state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of the found saleyard report.
     * @summary Get the details of a single saleyard report
     * @param {SellerSaleyardReportApiSellerSaleyardReportRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerSaleyardReportApi
     */
    public sellerSaleyardReportRetrieve(requestParameters: SellerSaleyardReportApiSellerSaleyardReportRetrieveRequest, options?: any) {
        return SellerSaleyardReportApiFp(this.configuration).sellerSaleyardReportRetrieve(requestParameters.saleyardReportId, options).then((request) => request(this.axios, this.basePath));
    }
}
