import { useNanoID } from 'hooks/use-nanoid';
import { FC, forwardRef } from 'react';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Checkbox, CheckboxProps } from '../checkbox/checkbox';
import { Text } from '../text';

export interface CheckboxCardProps extends CheckboxProps {
  description?: string;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: ${globalTheme.space[1]};
  align-items: center;
`;

const Card = styled.label`
  padding: ${globalTheme.space[1]};
  background-color: ${globalTheme.elements.checkboxCard.backgroundColor};
  border-radius: 8px;

  * {
    display: block;
  }
`;

export const CheckboxCard: FC<CheckboxCardProps> = forwardRef<
  HTMLInputElement,
  CheckboxCardProps
>(({ label, description, id: passedId, ...checkboxProps }, ref) => {
  const fallbackId = useNanoID();
  const id = passedId || `id${fallbackId}`;

  return (
    <Wrapper>
      <Checkbox id={id} ref={ref} {...checkboxProps} />
      <Card htmlFor={id}>
        <Text as='span' fontWeight='bold' fontSize={2}>
          {label}
        </Text>
        <Text as='span'>{description}</Text>
      </Card>
    </Wrapper>
  );
});
