import { SubscriptionPriceCard } from 'components/subscription-price-card';
import { ErrorMessage, Grid, Loader } from 'components/ui';
import { useSubscriptionPlans } from 'hooks/use-subscription-plans';
import {
  BillingCycleEnum,
  PlanTierEnum,
  SubscriptionPlan,
} from 'lib/api/generated';
import groupBy from 'lodash/groupBy';
import { FC, Fragment, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FREE_PLAN_FEATURES, PLANS } from '../../settings/config';
import { breakpoints } from '../../styles/breakpoints';
import { globalTheme } from '../../styles/global-theme';

const PlanWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${globalTheme.space[1]};

  @media screen and (max-width: ${breakpoints.large}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${breakpoints.medium}) {
    grid-template-columns: 1fr;
  }
`;

export interface PlanPickerProps {
  defaultBillingCycle?: BillingCycleEnum;
  defaultPlanTier?: PlanTierEnum;
  enableFrequencyChange?: boolean;
  disabledPlanId?: string;
  includeFreeTrial?: boolean;
  onChange?: (value?: SubscriptionPlan) => void;
  upgrade?: boolean;
  selectedTierPlans?: SubscriptionPlan[];
}

/** Format list of plans by selected billing cycle */
function getPlansBySelectedBillingCycle(
  plans: SubscriptionPlan[] | undefined,
  selectedBillingCycle: BillingCycleEnum
) {
  if (!plans) return [];

  // Group plans by plan tier
  const groupedPlans = groupBy(plans, 'plan_tier');

  // Manually organise plan tiers based on how they should appear
  const orderedPlans = [
    groupedPlans[PlanTierEnum.Base],
    groupedPlans[PlanTierEnum.Standard],
    groupedPlans[PlanTierEnum.Premium],
    groupedPlans[PlanTierEnum.Enterprise],
  ];

  // Finally return a list of plans based on the selected billing cycle
  return orderedPlans.map((planGroup) => {
    const selectedPlan = planGroup.find(
      (plan) => plan.billing_cycle === selectedBillingCycle
    );
    return selectedPlan;
  });
}

export const PlanPicker: FC<PlanPickerProps> = ({
  defaultBillingCycle,
  defaultPlanTier,
  disabledPlanId,
  onChange,
  upgrade,
  includeFreeTrial = true,
}) => {
  // The active plan billing cycle
  const [selectedBillingCycle, setSelectedBillingCycle] = useState(
    defaultBillingCycle || BillingCycleEnum.Monthly
  );

  // The selected plan
  const [selectedPlanTier, setSelectedPlanTier] = useState(defaultPlanTier);
  const [isFreeTierSelected, setIsFreeTierSelected] = useState<boolean>(
    selectedPlanTier === undefined
  );

  // Subscription plan data from the API
  const { data: plans, isLoading, isError } = useSubscriptionPlans();

  // Group plans by selected billing cycle
  const plansByBillingCycle = useMemo(
    () => getPlansBySelectedBillingCycle(plans, selectedBillingCycle),
    [plans, selectedBillingCycle]
  );

  if (isLoading) return <Loader />;

  if (isError)
    return <ErrorMessage>Error loading subscription plans</ErrorMessage>;

  return (
    <Grid justifyItems='center' gap={4}>
      <PlanWrap>
        <Fragment>
          {!upgrade && (
            <SubscriptionPriceCard
              name='Free Plan'
              features={FREE_PLAN_FEATURES}
              selected={isFreeTierSelected}
              selectable={true}
              onSelect={() => {
                setIsFreeTierSelected(true);
                setSelectedPlanTier(undefined);
                onChange?.(undefined);
              }}
            />
          )}
        </Fragment>

        {plansByBillingCycle.map((plan) => {
          const planFeatures = PLANS?.filter(
            (billingPlans) => billingPlans?.plan === plan?.plan_tier
          );

          const plansMapped = plans?.filter(
            (planM) => planM.plan_tier === plan?.plan_tier
          );

          return (
            <Fragment key={plan?.plan_id}>
              {plan && (
                <SubscriptionPriceCard
                  name={plan.name}
                  selected={
                    (plan.plan_tier === selectedPlanTier ||
                      (!selectedPlanTier &&
                        plan.plan_tier === PlanTierEnum.Base)) &&
                    !isFreeTierSelected
                  }
                  selectable={disabledPlanId !== plan.plan_id}
                  onSelect={() => {
                    setIsFreeTierSelected(false);
                    setSelectedPlanTier(plan.plan_tier);
                    onChange?.(plan);
                  }}
                  additionalFeatures={planFeatures}
                  selectedTierPlans={plansMapped}
                />
              )}
            </Fragment>
          );
        })}
      </PlanWrap>
    </Grid>
  );
};
