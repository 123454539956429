import {
  Menu,
  MenuButton,
  MenuItem,
  MenuLink,
  MenuList,
} from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import { Link } from 'components/link';
import { Icon, IconProps, LetterIcon, Text } from 'components/ui';
import { signOutCurrentUser } from 'lib/helpers';
import { useRouter } from 'next/router';
import { rgba } from 'polished';
import { FC } from 'react';
import { useIntercom } from 'react-use-intercom';
import { NAVBAR, REDIRECT_URL } from 'settings/config';
import styled, { css } from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { breakpoints } from '../../styles/breakpoints';

export type UserNavLink = {
  title: string;
  href: string;
  icon: IconProps['component'];
};

export interface UserNavProps {
  userName: string;
  links?: UserNavLink[];
  style?: 'light' | 'dark';
}

const Wrapper = styled.div`
  position: relative;
  z-index: ${globalTheme.zIndices.nav};
  display: inline-block;

  [data-reach-menu-popover] {
    top: calc(100% + ${globalTheme.space[0]});
    right: 0;
    display: block;
    min-width: 100%;
    transform: translateY(0);
    opacity: 1;
    transition: ${globalTheme.transitions.fast};

    &[hidden] {
      transform: translateY(-${globalTheme.space[0]});
      visibility: hidden;
      opacity: 0;
    }
  }
`;

const StyledMenuButton = styled(MenuButton)`
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
  min-width: 200px;
  height: 40px;
  margin-right: ${globalTheme.space[1]};
  color: ${globalTheme.elements.userNav.color};
  background-color: ${globalTheme.elements.userNav.backgroundColor};
  border: 0;
  border-radius: 40px;
  cursor: pointer;

  @media screen and (max-width: ${breakpoints.small}) {
    min-width: unset;
    grid-template-columns: 1fr;
  }
`;

const StyledText = styled(Text)`
  padding: 0 0 0 15px;

  @media screen and (max-width: ${breakpoints.small}) {
    display: none;
  }
`;

const StyledMenuList = styled(MenuList)`
  padding: 0;
  overflow: hidden;
  background-color: ${globalTheme.elements.userNav.listBackgroundColor};
  border: 0;
  border-radius: 8px;
  box-shadow: 0 8px 16px ${rgba(globalTheme.colors.darkGrey, 0.2)};
`;

const menuItemCommon = css`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: ${globalTheme.space[1]};
  align-items: center;
  height: ${globalTheme.space[6]};
  padding: 0 ${globalTheme.space[2]};
  color: ${globalTheme.elements.userNav.itemColor};

  &:hover,
  &[data-selected] {
    color: ${globalTheme.elements.userNav.itemHoverColor};
    background-color: ${globalTheme.elements.userNav.itemHoverBackgroundColor};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  ${menuItemCommon}
`;

const StyledMenuLink = styled(MenuLink)`
  ${menuItemCommon}
`;

export const UserNav: FC<UserNavProps> = ({ userName, links, style }) => {
  const router = useRouter();

  const handleMenuClick = (link: string) => {
    if ((link = '/organisation')) {
      localStorage.setItem(REDIRECT_URL, router.pathname);
    }
  };

  const { shutdown } = useIntercom();

  const logoutHandler = () => {
    signOutCurrentUser();
    localStorage.removeItem(NAVBAR);
    shutdown();
  };

  return (
    <Wrapper data-cy='userNavMenu'>
      <Menu>
        {() => (
          <>
            <StyledMenuButton data-cy='userNavName'>
              <StyledText>{userName}</StyledText>
              <LetterIcon
                letter={userName}
                color='white'
                backgroundColor={style === 'dark' ? 'darkBlue' : 'pink'}
                size={4}
                fontSize={1}
              />
            </StyledMenuButton>

            <StyledMenuList portal={false}>
              {links?.map((link) => (
                <Link href={link.href} passHref key={link.title}>
                  <StyledMenuItem onSelect={() => handleMenuClick(link.href)}>
                    <Icon component={link.icon} size={2} />
                    {link.title}
                  </StyledMenuItem>
                </Link>
              ))}
              <StyledMenuItem onSelect={logoutHandler} data-cy='userNavLogOut'>
                <Icon component='LogoutIcon' size={2} />
                Log Out
              </StyledMenuItem>
            </StyledMenuList>
          </>
        )}
      </Menu>
    </Wrapper>
  );
};
