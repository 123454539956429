import { Text } from 'components/ui';
import { motion } from 'framer-motion';
import { FC, forwardRef, useState } from 'react';
import styled from 'styled-components';
import { globalTheme } from '../../../styles/global-theme';
import { Icon } from '../icon';

interface TextToggleDisplayProps {
  mainText: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-left: ${globalTheme.space[1]};
  cursor: pointer;
`;

const MainText = styled(Text)`
  @media screen and (max-width: 768px) {
    font-size: ${globalTheme.space[2]};
  }
`;

export const TextToggleDisplay: FC<TextToggleDisplayProps> = forwardRef<
  HTMLDivElement,
  TextToggleDisplayProps
>((props, ref) => {
  const [expandActive, setExpandActive] = useState<boolean>(false);

  return (
    <div>
      <Wrapper>
        <MainText fontSize={2}>{props.mainText}</MainText>
        {(props?.children as any)?.props?.children &&
          (!expandActive ? (
            <IconWrapper onClick={() => setExpandActive(!expandActive)}>
              <Icon component={'ChevronDownIcon'} size={1} color={'darkBlue'} />
            </IconWrapper>
          ) : (
            <IconWrapper onClick={() => setExpandActive(!expandActive)}>
              <Icon component={'ChevronUpIcon'} size={1} color={'darkBlue'} />
            </IconWrapper>
          ))}
      </Wrapper>
      {expandActive && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          ref={ref}
        >
          {props.children}
        </motion.div>
      )}
    </div>
  );
});
