import { FC } from 'react';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Select, SelectProps } from '../select';
import { InputWrapper } from '../input';

const StyledSelect = styled(Select)`
  ${InputWrapper} {
    border-radius: ${globalTheme.elements.curvedInput.borderRadius};
  }
`;

export interface CurvedSelectProps extends SelectProps {}

export const CurvedSelect: FC<CurvedSelectProps> = (props) => (
  <StyledSelect {...props} />
);
