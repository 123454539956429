import { logger } from '@hatchd/utils';
import * as Sentry from '@sentry/nextjs';
import { compare } from 'compare-versions';
import { useEffect } from 'react';
import { useApiStatus } from './use-api-status';

const COMPATIBLE_VERSION = process.env.NEXT_PUBLIC_COMPATIBLE_API_VERSION;

/** Check the COMPATIBLE_API_VERSION environmental variable matches the current API */
export function useVersionMatch() {
  const { data: status } = useApiStatus();

  useEffect(() => {
    if (status?.version && COMPATIBLE_VERSION) {
      const isCompatible = compare(status.version, COMPATIBLE_VERSION, '=');

      if (!isCompatible) {
        logger.warn(
          '🐮 Incompatible Agora API Version detected, update the COMPATIBLE_API_VERSION environmental variable or check you are connected to the right API 🐮'
        );
        Sentry.captureMessage(
          `API Version mismatch, expected ${COMPATIBLE_VERSION} but got ${status.version}`,
          'warning'
        );
      }
    }
  }, [status?.version]);
}
