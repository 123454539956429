/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PaginatedSellerPriceGridListingList } from '../model';
// @ts-ignore
import { SellerPriceGridListing } from '../model';
// @ts-ignore
import { SellerPriceGridSearch } from '../model';
// @ts-ignore
import { SellerPriceGridSearchOrdering } from '../model';
/**
 * SellerPriceGridsSearchApi - axios parameter creator
 * @export
 */
export const SellerPriceGridsSearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of all the status=ACTIVE Price Grids which match the given search criteria  It is also filtered on the visibility of the grid.If it is is PUBLIC then everyone can see it.If it is CONTACTS only then only those who are approved contacts of the Buyer organisation can see it.  ## Filters See parameters below  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=updated` To reverse, simply place a hyphen in front. eg. `?ordering=-updated`  - updated: The last time the grid was updated (default: -updated = latest to oldest) - name: The buyer organisations name - price: The pricing range, default is highest first so it goes against the price_range_max field, when     -price then it goes via the min field     -When searching via this then those that are set to PRICE_RANGE will be at the top of the listing, even in both     -orders `price` and `-price`. Putting those that are POA and MARKET_RATE at the bottom.  The results by default are returned with the latest updated first.         
         * @summary Perform a search on the accessible price grids.
         * @param {SellerPriceGridSearch} sellerPriceGridSearch 
         * @param {'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date'} [ordering] Ordering
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridCreate: async (sellerPriceGridSearch: SellerPriceGridSearch, ordering?: 'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date', pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sellerPriceGridSearch' is not null or undefined
            assertParamExists('sellerGridCreate', 'sellerPriceGridSearch', sellerPriceGridSearch)
            const localVarPath = `/v2/seller/grid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerPriceGridSearch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of a price grid
         * @param {string} priceGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridRetrieve: async (priceGridId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'priceGridId' is not null or undefined
            assertParamExists('sellerGridRetrieve', 'priceGridId', priceGridId)
            const localVarPath = `/v2/seller/grid/{price_grid_id}`
                .replace(`{${"price_grid_id"}}`, encodeURIComponent(String(priceGridId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all the status=ACTIVE Price Grids which match the given search criteria  It is also filtered on the visibility of the grid.If it is is PUBLIC then everyone can see it.If it is CONTACTS only then only those who are approved contacts of the Buyer organisation can see it.  ## Filters See parameters below  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=updated` To reverse, simply place a hyphen in front. eg. `?ordering=-updated`  - updated: The last time the grid was updated (default: -updated = latest to oldest) - name: The buyer organisations name - price: The pricing range, default is highest first so it goes against the price_range_max field, when     -price then it goes via the min field     -When searching via this then those that are set to PRICE_RANGE will be at the top of the listing, even in both     -orders `price` and `-price`. Putting those that are POA and MARKET_RATE at the bottom.  The results by default are returned with the latest updated first.         
         * @summary Perform a search on the accessible price grids.
         * @param {SellerPriceGridSearch} sellerPriceGridSearch 
         * @param {'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date'} [ordering] Ordering
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchCreate: async (sellerPriceGridSearch: SellerPriceGridSearch, ordering?: 'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date', pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sellerPriceGridSearch' is not null or undefined
            assertParamExists('sellerGridSearchCreate', 'sellerPriceGridSearch', sellerPriceGridSearch)
            const localVarPath = `/v2/seller/grid/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerPriceGridSearch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The view behaves in the same manner as the search listing but it returns only those who the user has bookmarked
         * @summary Returns the prices the user has bookmarked
         * @param {'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date'} [ordering] Ordering
         * @param {number} [pageSize] Number of results to return per page.
         * @param {SellerPriceGridSearchOrdering} [sellerPriceGridSearchOrdering] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchMyPricesCreate: async (ordering?: 'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date', pageSize?: number, sellerPriceGridSearchOrdering?: SellerPriceGridSearchOrdering, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/seller/grid/search/my-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerPriceGridSearchOrdering, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of a price grid
         * @param {string} priceGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchRetrieve: async (priceGridId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'priceGridId' is not null or undefined
            assertParamExists('sellerGridSearchRetrieve', 'priceGridId', priceGridId)
            const localVarPath = `/v2/seller/grid/search/{price_grid_id}`
                .replace(`{${"price_grid_id"}}`, encodeURIComponent(String(priceGridId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerPriceGridsSearchApi - functional programming interface
 * @export
 */
export const SellerPriceGridsSearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerPriceGridsSearchApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of all the status=ACTIVE Price Grids which match the given search criteria  It is also filtered on the visibility of the grid.If it is is PUBLIC then everyone can see it.If it is CONTACTS only then only those who are approved contacts of the Buyer organisation can see it.  ## Filters See parameters below  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=updated` To reverse, simply place a hyphen in front. eg. `?ordering=-updated`  - updated: The last time the grid was updated (default: -updated = latest to oldest) - name: The buyer organisations name - price: The pricing range, default is highest first so it goes against the price_range_max field, when     -price then it goes via the min field     -When searching via this then those that are set to PRICE_RANGE will be at the top of the listing, even in both     -orders `price` and `-price`. Putting those that are POA and MARKET_RATE at the bottom.  The results by default are returned with the latest updated first.         
         * @summary Perform a search on the accessible price grids.
         * @param {SellerPriceGridSearch} sellerPriceGridSearch 
         * @param {'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date'} [ordering] Ordering
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerGridCreate(sellerPriceGridSearch: SellerPriceGridSearch, ordering?: 'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date', pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSellerPriceGridListingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerGridCreate(sellerPriceGridSearch, ordering, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of a price grid
         * @param {string} priceGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerGridRetrieve(priceGridId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerPriceGridListing>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerGridRetrieve(priceGridId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of all the status=ACTIVE Price Grids which match the given search criteria  It is also filtered on the visibility of the grid.If it is is PUBLIC then everyone can see it.If it is CONTACTS only then only those who are approved contacts of the Buyer organisation can see it.  ## Filters See parameters below  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=updated` To reverse, simply place a hyphen in front. eg. `?ordering=-updated`  - updated: The last time the grid was updated (default: -updated = latest to oldest) - name: The buyer organisations name - price: The pricing range, default is highest first so it goes against the price_range_max field, when     -price then it goes via the min field     -When searching via this then those that are set to PRICE_RANGE will be at the top of the listing, even in both     -orders `price` and `-price`. Putting those that are POA and MARKET_RATE at the bottom.  The results by default are returned with the latest updated first.         
         * @summary Perform a search on the accessible price grids.
         * @param {SellerPriceGridSearch} sellerPriceGridSearch 
         * @param {'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date'} [ordering] Ordering
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerGridSearchCreate(sellerPriceGridSearch: SellerPriceGridSearch, ordering?: 'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date', pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSellerPriceGridListingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerGridSearchCreate(sellerPriceGridSearch, ordering, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The view behaves in the same manner as the search listing but it returns only those who the user has bookmarked
         * @summary Returns the prices the user has bookmarked
         * @param {'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date'} [ordering] Ordering
         * @param {number} [pageSize] Number of results to return per page.
         * @param {SellerPriceGridSearchOrdering} [sellerPriceGridSearchOrdering] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerGridSearchMyPricesCreate(ordering?: 'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date', pageSize?: number, sellerPriceGridSearchOrdering?: SellerPriceGridSearchOrdering, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSellerPriceGridListingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerGridSearchMyPricesCreate(ordering, pageSize, sellerPriceGridSearchOrdering, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of a price grid
         * @param {string} priceGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerGridSearchRetrieve(priceGridId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerPriceGridListing>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerGridSearchRetrieve(priceGridId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SellerPriceGridsSearchApi - factory interface
 * @export
 */
export const SellerPriceGridsSearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerPriceGridsSearchApiFp(configuration)
    return {
        /**
         * Returns a list of all the status=ACTIVE Price Grids which match the given search criteria  It is also filtered on the visibility of the grid.If it is is PUBLIC then everyone can see it.If it is CONTACTS only then only those who are approved contacts of the Buyer organisation can see it.  ## Filters See parameters below  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=updated` To reverse, simply place a hyphen in front. eg. `?ordering=-updated`  - updated: The last time the grid was updated (default: -updated = latest to oldest) - name: The buyer organisations name - price: The pricing range, default is highest first so it goes against the price_range_max field, when     -price then it goes via the min field     -When searching via this then those that are set to PRICE_RANGE will be at the top of the listing, even in both     -orders `price` and `-price`. Putting those that are POA and MARKET_RATE at the bottom.  The results by default are returned with the latest updated first.         
         * @summary Perform a search on the accessible price grids.
         * @param {SellerPriceGridSearch} sellerPriceGridSearch 
         * @param {'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date'} [ordering] Ordering
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridCreate(sellerPriceGridSearch: SellerPriceGridSearch, ordering?: 'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date', pageSize?: number, options?: any): AxiosPromise<PaginatedSellerPriceGridListingList> {
            return localVarFp.sellerGridCreate(sellerPriceGridSearch, ordering, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of a price grid
         * @param {string} priceGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridRetrieve(priceGridId: string, options?: any): AxiosPromise<SellerPriceGridListing> {
            return localVarFp.sellerGridRetrieve(priceGridId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all the status=ACTIVE Price Grids which match the given search criteria  It is also filtered on the visibility of the grid.If it is is PUBLIC then everyone can see it.If it is CONTACTS only then only those who are approved contacts of the Buyer organisation can see it.  ## Filters See parameters below  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=updated` To reverse, simply place a hyphen in front. eg. `?ordering=-updated`  - updated: The last time the grid was updated (default: -updated = latest to oldest) - name: The buyer organisations name - price: The pricing range, default is highest first so it goes against the price_range_max field, when     -price then it goes via the min field     -When searching via this then those that are set to PRICE_RANGE will be at the top of the listing, even in both     -orders `price` and `-price`. Putting those that are POA and MARKET_RATE at the bottom.  The results by default are returned with the latest updated first.         
         * @summary Perform a search on the accessible price grids.
         * @param {SellerPriceGridSearch} sellerPriceGridSearch 
         * @param {'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date'} [ordering] Ordering
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchCreate(sellerPriceGridSearch: SellerPriceGridSearch, ordering?: 'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date', pageSize?: number, options?: any): AxiosPromise<PaginatedSellerPriceGridListingList> {
            return localVarFp.sellerGridSearchCreate(sellerPriceGridSearch, ordering, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * The view behaves in the same manner as the search listing but it returns only those who the user has bookmarked
         * @summary Returns the prices the user has bookmarked
         * @param {'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date'} [ordering] Ordering
         * @param {number} [pageSize] Number of results to return per page.
         * @param {SellerPriceGridSearchOrdering} [sellerPriceGridSearchOrdering] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchMyPricesCreate(ordering?: 'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date', pageSize?: number, sellerPriceGridSearchOrdering?: SellerPriceGridSearchOrdering, options?: any): AxiosPromise<PaginatedSellerPriceGridListingList> {
            return localVarFp.sellerGridSearchMyPricesCreate(ordering, pageSize, sellerPriceGridSearchOrdering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of a price grid
         * @param {string} priceGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchRetrieve(priceGridId: string, options?: any): AxiosPromise<SellerPriceGridListing> {
            return localVarFp.sellerGridSearchRetrieve(priceGridId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sellerGridCreate operation in SellerPriceGridsSearchApi.
 * @export
 * @interface SellerPriceGridsSearchApiSellerGridCreateRequest
 */
export interface SellerPriceGridsSearchApiSellerGridCreateRequest {
    /**
     * 
     * @type {SellerPriceGridSearch}
     * @memberof SellerPriceGridsSearchApiSellerGridCreate
     */
    readonly sellerPriceGridSearch: SellerPriceGridSearch

    /**
     * Ordering
     * @type {'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date'}
     * @memberof SellerPriceGridsSearchApiSellerGridCreate
     */
    readonly ordering?: 'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date'

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SellerPriceGridsSearchApiSellerGridCreate
     */
    readonly pageSize?: number
}

/**
 * Request parameters for sellerGridRetrieve operation in SellerPriceGridsSearchApi.
 * @export
 * @interface SellerPriceGridsSearchApiSellerGridRetrieveRequest
 */
export interface SellerPriceGridsSearchApiSellerGridRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerPriceGridsSearchApiSellerGridRetrieve
     */
    readonly priceGridId: string
}

/**
 * Request parameters for sellerGridSearchCreate operation in SellerPriceGridsSearchApi.
 * @export
 * @interface SellerPriceGridsSearchApiSellerGridSearchCreateRequest
 */
export interface SellerPriceGridsSearchApiSellerGridSearchCreateRequest {
    /**
     * 
     * @type {SellerPriceGridSearch}
     * @memberof SellerPriceGridsSearchApiSellerGridSearchCreate
     */
    readonly sellerPriceGridSearch: SellerPriceGridSearch

    /**
     * Ordering
     * @type {'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date'}
     * @memberof SellerPriceGridsSearchApiSellerGridSearchCreate
     */
    readonly ordering?: 'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date'

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SellerPriceGridsSearchApiSellerGridSearchCreate
     */
    readonly pageSize?: number
}

/**
 * Request parameters for sellerGridSearchMyPricesCreate operation in SellerPriceGridsSearchApi.
 * @export
 * @interface SellerPriceGridsSearchApiSellerGridSearchMyPricesCreateRequest
 */
export interface SellerPriceGridsSearchApiSellerGridSearchMyPricesCreateRequest {
    /**
     * Ordering
     * @type {'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date'}
     * @memberof SellerPriceGridsSearchApiSellerGridSearchMyPricesCreate
     */
    readonly ordering?: 'updated' | '-updated' | 'name' | '-name' | 'price' | '-price' | 'delivery_date' | '-delivery_date'

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SellerPriceGridsSearchApiSellerGridSearchMyPricesCreate
     */
    readonly pageSize?: number

    /**
     * 
     * @type {SellerPriceGridSearchOrdering}
     * @memberof SellerPriceGridsSearchApiSellerGridSearchMyPricesCreate
     */
    readonly sellerPriceGridSearchOrdering?: SellerPriceGridSearchOrdering
}

/**
 * Request parameters for sellerGridSearchRetrieve operation in SellerPriceGridsSearchApi.
 * @export
 * @interface SellerPriceGridsSearchApiSellerGridSearchRetrieveRequest
 */
export interface SellerPriceGridsSearchApiSellerGridSearchRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerPriceGridsSearchApiSellerGridSearchRetrieve
     */
    readonly priceGridId: string
}

/**
 * SellerPriceGridsSearchApi - object-oriented interface
 * @export
 * @class SellerPriceGridsSearchApi
 * @extends {BaseAPI}
 */
export class SellerPriceGridsSearchApi extends BaseAPI {
    /**
     * Returns a list of all the status=ACTIVE Price Grids which match the given search criteria  It is also filtered on the visibility of the grid.If it is is PUBLIC then everyone can see it.If it is CONTACTS only then only those who are approved contacts of the Buyer organisation can see it.  ## Filters See parameters below  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=updated` To reverse, simply place a hyphen in front. eg. `?ordering=-updated`  - updated: The last time the grid was updated (default: -updated = latest to oldest) - name: The buyer organisations name - price: The pricing range, default is highest first so it goes against the price_range_max field, when     -price then it goes via the min field     -When searching via this then those that are set to PRICE_RANGE will be at the top of the listing, even in both     -orders `price` and `-price`. Putting those that are POA and MARKET_RATE at the bottom.  The results by default are returned with the latest updated first.         
     * @summary Perform a search on the accessible price grids.
     * @param {SellerPriceGridsSearchApiSellerGridCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerPriceGridsSearchApi
     */
    public sellerGridCreate(requestParameters: SellerPriceGridsSearchApiSellerGridCreateRequest, options?: any) {
        return SellerPriceGridsSearchApiFp(this.configuration).sellerGridCreate(requestParameters.sellerPriceGridSearch, requestParameters.ordering, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of a price grid
     * @param {SellerPriceGridsSearchApiSellerGridRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerPriceGridsSearchApi
     */
    public sellerGridRetrieve(requestParameters: SellerPriceGridsSearchApiSellerGridRetrieveRequest, options?: any) {
        return SellerPriceGridsSearchApiFp(this.configuration).sellerGridRetrieve(requestParameters.priceGridId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all the status=ACTIVE Price Grids which match the given search criteria  It is also filtered on the visibility of the grid.If it is is PUBLIC then everyone can see it.If it is CONTACTS only then only those who are approved contacts of the Buyer organisation can see it.  ## Filters See parameters below  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=updated` To reverse, simply place a hyphen in front. eg. `?ordering=-updated`  - updated: The last time the grid was updated (default: -updated = latest to oldest) - name: The buyer organisations name - price: The pricing range, default is highest first so it goes against the price_range_max field, when     -price then it goes via the min field     -When searching via this then those that are set to PRICE_RANGE will be at the top of the listing, even in both     -orders `price` and `-price`. Putting those that are POA and MARKET_RATE at the bottom.  The results by default are returned with the latest updated first.         
     * @summary Perform a search on the accessible price grids.
     * @param {SellerPriceGridsSearchApiSellerGridSearchCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerPriceGridsSearchApi
     */
    public sellerGridSearchCreate(requestParameters: SellerPriceGridsSearchApiSellerGridSearchCreateRequest, options?: any) {
        return SellerPriceGridsSearchApiFp(this.configuration).sellerGridSearchCreate(requestParameters.sellerPriceGridSearch, requestParameters.ordering, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The view behaves in the same manner as the search listing but it returns only those who the user has bookmarked
     * @summary Returns the prices the user has bookmarked
     * @param {SellerPriceGridsSearchApiSellerGridSearchMyPricesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerPriceGridsSearchApi
     */
    public sellerGridSearchMyPricesCreate(requestParameters: SellerPriceGridsSearchApiSellerGridSearchMyPricesCreateRequest = {}, options?: any) {
        return SellerPriceGridsSearchApiFp(this.configuration).sellerGridSearchMyPricesCreate(requestParameters.ordering, requestParameters.pageSize, requestParameters.sellerPriceGridSearchOrdering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of a price grid
     * @param {SellerPriceGridsSearchApiSellerGridSearchRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerPriceGridsSearchApi
     */
    public sellerGridSearchRetrieve(requestParameters: SellerPriceGridsSearchApiSellerGridSearchRetrieveRequest, options?: any) {
        return SellerPriceGridsSearchApiFp(this.configuration).sellerGridSearchRetrieve(requestParameters.priceGridId, options).then((request) => request(this.axios, this.basePath));
    }
}
