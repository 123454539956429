import { CSSProperties } from 'react';
import styled, { CSSObject } from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Box, BoxProps } from '../box';

export interface TextProps extends BoxProps {
  color?: keyof typeof globalTheme.colors;
  fontSize?: keyof typeof globalTheme.fontSizes;
  fontWeight?: keyof typeof globalTheme.fontWeights;
  lineHeight?: keyof typeof globalTheme.lineHeights;
  textAlign?: CSSProperties['textAlign'];
  fontStyle?: CSSProperties['fontStyle'];
  textDecoration?: CSSProperties['textDecoration'];
  className?: string;
  preWrap?: boolean;
  opacity?: CSSProperties['opacity'];
  textTransform?: CSSProperties['textTransform'];
}

const getPropStyles = (props: TextProps): CSSObject => {
  return {
    color: (props.color && globalTheme.colors[props.color]) || 'inherit',
    fontWeight: ((props.fontWeight &&
      globalTheme.fontWeights[props.fontWeight]) ||
      globalTheme.fontWeights.regular) as CSSProperties['fontWeight'],
    fontSize:
      (props.fontSize !== undefined && globalTheme.fontSizes[props.fontSize]) ||
      globalTheme.fontSizes.default,
    fontStyle: props.fontStyle,
    textAlign: props.textAlign,
    textDecoration: props.textDecoration,
    whiteSpace: props.preWrap ? 'pre-wrap' : 'normal',
    lineHeight:
      props.lineHeight !== undefined
        ? globalTheme.lineHeights[props.lineHeight]
        : globalTheme.lineHeights['body'],
    opacity: props.opacity,
    textTransform: props.textTransform,
  };
};

export const Text = styled(Box)<TextProps>`
  ${getPropStyles}

  border: 0;
`;

export const EllipsisText = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

Text.defaultProps = {
  // @ts-ignore
  as: 'p',
};

EllipsisText.defaultProps = {
  // @ts-ignore
  as: 'p',
};
