import { useNanoID } from 'hooks/use-nanoid';
import { ButtonHTMLAttributes, ElementType, FC, forwardRef } from 'react';
import styled, { css, CSSObject } from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Icon, IconProps } from '../icon';
import { Text } from '../text';

export interface IconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconProps['component'];
  buttonColor?: keyof typeof globalTheme.colors | false;
  iconColor?: IconProps['color'];
  size?: keyof typeof globalTheme.space;
  text?: string;
  textLeft?: boolean;
  buttonAs?: ElementType;
}

const getPropStyles = (props: Omit<IconButtonProps, 'icon'>): CSSObject => {
  const size = props.size !== undefined ? globalTheme.space[props.size] : '1em';

  return {
    width: size,
    height: size,
    backgroundColor:
      props.buttonColor !== false && props.buttonColor !== undefined
        ? globalTheme.colors[props.buttonColor]
        : 'transparent',
  };
};

const IconButtonStyled = styled.button`
  /* Icon size */
  --icon-size: 100%;

  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 100%;
  cursor: pointer;

  ${getPropStyles}

  /* Tappable area */
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.25;

    + ${Text} {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  ${Icon} {
    width: var(--icon-size);
    height: var(--icon-size);
  }

  ${(props) =>
    props.buttonColor !== false &&
    css`
      /* Third icon size when there's a background */
      --icon-size: calc(100% / 3);
    `}
`;

const Wrapper = styled.div<{ textLeft?: boolean }>`
  position: relative;
  display: inline-flex;
  gap: 8px;
  align-items: center;

  ${IconButtonStyled} {
    ${(p) => (p.textLeft ? `order: 1` : `order: 0`)}
  }

  ${Text} {
    ${(p) => (p.textLeft ? `order: 0` : `order: 1`)}
  }
`;

const IconButtonBase: FC<IconButtonProps> = forwardRef<
  HTMLDivElement,
  IconButtonProps
>(({ icon, iconColor, text, textLeft, className, buttonAs, ...props }, ref) => {
  const id = useNanoID();

  return (
    <Wrapper className={className} ref={ref} textLeft={textLeft}>
      <IconButtonStyled aria-labelledby={text && id} as={buttonAs} {...props}>
        <Icon component={icon} color={iconColor} />
      </IconButtonStyled>
      {text && (
        <Text id={id} as='span'>
          {text}
        </Text>
      )}
    </Wrapper>
  );
});

export const IconButton = styled(IconButtonBase)`
  /* Icon button */
`;
