/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PaginatedSavedSearchList } from '../model';
// @ts-ignore
import { PatchedSavedSearch } from '../model';
// @ts-ignore
import { SavedSearch } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * SellerPriceGridsSearchSavedApi - axios parameter creator
 * @export
 */
export const SellerPriceGridsSearchSavedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the created Saved Search
         * @summary Create a new Saved Search
         * @param {SavedSearch} savedSearch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchSavedCreate: async (savedSearch: SavedSearch, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'savedSearch' is not null or undefined
            assertParamExists('sellerGridSearchSavedCreate', 'savedSearch', savedSearch)
            const localVarPath = `/v2/seller/grid/search/saved`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savedSearch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a Saved Search
         * @param {string} savedSearchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchSavedDestroy: async (savedSearchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'savedSearchId' is not null or undefined
            assertParamExists('sellerGridSearchSavedDestroy', 'savedSearchId', savedSearchId)
            const localVarPath = `/v2/seller/grid/search/saved/{saved_search_id}`
                .replace(`{${"saved_search_id"}}`, encodeURIComponent(String(savedSearchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of the Saved Searches created by the user.  # Filtering Options   ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=name` To reverse, simply place a hypen in front. eg. `?ordering-name`  - name: The name of the saved search - last_updated: When the item was last updated  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isnt one     previous: null <-- previous page number, or null if there isnt one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of the current Saved Searches for the user
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchSavedList: async (ordering?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/seller/grid/search/saved`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the newly updated details of the Saved Search.
         * @summary Update the details of the Saved Search
         * @param {string} savedSearchId 
         * @param {PatchedSavedSearch} [patchedSavedSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchSavedPartialUpdate: async (savedSearchId: string, patchedSavedSearch?: PatchedSavedSearch, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'savedSearchId' is not null or undefined
            assertParamExists('sellerGridSearchSavedPartialUpdate', 'savedSearchId', savedSearchId)
            const localVarPath = `/v2/seller/grid/search/saved/{saved_search_id}`
                .replace(`{${"saved_search_id"}}`, encodeURIComponent(String(savedSearchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedSavedSearch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the detailed information about the Saved Search
         * @param {string} savedSearchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchSavedRetrieve: async (savedSearchId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'savedSearchId' is not null or undefined
            assertParamExists('sellerGridSearchSavedRetrieve', 'savedSearchId', savedSearchId)
            const localVarPath = `/v2/seller/grid/search/saved/{saved_search_id}`
                .replace(`{${"saved_search_id"}}`, encodeURIComponent(String(savedSearchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerPriceGridsSearchSavedApi - functional programming interface
 * @export
 */
export const SellerPriceGridsSearchSavedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerPriceGridsSearchSavedApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the created Saved Search
         * @summary Create a new Saved Search
         * @param {SavedSearch} savedSearch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerGridSearchSavedCreate(savedSearch: SavedSearch, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedSearch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerGridSearchSavedCreate(savedSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a Saved Search
         * @param {string} savedSearchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerGridSearchSavedDestroy(savedSearchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerGridSearchSavedDestroy(savedSearchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of the Saved Searches created by the user.  # Filtering Options   ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=name` To reverse, simply place a hypen in front. eg. `?ordering-name`  - name: The name of the saved search - last_updated: When the item was last updated  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isnt one     previous: null <-- previous page number, or null if there isnt one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of the current Saved Searches for the user
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerGridSearchSavedList(ordering?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSavedSearchList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerGridSearchSavedList(ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the newly updated details of the Saved Search.
         * @summary Update the details of the Saved Search
         * @param {string} savedSearchId 
         * @param {PatchedSavedSearch} [patchedSavedSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerGridSearchSavedPartialUpdate(savedSearchId: string, patchedSavedSearch?: PatchedSavedSearch, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedSearch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerGridSearchSavedPartialUpdate(savedSearchId, patchedSavedSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the detailed information about the Saved Search
         * @param {string} savedSearchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerGridSearchSavedRetrieve(savedSearchId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedSearch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerGridSearchSavedRetrieve(savedSearchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SellerPriceGridsSearchSavedApi - factory interface
 * @export
 */
export const SellerPriceGridsSearchSavedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerPriceGridsSearchSavedApiFp(configuration)
    return {
        /**
         * Returns the created Saved Search
         * @summary Create a new Saved Search
         * @param {SavedSearch} savedSearch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchSavedCreate(savedSearch: SavedSearch, options?: any): AxiosPromise<SavedSearch> {
            return localVarFp.sellerGridSearchSavedCreate(savedSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a Saved Search
         * @param {string} savedSearchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchSavedDestroy(savedSearchId: string, options?: any): AxiosPromise<void> {
            return localVarFp.sellerGridSearchSavedDestroy(savedSearchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of the Saved Searches created by the user.  # Filtering Options   ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=name` To reverse, simply place a hypen in front. eg. `?ordering-name`  - name: The name of the saved search - last_updated: When the item was last updated  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isnt one     previous: null <-- previous page number, or null if there isnt one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of the current Saved Searches for the user
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchSavedList(ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedSavedSearchList> {
            return localVarFp.sellerGridSearchSavedList(ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the newly updated details of the Saved Search.
         * @summary Update the details of the Saved Search
         * @param {string} savedSearchId 
         * @param {PatchedSavedSearch} [patchedSavedSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchSavedPartialUpdate(savedSearchId: string, patchedSavedSearch?: PatchedSavedSearch, options?: any): AxiosPromise<SavedSearch> {
            return localVarFp.sellerGridSearchSavedPartialUpdate(savedSearchId, patchedSavedSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the detailed information about the Saved Search
         * @param {string} savedSearchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridSearchSavedRetrieve(savedSearchId: string, options?: any): AxiosPromise<SavedSearch> {
            return localVarFp.sellerGridSearchSavedRetrieve(savedSearchId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sellerGridSearchSavedCreate operation in SellerPriceGridsSearchSavedApi.
 * @export
 * @interface SellerPriceGridsSearchSavedApiSellerGridSearchSavedCreateRequest
 */
export interface SellerPriceGridsSearchSavedApiSellerGridSearchSavedCreateRequest {
    /**
     * 
     * @type {SavedSearch}
     * @memberof SellerPriceGridsSearchSavedApiSellerGridSearchSavedCreate
     */
    readonly savedSearch: SavedSearch
}

/**
 * Request parameters for sellerGridSearchSavedDestroy operation in SellerPriceGridsSearchSavedApi.
 * @export
 * @interface SellerPriceGridsSearchSavedApiSellerGridSearchSavedDestroyRequest
 */
export interface SellerPriceGridsSearchSavedApiSellerGridSearchSavedDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerPriceGridsSearchSavedApiSellerGridSearchSavedDestroy
     */
    readonly savedSearchId: string
}

/**
 * Request parameters for sellerGridSearchSavedList operation in SellerPriceGridsSearchSavedApi.
 * @export
 * @interface SellerPriceGridsSearchSavedApiSellerGridSearchSavedListRequest
 */
export interface SellerPriceGridsSearchSavedApiSellerGridSearchSavedListRequest {
    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SellerPriceGridsSearchSavedApiSellerGridSearchSavedList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof SellerPriceGridsSearchSavedApiSellerGridSearchSavedList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SellerPriceGridsSearchSavedApiSellerGridSearchSavedList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for sellerGridSearchSavedPartialUpdate operation in SellerPriceGridsSearchSavedApi.
 * @export
 * @interface SellerPriceGridsSearchSavedApiSellerGridSearchSavedPartialUpdateRequest
 */
export interface SellerPriceGridsSearchSavedApiSellerGridSearchSavedPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerPriceGridsSearchSavedApiSellerGridSearchSavedPartialUpdate
     */
    readonly savedSearchId: string

    /**
     * 
     * @type {PatchedSavedSearch}
     * @memberof SellerPriceGridsSearchSavedApiSellerGridSearchSavedPartialUpdate
     */
    readonly patchedSavedSearch?: PatchedSavedSearch
}

/**
 * Request parameters for sellerGridSearchSavedRetrieve operation in SellerPriceGridsSearchSavedApi.
 * @export
 * @interface SellerPriceGridsSearchSavedApiSellerGridSearchSavedRetrieveRequest
 */
export interface SellerPriceGridsSearchSavedApiSellerGridSearchSavedRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerPriceGridsSearchSavedApiSellerGridSearchSavedRetrieve
     */
    readonly savedSearchId: string
}

/**
 * SellerPriceGridsSearchSavedApi - object-oriented interface
 * @export
 * @class SellerPriceGridsSearchSavedApi
 * @extends {BaseAPI}
 */
export class SellerPriceGridsSearchSavedApi extends BaseAPI {
    /**
     * Returns the created Saved Search
     * @summary Create a new Saved Search
     * @param {SellerPriceGridsSearchSavedApiSellerGridSearchSavedCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerPriceGridsSearchSavedApi
     */
    public sellerGridSearchSavedCreate(requestParameters: SellerPriceGridsSearchSavedApiSellerGridSearchSavedCreateRequest, options?: any) {
        return SellerPriceGridsSearchSavedApiFp(this.configuration).sellerGridSearchSavedCreate(requestParameters.savedSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a Saved Search
     * @param {SellerPriceGridsSearchSavedApiSellerGridSearchSavedDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerPriceGridsSearchSavedApi
     */
    public sellerGridSearchSavedDestroy(requestParameters: SellerPriceGridsSearchSavedApiSellerGridSearchSavedDestroyRequest, options?: any) {
        return SellerPriceGridsSearchSavedApiFp(this.configuration).sellerGridSearchSavedDestroy(requestParameters.savedSearchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of the Saved Searches created by the user.  # Filtering Options   ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=name` To reverse, simply place a hypen in front. eg. `?ordering-name`  - name: The name of the saved search - last_updated: When the item was last updated  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isnt one     previous: null <-- previous page number, or null if there isnt one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
     * @summary Get a list of the current Saved Searches for the user
     * @param {SellerPriceGridsSearchSavedApiSellerGridSearchSavedListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerPriceGridsSearchSavedApi
     */
    public sellerGridSearchSavedList(requestParameters: SellerPriceGridsSearchSavedApiSellerGridSearchSavedListRequest = {}, options?: any) {
        return SellerPriceGridsSearchSavedApiFp(this.configuration).sellerGridSearchSavedList(requestParameters.ordering, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the newly updated details of the Saved Search.
     * @summary Update the details of the Saved Search
     * @param {SellerPriceGridsSearchSavedApiSellerGridSearchSavedPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerPriceGridsSearchSavedApi
     */
    public sellerGridSearchSavedPartialUpdate(requestParameters: SellerPriceGridsSearchSavedApiSellerGridSearchSavedPartialUpdateRequest, options?: any) {
        return SellerPriceGridsSearchSavedApiFp(this.configuration).sellerGridSearchSavedPartialUpdate(requestParameters.savedSearchId, requestParameters.patchedSavedSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the detailed information about the Saved Search
     * @param {SellerPriceGridsSearchSavedApiSellerGridSearchSavedRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerPriceGridsSearchSavedApi
     */
    public sellerGridSearchSavedRetrieve(requestParameters: SellerPriceGridsSearchSavedApiSellerGridSearchSavedRetrieveRequest, options?: any) {
        return SellerPriceGridsSearchSavedApiFp(this.configuration).sellerGridSearchSavedRetrieve(requestParameters.savedSearchId, options).then((request) => request(this.axios, this.basePath));
    }
}
