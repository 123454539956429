import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';

export const TextButton = styled.button`
  width: auto;
  color: ${globalTheme.colors.blue};
  font-size: ${globalTheme.fontSizes.default};
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    text-decoration: none;
    cursor: no-drop;
    opacity: 0.5;
  }
`;
