import styled, { CSSObject } from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Text, TextProps } from '../text';

export interface TagProps extends TextProps {
  height?: keyof typeof globalTheme.space;
}

function getHeight(props: TagProps): CSSObject {
  return {
    height:
      (props.height !== undefined && globalTheme.space[props.height]) ||
      globalTheme.space[4],
    get borderRadius() {
      return this.height;
    },
  };
}

export const Tag = styled(Text)`
  ${getHeight};

  display: inline-flex;
  gap: ${globalTheme.space[0]};
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 64px;
  padding-top: 0;
  padding-bottom: 0;
`;

Tag.defaultProps = {
  // @ts-ignore
  as: 'div',
  backgroundColor: 'neutral',
  padding: 0,
  fontSize: 0,
  height: 2,
};
