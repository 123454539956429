import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';

export const PageWrapper = styled.div<{
  fullHeight?: boolean;
  noBg?: boolean;
  includeMarginTop?: boolean;
}>`
  min-height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
  padding: ${globalTheme.space[1]};
  overflow: auto;
  background: ${(p) => (p.noBg ? 'transparent' : globalTheme.colors.white)};

  @media screen and (min-width: ${breakpoints.small}) {
    padding: ${globalTheme.space[2]};
  }

  @media screen and (min-width: ${breakpoints.medium}) {
    padding: ${globalTheme.space[3]};
  }

  @media screen and (min-width: ${breakpoints.large}) {
    padding: ${globalTheme.space[4]};
  }

  @media screen and (min-width: ${breakpoints.xLarge}) {
    padding: ${globalTheme.space[5]};
  }

  @media screen and (max-width: ${breakpoints.small}) {
    margin-top: ${(p) => (p.includeMarginTop ? globalTheme.space[0] : '')};
  }
`;
