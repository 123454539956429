/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { EmailCode } from '../model';
// @ts-ignore
import { MobileCode } from '../model';
// @ts-ignore
import { RegisteredUser } from '../model';
// @ts-ignore
import { UserRegisterBuyer } from '../model';
// @ts-ignore
import { UserRegisterSeller } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * UserRegisterApi - axios parameter creator
 * @export
 */
export const UserRegisterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The seller is first created and a verification Email and SMS is sent out
         * @summary Register a seller
         * @param {UserRegisterSeller} userRegisterSeller 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerRegisterCreate: async (userRegisterSeller: UserRegisterSeller, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRegisterSeller' is not null or undefined
            assertParamExists('sellerRegisterCreate', 'userRegisterSeller', userRegisterSeller)
            const localVarPath = `/v2/seller/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRegisterSeller, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validates the user has received the email by validating the database record against the code sent
         * @summary Validates a users email address
         * @param {EmailCode} emailCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerValidateEmailCreate: async (emailCode: EmailCode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailCode' is not null or undefined
            assertParamExists('sellerValidateEmailCreate', 'emailCode', emailCode)
            const localVarPath = `/v2/seller/validate/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends out a new code, making any previous ones sent out invalid
         * @summary Resends out a verification code to the users email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerValidateEmailResendCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/seller/validate/email/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validates the users mobile number by validating the code being entered matches that of the latest one assigned to the user.   If a newer code has been sent out after the code being used, an expired code error will be returned
         * @summary Validates a users mobile number
         * @param {MobileCode} mobileCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerValidateMobileCreate: async (mobileCode: MobileCode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mobileCode' is not null or undefined
            assertParamExists('sellerValidateMobileCreate', 'mobileCode', mobileCode)
            const localVarPath = `/v2/seller/validate/mobile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mobileCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends out a new code, making any previous ones sent out invalid
         * @summary Resends out a verification code to the users mobile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerValidateMobileResendCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/seller/validate/mobile/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The buyer is first created and a verification email is then sent out
         * @summary Register a buyer
         * @param {UserRegisterBuyer} userRegisterBuyer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegisterCreate: async (userRegisterBuyer: UserRegisterBuyer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRegisterBuyer' is not null or undefined
            assertParamExists('userRegisterCreate', 'userRegisterBuyer', userRegisterBuyer)
            const localVarPath = `/v2/user/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRegisterBuyer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validates the user has received the email by validating the database record against the code sent
         * @summary Validates a users email address
         * @param {EmailCode} emailCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegisterValidateEmailCreate: async (emailCode: EmailCode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailCode' is not null or undefined
            assertParamExists('userRegisterValidateEmailCreate', 'emailCode', emailCode)
            const localVarPath = `/v2/user/register/validate/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends out a new code, making any previous ones sent out invalid
         * @summary Resends out a verification code to the users email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegisterValidateEmailResendCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/user/register/validate/email/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validates the users mobile number by validating the code being entered matches that of the latest one assigned to the user.   If a newer code has been sent out after the code being used, an expired code error will be returned
         * @summary Validates a users mobile number
         * @param {MobileCode} mobileCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegisterValidateMobileCreate: async (mobileCode: MobileCode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mobileCode' is not null or undefined
            assertParamExists('userRegisterValidateMobileCreate', 'mobileCode', mobileCode)
            const localVarPath = `/v2/user/register/validate/mobile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mobileCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends out a new code, making any previous ones sent out invalid
         * @summary Resends out a verification code to the users mobile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegisterValidateMobileResendCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/user/register/validate/mobile/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRegisterApi - functional programming interface
 * @export
 */
export const UserRegisterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRegisterApiAxiosParamCreator(configuration)
    return {
        /**
         * The seller is first created and a verification Email and SMS is sent out
         * @summary Register a seller
         * @param {UserRegisterSeller} userRegisterSeller 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerRegisterCreate(userRegisterSeller: UserRegisterSeller, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisteredUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerRegisterCreate(userRegisterSeller, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validates the user has received the email by validating the database record against the code sent
         * @summary Validates a users email address
         * @param {EmailCode} emailCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerValidateEmailCreate(emailCode: EmailCode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerValidateEmailCreate(emailCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sends out a new code, making any previous ones sent out invalid
         * @summary Resends out a verification code to the users email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerValidateEmailResendCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerValidateEmailResendCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validates the users mobile number by validating the code being entered matches that of the latest one assigned to the user.   If a newer code has been sent out after the code being used, an expired code error will be returned
         * @summary Validates a users mobile number
         * @param {MobileCode} mobileCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerValidateMobileCreate(mobileCode: MobileCode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerValidateMobileCreate(mobileCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sends out a new code, making any previous ones sent out invalid
         * @summary Resends out a verification code to the users mobile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerValidateMobileResendCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerValidateMobileResendCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The buyer is first created and a verification email is then sent out
         * @summary Register a buyer
         * @param {UserRegisterBuyer} userRegisterBuyer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRegisterCreate(userRegisterBuyer: UserRegisterBuyer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisteredUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRegisterCreate(userRegisterBuyer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validates the user has received the email by validating the database record against the code sent
         * @summary Validates a users email address
         * @param {EmailCode} emailCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRegisterValidateEmailCreate(emailCode: EmailCode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRegisterValidateEmailCreate(emailCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sends out a new code, making any previous ones sent out invalid
         * @summary Resends out a verification code to the users email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRegisterValidateEmailResendCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRegisterValidateEmailResendCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validates the users mobile number by validating the code being entered matches that of the latest one assigned to the user.   If a newer code has been sent out after the code being used, an expired code error will be returned
         * @summary Validates a users mobile number
         * @param {MobileCode} mobileCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRegisterValidateMobileCreate(mobileCode: MobileCode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRegisterValidateMobileCreate(mobileCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sends out a new code, making any previous ones sent out invalid
         * @summary Resends out a verification code to the users mobile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRegisterValidateMobileResendCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRegisterValidateMobileResendCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserRegisterApi - factory interface
 * @export
 */
export const UserRegisterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRegisterApiFp(configuration)
    return {
        /**
         * The seller is first created and a verification Email and SMS is sent out
         * @summary Register a seller
         * @param {UserRegisterSeller} userRegisterSeller 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerRegisterCreate(userRegisterSeller: UserRegisterSeller, options?: any): AxiosPromise<RegisteredUser> {
            return localVarFp.sellerRegisterCreate(userRegisterSeller, options).then((request) => request(axios, basePath));
        },
        /**
         * Validates the user has received the email by validating the database record against the code sent
         * @summary Validates a users email address
         * @param {EmailCode} emailCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerValidateEmailCreate(emailCode: EmailCode, options?: any): AxiosPromise<string> {
            return localVarFp.sellerValidateEmailCreate(emailCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends out a new code, making any previous ones sent out invalid
         * @summary Resends out a verification code to the users email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerValidateEmailResendCreate(options?: any): AxiosPromise<void> {
            return localVarFp.sellerValidateEmailResendCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * Validates the users mobile number by validating the code being entered matches that of the latest one assigned to the user.   If a newer code has been sent out after the code being used, an expired code error will be returned
         * @summary Validates a users mobile number
         * @param {MobileCode} mobileCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerValidateMobileCreate(mobileCode: MobileCode, options?: any): AxiosPromise<void> {
            return localVarFp.sellerValidateMobileCreate(mobileCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends out a new code, making any previous ones sent out invalid
         * @summary Resends out a verification code to the users mobile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerValidateMobileResendCreate(options?: any): AxiosPromise<void> {
            return localVarFp.sellerValidateMobileResendCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * The buyer is first created and a verification email is then sent out
         * @summary Register a buyer
         * @param {UserRegisterBuyer} userRegisterBuyer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegisterCreate(userRegisterBuyer: UserRegisterBuyer, options?: any): AxiosPromise<RegisteredUser> {
            return localVarFp.userRegisterCreate(userRegisterBuyer, options).then((request) => request(axios, basePath));
        },
        /**
         * Validates the user has received the email by validating the database record against the code sent
         * @summary Validates a users email address
         * @param {EmailCode} emailCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegisterValidateEmailCreate(emailCode: EmailCode, options?: any): AxiosPromise<string> {
            return localVarFp.userRegisterValidateEmailCreate(emailCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends out a new code, making any previous ones sent out invalid
         * @summary Resends out a verification code to the users email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegisterValidateEmailResendCreate(options?: any): AxiosPromise<void> {
            return localVarFp.userRegisterValidateEmailResendCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * Validates the users mobile number by validating the code being entered matches that of the latest one assigned to the user.   If a newer code has been sent out after the code being used, an expired code error will be returned
         * @summary Validates a users mobile number
         * @param {MobileCode} mobileCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegisterValidateMobileCreate(mobileCode: MobileCode, options?: any): AxiosPromise<void> {
            return localVarFp.userRegisterValidateMobileCreate(mobileCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends out a new code, making any previous ones sent out invalid
         * @summary Resends out a verification code to the users mobile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegisterValidateMobileResendCreate(options?: any): AxiosPromise<void> {
            return localVarFp.userRegisterValidateMobileResendCreate(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sellerRegisterCreate operation in UserRegisterApi.
 * @export
 * @interface UserRegisterApiSellerRegisterCreateRequest
 */
export interface UserRegisterApiSellerRegisterCreateRequest {
    /**
     * 
     * @type {UserRegisterSeller}
     * @memberof UserRegisterApiSellerRegisterCreate
     */
    readonly userRegisterSeller: UserRegisterSeller
}

/**
 * Request parameters for sellerValidateEmailCreate operation in UserRegisterApi.
 * @export
 * @interface UserRegisterApiSellerValidateEmailCreateRequest
 */
export interface UserRegisterApiSellerValidateEmailCreateRequest {
    /**
     * 
     * @type {EmailCode}
     * @memberof UserRegisterApiSellerValidateEmailCreate
     */
    readonly emailCode: EmailCode
}

/**
 * Request parameters for sellerValidateMobileCreate operation in UserRegisterApi.
 * @export
 * @interface UserRegisterApiSellerValidateMobileCreateRequest
 */
export interface UserRegisterApiSellerValidateMobileCreateRequest {
    /**
     * 
     * @type {MobileCode}
     * @memberof UserRegisterApiSellerValidateMobileCreate
     */
    readonly mobileCode: MobileCode
}

/**
 * Request parameters for userRegisterCreate operation in UserRegisterApi.
 * @export
 * @interface UserRegisterApiUserRegisterCreateRequest
 */
export interface UserRegisterApiUserRegisterCreateRequest {
    /**
     * 
     * @type {UserRegisterBuyer}
     * @memberof UserRegisterApiUserRegisterCreate
     */
    readonly userRegisterBuyer: UserRegisterBuyer
}

/**
 * Request parameters for userRegisterValidateEmailCreate operation in UserRegisterApi.
 * @export
 * @interface UserRegisterApiUserRegisterValidateEmailCreateRequest
 */
export interface UserRegisterApiUserRegisterValidateEmailCreateRequest {
    /**
     * 
     * @type {EmailCode}
     * @memberof UserRegisterApiUserRegisterValidateEmailCreate
     */
    readonly emailCode: EmailCode
}

/**
 * Request parameters for userRegisterValidateMobileCreate operation in UserRegisterApi.
 * @export
 * @interface UserRegisterApiUserRegisterValidateMobileCreateRequest
 */
export interface UserRegisterApiUserRegisterValidateMobileCreateRequest {
    /**
     * 
     * @type {MobileCode}
     * @memberof UserRegisterApiUserRegisterValidateMobileCreate
     */
    readonly mobileCode: MobileCode
}

/**
 * UserRegisterApi - object-oriented interface
 * @export
 * @class UserRegisterApi
 * @extends {BaseAPI}
 */
export class UserRegisterApi extends BaseAPI {
    /**
     * The seller is first created and a verification Email and SMS is sent out
     * @summary Register a seller
     * @param {UserRegisterApiSellerRegisterCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRegisterApi
     */
    public sellerRegisterCreate(requestParameters: UserRegisterApiSellerRegisterCreateRequest, options?: any) {
        return UserRegisterApiFp(this.configuration).sellerRegisterCreate(requestParameters.userRegisterSeller, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validates the user has received the email by validating the database record against the code sent
     * @summary Validates a users email address
     * @param {UserRegisterApiSellerValidateEmailCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRegisterApi
     */
    public sellerValidateEmailCreate(requestParameters: UserRegisterApiSellerValidateEmailCreateRequest, options?: any) {
        return UserRegisterApiFp(this.configuration).sellerValidateEmailCreate(requestParameters.emailCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends out a new code, making any previous ones sent out invalid
     * @summary Resends out a verification code to the users email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRegisterApi
     */
    public sellerValidateEmailResendCreate(options?: any) {
        return UserRegisterApiFp(this.configuration).sellerValidateEmailResendCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validates the users mobile number by validating the code being entered matches that of the latest one assigned to the user.   If a newer code has been sent out after the code being used, an expired code error will be returned
     * @summary Validates a users mobile number
     * @param {UserRegisterApiSellerValidateMobileCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRegisterApi
     */
    public sellerValidateMobileCreate(requestParameters: UserRegisterApiSellerValidateMobileCreateRequest, options?: any) {
        return UserRegisterApiFp(this.configuration).sellerValidateMobileCreate(requestParameters.mobileCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends out a new code, making any previous ones sent out invalid
     * @summary Resends out a verification code to the users mobile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRegisterApi
     */
    public sellerValidateMobileResendCreate(options?: any) {
        return UserRegisterApiFp(this.configuration).sellerValidateMobileResendCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The buyer is first created and a verification email is then sent out
     * @summary Register a buyer
     * @param {UserRegisterApiUserRegisterCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRegisterApi
     */
    public userRegisterCreate(requestParameters: UserRegisterApiUserRegisterCreateRequest, options?: any) {
        return UserRegisterApiFp(this.configuration).userRegisterCreate(requestParameters.userRegisterBuyer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validates the user has received the email by validating the database record against the code sent
     * @summary Validates a users email address
     * @param {UserRegisterApiUserRegisterValidateEmailCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRegisterApi
     */
    public userRegisterValidateEmailCreate(requestParameters: UserRegisterApiUserRegisterValidateEmailCreateRequest, options?: any) {
        return UserRegisterApiFp(this.configuration).userRegisterValidateEmailCreate(requestParameters.emailCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends out a new code, making any previous ones sent out invalid
     * @summary Resends out a verification code to the users email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRegisterApi
     */
    public userRegisterValidateEmailResendCreate(options?: any) {
        return UserRegisterApiFp(this.configuration).userRegisterValidateEmailResendCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validates the users mobile number by validating the code being entered matches that of the latest one assigned to the user.   If a newer code has been sent out after the code being used, an expired code error will be returned
     * @summary Validates a users mobile number
     * @param {UserRegisterApiUserRegisterValidateMobileCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRegisterApi
     */
    public userRegisterValidateMobileCreate(requestParameters: UserRegisterApiUserRegisterValidateMobileCreateRequest, options?: any) {
        return UserRegisterApiFp(this.configuration).userRegisterValidateMobileCreate(requestParameters.mobileCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends out a new code, making any previous ones sent out invalid
     * @summary Resends out a verification code to the users mobile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRegisterApi
     */
    public userRegisterValidateMobileResendCreate(options?: any) {
        return UserRegisterApiFp(this.configuration).userRegisterValidateMobileResendCreate(options).then((request) => request(this.axios, this.basePath));
    }
}
