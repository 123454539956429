import { useStore } from '../lib/store';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useRouteTrackingInitializer = () => {
  const { asPath } = useRouter();
  const setRoutes = useStore((store) => store.setRoutes);

  useEffect(() => {
    setRoutes(asPath);
  }, [asPath]);
};
