import { useStore } from 'lib/store';
import { useSession } from 'next-auth/react';

/** This hook indicates when the API is ready to accept requests, meaning we have an access token and the session is not currently loading */
export function useApiReady() {
  const accessToken = useStore((store) => store.accessToken);
  const { status } = useSession();
  const loading = status === 'loading';
  return !!accessToken && !loading;
}
