/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { PublicPriceGrid } from '../model';
// @ts-ignore
import { PublicPriceGridToken } from '../model';
/**
 * PublicPriceGridsApi - axios parameter creator
 * @export
 */
export const PublicPriceGridsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve the Prices public information
         * @param {string} priceGridId 
         * @param {PublicPriceGridToken} publicPriceGridToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicPriceRetrieve: async (priceGridId: string, publicPriceGridToken: PublicPriceGridToken, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'priceGridId' is not null or undefined
            assertParamExists('publicPriceRetrieve', 'priceGridId', priceGridId)
            // verify required parameter 'publicPriceGridToken' is not null or undefined
            assertParamExists('publicPriceRetrieve', 'publicPriceGridToken', publicPriceGridToken)
            const localVarPath = `/v2/public/price/{price_grid_id}`
                .replace(`{${"price_grid_id"}}`, encodeURIComponent(String(priceGridId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publicPriceGridToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicPriceGridsApi - functional programming interface
 * @export
 */
export const PublicPriceGridsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicPriceGridsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve the Prices public information
         * @param {string} priceGridId 
         * @param {PublicPriceGridToken} publicPriceGridToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicPriceRetrieve(priceGridId: string, publicPriceGridToken: PublicPriceGridToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicPriceGrid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicPriceRetrieve(priceGridId, publicPriceGridToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicPriceGridsApi - factory interface
 * @export
 */
export const PublicPriceGridsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicPriceGridsApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve the Prices public information
         * @param {string} priceGridId 
         * @param {PublicPriceGridToken} publicPriceGridToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicPriceRetrieve(priceGridId: string, publicPriceGridToken: PublicPriceGridToken, options?: any): AxiosPromise<PublicPriceGrid> {
            return localVarFp.publicPriceRetrieve(priceGridId, publicPriceGridToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for publicPriceRetrieve operation in PublicPriceGridsApi.
 * @export
 * @interface PublicPriceGridsApiPublicPriceRetrieveRequest
 */
export interface PublicPriceGridsApiPublicPriceRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicPriceGridsApiPublicPriceRetrieve
     */
    readonly priceGridId: string

    /**
     * 
     * @type {PublicPriceGridToken}
     * @memberof PublicPriceGridsApiPublicPriceRetrieve
     */
    readonly publicPriceGridToken: PublicPriceGridToken
}

/**
 * PublicPriceGridsApi - object-oriented interface
 * @export
 * @class PublicPriceGridsApi
 * @extends {BaseAPI}
 */
export class PublicPriceGridsApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve the Prices public information
     * @param {PublicPriceGridsApiPublicPriceRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicPriceGridsApi
     */
    public publicPriceRetrieve(requestParameters: PublicPriceGridsApiPublicPriceRetrieveRequest, options?: any) {
        return PublicPriceGridsApiFp(this.configuration).publicPriceRetrieve(requestParameters.priceGridId, requestParameters.publicPriceGridToken, options).then((request) => request(this.axios, this.basePath));
    }
}
