import enAU from 'date-fns/locale/en-AU';
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
registerLocale('en-AU', enAU);

export const DatePickerWrap = styled.div<{ bookingStyle?: boolean }>`
  input[type='text'] {
    width: 100%;
    min-width: 120px;
    height: ${(props) => (props.bookingStyle ? 'auto' : '44px')};
    padding: ${(props) =>
      props.bookingStyle
        ? '4px 8px'
        : `${globalTheme.space[0]} ${globalTheme.space[1]}`};
    font-size: inherit;
    background-color: ${globalTheme.colors.white};
    border: 2px solid ${globalTheme.colors.neutral};
    border-radius: 8px;
    outline: 0;
    transition: border-color ${globalTheme.transitions.fast};

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .react-datepicker {
    border: 2px solid ${globalTheme.colors.neutral};
    border-radius: 8px;
  }

  .react-datepicker__header {
    background-color: ${globalTheme.colors.white};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--in-range {
    background-color: ${globalTheme.colors.blue};
  }
`;

export interface DatepickerProps
  extends Omit<ReactDatePickerProps, 'selectsRange'> {
  selectsRange?: boolean;
  bookingStyle?: boolean;
}

export const Datepicker = ({
  bookingStyle,
  ...ReactDatePicker
}: DatepickerProps) => {
  return (
    <DatePickerWrap bookingStyle={bookingStyle}>
      <DatePicker {...ReactDatePicker} />
    </DatePickerWrap>
  );
};
