import { ErrorMessage, RadioBox, Stack, Text } from 'components/ui';
import { useConfig } from 'hooks/use-config';
import { useProducts } from 'hooks/use-products';
import { getLivestockTypeIconName } from 'lib/helpers';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';
import { CreateOrgFormData } from '../common';

interface TypeDetailsProps {
  setSelectedType: Function;
  setSelectedProductType: Function;
}

const OrgTypeContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const OrgText = styled(Text)`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    font-size: ${globalTheme.space[2]};
  }
`;

export const LivestockTypes = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${globalTheme.space[1]};
  justify-content: center;
`;

export const OrgType: FC<TypeDetailsProps> = ({
  setSelectedType,
  setSelectedProductType,
}) => {
  const {
    register,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext<CreateOrgFormData>();

  const { data: products } = useProducts();
  const { data: config } = useConfig();

  const type = watch('types'); // Access specific field value
  const product = watch('products');
  const isMobile = useMedia(`(max-width: ${breakpoints.medium})`);

  useEffect(() => {
    if (type) {
      setSelectedType(getValues('types'));
    }
  }, [type]);

  useEffect(() => {
    if (product) {
      setSelectedProductType(getValues('products'));
    }
  }, [product]);

  return (
    <OrgTypeContainer>
      <Stack space={2}>
        <OrgText fontWeight='bold' textAlign='center'>
          Which of these best describes you (select multiple if required)?
        </OrgText>
        <LivestockTypes>
          {config?.org_types &&
            Object.entries(config?.org_types)?.map(([key, value], index) => {
              return (
                <RadioBox
                  key={key}
                  data-cy={`${value}Box`}
                  type='checkbox'
                  label={value}
                  value={key}
                  {...register('types')}
                  orgFontSize={3}
                  isMobile={isMobile}
                />
              );
            })}
        </LivestockTypes>
        {errors.types && <ErrorMessage>{errors?.types?.message}</ErrorMessage>}

        <OrgText fontWeight='bold' textAlign='center'>
          Stock type(s)*
        </OrgText>
        <LivestockTypes>
          {products?.map((product, index) => (
            <RadioBox
              key={product.product_id}
              data-cy={`${product.name}Box`}
              type='checkbox'
              label={product.name}
              value={product.product_id}
              icon={getLivestockTypeIconName(product.product_id)}
              {...register('products')}
              orgFontSize={3}
              isMobile={isMobile}
            />
          ))}
        </LivestockTypes>
        {errors.products && (
          <ErrorMessage>{errors?.products?.message}</ErrorMessage>
        )}
      </Stack>
    </OrgTypeContainer>
  );
};
