import { createGlobalStyle } from 'styled-components';
import { globalTheme } from './global-theme';

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    color: ${globalTheme.elements.global.color};
    font-weight: ${globalTheme.fontWeights.regular};
    font-family: ${globalTheme.fontFamily};
    line-height: ${globalTheme.lineHeights.body};
    background-color: ${globalTheme.elements.global.backgroundColor};
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    font-family: inherit;
  }

  a {
    color: inherit;
    font-size: ${globalTheme.fontSizes.default};

    &:hover {
      text-decoration: none;
    }
  }

  /* Disable outline for non-keyboard users: https://css-tricks.com/the-focus-visible-trick/ */
  :focus:not(:focus-visible) { outline: none }

  /* Disables reach ui's warning of not including a stylesheet for tabs */
  &:root {
    --reach-tabs: 1;
  }
  .carousel .thumb img {
    width: 80px !important;
    height: 80px !important;
}
.carousel .thumb video {
  width: 80px !important;
  height: 75px !important;

}

.carousel .slide img {
    max-height: 400px;  /* change this to whatever you want */
    max-width: 100%;
    object-fit: contain;
}
.carousel .slide video {
  max-height: 400px;  /* change this to whatever you want */
  max-width: 100%;
  object-fit: contain;
}

.carousel .thumb {
  padding: 0px !important;
}

.carousel .control-dots .dot{
  background: #000;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000;
}

.stockbook-required-header{
  text-align: center;
  justify-content: center;
  font-weight: 700;
}

.groupstart {
  border-right: 1px solid #DDDDDD !important;
}

.stock-negative-value {
  color: #FF0000;
}


.history-confirmation_accepted_row {
    background-color: #008500ab;
}
.history-confirmation_declined_row {
    background-color: #ff6464ab;

}

.history-confirmation_open_row {
    background-color: #e8d445ab;
}

.sick-days-breach {
    background-color: #cc333344;
}
`;
