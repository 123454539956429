export { ReactComponent as CowSheepImage } from './cow-sheep-illustration.svg';
export { ReactComponent as EmailImage } from './email-illustration.svg';
export { ReactComponent as GroupsImage } from './groups.svg';
export { ReactComponent as IndividualImage } from './individual.svg';
export { ReactComponent as InfoImage } from './info.svg';
export { ReactComponent as LiveStockLogo } from './livestock-logo.svg';
export { ReactComponent as LogoDark } from './logo-dark.svg';
export { ReactComponent as LogoLight } from './agora-logo-light.svg';
export { ReactComponent as PoweredByStripeImage } from './powered-by-stripe.svg';
export { ReactComponent as RotationLock } from './rotation-lock.svg';
export { ReactComponent as SuccessImage } from './success.svg';
export { ReactComponent as TrashImage } from './trash.svg';
export { ReactComponent as WarningImage } from './warning.svg';
export { ReactComponent as NoImageAvailable } from './no-image-available.svg';
