import { getParams } from '@hatchd/utils';
import { SummaryGridEditFormData } from 'components/summary-grid-edit/common';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { BUYER_ORG_STORAGE_KEY, NAVBAR } from 'settings/config';
import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { CloudinaryListingMedia } from '../components/image-upload/image-upload';
import {
  BookingPropertyMedia,
  ListingMedia,
  ListingMediaCreate,
  MediaCloudinary,
} from './api/generated';
import { isBrowser } from './helpers';
import { IFilter } from './marketplace-utils';

export type DraftPriceGrid = {
  name: string;
  reference: string;
  product: string;
};

export type DraftOffer = {
  priceGridId: string;
  mobileNumber?: string;
  userId?: string;
  contactId?: string;
  counterOffer?: boolean;
  eoiId?: string;
  bookingData?: {
    id: string | undefined;
    type: string | undefined;
    date: string | undefined;
    date_to: string | undefined;
    deliveryDate: string[] | undefined;
  };
};

export type DraftSummaryGrid = Pick<
  SummaryGridEditFormData,
  'date_from' | 'date_to' | 'reference' | 'name'
>;

type State = {
  overrideHeaderContent: boolean;
  draftPriceGrid: Partial<DraftPriceGrid>;
  draftOffer: Partial<DraftOffer>;
  draftSummaryGrid: Partial<DraftSummaryGrid>;
  activeBuyerOrgId?: string;
  accessToken?: string;
  resetDraftPriceGrid: () => void;
  resetDraftOffer: () => void;
  setOverrideHeaderContent: (value: boolean) => void;
  setDraftPriceGridBaseFields: (fields: DraftPriceGrid) => void;
  setDraftOffer: (fields: DraftOffer) => void;
  setDraftSummaryGrid: (fields: DraftSummaryGrid) => void;
  resetDraftSummaryGrid: () => void;
  setActiveBuyerOrgId: (value: string) => void;
  removeActiveBuyerOrgId: () => void;
  setAccessToken: (value: string) => void;
  setMedia: (
    value: ListingMedia | ListingMediaCreate | BookingPropertyMedia
  ) => void;
  resetMedia: () => void;

  removeMediaItem: (item: any) => void;
  media: CloudinaryListingMedia[] | [];
  setRoutes: (value: string) => void;
  routes: string[];
  filter: IFilter;
  setFilter: (filter: IFilter) => void;

  isNavOpen: boolean;
  toggleNav: () => void;
  setNavbar: (value: boolean) => void;
};

// Base data
const draftPriceGrid = {
  name: '',
  reference: '',
  product: 'CT',
};

const draftOffer = {
  priceGridId: '',
};

export const defaultDraftSummaryGrid: DraftSummaryGrid = {
  name: '',
  reference: '',
  date_from: '',
  date_to: '',
};

export const media: ListingMedia[] | [] = [];

export let routes: string[] = [];

function getInitialActiveBuyerOrg() {
  if (isBrowser) {
    const query = getParams<{ orgId: string }>(window.location.search).orgId;
    const cookie = parseCookies()?.[BUYER_ORG_STORAGE_KEY];

    // If the query is present also set a cookie
    if (query) {
      setCookie(null, BUYER_ORG_STORAGE_KEY, query, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
        sameSite: 'Lax',
      });
      return query;
    }

    // Return the stored cookie value
    if (cookie) {
      return cookie;
    }
  }
  return undefined;
}
let navBarValue: string | null;

if (typeof window !== 'undefined') {
  navBarValue = localStorage.getItem(NAVBAR);
}

// Set up the store and methods for setting/retrieval
export const useStore = create(
  immer<State>((set) => ({
    // Initial state
    overrideHeaderContent: false,
    draftPriceGrid,
    draftOffer,
    filter: {},
    draftSummaryGrid: defaultDraftSummaryGrid,
    media,
    activeBuyerOrgId: getInitialActiveBuyerOrg(),
    routes,
    isNavOpen: navBarValue === 'open',
    // Actions
    // Set Header content override flag
    setOverrideHeaderContent: (value) =>
      set((state) => {
        state.overrideHeaderContent = value;
      }),
    // Set new/initial price grid fields
    setDraftPriceGridBaseFields: (fields) =>
      set((state) => {
        state.draftPriceGrid = fields;
      }),
    resetDraftPriceGrid: () =>
      set((state) => {
        state.draftPriceGrid = draftPriceGrid;
      }),
    resetDraftOffer: () =>
      set((state) => {
        state.draftOffer = draftOffer;
      }),
    // Set the active buyer group ID
    setActiveBuyerOrgId: (value) =>
      set((state) => {
        state.activeBuyerOrgId = value;
        setCookie(null, BUYER_ORG_STORAGE_KEY, value, {
          maxAge: 30 * 24 * 60 * 60,
          path: '/',
          sameSite: 'Lax',
        });
      }),
    // Remove/reset the active buyer group ID
    removeActiveBuyerOrgId: () =>
      set((state) => {
        state.activeBuyerOrgId = undefined;
        destroyCookie(null, BUYER_ORG_STORAGE_KEY, { path: '/' });
      }),
    // Set the access token
    setAccessToken: (value) =>
      set((state) => {
        state.accessToken = value;
      }),

    // Set the draft offer
    setDraftOffer: (fields) =>
      set((state) => {
        state.draftOffer = fields;
      }),
    // Set the draft summary grid
    setDraftSummaryGrid: (fields) =>
      set((state) => {
        state.draftSummaryGrid = fields;
      }),
    // Reset the draft summary grid
    resetDraftSummaryGrid: () =>
      set((state) => {
        state.draftSummaryGrid = defaultDraftSummaryGrid;
      }),
    setMedia: (value) => {
      set((state) => ({
        media: [...state.media, value],
      }));
    },
    removeMediaItem: (mediaItem: ListingMedia | MediaCloudinary) => {
      set((state) => {
        if ('signature' in mediaItem && mediaItem.signature) {
          return {
            media: [...state.media].filter((item) => {
              return item.public_id !== mediaItem.public_id;
            }),
          };
        } else {
          return {
            media: [...state.media].filter((item) => {
              return item.id !== ('id' in mediaItem && mediaItem.id);
            }),
          };
        }
      });
    },
    resetMedia: () =>
      set((state) => {
        state.media = [];
      }),
    setRoutes: (value) =>
      set((state) => {
        if (state.routes.length < 10) {
          return { routes: [...state.routes, value] };
        } else {
          return { routes: [...state.routes, value].splice(1) };
        }
      }),
    //Set Public Marketplace Filter
    setFilter: (value) =>
      set((state) => {
        state.filter = value;
      }),
    toggleNav: () =>
      set((state) => {
        state.isNavOpen = !state.isNavOpen;
      }),

    setNavbar: (value) =>
      set((state) => {
        state.isNavOpen = value;
      }),
  }))
);
