/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FCMRegistrationToken } from '../model';
// @ts-ignore
import { FCMRegistrationTokenCreate } from '../model';
// @ts-ignore
import { Logout } from '../model';
// @ts-ignore
import { OneTimeLogin } from '../model';
// @ts-ignore
import { PatchedOneTimeLogin } from '../model';
// @ts-ignore
import { PatchedResetPassword } from '../model';
// @ts-ignore
import { PatchedSellerResetPasswordConfirm } from '../model';
// @ts-ignore
import { PatchedUserUpdatePassword } from '../model';
// @ts-ignore
import { ProfileTokenObtainPairResponse } from '../model';
// @ts-ignore
import { ResetPasswordContact } from '../model';
// @ts-ignore
import { SellerResetPassword } from '../model';
// @ts-ignore
import { TokenObtainPairResponse } from '../model';
// @ts-ignore
import { TokenRefresh } from '../model';
// @ts-ignore
import { TokenRefreshResponse } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * UserAuthenticationApi - axios parameter creator
 * @export
 */
export const UserAuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Captures a user\'s FCM registrationToken
         * @summary Captures a user\'s FCM registrationToken
         * @param {FCMRegistrationTokenCreate} fCMRegistrationTokenCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerFcmCreateTokenCreate: async (fCMRegistrationTokenCreate: FCMRegistrationTokenCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fCMRegistrationTokenCreate' is not null or undefined
            assertParamExists('sellerFcmCreateTokenCreate', 'fCMRegistrationTokenCreate', fCMRegistrationTokenCreate)
            const localVarPath = `/v2/seller/fcm/create-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fCMRegistrationTokenCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validates the users email and password and logs the user in.   Returning their JWT token, refresh token and their current profile.
         * @summary Authenticates the user
         * @param {ProfileTokenObtainPairResponse} profileTokenObtainPairResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerLoginCreate: async (profileTokenObtainPairResponse: ProfileTokenObtainPairResponse, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileTokenObtainPairResponse' is not null or undefined
            assertParamExists('sellerLoginCreate', 'profileTokenObtainPairResponse', profileTokenObtainPairResponse)
            const localVarPath = `/v2/seller/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileTokenObtainPairResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logs the user out by blacklisting their current refresh
         * @summary Logs the user out
         * @param {Logout} logout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerLogoutCreate: async (logout: Logout, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'logout' is not null or undefined
            assertParamExists('sellerLogoutCreate', 'logout', logout)
            const localVarPath = `/v2/seller/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logout, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends out an SMS to the user with a 6 digit reset password token
         * @summary Requests to reset the sellers password
         * @param {SellerResetPassword} sellerResetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerResetPasswordCreate: async (sellerResetPassword: SellerResetPassword, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sellerResetPassword' is not null or undefined
            assertParamExists('sellerResetPasswordCreate', 'sellerResetPassword', sellerResetPassword)
            const localVarPath = `/v2/seller/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerResetPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The code is the code sent out in the SMS  Validation on the code may return a 400 VALIDATION_ERROR if the code has expired after 2 hours from being sent, it is not found, the code and mobile dont match up, or it has already been used before
         * @summary Resets the users password
         * @param {PatchedSellerResetPasswordConfirm} [patchedSellerResetPasswordConfirm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerResetPasswordPartialUpdate: async (patchedSellerResetPasswordConfirm?: PatchedSellerResetPasswordConfirm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/seller/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedSellerResetPasswordConfirm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Accepts a refresh token and returns a new access token and refresh token for the user to use
         * @summary Refreshes a users access token
         * @param {TokenRefresh} tokenRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerTokenRefreshCreate: async (tokenRefresh: TokenRefresh, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenRefresh' is not null or undefined
            assertParamExists('sellerTokenRefreshCreate', 'tokenRefresh', tokenRefresh)
            const localVarPath = `/v2/seller/token/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRefresh, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the users password
         * @param {PatchedUserUpdatePassword} [patchedUserUpdatePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerUpdatePasswordPartialUpdate: async (patchedUserUpdatePassword?: PatchedUserUpdatePassword, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/seller/update-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUserUpdatePassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validates the users email and password and logs the user in.   Returning their JWT token, refresh token and their current profile.
         * @summary Authenticates the user
         * @param {ProfileTokenObtainPairResponse} profileTokenObtainPairResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthLoginCreate: async (profileTokenObtainPairResponse: ProfileTokenObtainPairResponse, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileTokenObtainPairResponse' is not null or undefined
            assertParamExists('userAuthLoginCreate', 'profileTokenObtainPairResponse', profileTokenObtainPairResponse)
            const localVarPath = `/v2/user/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileTokenObtainPairResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logs the user out by blacklisting their current refresh
         * @summary Logs the user out
         * @param {Logout} logout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthLogoutCreate: async (logout: Logout, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'logout' is not null or undefined
            assertParamExists('userAuthLogoutCreate', 'logout', logout)
            const localVarPath = `/v2/user/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logout, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates a one-time login token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthOneTimeCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/user/auth/one-time`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns valid User access and refresh tokens along with their profile information
         * @summary Validates a one-time login token
         * @param {PatchedOneTimeLogin} [patchedOneTimeLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthOneTimePartialUpdate: async (patchedOneTimeLogin?: PatchedOneTimeLogin, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/user/auth/one-time`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedOneTimeLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the users password
         * @param {PatchedUserUpdatePassword} [patchedUserUpdatePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthPasswordPartialUpdate: async (patchedUserUpdatePassword?: PatchedUserUpdatePassword, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/user/auth/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUserUpdatePassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends out an email or SMS to the user with a reset password token
         * @summary Requests to reset the users password
         * @param {ResetPasswordContact} resetPasswordContact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthPasswordResetCreate: async (resetPasswordContact: ResetPasswordContact, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordContact' is not null or undefined
            assertParamExists('userAuthPasswordResetCreate', 'resetPasswordContact', resetPasswordContact)
            const localVarPath = `/v2/user/auth/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordContact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The code is the code sent out with a link via email/sms
         * @summary Resets the users password
         * @param {PatchedResetPassword} [patchedResetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthPasswordResetPartialUpdate: async (patchedResetPassword?: PatchedResetPassword, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/user/auth/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedResetPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Accepts a refresh token and returns a new access token and refresh token for the user to use
         * @summary Refreshes a users access token
         * @param {TokenRefresh} tokenRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthTokenRefreshCreate: async (tokenRefresh: TokenRefresh, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenRefresh' is not null or undefined
            assertParamExists('userAuthTokenRefreshCreate', 'tokenRefresh', tokenRefresh)
            const localVarPath = `/v2/user/auth/token/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRefresh, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAuthenticationApi - functional programming interface
 * @export
 */
export const UserAuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * Captures a user\'s FCM registrationToken
         * @summary Captures a user\'s FCM registrationToken
         * @param {FCMRegistrationTokenCreate} fCMRegistrationTokenCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerFcmCreateTokenCreate(fCMRegistrationTokenCreate: FCMRegistrationTokenCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FCMRegistrationToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerFcmCreateTokenCreate(fCMRegistrationTokenCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validates the users email and password and logs the user in.   Returning their JWT token, refresh token and their current profile.
         * @summary Authenticates the user
         * @param {ProfileTokenObtainPairResponse} profileTokenObtainPairResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerLoginCreate(profileTokenObtainPairResponse: ProfileTokenObtainPairResponse, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenObtainPairResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerLoginCreate(profileTokenObtainPairResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Logs the user out by blacklisting their current refresh
         * @summary Logs the user out
         * @param {Logout} logout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerLogoutCreate(logout: Logout, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerLogoutCreate(logout, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sends out an SMS to the user with a 6 digit reset password token
         * @summary Requests to reset the sellers password
         * @param {SellerResetPassword} sellerResetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerResetPasswordCreate(sellerResetPassword: SellerResetPassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerResetPasswordCreate(sellerResetPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The code is the code sent out in the SMS  Validation on the code may return a 400 VALIDATION_ERROR if the code has expired after 2 hours from being sent, it is not found, the code and mobile dont match up, or it has already been used before
         * @summary Resets the users password
         * @param {PatchedSellerResetPasswordConfirm} [patchedSellerResetPasswordConfirm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerResetPasswordPartialUpdate(patchedSellerResetPasswordConfirm?: PatchedSellerResetPasswordConfirm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerResetPasswordPartialUpdate(patchedSellerResetPasswordConfirm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Accepts a refresh token and returns a new access token and refresh token for the user to use
         * @summary Refreshes a users access token
         * @param {TokenRefresh} tokenRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerTokenRefreshCreate(tokenRefresh: TokenRefresh, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenRefreshResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerTokenRefreshCreate(tokenRefresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the users password
         * @param {PatchedUserUpdatePassword} [patchedUserUpdatePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerUpdatePasswordPartialUpdate(patchedUserUpdatePassword?: PatchedUserUpdatePassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerUpdatePasswordPartialUpdate(patchedUserUpdatePassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validates the users email and password and logs the user in.   Returning their JWT token, refresh token and their current profile.
         * @summary Authenticates the user
         * @param {ProfileTokenObtainPairResponse} profileTokenObtainPairResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAuthLoginCreate(profileTokenObtainPairResponse: ProfileTokenObtainPairResponse, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenObtainPairResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAuthLoginCreate(profileTokenObtainPairResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Logs the user out by blacklisting their current refresh
         * @summary Logs the user out
         * @param {Logout} logout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAuthLogoutCreate(logout: Logout, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAuthLogoutCreate(logout, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates a one-time login token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAuthOneTimeCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OneTimeLogin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAuthOneTimeCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns valid User access and refresh tokens along with their profile information
         * @summary Validates a one-time login token
         * @param {PatchedOneTimeLogin} [patchedOneTimeLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAuthOneTimePartialUpdate(patchedOneTimeLogin?: PatchedOneTimeLogin, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenObtainPairResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAuthOneTimePartialUpdate(patchedOneTimeLogin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the users password
         * @param {PatchedUserUpdatePassword} [patchedUserUpdatePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAuthPasswordPartialUpdate(patchedUserUpdatePassword?: PatchedUserUpdatePassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAuthPasswordPartialUpdate(patchedUserUpdatePassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sends out an email or SMS to the user with a reset password token
         * @summary Requests to reset the users password
         * @param {ResetPasswordContact} resetPasswordContact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAuthPasswordResetCreate(resetPasswordContact: ResetPasswordContact, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAuthPasswordResetCreate(resetPasswordContact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The code is the code sent out with a link via email/sms
         * @summary Resets the users password
         * @param {PatchedResetPassword} [patchedResetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAuthPasswordResetPartialUpdate(patchedResetPassword?: PatchedResetPassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAuthPasswordResetPartialUpdate(patchedResetPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Accepts a refresh token and returns a new access token and refresh token for the user to use
         * @summary Refreshes a users access token
         * @param {TokenRefresh} tokenRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAuthTokenRefreshCreate(tokenRefresh: TokenRefresh, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenRefreshResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAuthTokenRefreshCreate(tokenRefresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAuthenticationApi - factory interface
 * @export
 */
export const UserAuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAuthenticationApiFp(configuration)
    return {
        /**
         * Captures a user\'s FCM registrationToken
         * @summary Captures a user\'s FCM registrationToken
         * @param {FCMRegistrationTokenCreate} fCMRegistrationTokenCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerFcmCreateTokenCreate(fCMRegistrationTokenCreate: FCMRegistrationTokenCreate, options?: any): AxiosPromise<FCMRegistrationToken> {
            return localVarFp.sellerFcmCreateTokenCreate(fCMRegistrationTokenCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Validates the users email and password and logs the user in.   Returning their JWT token, refresh token and their current profile.
         * @summary Authenticates the user
         * @param {ProfileTokenObtainPairResponse} profileTokenObtainPairResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerLoginCreate(profileTokenObtainPairResponse: ProfileTokenObtainPairResponse, options?: any): AxiosPromise<TokenObtainPairResponse> {
            return localVarFp.sellerLoginCreate(profileTokenObtainPairResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * Logs the user out by blacklisting their current refresh
         * @summary Logs the user out
         * @param {Logout} logout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerLogoutCreate(logout: Logout, options?: any): AxiosPromise<string> {
            return localVarFp.sellerLogoutCreate(logout, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends out an SMS to the user with a 6 digit reset password token
         * @summary Requests to reset the sellers password
         * @param {SellerResetPassword} sellerResetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerResetPasswordCreate(sellerResetPassword: SellerResetPassword, options?: any): AxiosPromise<void> {
            return localVarFp.sellerResetPasswordCreate(sellerResetPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * The code is the code sent out in the SMS  Validation on the code may return a 400 VALIDATION_ERROR if the code has expired after 2 hours from being sent, it is not found, the code and mobile dont match up, or it has already been used before
         * @summary Resets the users password
         * @param {PatchedSellerResetPasswordConfirm} [patchedSellerResetPasswordConfirm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerResetPasswordPartialUpdate(patchedSellerResetPasswordConfirm?: PatchedSellerResetPasswordConfirm, options?: any): AxiosPromise<void> {
            return localVarFp.sellerResetPasswordPartialUpdate(patchedSellerResetPasswordConfirm, options).then((request) => request(axios, basePath));
        },
        /**
         * Accepts a refresh token and returns a new access token and refresh token for the user to use
         * @summary Refreshes a users access token
         * @param {TokenRefresh} tokenRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerTokenRefreshCreate(tokenRefresh: TokenRefresh, options?: any): AxiosPromise<TokenRefreshResponse> {
            return localVarFp.sellerTokenRefreshCreate(tokenRefresh, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the users password
         * @param {PatchedUserUpdatePassword} [patchedUserUpdatePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerUpdatePasswordPartialUpdate(patchedUserUpdatePassword?: PatchedUserUpdatePassword, options?: any): AxiosPromise<void> {
            return localVarFp.sellerUpdatePasswordPartialUpdate(patchedUserUpdatePassword, options).then((request) => request(axios, basePath));
        },
        /**
         * Validates the users email and password and logs the user in.   Returning their JWT token, refresh token and their current profile.
         * @summary Authenticates the user
         * @param {ProfileTokenObtainPairResponse} profileTokenObtainPairResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthLoginCreate(profileTokenObtainPairResponse: ProfileTokenObtainPairResponse, options?: any): AxiosPromise<TokenObtainPairResponse> {
            return localVarFp.userAuthLoginCreate(profileTokenObtainPairResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * Logs the user out by blacklisting their current refresh
         * @summary Logs the user out
         * @param {Logout} logout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthLogoutCreate(logout: Logout, options?: any): AxiosPromise<string> {
            return localVarFp.userAuthLogoutCreate(logout, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates a one-time login token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthOneTimeCreate(options?: any): AxiosPromise<OneTimeLogin> {
            return localVarFp.userAuthOneTimeCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns valid User access and refresh tokens along with their profile information
         * @summary Validates a one-time login token
         * @param {PatchedOneTimeLogin} [patchedOneTimeLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthOneTimePartialUpdate(patchedOneTimeLogin?: PatchedOneTimeLogin, options?: any): AxiosPromise<TokenObtainPairResponse> {
            return localVarFp.userAuthOneTimePartialUpdate(patchedOneTimeLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the users password
         * @param {PatchedUserUpdatePassword} [patchedUserUpdatePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthPasswordPartialUpdate(patchedUserUpdatePassword?: PatchedUserUpdatePassword, options?: any): AxiosPromise<void> {
            return localVarFp.userAuthPasswordPartialUpdate(patchedUserUpdatePassword, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends out an email or SMS to the user with a reset password token
         * @summary Requests to reset the users password
         * @param {ResetPasswordContact} resetPasswordContact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthPasswordResetCreate(resetPasswordContact: ResetPasswordContact, options?: any): AxiosPromise<void> {
            return localVarFp.userAuthPasswordResetCreate(resetPasswordContact, options).then((request) => request(axios, basePath));
        },
        /**
         * The code is the code sent out with a link via email/sms
         * @summary Resets the users password
         * @param {PatchedResetPassword} [patchedResetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthPasswordResetPartialUpdate(patchedResetPassword?: PatchedResetPassword, options?: any): AxiosPromise<void> {
            return localVarFp.userAuthPasswordResetPartialUpdate(patchedResetPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * Accepts a refresh token and returns a new access token and refresh token for the user to use
         * @summary Refreshes a users access token
         * @param {TokenRefresh} tokenRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthTokenRefreshCreate(tokenRefresh: TokenRefresh, options?: any): AxiosPromise<TokenRefreshResponse> {
            return localVarFp.userAuthTokenRefreshCreate(tokenRefresh, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sellerFcmCreateTokenCreate operation in UserAuthenticationApi.
 * @export
 * @interface UserAuthenticationApiSellerFcmCreateTokenCreateRequest
 */
export interface UserAuthenticationApiSellerFcmCreateTokenCreateRequest {
    /**
     * 
     * @type {FCMRegistrationTokenCreate}
     * @memberof UserAuthenticationApiSellerFcmCreateTokenCreate
     */
    readonly fCMRegistrationTokenCreate: FCMRegistrationTokenCreate
}

/**
 * Request parameters for sellerLoginCreate operation in UserAuthenticationApi.
 * @export
 * @interface UserAuthenticationApiSellerLoginCreateRequest
 */
export interface UserAuthenticationApiSellerLoginCreateRequest {
    /**
     * 
     * @type {ProfileTokenObtainPairResponse}
     * @memberof UserAuthenticationApiSellerLoginCreate
     */
    readonly profileTokenObtainPairResponse: ProfileTokenObtainPairResponse
}

/**
 * Request parameters for sellerLogoutCreate operation in UserAuthenticationApi.
 * @export
 * @interface UserAuthenticationApiSellerLogoutCreateRequest
 */
export interface UserAuthenticationApiSellerLogoutCreateRequest {
    /**
     * 
     * @type {Logout}
     * @memberof UserAuthenticationApiSellerLogoutCreate
     */
    readonly logout: Logout
}

/**
 * Request parameters for sellerResetPasswordCreate operation in UserAuthenticationApi.
 * @export
 * @interface UserAuthenticationApiSellerResetPasswordCreateRequest
 */
export interface UserAuthenticationApiSellerResetPasswordCreateRequest {
    /**
     * 
     * @type {SellerResetPassword}
     * @memberof UserAuthenticationApiSellerResetPasswordCreate
     */
    readonly sellerResetPassword: SellerResetPassword
}

/**
 * Request parameters for sellerResetPasswordPartialUpdate operation in UserAuthenticationApi.
 * @export
 * @interface UserAuthenticationApiSellerResetPasswordPartialUpdateRequest
 */
export interface UserAuthenticationApiSellerResetPasswordPartialUpdateRequest {
    /**
     * 
     * @type {PatchedSellerResetPasswordConfirm}
     * @memberof UserAuthenticationApiSellerResetPasswordPartialUpdate
     */
    readonly patchedSellerResetPasswordConfirm?: PatchedSellerResetPasswordConfirm
}

/**
 * Request parameters for sellerTokenRefreshCreate operation in UserAuthenticationApi.
 * @export
 * @interface UserAuthenticationApiSellerTokenRefreshCreateRequest
 */
export interface UserAuthenticationApiSellerTokenRefreshCreateRequest {
    /**
     * 
     * @type {TokenRefresh}
     * @memberof UserAuthenticationApiSellerTokenRefreshCreate
     */
    readonly tokenRefresh: TokenRefresh
}

/**
 * Request parameters for sellerUpdatePasswordPartialUpdate operation in UserAuthenticationApi.
 * @export
 * @interface UserAuthenticationApiSellerUpdatePasswordPartialUpdateRequest
 */
export interface UserAuthenticationApiSellerUpdatePasswordPartialUpdateRequest {
    /**
     * 
     * @type {PatchedUserUpdatePassword}
     * @memberof UserAuthenticationApiSellerUpdatePasswordPartialUpdate
     */
    readonly patchedUserUpdatePassword?: PatchedUserUpdatePassword
}

/**
 * Request parameters for userAuthLoginCreate operation in UserAuthenticationApi.
 * @export
 * @interface UserAuthenticationApiUserAuthLoginCreateRequest
 */
export interface UserAuthenticationApiUserAuthLoginCreateRequest {
    /**
     * 
     * @type {ProfileTokenObtainPairResponse}
     * @memberof UserAuthenticationApiUserAuthLoginCreate
     */
    readonly profileTokenObtainPairResponse: ProfileTokenObtainPairResponse
}

/**
 * Request parameters for userAuthLogoutCreate operation in UserAuthenticationApi.
 * @export
 * @interface UserAuthenticationApiUserAuthLogoutCreateRequest
 */
export interface UserAuthenticationApiUserAuthLogoutCreateRequest {
    /**
     * 
     * @type {Logout}
     * @memberof UserAuthenticationApiUserAuthLogoutCreate
     */
    readonly logout: Logout
}

/**
 * Request parameters for userAuthOneTimePartialUpdate operation in UserAuthenticationApi.
 * @export
 * @interface UserAuthenticationApiUserAuthOneTimePartialUpdateRequest
 */
export interface UserAuthenticationApiUserAuthOneTimePartialUpdateRequest {
    /**
     * 
     * @type {PatchedOneTimeLogin}
     * @memberof UserAuthenticationApiUserAuthOneTimePartialUpdate
     */
    readonly patchedOneTimeLogin?: PatchedOneTimeLogin
}

/**
 * Request parameters for userAuthPasswordPartialUpdate operation in UserAuthenticationApi.
 * @export
 * @interface UserAuthenticationApiUserAuthPasswordPartialUpdateRequest
 */
export interface UserAuthenticationApiUserAuthPasswordPartialUpdateRequest {
    /**
     * 
     * @type {PatchedUserUpdatePassword}
     * @memberof UserAuthenticationApiUserAuthPasswordPartialUpdate
     */
    readonly patchedUserUpdatePassword?: PatchedUserUpdatePassword
}

/**
 * Request parameters for userAuthPasswordResetCreate operation in UserAuthenticationApi.
 * @export
 * @interface UserAuthenticationApiUserAuthPasswordResetCreateRequest
 */
export interface UserAuthenticationApiUserAuthPasswordResetCreateRequest {
    /**
     * 
     * @type {ResetPasswordContact}
     * @memberof UserAuthenticationApiUserAuthPasswordResetCreate
     */
    readonly resetPasswordContact: ResetPasswordContact
}

/**
 * Request parameters for userAuthPasswordResetPartialUpdate operation in UserAuthenticationApi.
 * @export
 * @interface UserAuthenticationApiUserAuthPasswordResetPartialUpdateRequest
 */
export interface UserAuthenticationApiUserAuthPasswordResetPartialUpdateRequest {
    /**
     * 
     * @type {PatchedResetPassword}
     * @memberof UserAuthenticationApiUserAuthPasswordResetPartialUpdate
     */
    readonly patchedResetPassword?: PatchedResetPassword
}

/**
 * Request parameters for userAuthTokenRefreshCreate operation in UserAuthenticationApi.
 * @export
 * @interface UserAuthenticationApiUserAuthTokenRefreshCreateRequest
 */
export interface UserAuthenticationApiUserAuthTokenRefreshCreateRequest {
    /**
     * 
     * @type {TokenRefresh}
     * @memberof UserAuthenticationApiUserAuthTokenRefreshCreate
     */
    readonly tokenRefresh: TokenRefresh
}

/**
 * UserAuthenticationApi - object-oriented interface
 * @export
 * @class UserAuthenticationApi
 * @extends {BaseAPI}
 */
export class UserAuthenticationApi extends BaseAPI {
    /**
     * Captures a user\'s FCM registrationToken
     * @summary Captures a user\'s FCM registrationToken
     * @param {UserAuthenticationApiSellerFcmCreateTokenCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthenticationApi
     */
    public sellerFcmCreateTokenCreate(requestParameters: UserAuthenticationApiSellerFcmCreateTokenCreateRequest, options?: any) {
        return UserAuthenticationApiFp(this.configuration).sellerFcmCreateTokenCreate(requestParameters.fCMRegistrationTokenCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validates the users email and password and logs the user in.   Returning their JWT token, refresh token and their current profile.
     * @summary Authenticates the user
     * @param {UserAuthenticationApiSellerLoginCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthenticationApi
     */
    public sellerLoginCreate(requestParameters: UserAuthenticationApiSellerLoginCreateRequest, options?: any) {
        return UserAuthenticationApiFp(this.configuration).sellerLoginCreate(requestParameters.profileTokenObtainPairResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logs the user out by blacklisting their current refresh
     * @summary Logs the user out
     * @param {UserAuthenticationApiSellerLogoutCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthenticationApi
     */
    public sellerLogoutCreate(requestParameters: UserAuthenticationApiSellerLogoutCreateRequest, options?: any) {
        return UserAuthenticationApiFp(this.configuration).sellerLogoutCreate(requestParameters.logout, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends out an SMS to the user with a 6 digit reset password token
     * @summary Requests to reset the sellers password
     * @param {UserAuthenticationApiSellerResetPasswordCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthenticationApi
     */
    public sellerResetPasswordCreate(requestParameters: UserAuthenticationApiSellerResetPasswordCreateRequest, options?: any) {
        return UserAuthenticationApiFp(this.configuration).sellerResetPasswordCreate(requestParameters.sellerResetPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The code is the code sent out in the SMS  Validation on the code may return a 400 VALIDATION_ERROR if the code has expired after 2 hours from being sent, it is not found, the code and mobile dont match up, or it has already been used before
     * @summary Resets the users password
     * @param {UserAuthenticationApiSellerResetPasswordPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthenticationApi
     */
    public sellerResetPasswordPartialUpdate(requestParameters: UserAuthenticationApiSellerResetPasswordPartialUpdateRequest = {}, options?: any) {
        return UserAuthenticationApiFp(this.configuration).sellerResetPasswordPartialUpdate(requestParameters.patchedSellerResetPasswordConfirm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Accepts a refresh token and returns a new access token and refresh token for the user to use
     * @summary Refreshes a users access token
     * @param {UserAuthenticationApiSellerTokenRefreshCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthenticationApi
     */
    public sellerTokenRefreshCreate(requestParameters: UserAuthenticationApiSellerTokenRefreshCreateRequest, options?: any) {
        return UserAuthenticationApiFp(this.configuration).sellerTokenRefreshCreate(requestParameters.tokenRefresh, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the users password
     * @param {UserAuthenticationApiSellerUpdatePasswordPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthenticationApi
     */
    public sellerUpdatePasswordPartialUpdate(requestParameters: UserAuthenticationApiSellerUpdatePasswordPartialUpdateRequest = {}, options?: any) {
        return UserAuthenticationApiFp(this.configuration).sellerUpdatePasswordPartialUpdate(requestParameters.patchedUserUpdatePassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validates the users email and password and logs the user in.   Returning their JWT token, refresh token and their current profile.
     * @summary Authenticates the user
     * @param {UserAuthenticationApiUserAuthLoginCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthenticationApi
     */
    public userAuthLoginCreate(requestParameters: UserAuthenticationApiUserAuthLoginCreateRequest, options?: any) {
        return UserAuthenticationApiFp(this.configuration).userAuthLoginCreate(requestParameters.profileTokenObtainPairResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logs the user out by blacklisting their current refresh
     * @summary Logs the user out
     * @param {UserAuthenticationApiUserAuthLogoutCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthenticationApi
     */
    public userAuthLogoutCreate(requestParameters: UserAuthenticationApiUserAuthLogoutCreateRequest, options?: any) {
        return UserAuthenticationApiFp(this.configuration).userAuthLogoutCreate(requestParameters.logout, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates a one-time login token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthenticationApi
     */
    public userAuthOneTimeCreate(options?: any) {
        return UserAuthenticationApiFp(this.configuration).userAuthOneTimeCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns valid User access and refresh tokens along with their profile information
     * @summary Validates a one-time login token
     * @param {UserAuthenticationApiUserAuthOneTimePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthenticationApi
     */
    public userAuthOneTimePartialUpdate(requestParameters: UserAuthenticationApiUserAuthOneTimePartialUpdateRequest = {}, options?: any) {
        return UserAuthenticationApiFp(this.configuration).userAuthOneTimePartialUpdate(requestParameters.patchedOneTimeLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the users password
     * @param {UserAuthenticationApiUserAuthPasswordPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthenticationApi
     */
    public userAuthPasswordPartialUpdate(requestParameters: UserAuthenticationApiUserAuthPasswordPartialUpdateRequest = {}, options?: any) {
        return UserAuthenticationApiFp(this.configuration).userAuthPasswordPartialUpdate(requestParameters.patchedUserUpdatePassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends out an email or SMS to the user with a reset password token
     * @summary Requests to reset the users password
     * @param {UserAuthenticationApiUserAuthPasswordResetCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthenticationApi
     */
    public userAuthPasswordResetCreate(requestParameters: UserAuthenticationApiUserAuthPasswordResetCreateRequest, options?: any) {
        return UserAuthenticationApiFp(this.configuration).userAuthPasswordResetCreate(requestParameters.resetPasswordContact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The code is the code sent out with a link via email/sms
     * @summary Resets the users password
     * @param {UserAuthenticationApiUserAuthPasswordResetPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthenticationApi
     */
    public userAuthPasswordResetPartialUpdate(requestParameters: UserAuthenticationApiUserAuthPasswordResetPartialUpdateRequest = {}, options?: any) {
        return UserAuthenticationApiFp(this.configuration).userAuthPasswordResetPartialUpdate(requestParameters.patchedResetPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Accepts a refresh token and returns a new access token and refresh token for the user to use
     * @summary Refreshes a users access token
     * @param {UserAuthenticationApiUserAuthTokenRefreshCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthenticationApi
     */
    public userAuthTokenRefreshCreate(requestParameters: UserAuthenticationApiUserAuthTokenRefreshCreateRequest, options?: any) {
        return UserAuthenticationApiFp(this.configuration).userAuthTokenRefreshCreate(requestParameters.tokenRefresh, options).then((request) => request(this.axios, this.basePath));
    }
}
