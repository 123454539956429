import { useNanoID } from 'hooks/use-nanoid';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { CheckboxWrapper } from '../checkbox';
import { Checkbox } from '../checkbox/checkbox';
import { HelpDialog, HelpDialogProps } from '../help-dialog';
import { Text } from '../text';

export interface CheckboxMultiProps {
  options: {
    value: string;
    label?: string;
    hideLabel?: boolean;
    labelledBy?: string;
    help?: HelpDialogProps;
  }[];
  selectedOption: string | null;
  onChange?: (value: string | null) => void;
  onBlur?: (value: string | null) => void;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: ${globalTheme.space[3]};
`;

const InnerWrapper = styled.div<{ hideLabel?: boolean }>`
  display: grid;
  gap: ${globalTheme.space[0]};

  ${CheckboxWrapper} {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  ${({ hideLabel }) =>
    hideLabel &&
    css`
      display: block;

      ${Text} {
        height: 0;
        visibility: hidden;
      }
    `}
`;

/** Returns multiple checkboxes that behave in a similar fashion to radio buttons (only one selected) but like a checkbox, can be unselected */
export const CheckboxMulti: FC<CheckboxMultiProps> = ({
  options,
  selectedOption,
  onChange,
  onBlur,
}) => {
  const [currentSelection, setCurrentSelection] = useState<string | null>(
    selectedOption
  );
  const wrapperRef = useRef<HTMLDivElement>(null);
  const id = useNanoID();

  const handleUpdate = (
    e: ChangeEvent<HTMLInputElement>,
    type: 'change' | 'blur'
  ) => {
    const newSelection = e.currentTarget.checked ? e.target.value : null;
    setCurrentSelection(newSelection);

    // Uncheck other inputs other than the one that was selected
    wrapperRef.current
      ?.querySelectorAll(`input:not(#${e.target.id})`)
      .forEach((el) => {
        if (el instanceof HTMLInputElement) {
          el.checked = false;
        }
      });

    if (type === 'change') {
      onChange && onChange(newSelection);
    }

    if (type === 'blur') {
      onBlur && onBlur(newSelection);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleUpdate(e, 'change');
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    handleUpdate(e, 'blur');
  };

  // The value has changed outside of this component, update internal state accordingly
  useEffect(() => {
    if (selectedOption !== currentSelection) {
      setCurrentSelection(selectedOption);
    }
  }, [currentSelection, selectedOption]);

  return (
    <Wrapper ref={wrapperRef}>
      {options?.map(({ value, label, hideLabel, labelledBy, help }, index) => (
        <InnerWrapper key={value + index} hideLabel={hideLabel}>
          <Text fontSize={0} id={id + index} as='div'>
            {label} {help && <HelpDialog {...help} />}
          </Text>
          <Checkbox
            value={value}
            aria-labelledby={`${labelledBy} ${id + index}`}
            onChange={handleChange}
            onBlur={handleBlur}
            checked={currentSelection === value}
            data-cy={`${labelledBy?.replace(/\s+|[,\/]/g, '')}${label?.replace(
              /\s+|[,\/]/g,
              ''
            )}Checkbox`}
          />
        </InnerWrapper>
      ))}
    </Wrapper>
  );
};
