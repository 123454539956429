import { FC } from 'react';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Text } from '../text';
import { Tooltip } from '../tooltip';
import { VisuallyHidden } from '../visually-hidden';

export type Step = {
  title: string;
  complete?: boolean;
  active?: boolean;
};

export interface StepIndicatorProps {
  steps: Step[];
  onStepClick?: (step: Step) => void;
}

const Wrapper = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const List = styled.ol`
  /* Gap between each item and the length of the divider line */
  --gap: ${globalTheme.space[3]};

  display: inline-flex;
  gap: var(--gap);
  margin-top: ${globalTheme.space[1]};
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  /* Colors */
  --bg: ${globalTheme.colors.neutral};
  --bar: ${globalTheme.colors.neutral};
  --border: ${globalTheme.colors.neutral};

  position: relative;
  width: 16px;
  height: 16px;
  background-color: var(--bg);
  border-radius: 100%;
  box-shadow: inset 0 0 0 2px var(--border);
  transition: ${globalTheme.transitions.fast};

  button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    border: 0;
    appearance: none;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 100%;
    width: var(--gap);
    height: 3px;
    background-color: var(--bar);
    transform: translateY(-50%);
    transition: ${globalTheme.transitions.fast};
    content: '';
  }

  &:last-of-type::after {
    display: none;
  }
`;

export const StepIndicator: FC<StepIndicatorProps> = ({
  steps,
  onStepClick,
}) => {
  const activeStep = steps.find((step) => step.active);
  return (
    <Wrapper>
      <Text
        aria-live='polite'
        fontSize={4}
        fontWeight='medium'
        data-cy='stepTitle'
      >
        {activeStep?.title === 'Organisation type details'
          ? 'About you'
          : activeStep?.title}
      </Text>
      <List>
        {steps.map((step) => {
          const bgColor = step.active
            ? globalTheme.colors.white
            : step.complete
            ? globalTheme.colors.pink
            : globalTheme.colors.neutral;

          const borderColor = step.active
            ? globalTheme.colors.pink
            : step.complete
            ? globalTheme.colors.pink
            : globalTheme.colors.neutral;

          const barColor = step.complete
            ? globalTheme.colors.pink
            : globalTheme.colors.neutral;

          return (
            <Tooltip key={step.title} label={step.title}>
              <ListItem
                style={{
                  '--bg': bgColor,
                  '--border': borderColor,
                  '--bar': barColor,
                }}
              >
                <button onClick={() => onStepClick?.(step)} type='button'>
                  <VisuallyHidden>
                    {step.complete && !step.active && 'Completed:'}
                    {step.active && 'Current:'} {step.title}
                  </VisuallyHidden>
                </button>
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Wrapper>
  );
};
