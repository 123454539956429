import { darken } from 'polished';
import { FC, forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';

interface StateLabelProps {
  toggleOnValue?: string | undefined;
  toggleOffValue?: string | undefined;
  backgroundColor?: keyof typeof globalTheme.colors;
}
export type ToggleProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type'
> & {
  toggleOnContent?: string;
  toggleOffContent?: string;
  backgroundColor?: keyof typeof globalTheme.colors;
};

const Wrapper = styled.div`
  position: relative;
  display: inline-grid;
  grid-template-rows: 1fr;
  grid-template-columns: max-content 1fr;
  gap: ${globalTheme.space[0]};
  align-items: center;
`;

const StateLabel = styled.span<{ offValue: string | undefined }>`
  display: block;
  grid-row: 1 / 1;
  grid-column: 2 / span 1;
  min-width: ${globalTheme.space[2]};
  font-size: ${globalTheme.fontSizes[0]};

  &::before {
    display: block;
    content: ${({ offValue }) => (offValue ? `"${offValue}"` : `"Off"`)};
  }
`;

const Input = styled.input<StateLabelProps>`
  display: inline-flex;
  grid-row: 1 / 1;
  grid-column: 1 / span 1;
  align-items: center;
  width: 64px;
  height: 32px;
  padding: 0 ${globalTheme.space[0]};
  background-color: ${globalTheme.elements.toggle.backgroundColor};
  border-radius: 32px;
  transition: ${globalTheme.transitions.fast};
  appearance: none;
  cursor: pointer;

  /* Expand clickable area */
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  &::after {
    display: block;
    width: 24px;
    height: 24px;
    background-color: ${globalTheme.elements.toggle.toggleButtonColor};
    border-radius: 100%;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    transition: ${globalTheme.transitions.inOutBack};
    content: '';
  }

  &:active {
    background-color: ${(props) =>
      props.backgroundColor
        ? darken(0.05, globalTheme.elements.toggle.backgroundColor)
        : darken(0.05, globalTheme.elements.toggle.clickedBackgroundColor)};
  }

  &:checked {
    background-color: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : globalTheme.elements.toggle.activeBackgroundColor};

    &::after {
      transform: translateX(100%);
    }

    & + ${StateLabel}::before {
      content: ${({ toggleOnValue }) =>
        toggleOnValue ? `"${toggleOnValue}"` : `"ON"`};
    }
  }
`;

export const Toggle: FC<ToggleProps> = forwardRef<
  HTMLInputElement,
  ToggleProps
>((props, ref) => {
  return (
    <Wrapper>
      <Input
        type='checkbox'
        {...props}
        ref={ref}
        toggleOnValue={props?.toggleOnContent}
        backgroundColor={props?.backgroundColor}
      />
      <StateLabel offValue={props.toggleOffContent} />
    </Wrapper>
  );
});
