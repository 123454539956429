import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Checkbox, CheckboxProps } from '../checkbox';

export interface RadioProps extends CheckboxProps {}

export const Radio = styled(Checkbox)`
  border-radius: 100%;

  &::after {
    position: absolute;
    top: calc(50% - 7px);
    left: calc(50% - 7px);
    width: 14px;
    height: 14px;
    background-color: ${globalTheme.elements.radio.radioButtonColor};
    border-radius: inherit;
    transform: scale(0);
    opacity: 0;
    transition: ${globalTheme.transitions.inOutBack};
    content: '';
  }

  &:checked::after {
    transform: scale(1);
    opacity: 1;
  }
`;

Radio.defaultProps = {
  type: 'radio',
};
