import { useState } from 'react';

/** Helper hook to handle basic dialog open state */
export function useDialogToggle(
  initialState: boolean = false
): [isOpen: boolean, open: () => void, close: () => void] {
  const [isOpen, setIsOpen] = useState(initialState);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return [isOpen, open, close];
}
