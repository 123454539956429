/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { MediaSignature } from '../model';
// @ts-ignore
import { MediaSignatureCreate } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * OrganisationMediaApi - axios parameter creator
 * @export
 */
export const OrganisationMediaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generates a signature to allow content to be uploaded to Cloudinary directly
         * @param {string} buyerOrgId 
         * @param {MediaSignatureCreate} mediaSignatureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaSignatureCreate: async (buyerOrgId: string, mediaSignatureCreate: MediaSignatureCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMediaSignatureCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'mediaSignatureCreate' is not null or undefined
            assertParamExists('orgMediaSignatureCreate', 'mediaSignatureCreate', mediaSignatureCreate)
            const localVarPath = `/v2/org/{buyer_org_id}/media/signature`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mediaSignatureCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationMediaApi - functional programming interface
 * @export
 */
export const OrganisationMediaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationMediaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generates a signature to allow content to be uploaded to Cloudinary directly
         * @param {string} buyerOrgId 
         * @param {MediaSignatureCreate} mediaSignatureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMediaSignatureCreate(buyerOrgId: string, mediaSignatureCreate: MediaSignatureCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaSignature>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMediaSignatureCreate(buyerOrgId, mediaSignatureCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationMediaApi - factory interface
 * @export
 */
export const OrganisationMediaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationMediaApiFp(configuration)
    return {
        /**
         * 
         * @summary Generates a signature to allow content to be uploaded to Cloudinary directly
         * @param {string} buyerOrgId 
         * @param {MediaSignatureCreate} mediaSignatureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaSignatureCreate(buyerOrgId: string, mediaSignatureCreate: MediaSignatureCreate, options?: any): AxiosPromise<MediaSignature> {
            return localVarFp.orgMediaSignatureCreate(buyerOrgId, mediaSignatureCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgMediaSignatureCreate operation in OrganisationMediaApi.
 * @export
 * @interface OrganisationMediaApiOrgMediaSignatureCreateRequest
 */
export interface OrganisationMediaApiOrgMediaSignatureCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaSignatureCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {MediaSignatureCreate}
     * @memberof OrganisationMediaApiOrgMediaSignatureCreate
     */
    readonly mediaSignatureCreate: MediaSignatureCreate
}

/**
 * OrganisationMediaApi - object-oriented interface
 * @export
 * @class OrganisationMediaApi
 * @extends {BaseAPI}
 */
export class OrganisationMediaApi extends BaseAPI {
    /**
     * 
     * @summary Generates a signature to allow content to be uploaded to Cloudinary directly
     * @param {OrganisationMediaApiOrgMediaSignatureCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationMediaApi
     */
    public orgMediaSignatureCreate(requestParameters: OrganisationMediaApiOrgMediaSignatureCreateRequest, options?: any) {
        return OrganisationMediaApiFp(this.configuration).orgMediaSignatureCreate(requestParameters.buyerOrgId, requestParameters.mediaSignatureCreate, options).then((request) => request(this.axios, this.basePath));
    }
}
