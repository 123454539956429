/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { PaginatedSellerContactList } from '../model';
// @ts-ignore
import { SellerContact } from '../model';
/**
 * SellerContactApi - axios parameter creator
 * @export
 */
export const SellerContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Set the invite to ACCEPTED, this will only be possible if the invite is not yet REJECTED (deleted) or BLOCKED, otherwise an error will be raised.
         * @summary Accepts an invite.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactAcceptCreate: async (contactId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('sellerBuyerOrgContactAcceptCreate', 'contactId', contactId)
            const localVarPath = `/v2/seller/buyer_org/contact/{contact_id}/accept`
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Blocks the buyer from contacting the seller again. This can only occur on PENDING or APPROVED contact invites otherwise a 404 will be raised as its in the REJECTED (deleted) state.
         * @summary Blocks a contact.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactBlockCreate: async (contactId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('sellerBuyerOrgContactBlockCreate', 'contactId', contactId)
            const localVarPath = `/v2/seller/buyer_org/contact/{contact_id}/block`
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of the Contacts associated to the user.  # Filtering Options  ## Fields - search str: case insensitive contains query. This will search on the user buyer name, email and phone - status str: limit results to contacts with this states: valid values APPROVED, PENDING, REJECTED Leaving this     empty will default to APPROVED eg. ?status=APPROVED  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=name` To reverse, simply place a hypen in front. eg. `?ordering=-name`  - name: The buyer organisations name - email: The buyer organisation contact email - phone: The buyer organisations contact number - created: The time the contact was created  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isnt one     previous: null <-- previous page number, or null if there isnt one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of the current Contacts for the user
         * @param {Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>} [ordering] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] 
         * @param {'APPROVED' | 'BLOCKED' | 'PENDING' | 'REJECTED'} [status] Current status of the invite, this is not to be updated. It gets updated depending on the values set by the approved, blocked or rejected timestamps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactList: async (ordering?: Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>, page?: number, pageSize?: number, search?: string, status?: 'APPROVED' | 'BLOCKED' | 'PENDING' | 'REJECTED', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/seller/buyer_org/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ordering) {
                localVarQueryParameter['ordering'] = ordering.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mutes the buyer from sending any notifications to the contact.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactMuteCreate: async (contactId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('sellerBuyerOrgContactMuteCreate', 'contactId', contactId)
            const localVarPath = `/v2/seller/buyer_org/contact/{contact_id}/mute`
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set the invite to REJECTED. This will only be possible if it is not BLOCKED already otherwise an error will be raised.  Once REJECTED it will no longer appear in their sellers contact listings and essentially be treated as if its been deleted.
         * @summary Rejects an invite.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactRejectCreate: async (contactId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('sellerBuyerOrgContactRejectCreate', 'contactId', contactId)
            const localVarPath = `/v2/seller/buyer_org/contact/{contact_id}/reject`
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This will be the basic data of the Buyer Organisation
         * @summary Retrieve the details about the Contact
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactRetrieve: async (contactId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('sellerBuyerOrgContactRetrieve', 'contactId', contactId)
            const localVarPath = `/v2/seller/buyer_org/contact/{contact_id}`
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unblocks a contact, this can only occur when the contact is BLOCKED  In the case of where the contact has been deleted on the buyer side and then unblocked, it will be permanently deleted and as a result can return a 204 NO_CONTENT in that case.
         * @summary Unblocks a contact.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactUnblockCreate: async (contactId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('sellerBuyerOrgContactUnblockCreate', 'contactId', contactId)
            const localVarPath = `/v2/seller/buyer_org/contact/{contact_id}/unblock`
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This change can occur at any time, but even when unmuted the user wont get notifications from the Contact until its the status is APPROVED
         * @summary Unmutes the buyer from sending any notifications to the contact.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactUnmuteCreate: async (contactId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('sellerBuyerOrgContactUnmuteCreate', 'contactId', contactId)
            const localVarPath = `/v2/seller/buyer_org/contact/{contact_id}/unmute`
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerContactApi - functional programming interface
 * @export
 */
export const SellerContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerContactApiAxiosParamCreator(configuration)
    return {
        /**
         * Set the invite to ACCEPTED, this will only be possible if the invite is not yet REJECTED (deleted) or BLOCKED, otherwise an error will be raised.
         * @summary Accepts an invite.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerBuyerOrgContactAcceptCreate(contactId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerBuyerOrgContactAcceptCreate(contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Blocks the buyer from contacting the seller again. This can only occur on PENDING or APPROVED contact invites otherwise a 404 will be raised as its in the REJECTED (deleted) state.
         * @summary Blocks a contact.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerBuyerOrgContactBlockCreate(contactId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerBuyerOrgContactBlockCreate(contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of the Contacts associated to the user.  # Filtering Options  ## Fields - search str: case insensitive contains query. This will search on the user buyer name, email and phone - status str: limit results to contacts with this states: valid values APPROVED, PENDING, REJECTED Leaving this     empty will default to APPROVED eg. ?status=APPROVED  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=name` To reverse, simply place a hypen in front. eg. `?ordering=-name`  - name: The buyer organisations name - email: The buyer organisation contact email - phone: The buyer organisations contact number - created: The time the contact was created  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isnt one     previous: null <-- previous page number, or null if there isnt one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of the current Contacts for the user
         * @param {Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>} [ordering] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] 
         * @param {'APPROVED' | 'BLOCKED' | 'PENDING' | 'REJECTED'} [status] Current status of the invite, this is not to be updated. It gets updated depending on the values set by the approved, blocked or rejected timestamps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerBuyerOrgContactList(ordering?: Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>, page?: number, pageSize?: number, search?: string, status?: 'APPROVED' | 'BLOCKED' | 'PENDING' | 'REJECTED', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSellerContactList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerBuyerOrgContactList(ordering, page, pageSize, search, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mutes the buyer from sending any notifications to the contact.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerBuyerOrgContactMuteCreate(contactId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerBuyerOrgContactMuteCreate(contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set the invite to REJECTED. This will only be possible if it is not BLOCKED already otherwise an error will be raised.  Once REJECTED it will no longer appear in their sellers contact listings and essentially be treated as if its been deleted.
         * @summary Rejects an invite.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerBuyerOrgContactRejectCreate(contactId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerBuyerOrgContactRejectCreate(contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This will be the basic data of the Buyer Organisation
         * @summary Retrieve the details about the Contact
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerBuyerOrgContactRetrieve(contactId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerBuyerOrgContactRetrieve(contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unblocks a contact, this can only occur when the contact is BLOCKED  In the case of where the contact has been deleted on the buyer side and then unblocked, it will be permanently deleted and as a result can return a 204 NO_CONTENT in that case.
         * @summary Unblocks a contact.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerBuyerOrgContactUnblockCreate(contactId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerBuyerOrgContactUnblockCreate(contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This change can occur at any time, but even when unmuted the user wont get notifications from the Contact until its the status is APPROVED
         * @summary Unmutes the buyer from sending any notifications to the contact.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerBuyerOrgContactUnmuteCreate(contactId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerBuyerOrgContactUnmuteCreate(contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SellerContactApi - factory interface
 * @export
 */
export const SellerContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerContactApiFp(configuration)
    return {
        /**
         * Set the invite to ACCEPTED, this will only be possible if the invite is not yet REJECTED (deleted) or BLOCKED, otherwise an error will be raised.
         * @summary Accepts an invite.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactAcceptCreate(contactId: string, options?: any): AxiosPromise<SellerContact> {
            return localVarFp.sellerBuyerOrgContactAcceptCreate(contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * Blocks the buyer from contacting the seller again. This can only occur on PENDING or APPROVED contact invites otherwise a 404 will be raised as its in the REJECTED (deleted) state.
         * @summary Blocks a contact.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactBlockCreate(contactId: string, options?: any): AxiosPromise<SellerContact> {
            return localVarFp.sellerBuyerOrgContactBlockCreate(contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of the Contacts associated to the user.  # Filtering Options  ## Fields - search str: case insensitive contains query. This will search on the user buyer name, email and phone - status str: limit results to contacts with this states: valid values APPROVED, PENDING, REJECTED Leaving this     empty will default to APPROVED eg. ?status=APPROVED  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=name` To reverse, simply place a hypen in front. eg. `?ordering=-name`  - name: The buyer organisations name - email: The buyer organisation contact email - phone: The buyer organisations contact number - created: The time the contact was created  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isnt one     previous: null <-- previous page number, or null if there isnt one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of the current Contacts for the user
         * @param {Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>} [ordering] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] 
         * @param {'APPROVED' | 'BLOCKED' | 'PENDING' | 'REJECTED'} [status] Current status of the invite, this is not to be updated. It gets updated depending on the values set by the approved, blocked or rejected timestamps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactList(ordering?: Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>, page?: number, pageSize?: number, search?: string, status?: 'APPROVED' | 'BLOCKED' | 'PENDING' | 'REJECTED', options?: any): AxiosPromise<PaginatedSellerContactList> {
            return localVarFp.sellerBuyerOrgContactList(ordering, page, pageSize, search, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mutes the buyer from sending any notifications to the contact.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactMuteCreate(contactId: string, options?: any): AxiosPromise<SellerContact> {
            return localVarFp.sellerBuyerOrgContactMuteCreate(contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * Set the invite to REJECTED. This will only be possible if it is not BLOCKED already otherwise an error will be raised.  Once REJECTED it will no longer appear in their sellers contact listings and essentially be treated as if its been deleted.
         * @summary Rejects an invite.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactRejectCreate(contactId: string, options?: any): AxiosPromise<void> {
            return localVarFp.sellerBuyerOrgContactRejectCreate(contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * This will be the basic data of the Buyer Organisation
         * @summary Retrieve the details about the Contact
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactRetrieve(contactId: string, options?: any): AxiosPromise<SellerContact> {
            return localVarFp.sellerBuyerOrgContactRetrieve(contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * Unblocks a contact, this can only occur when the contact is BLOCKED  In the case of where the contact has been deleted on the buyer side and then unblocked, it will be permanently deleted and as a result can return a 204 NO_CONTENT in that case.
         * @summary Unblocks a contact.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactUnblockCreate(contactId: string, options?: any): AxiosPromise<SellerContact> {
            return localVarFp.sellerBuyerOrgContactUnblockCreate(contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * This change can occur at any time, but even when unmuted the user wont get notifications from the Contact until its the status is APPROVED
         * @summary Unmutes the buyer from sending any notifications to the contact.
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgContactUnmuteCreate(contactId: string, options?: any): AxiosPromise<SellerContact> {
            return localVarFp.sellerBuyerOrgContactUnmuteCreate(contactId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sellerBuyerOrgContactAcceptCreate operation in SellerContactApi.
 * @export
 * @interface SellerContactApiSellerBuyerOrgContactAcceptCreateRequest
 */
export interface SellerContactApiSellerBuyerOrgContactAcceptCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerContactApiSellerBuyerOrgContactAcceptCreate
     */
    readonly contactId: string
}

/**
 * Request parameters for sellerBuyerOrgContactBlockCreate operation in SellerContactApi.
 * @export
 * @interface SellerContactApiSellerBuyerOrgContactBlockCreateRequest
 */
export interface SellerContactApiSellerBuyerOrgContactBlockCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerContactApiSellerBuyerOrgContactBlockCreate
     */
    readonly contactId: string
}

/**
 * Request parameters for sellerBuyerOrgContactList operation in SellerContactApi.
 * @export
 * @interface SellerContactApiSellerBuyerOrgContactListRequest
 */
export interface SellerContactApiSellerBuyerOrgContactListRequest {
    /**
     * Ordering
     * @type {Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>}
     * @memberof SellerContactApiSellerBuyerOrgContactList
     */
    readonly ordering?: Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof SellerContactApiSellerBuyerOrgContactList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SellerContactApiSellerBuyerOrgContactList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof SellerContactApiSellerBuyerOrgContactList
     */
    readonly search?: string

    /**
     * Current status of the invite, this is not to be updated. It gets updated depending on the values set by the approved, blocked or rejected timestamps
     * @type {'APPROVED' | 'BLOCKED' | 'PENDING' | 'REJECTED'}
     * @memberof SellerContactApiSellerBuyerOrgContactList
     */
    readonly status?: 'APPROVED' | 'BLOCKED' | 'PENDING' | 'REJECTED'
}

/**
 * Request parameters for sellerBuyerOrgContactMuteCreate operation in SellerContactApi.
 * @export
 * @interface SellerContactApiSellerBuyerOrgContactMuteCreateRequest
 */
export interface SellerContactApiSellerBuyerOrgContactMuteCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerContactApiSellerBuyerOrgContactMuteCreate
     */
    readonly contactId: string
}

/**
 * Request parameters for sellerBuyerOrgContactRejectCreate operation in SellerContactApi.
 * @export
 * @interface SellerContactApiSellerBuyerOrgContactRejectCreateRequest
 */
export interface SellerContactApiSellerBuyerOrgContactRejectCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerContactApiSellerBuyerOrgContactRejectCreate
     */
    readonly contactId: string
}

/**
 * Request parameters for sellerBuyerOrgContactRetrieve operation in SellerContactApi.
 * @export
 * @interface SellerContactApiSellerBuyerOrgContactRetrieveRequest
 */
export interface SellerContactApiSellerBuyerOrgContactRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerContactApiSellerBuyerOrgContactRetrieve
     */
    readonly contactId: string
}

/**
 * Request parameters for sellerBuyerOrgContactUnblockCreate operation in SellerContactApi.
 * @export
 * @interface SellerContactApiSellerBuyerOrgContactUnblockCreateRequest
 */
export interface SellerContactApiSellerBuyerOrgContactUnblockCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerContactApiSellerBuyerOrgContactUnblockCreate
     */
    readonly contactId: string
}

/**
 * Request parameters for sellerBuyerOrgContactUnmuteCreate operation in SellerContactApi.
 * @export
 * @interface SellerContactApiSellerBuyerOrgContactUnmuteCreateRequest
 */
export interface SellerContactApiSellerBuyerOrgContactUnmuteCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerContactApiSellerBuyerOrgContactUnmuteCreate
     */
    readonly contactId: string
}

/**
 * SellerContactApi - object-oriented interface
 * @export
 * @class SellerContactApi
 * @extends {BaseAPI}
 */
export class SellerContactApi extends BaseAPI {
    /**
     * Set the invite to ACCEPTED, this will only be possible if the invite is not yet REJECTED (deleted) or BLOCKED, otherwise an error will be raised.
     * @summary Accepts an invite.
     * @param {SellerContactApiSellerBuyerOrgContactAcceptCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerContactApi
     */
    public sellerBuyerOrgContactAcceptCreate(requestParameters: SellerContactApiSellerBuyerOrgContactAcceptCreateRequest, options?: any) {
        return SellerContactApiFp(this.configuration).sellerBuyerOrgContactAcceptCreate(requestParameters.contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Blocks the buyer from contacting the seller again. This can only occur on PENDING or APPROVED contact invites otherwise a 404 will be raised as its in the REJECTED (deleted) state.
     * @summary Blocks a contact.
     * @param {SellerContactApiSellerBuyerOrgContactBlockCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerContactApi
     */
    public sellerBuyerOrgContactBlockCreate(requestParameters: SellerContactApiSellerBuyerOrgContactBlockCreateRequest, options?: any) {
        return SellerContactApiFp(this.configuration).sellerBuyerOrgContactBlockCreate(requestParameters.contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of the Contacts associated to the user.  # Filtering Options  ## Fields - search str: case insensitive contains query. This will search on the user buyer name, email and phone - status str: limit results to contacts with this states: valid values APPROVED, PENDING, REJECTED Leaving this     empty will default to APPROVED eg. ?status=APPROVED  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=name` To reverse, simply place a hypen in front. eg. `?ordering=-name`  - name: The buyer organisations name - email: The buyer organisation contact email - phone: The buyer organisations contact number - created: The time the contact was created  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isnt one     previous: null <-- previous page number, or null if there isnt one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
     * @summary Get a list of the current Contacts for the user
     * @param {SellerContactApiSellerBuyerOrgContactListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerContactApi
     */
    public sellerBuyerOrgContactList(requestParameters: SellerContactApiSellerBuyerOrgContactListRequest = {}, options?: any) {
        return SellerContactApiFp(this.configuration).sellerBuyerOrgContactList(requestParameters.ordering, requestParameters.page, requestParameters.pageSize, requestParameters.search, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mutes the buyer from sending any notifications to the contact.
     * @param {SellerContactApiSellerBuyerOrgContactMuteCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerContactApi
     */
    public sellerBuyerOrgContactMuteCreate(requestParameters: SellerContactApiSellerBuyerOrgContactMuteCreateRequest, options?: any) {
        return SellerContactApiFp(this.configuration).sellerBuyerOrgContactMuteCreate(requestParameters.contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set the invite to REJECTED. This will only be possible if it is not BLOCKED already otherwise an error will be raised.  Once REJECTED it will no longer appear in their sellers contact listings and essentially be treated as if its been deleted.
     * @summary Rejects an invite.
     * @param {SellerContactApiSellerBuyerOrgContactRejectCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerContactApi
     */
    public sellerBuyerOrgContactRejectCreate(requestParameters: SellerContactApiSellerBuyerOrgContactRejectCreateRequest, options?: any) {
        return SellerContactApiFp(this.configuration).sellerBuyerOrgContactRejectCreate(requestParameters.contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This will be the basic data of the Buyer Organisation
     * @summary Retrieve the details about the Contact
     * @param {SellerContactApiSellerBuyerOrgContactRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerContactApi
     */
    public sellerBuyerOrgContactRetrieve(requestParameters: SellerContactApiSellerBuyerOrgContactRetrieveRequest, options?: any) {
        return SellerContactApiFp(this.configuration).sellerBuyerOrgContactRetrieve(requestParameters.contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unblocks a contact, this can only occur when the contact is BLOCKED  In the case of where the contact has been deleted on the buyer side and then unblocked, it will be permanently deleted and as a result can return a 204 NO_CONTENT in that case.
     * @summary Unblocks a contact.
     * @param {SellerContactApiSellerBuyerOrgContactUnblockCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerContactApi
     */
    public sellerBuyerOrgContactUnblockCreate(requestParameters: SellerContactApiSellerBuyerOrgContactUnblockCreateRequest, options?: any) {
        return SellerContactApiFp(this.configuration).sellerBuyerOrgContactUnblockCreate(requestParameters.contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This change can occur at any time, but even when unmuted the user wont get notifications from the Contact until its the status is APPROVED
     * @summary Unmutes the buyer from sending any notifications to the contact.
     * @param {SellerContactApiSellerBuyerOrgContactUnmuteCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerContactApi
     */
    public sellerBuyerOrgContactUnmuteCreate(requestParameters: SellerContactApiSellerBuyerOrgContactUnmuteCreateRequest, options?: any) {
        return SellerContactApiFp(this.configuration).sellerBuyerOrgContactUnmuteCreate(requestParameters.contactId, options).then((request) => request(this.axios, this.basePath));
    }
}
