import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';
import { Box } from '../box';

export const ContentBox = styled(Box)`
  padding: ${globalTheme.space[2]};

  @media screen and (min-width: ${breakpoints.large}) {
    padding: ${globalTheme.space[4]};
  }

  @media screen and (min-width: ${breakpoints.xLarge}) {
    padding: ${globalTheme.space[6]};
  }
`;

ContentBox.defaultProps = {
  backgroundColor: 'white',
};
