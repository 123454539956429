import { FC } from 'react';
import styled, { CSSObject } from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Box } from '../box';

export interface LetterIconProps {
  letter: string;
  color?: keyof typeof globalTheme.colors;
  backgroundColor?: keyof typeof globalTheme.colors;
  size?: keyof typeof globalTheme.space;
  fontWeight?: keyof typeof globalTheme.fontWeights;
  fontSize?: keyof typeof globalTheme.fontSizes;
  className?: string;
}

function getSize(props: Pick<LetterIconProps, 'size'>): CSSObject {
  const sizeValue =
    props.size !== undefined ? globalTheme.space[props.size] : '1em';
  return {
    width: sizeValue,
    height: sizeValue,
  };
}

const Wrapper = styled(Box)`
  ${getSize};

  position: relative;
  border-radius: 100%;
`;

const Icon = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-weight: ${globalTheme.fontWeights.light};
  text-transform: uppercase;
  fill: currentColor;
`;

export const LetterIcon: FC<LetterIconProps> = ({
  className,
  letter,
  color,
  backgroundColor,
  fontWeight: fontWeightProp,
  size,
  fontSize: fontSizeProp,
}) => {
  // Grab the first letter passed in, in case of a whole word etc
  const [firstLetter] = letter.split('');
  const viewBoxSize = parseInt((size && globalTheme.space[size]) || '', 10);
  const fontSize = fontSizeProp
    ? globalTheme.fontSizes[fontSizeProp]
    : viewBoxSize / 2;
  const fontWeight = fontWeightProp
    ? globalTheme.fontWeights[fontWeightProp]
    : globalTheme.fontWeights.regular;

  return (
    <Wrapper
      size={size}
      color={color}
      backgroundColor={backgroundColor}
      aria-hidden
      className={className}
    >
      <Icon viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}>
        <text
          textAnchor='middle'
          dominantBaseline='central'
          x='50%'
          y='50%'
          fontSize={fontSize}
          fontWeight={fontWeight}
        >
          {firstLetter}
        </text>
      </Icon>
    </Wrapper>
  );
};
