/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * SellerSaleyardReportBookmarkApi - axios parameter creator
 * @export
 */
export const SellerSaleyardReportBookmarkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a bookmark on the saleyard report
         * @param {string} saleyardReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerSaleyardReportBookmarkCreate: async (saleyardReportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleyardReportId' is not null or undefined
            assertParamExists('sellerSaleyardReportBookmarkCreate', 'saleyardReportId', saleyardReportId)
            const localVarPath = `/v2/seller/saleyard-report/{saleyard_report_id}/bookmark`
                .replace(`{${"saleyard_report_id"}}`, encodeURIComponent(String(saleyardReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a bookmark against the saleyard report
         * @param {string} saleyardReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerSaleyardReportBookmarkDestroy: async (saleyardReportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleyardReportId' is not null or undefined
            assertParamExists('sellerSaleyardReportBookmarkDestroy', 'saleyardReportId', saleyardReportId)
            const localVarPath = `/v2/seller/saleyard-report/{saleyard_report_id}/bookmark`
                .replace(`{${"saleyard_report_id"}}`, encodeURIComponent(String(saleyardReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerSaleyardReportBookmarkApi - functional programming interface
 * @export
 */
export const SellerSaleyardReportBookmarkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerSaleyardReportBookmarkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a bookmark on the saleyard report
         * @param {string} saleyardReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerSaleyardReportBookmarkCreate(saleyardReportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerSaleyardReportBookmarkCreate(saleyardReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a bookmark against the saleyard report
         * @param {string} saleyardReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerSaleyardReportBookmarkDestroy(saleyardReportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerSaleyardReportBookmarkDestroy(saleyardReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SellerSaleyardReportBookmarkApi - factory interface
 * @export
 */
export const SellerSaleyardReportBookmarkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerSaleyardReportBookmarkApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a bookmark on the saleyard report
         * @param {string} saleyardReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerSaleyardReportBookmarkCreate(saleyardReportId: string, options?: any): AxiosPromise<void> {
            return localVarFp.sellerSaleyardReportBookmarkCreate(saleyardReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a bookmark against the saleyard report
         * @param {string} saleyardReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerSaleyardReportBookmarkDestroy(saleyardReportId: string, options?: any): AxiosPromise<void> {
            return localVarFp.sellerSaleyardReportBookmarkDestroy(saleyardReportId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sellerSaleyardReportBookmarkCreate operation in SellerSaleyardReportBookmarkApi.
 * @export
 * @interface SellerSaleyardReportBookmarkApiSellerSaleyardReportBookmarkCreateRequest
 */
export interface SellerSaleyardReportBookmarkApiSellerSaleyardReportBookmarkCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerSaleyardReportBookmarkApiSellerSaleyardReportBookmarkCreate
     */
    readonly saleyardReportId: string
}

/**
 * Request parameters for sellerSaleyardReportBookmarkDestroy operation in SellerSaleyardReportBookmarkApi.
 * @export
 * @interface SellerSaleyardReportBookmarkApiSellerSaleyardReportBookmarkDestroyRequest
 */
export interface SellerSaleyardReportBookmarkApiSellerSaleyardReportBookmarkDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerSaleyardReportBookmarkApiSellerSaleyardReportBookmarkDestroy
     */
    readonly saleyardReportId: string
}

/**
 * SellerSaleyardReportBookmarkApi - object-oriented interface
 * @export
 * @class SellerSaleyardReportBookmarkApi
 * @extends {BaseAPI}
 */
export class SellerSaleyardReportBookmarkApi extends BaseAPI {
    /**
     * 
     * @summary Creates a bookmark on the saleyard report
     * @param {SellerSaleyardReportBookmarkApiSellerSaleyardReportBookmarkCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerSaleyardReportBookmarkApi
     */
    public sellerSaleyardReportBookmarkCreate(requestParameters: SellerSaleyardReportBookmarkApiSellerSaleyardReportBookmarkCreateRequest, options?: any) {
        return SellerSaleyardReportBookmarkApiFp(this.configuration).sellerSaleyardReportBookmarkCreate(requestParameters.saleyardReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a bookmark against the saleyard report
     * @param {SellerSaleyardReportBookmarkApiSellerSaleyardReportBookmarkDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerSaleyardReportBookmarkApi
     */
    public sellerSaleyardReportBookmarkDestroy(requestParameters: SellerSaleyardReportBookmarkApiSellerSaleyardReportBookmarkDestroyRequest, options?: any) {
        return SellerSaleyardReportBookmarkApiFp(this.configuration).sellerSaleyardReportBookmarkDestroy(requestParameters.saleyardReportId, options).then((request) => request(this.axios, this.basePath));
    }
}
