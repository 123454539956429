import { useRect } from '@reach/rect';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import { AnimatePresence } from 'framer-motion';
import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import {
  getStateValues,
  InputProps,
  InputStyled,
  InputWrapper,
  Label,
  StyledIcon,
  Wrapper,
} from '../input';
import { InputMessage } from '../input-message';

export type ComboboxTextItem = { id?: string; value: string };

export interface ComboboxTextProps
  extends Omit<InputProps, 'as' | 'id' | 'value'> {
  label: string;
  isTablet: boolean;
  items?: ComboboxTextItem[];
  onSelectedItemChange?: (
    changes: UseComboboxStateChange<ComboboxTextItem>
  ) => void;
  isLoading?: boolean;
  initialValue?: ComboboxTextItem;
  value?: ComboboxTextItem;
  hideLabel?: boolean;
  enableNoResultsMessage?: boolean;
  bookingStyle?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  lineLimit?: number;
  type?: 'text' | 'number';
  min?: number;
  max?: number;
  id?: string;
  listUpwards?: boolean;
  onInputChange: (value: { id?: string; value: string }) => void;
  isInitialValue?: any;
  updateError?: boolean;
  textAlign?: string;
}

export const ComboboxTextWrapper = styled(InputWrapper)<{
  bookingStyle?: boolean;
  updateError?: boolean;
}>`
  position: relative;
  min-height: ${(props) => (props.bookingStyle ? '32px' : 'auto')};

  /* TODO: Fix this, z-index with input wrapper */
  &::before {
    position: absolute;
    top: ${globalTheme.space[0]};
    left: -2px;
    z-index: -1;
    width: calc(100% + 4px);
    height: 100%;
    background-color: ${globalTheme.elements.combobox.inputBackgroundColor};
    opacity: 0;
    content: '';
    pointer-events: none;
  }

  &[aria-expanded='true']::before {
    opacity: 1;
  }

  ${({ updateError }) => {
    if (updateError) {
      return css`
        && {
          border-color: red;
        }
      `;
    }
  }}
`;

export const ComboboxTextInput = styled(InputStyled)<{
  bookingStyle?: boolean;
  updateError?: boolean;
  textAlign?: string;
}>`
  ${({ bookingStyle }) => {
    if (bookingStyle) {
      return css`
        padding: 4px 8px;
        font-weight: ${globalTheme.fontWeights.light};
        border-radius: 6px;
        && {
          height: 24px;
          min-height: auto;
        }

        textarea& {
          resize: none;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      `;
    }
  }}
  ${({ textAlign }) => {
    if (textAlign) {
      return css`
        text-align: ${textAlign};
      `;
    }
  }}
`;

export const ComboboxTextList = styled.ul<{
  upwards: boolean;
  textAlign: string;
  isTablet: boolean;
}>`
  z-index: ${globalTheme.zIndices.listBox};
  max-height: 200px;
  overflow: auto;
  font-size: ${globalTheme.fontSizes.default};
  background-color: ${globalTheme.elements.combobox.backgroundColor};
  border: 0;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(17, 17, 17, 0.2);
  transform: ${(props) =>
    props.upwards
      ? `translateY(calc(-480px / 2))`
      : `translateY(calc(${globalTheme.space[0]} / 2))`};
  ${({ textAlign }) => {
    if (textAlign) {
      return css`
        text-align: ${textAlign};
      `;
    }
  }}
  position: ${(props) => (props.isTablet ? 'unset' : 'fixed')};
`;

export const ComboboxTextListItem = styled.li`
  padding: ${globalTheme.space[1]};
  line-height: ${globalTheme.lineHeights.heading};
  cursor: default;
  transition: ${globalTheme.transitions.fast};

  &[aria-selected='true'] {
    color: ${globalTheme.elements.combobox.resultHoverColor};
    background-color: ${globalTheme.elements.combobox
      .resultHoverBackgroundColor};
  }
`;

export const ScreenReaderLabel = styled(Label)`
  & {
    position: absolute;
    top: auto;
    left: -10000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
`;

export const ComboboxText: FC<ComboboxTextProps> = ({
  label,
  message,
  state,
  icon,
  items: listItems = [],
  onSelectedItemChange,
  isLoading,
  initialValue: passedInitialValue,
  value,
  hideLabel,
  enableNoResultsMessage,
  onInputChange,
  bookingStyle,
  disabled,
  type,
  min,
  max,
  multiline,
  lineLimit,
  id,
  listUpwards,
  isInitialValue,
  updateError,
  textAlign,
  isTablet,
}) => {
  // Set initial selection
  const initialValue = useRef(passedInitialValue);
  const [items, setItems] = useState(listItems);
  const [text, setText] = useState<string | undefined>(
    passedInitialValue?.value
  );

  // Expand and shrink the text
  useLayoutEffect(() => {
    const input = wrapperRef.current?.querySelector('textarea');
    if (input && multiline) {
      // Reset - important to shrink on delete
      input.style.height = 'inherit';
      input.rows = 1;

      const styles = window.getComputedStyle(input);
      const height = input.scrollHeight;
      const lineHeight = parseInt(styles.getPropertyValue('line-height'));
      const taRows = Math.ceil(height / lineHeight) - 1;

      // Set rows/lines
      if (lineLimit) {
        input.rows = taRows <= lineLimit ? taRows : lineLimit;
      } else {
        input.rows = taRows;
      }
    }
  }, [text, multiline, lineLimit]);

  /** Filter the list with the input value */
  const filterList = (inputValue: string | undefined) => {
    return listItems.filter((item) => {
      return (
        !inputValue ||
        item.value.toLowerCase().includes(inputValue.toLowerCase())
      );
    });
  };

  const {
    isOpen,
    selectedItem,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    inputValue,
    reset,
    selectItem,
  } = useCombobox({
    items,
    onInputValueChange({ inputValue }) {
      setText(inputValue);
      setItems(filterList(inputValue)); // Filters the list as they type
    },
    onSelectedItemChange,
    itemToString: (item) => item?.value || '',
    initialSelectedItem: initialValue.current,
    stateReducer: useCallback(
      (state, actionAndChanges) => {
        const { type, changes } = actionAndChanges;
        switch (type) {
          // When they type a value into the input
          case useCombobox.stateChangeTypes.InputBlur:
            onInputChange && onInputChange({ value: changes.inputValue });
            return {
              ...changes,
            };

          // When they select a value in the list
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            onInputChange && onInputChange(changes.selectedItem);
            return {
              ...changes,
            };

          default:
            return changes;
        }
      },
      [onInputChange]
    ),
  });

  const { stateColor } = getStateValues(state);

  // Popper reference element
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  // Observe the bounding rect of the input wrapper so that the list can be positioned
  const wrapperRef = useRef<HTMLDivElement>(null);
  const rect = useRect(wrapperRef, { observe: isOpen });

  // Handle selected item based on external changes to the value prop
  useEffect(() => {
    if (value !== undefined && selectedItem?.id !== value?.id) {
      if (value?.id) {
        // Set value
        selectItem(value);
      } else {
        // Reset if value is empty
        reset();
      }
    }
  }, [reset, selectItem, selectedItem?.id, value]);

  return (
    <Wrapper ref={setReferenceElement}>
      {label && !hideLabel && <Label {...getLabelProps()}>{label}</Label>}
      {label && hideLabel && (
        <ScreenReaderLabel {...getLabelProps()}>{label}</ScreenReaderLabel>
      )}
      <div
        ref={wrapperRef}
        data-cy={`${label.replace(/\s+|[,\/]/g, '')}InputBox`}
      >
        <ComboboxTextWrapper
          bookingStyle={bookingStyle}
          updateError={updateError}
          color={stateColor}
        >
          {icon && <StyledIcon component={icon} size={2} />}
          <ComboboxTextInput
            {...getInputProps({
              ...{
                bookingStyle,
                updateError,
                textAlign,
                type,
                min,
                max,
                id,
              },
            })}
            data-state={state}
            pattern={type === 'number' ? '^[-d]d*$' : undefined}
            step={type === 'number' ? 1 : undefined}
            onKeyDown={(ev) => {
              if (type === 'number') {
                const intRegex = /\d/;
                if (
                  (ev.currentTarget.value.length && ev.key === '.') || //input can be of any length and may be followed by . or not
                  ev.key.length > 1 ||
                  ev.ctrlKey ||
                  intRegex.test(ev.key)
                ) {
                  return false;
                }

                ev.preventDefault();
              }
            }}
            onBlurCapture={(ev) => {
              if (ev.target.value === initialValue.current?.value) {
                isInitialValue(true);
              } else {
                isInitialValue(false);
              }
            }}
            disabled={disabled}
            textAlign={'left'}
          />
        </ComboboxTextWrapper>
      </div>
      <ComboboxTextList
        {...getMenuProps()}
        upwards={listUpwards}
        textAlign={textAlign}
        style={{
          width: 'fit-content',
          minWidth: rect?.width,
          top: (rect?.top || 0) + (rect?.height || 0),
          left: rect?.left,
        }}
        isTablet={isTablet}
      >
        {isLoading && !selectedItem && (
          <ComboboxTextListItem>Loading...</ComboboxTextListItem>
        )}

        {enableNoResultsMessage &&
          inputValue &&
          items.length === 0 &&
          !isLoading &&
          !selectedItem && (
            <ComboboxTextListItem>No results</ComboboxTextListItem>
          )}

        {isOpen &&
          !isLoading &&
          items.map((item, index) => (
            <ComboboxTextListItem
              key={`${item}-${index}`}
              {...getItemProps({ item, index })}
            >
              {item.value}
            </ComboboxTextListItem>
          ))}
      </ComboboxTextList>
      <AnimatePresence>
        {message && (
          <InputMessage
            backgroundColor={stateColor}
            referenceElement={referenceElement}
          >
            {message}
          </InputMessage>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};
