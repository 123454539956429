import { useQuery } from '@tanstack/react-query';
import { ApiClient } from 'lib/api/client';
import { QUERY_KEYS } from 'settings/config';
import { useApiReady } from './use-api-ready';

/** Get the general config object */
export function useConfig() {
  const enabled = useApiReady();

  return useQuery(
    [QUERY_KEYS.config],
    async () => {
      const { data } = await new ApiClient().api.configRetrieve();
      return data;
    },
    {
      enabled: enabled,
    }
  );
}
