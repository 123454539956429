import { logger } from '@hatchd/utils';
import { Terms } from 'components/terms';
import { Button, Dialog, Stack, Text } from 'components/ui';
import { useDialogToggle } from 'hooks/use-dialog-toggle';
import { isBrowser, signOutCurrentUser } from 'lib/helpers';
import { useSession } from 'next-auth/react';
import { FC, useEffect } from 'react';
import { FIRST_LOGIN_STORAGE_KEY } from 'settings/config';

export const UserTermsDialog: FC = () => {
  const { data: session } = useSession();
  const [isOpen, open, close] = useDialogToggle(false);

  useEffect(() => {
    if (
      isBrowser &&
      localStorage.getItem(FIRST_LOGIN_STORAGE_KEY) === 'true' &&
      !!session
    ) {
      logger.info('Showing first time login, T&Cs');
      open();
    }
  }, [open, session]);

  const handleDismiss = async () => {
    await signOutCurrentUser();
    close();
  };

  const handleAgree = () => {
    localStorage.removeItem(FIRST_LOGIN_STORAGE_KEY);
    close();
  };

  return (
    <Dialog
      title='Terms of service'
      isOpen={isOpen}
      onDismiss={handleDismiss}
      actions={
        <>
          <Button size='small' variant='outline' onClick={handleDismiss}>
            I disagree
          </Button>
          <Button size='small' onClick={handleAgree}>
            I agree
          </Button>
        </>
      }
    >
      <Text as='div'>
        <Stack space={1}>
          <Terms />
        </Stack>
      </Text>
    </Dialog>
  );
};
