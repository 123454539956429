/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum BookingPropertyConfigTypeEnum {
    Checkbox = 'CHECKBOX',
    Date = 'DATE',
    Float = 'FLOAT',
    Integer = 'INTEGER',
    Select = 'SELECT',
    Text = 'TEXT',
    Contact = 'CONTACT',
    Pic = 'PIC',
    PriceGrid = 'PRICE_GRID',
    Staff = 'STAFF',
    Media = 'MEDIA',
    Calculated = 'CALCULATED',
    PicAttribute = 'PIC_ATTRIBUTE'
}



