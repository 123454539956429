import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';
import { Box, BoxProps } from '../box';

export interface CardProps extends BoxProps {}

export const Card = styled(Box)`
  position: relative;
  padding: ${globalTheme.space[2]};
  border-radius: 20px;

  @media screen and (min-width: ${breakpoints.large}) {
    padding: ${globalTheme.space[3]};
  }
`;

Card.defaultProps = {
  backgroundColor: 'background',
  color: 'darkGrey',
};
