import { theme } from '@hatchddigital/livestock-pricing-shared-utils';
import { LogoDark } from 'components/images';
import { Link } from 'components/link';
import { Button, Icon, LetterIcon, Text, VisuallyHidden } from 'components/ui';
import { UserNav, UserNavLink } from 'components/user-nav';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { useStore } from 'lib/store';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { useMedia } from 'react-use';
import { HEADER_OVERRIDE_PORTAL_ID, PATHS } from 'settings/config';
import styled, { css } from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';
import { useIntercom } from 'react-use-intercom';
import { useSession } from 'next-auth/react';
import { TokenObtainPairResponse } from '../../lib/api/generated';

export interface HeaderProps {
  userName: string;
  businessName?: string;
  overrideContent?: boolean;
  hideHomeLink?: boolean;
  hideUserNav?: boolean;
  hideTagline?: boolean;
  isPublic?: boolean;
  actionText?: string;
  taglineText?: string;
  onActionClick?: () => void;
}

const variants: Variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const Wrapper = styled.header<{
  hideHomeLink: boolean;
  isPublic: boolean;
  isWide: boolean;
  overrideContent?: boolean;
}>`
  z-index: ${globalTheme.zIndices.nav};
  display: grid;
  grid-template-columns: ${({ hideHomeLink, isWide }) =>
    hideHomeLink ? '1fr' : 'max-content 1fr'};
  gap: ${globalTheme.space[1]};
  align-items: center;
  height: ${globalTheme.sizes.header};
  padding: ${({ isPublic }) => isPublic && `0 ${theme.space[1]}`};
  color: ${globalTheme.elements.header.color};
  background: ${globalTheme.elements.header.backgroundColor};
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.12);
  ${({ overrideContent }) =>
    overrideContent &&
    css`
      grid-template-columns: max-content 1fr;
    `}
`;

const Content = styled(motion.div)<{ $hideTagline: boolean }>`
  display: grid;
  grid-template-columns: ${({ $hideTagline }) =>
    $hideTagline ? '1fr repeat(2, max-content)' : '1fr repeat(3, max-content)'};
  gap: ${globalTheme.space[1]};
  align-items: center;
  height: inherit;

  @media screen and (max-width: ${breakpoints.large}) {
    ${({ $hideTagline }) => {
      if (!$hideTagline) {
        return css`
          display: flex;
          gap: 0;
          justify-content: flex-end;
        `;
      }
    }}
  }
`;

const HomeLink = styled.a<{ publicLogo?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ publicLogo }) =>
    publicLogo ? 'auto' : globalTheme.sizes.header};
  height: inherit;
  border-right: 1px solid ${globalTheme.elements.header.homeLinkBorderColor};
`;

const BusinessName = styled(Text)`
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: ${globalTheme.space[0]};
  align-items: center;
  width: ${globalTheme.space[2]};
  overflow: hidden;

  @media screen and (min-width: ${breakpoints.medium}) {
    width: auto;
  }
`;

const PublicLogo = styled.figure`
  width: 80px;
  svg {
    width: 100%;
    height: auto;
  }
`;

const Tagline = styled.p`
  color: ${theme.colors};
  background-color: transparent;

  @media screen and (max-width: ${breakpoints.large}) {
    display: none;
  }
`;

const OverrideTarget = styled(motion.div)`
  height: 100%;
`;

const HeaderLeftContent = styled(motion.div)`
  display: grid;
  grid-template-columns: '1fr repeat(2, max-content)';
  gap: ${globalTheme.space[2]};
  align-items: center;
  height: inherit;
`;

const IntercomLauncher = styled.div`
  margin-right: ${globalTheme.space[1]};
`;

export const UserNavLinks: UserNavLink[] = [
  {
    title: 'Change Organisations',
    href: PATHS.organisation.picker,
    icon: 'AbnIcon',
  },
  {
    title: 'Manage Profile',
    href: PATHS.profile.home,
    icon: 'ProfileIcon',
  },
];

export const Header: FC<HeaderProps> = ({
  businessName,
  userName,
  overrideContent,
  actionText = '',
  taglineText = '',
  hideHomeLink = false,
  hideUserNav = false,
  hideTagline = true,
  isPublic = false,
  onActionClick,
}) => {
  const isWide = useMedia(`(min-width: ${breakpoints.medium})`, true);
  const setOverrideHeaderContent = useStore(
    (store) => store.setOverrideHeaderContent
  );

  const session = useSession();

  const { boot, show } = useIntercom();

  const router = useRouter();
  const isNavOpen = useStore((store) => store.isNavOpen);
  const toggleNav = useStore((store) => store.toggleNav);

  useEffect(() => {
    // update Intercom instance with user info, either from portal based authed session or an app auth session
    boot({
      name:
        (session?.data?.user?.firstname &&
          `${session?.data?.user?.firstname} ${session?.data?.user?.lastname}`) ||
        `${
          (session?.data?.user as unknown as TokenObtainPairResponse)?.profile
            ?.firstname
        } ${
          (session?.data?.user as unknown as TokenObtainPairResponse)?.profile
            ?.lastname
        }`,
      email:
        session?.data?.user?.email ||
        (session?.data?.user as unknown as TokenObtainPairResponse)?.profile
          ?.email,
      createdAt:
        session?.data?.user?.created ||
        (session?.data?.user as unknown as TokenObtainPairResponse)?.profile
          ?.created,
      phone:
        session?.data?.user?.mobile ||
        (session?.data?.user as unknown as TokenObtainPairResponse)?.profile
          ?.mobile,
      userId:
        session?.data?.user?.user_id ||
        (session?.data?.user as unknown as TokenObtainPairResponse)?.profile
          ?.user_id,
      hideDefaultLauncher: isWide ? false : true,
    });
  }, [session, isWide]);

  return (
    <Wrapper
      hideHomeLink={hideHomeLink}
      isPublic={isPublic}
      isWide={router.pathname === PATHS.organisation.picker ? true : isWide}
      overrideContent={overrideContent}
    >
      {/**TODO: hide this for now */}
      {/* {!hideUserNav &&
        isWide === false &&
        router.pathname !== PATHS.organisation.picker &&
        !overrideContent &&
        (isNavOpen ? (
          <CloseMenu
            size={globalTheme.fontSizes[5]}
            onClick={() => toggleNav()}
          />
        ) : (
          <Hamburgermenu
            size={globalTheme.fontSizes[5]}
            onClick={() => toggleNav()}
          />
        ))} */}
      {!hideHomeLink && (
        <Link href={PATHS.home} passHref>
          <HeaderLeftContent>
            <HomeLink data-cy='headerHomeLink' publicLogo={isPublic}>
              {isPublic ? (
                <PublicLogo>
                  <LogoDark />
                </PublicLogo>
              ) : (
                <Icon component='AgoraLogo' size={3} dontFill />
              )}
              <VisuallyHidden as='span'>Home</VisuallyHidden>
            </HomeLink>
          </HeaderLeftContent>
        </Link>
      )}
      <AnimatePresence exitBeforeEnter>
        {overrideContent ? (
          // Target Portal element for override content
          <OverrideTarget
            id={HEADER_OVERRIDE_PORTAL_ID}
            key='portalContent'
            variants={variants}
            animate={overrideContent ? 'visible' : 'hidden'}
            initial='hidden'
            exit='hidden'
            onAnimationStart={() => {
              // Indicate to the <HeaderContentOverride /> component that the target div is ready
              setOverrideHeaderContent(true);
            }}
            onAnimationComplete={(def) => {
              // Reset state after animation is complete
              setOverrideHeaderContent(def === 'visible');
            }}
          />
        ) : (
          <Content
            key='content'
            variants={variants}
            $hideTagline={hideTagline}
            animate={overrideContent ? 'hidden' : 'visible'}
            initial='hidden'
            exit='hidden'
          >
            <BusinessName as='div'>
              {businessName && (
                <LetterIcon
                  letter={businessName}
                  size={2}
                  fontSize='default'
                  backgroundColor='neutral'
                  color='darkGrey'
                  fontWeight='extraBold'
                />
              )}
              {businessName}
            </BusinessName>
            {/* TODO: Notification menu */}
            {!hideUserNav && (
              <UserNav links={UserNavLinks} userName={userName} />
            )}
            {!hideTagline && (
              <Tagline>
                {taglineText
                  ? taglineText
                  : 'Download the app to access all prices and features!'}
              </Tagline>
            )}
            {onActionClick && (
              <Button size='small' onClick={onActionClick}>
                {actionText}
              </Button>
            )}
            {!isWide && (
              <IntercomLauncher onClick={show}>
                <Icon component='IntercomIcon' size={4} dontFill />
              </IntercomLauncher>
            )}
          </Content>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};
