import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';

export const SectionCard = styled.div`
  padding: ${globalTheme.space[3]};
  background-color: ${globalTheme.elements.card.backgroundColor};
  border-radius: 32px;
  box-shadow: 0 16px 32px rgba(17, 17, 17, 0.04);

  @media screen and (min-width: ${breakpoints.small}) {
    padding: ${globalTheme.space[5]};
  }

  @media screen and (min-width: ${breakpoints.medium}) {
    padding: ${globalTheme.space[7]};
  }

  @media screen and (min-width: ${breakpoints.large}) {
    padding: ${globalTheme.space[9]};
  }

  @media screen and (min-width: ${breakpoints.xLarge}) {
    padding: ${globalTheme.space[11]};
  }
`;
