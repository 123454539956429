/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { StripePayload } from '../model';
/**
 * StripeApi - axios parameter creator
 * @export
 */
export const StripeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * N/A
         * @summary Stripe webhook endpoints
         * @param {StripePayload} stripePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhookCreate: async (stripePayload: StripePayload, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripePayload' is not null or undefined
            assertParamExists('stripeWebhookCreate', 'stripePayload', stripePayload)
            const localVarPath = `/v2/stripe/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stripePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeApi - functional programming interface
 * @export
 */
export const StripeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StripeApiAxiosParamCreator(configuration)
    return {
        /**
         * N/A
         * @summary Stripe webhook endpoints
         * @param {StripePayload} stripePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeWebhookCreate(stripePayload: StripePayload, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeWebhookCreate(stripePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StripeApi - factory interface
 * @export
 */
export const StripeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StripeApiFp(configuration)
    return {
        /**
         * N/A
         * @summary Stripe webhook endpoints
         * @param {StripePayload} stripePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhookCreate(stripePayload: StripePayload, options?: any): AxiosPromise<void> {
            return localVarFp.stripeWebhookCreate(stripePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for stripeWebhookCreate operation in StripeApi.
 * @export
 * @interface StripeApiStripeWebhookCreateRequest
 */
export interface StripeApiStripeWebhookCreateRequest {
    /**
     * 
     * @type {StripePayload}
     * @memberof StripeApiStripeWebhookCreate
     */
    readonly stripePayload: StripePayload
}

/**
 * StripeApi - object-oriented interface
 * @export
 * @class StripeApi
 * @extends {BaseAPI}
 */
export class StripeApi extends BaseAPI {
    /**
     * N/A
     * @summary Stripe webhook endpoints
     * @param {StripeApiStripeWebhookCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeWebhookCreate(requestParameters: StripeApiStripeWebhookCreateRequest, options?: any) {
        return StripeApiFp(this.configuration).stripeWebhookCreate(requestParameters.stripePayload, options).then((request) => request(this.axios, this.basePath));
    }
}
