import { FC, Fragment } from 'react';
import { DropzoneRootProps, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Stack } from '../stack';
import { TextButton } from '../text-button';

type AcceptableFileType = 'pdf' | 'jpeg' | 'jpg' | 'png' | 'csv';

export interface FileDropzoneProps {
  dropzoneText?: string;
  hideButtonLink?: boolean;
  /** File size allowed in bytes */
  acceptedFileSize?: number;
  /** Accepts all file types if nothing set */
  acceptedFileTypes?: AcceptableFileType[];
  /** Callback when a file is successfully dropped */
  onDropFile?: (file: File[]) => void;
  disabled?: boolean;
}

const getColor = (props: FileDropzoneProps & DropzoneRootProps) => {
  if (props.isDragAccept) {
    return globalTheme.colors.blue;
  }
  if (props.isDragReject) {
    return globalTheme.colors.orange;
  }
  if (props.isFocused) {
    return globalTheme.colors.darkGrey;
  }
  if (props.disabled) {
    return globalTheme.colors.neutralLight;
  }
  return globalTheme.colors.black;
};

const Container = styled.div<FileDropzoneProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: ${globalTheme.space[10]} 0;
  color: ${(props) => getColor(props)};
  background-color: #fafafa;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  border-width: 2px;
  border-radius: 2px;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const FileError = styled.p`
  margin-top: ${globalTheme.space[0]};
  color: ${globalTheme.colors.orange};
  font-size: ${globalTheme.fontSizes[1]};
`;

export const FileDropzone: FC<FileDropzoneProps> = ({
  dropzoneText = 'Drag your file here to upload',
  acceptedFileSize = 2000000,
  hideButtonLink = false,
  acceptedFileTypes,
  onDropFile,
  disabled,
}) => {
  const fileValidator = (file: File) => {
    // Check if file types is allowed based on prop
    const fileType = file.type.split('/')[1] as AcceptableFileType;
    if (acceptedFileTypes && !acceptedFileTypes.includes(fileType)) {
      return {
        code: 'filetype-not-allowed',
        message: `Please add file with type of .${acceptedFileTypes.join(
          ', .'
        )}`,
      };
    }

    // Check file size is under max allowed
    if (file.size > acceptedFileSize) {
      return {
        code: 'filesize-too-large',
        message: `Please make file size smaller than ${
          acceptedFileSize / 1000000
        }Mb`,
      };
    }

    return null;
  };

  const {
    getRootProps,
    getInputProps,
    open,
    fileRejections,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: (files: File[]) => {
      onDropFile?.(files);
    },
    validator: fileValidator,
    disabled,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <Fragment key={file.name}>
        {errors.map((e) => (
          <FileError key={e.code}>{e.message}</FileError>
        ))}
      </Fragment>
    );
  });

  return (
    <Stack space={1}>
      <Container
        {...getRootProps({
          className: 'dropzone',
          isFocused,
          isDragAccept,
          isDragReject,
        })}
        disabled={disabled}
      >
        <input {...getInputProps()} data-cy='dropzoneUploadFileInput' />
        <p>{dropzoneText}</p>
      </Container>
      {fileRejectionItems.length > 0 && <aside>{fileRejectionItems}</aside>}
      {!hideButtonLink && (
        <TextButton
          onClick={open}
          disabled={disabled}
          type='button'
          data-cy='dropzoneUploadFileButton'
        >
          Select file from computer
        </TextButton>
      )}
    </Stack>
  );
};
