import styled, { css, CSSObject } from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';
import { Box, BoxProps } from '../box';

export interface FLexProps extends BoxProps {
  direction?: CSSObject['flexDirection'];
  /** Space between items */
  gap?:
    | keyof typeof globalTheme['space']
    | {
        column?: keyof typeof globalTheme['space'];
        row?: keyof typeof globalTheme['space'];
      };
  alignItems?: CSSObject['alignItems'];
  justifyItems?: CSSObject['justifyItems'];
  justifyContent?: CSSObject['justifyContent'];
  wrap?: CSSObject['flexWrap'];
  /** Stack elements on small screen */
  stackOnSmall?: boolean | keyof typeof breakpoints;
  stackOnMedium?: boolean | keyof typeof breakpoints;

  /** This hides the elem exceeding the size if provided, defaults `small`*/
  visibleOn?: boolean | keyof typeof breakpoints;
}

function getValues(props: FLexProps): CSSObject {
  return {
    gap:
      props.gap !== undefined && typeof props.gap !== 'object'
        ? globalTheme.space[props.gap]
        : undefined,
    flexDirection: props.direction,
    alignItems: props.alignItems,
    justifyItems: props.justifyItems,
    justifyContent: props.justifyContent,
    flexWrap: props.wrap,
  };
}

export const Flex = styled(Box)<FLexProps>`
  display: flex;
  ${getValues}
  ${({ stackOnSmall }) =>
    stackOnSmall &&
    css`
      @media screen and (max-width: ${breakpoints[
          typeof stackOnSmall === 'string' ? stackOnSmall : 'small'
        ]}) {
        flex-direction: column;
      }
    `}

  ${({ stackOnMedium }) =>
    stackOnMedium &&
    css`
      @media screen and (max-width: ${breakpoints[
          typeof stackOnMedium === 'string' ? stackOnMedium : 'medium'
        ]}) {
        flex-direction: column;
      }
    `}

    ${({ visibleOn }) =>
    visibleOn &&
    css`
      @media screen and (min-width: ${breakpoints[
          typeof visibleOn === 'string' ? visibleOn : 'small'
        ]}) {
        display: none;
      }
    `}
`;
