import { SEO, SEOProps } from 'components/seo';
import { motion, Variants } from 'framer-motion';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { Session } from 'next-auth';

export interface LayoutProps {
  meta?: SEOProps;
  backgroundColor?: string;
  fullHeight?: boolean;
}

const variants: Variants = {
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 16,
  },
};

export const Main = styled(motion.main)<{
  $backgroundColor?: string;
  $fullHeight?: boolean;
  $webView?: boolean;
  $session?: Session | null;
}>`
  position: relative;
  ${({ $fullHeight, $session }) =>
    $fullHeight
      ? css`
          max-height: 100%;
        `
      : css`
          @media screen and (max-width: ${breakpoints.medium}) {
            max-height: calc(
              ${globalTheme.sizes.fullHeight} - ${globalTheme.sizes.header} -
                ${$session ? '70px' : '0px'}
            );
          }
          max-height: calc(
            ${globalTheme.sizes.fullHeight} - ${globalTheme.sizes.header}
          );
        `}
  overflow: auto;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor && $backgroundColor};
  scroll-behavior: smooth;

  @media print {
    max-height: inherit;
    height: 100%;
    overflow: unset;
  }
`;

export const Layout: FC<LayoutProps> = ({
  children,
  meta,
  backgroundColor,
  fullHeight,
}) => {
  const router = useRouter();
  const { data: session } = useSession();
  return (
    <>
      <SEO title='' {...meta} />
      <Main
        key='main'
        initial={'hidden'}
        exit={'hidden'}
        animate={'visible'}
        variants={variants}
        $backgroundColor={backgroundColor}
        $fullHeight={fullHeight}
        $webView={!!router?.query?.webView}
        $session={session}
      >
        {children}
      </Main>
    </>
  );
};
