/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { SellerPriceGridEOI } from '../model';
// @ts-ignore
import { SellerPriceGridEOIWrite } from '../model';
/**
 * SellerPriceGridsEOIApi - axios parameter creator
 * @export
 */
export const SellerPriceGridsEOIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new Enquiry against the Price Grid
         * @param {string} priceGridId 
         * @param {SellerPriceGridEOIWrite} sellerPriceGridEOIWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridEoiCreate: async (priceGridId: string, sellerPriceGridEOIWrite: SellerPriceGridEOIWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'priceGridId' is not null or undefined
            assertParamExists('sellerGridEoiCreate', 'priceGridId', priceGridId)
            // verify required parameter 'sellerPriceGridEOIWrite' is not null or undefined
            assertParamExists('sellerGridEoiCreate', 'sellerPriceGridEOIWrite', sellerPriceGridEOIWrite)
            const localVarPath = `/v2/seller/grid/{price_grid_id}/eoi`
                .replace(`{${"price_grid_id"}}`, encodeURIComponent(String(priceGridId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerPriceGridEOIWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an Enquiry
         * @param {string} eoiId 
         * @param {string} priceGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridEoiDestroy: async (eoiId: string, priceGridId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eoiId' is not null or undefined
            assertParamExists('sellerGridEoiDestroy', 'eoiId', eoiId)
            // verify required parameter 'priceGridId' is not null or undefined
            assertParamExists('sellerGridEoiDestroy', 'priceGridId', priceGridId)
            const localVarPath = `/v2/seller/grid/{price_grid_id}/eoi/{eoi_id}`
                .replace(`{${"eoi_id"}}`, encodeURIComponent(String(eoiId)))
                .replace(`{${"price_grid_id"}}`, encodeURIComponent(String(priceGridId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerPriceGridsEOIApi - functional programming interface
 * @export
 */
export const SellerPriceGridsEOIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerPriceGridsEOIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new Enquiry against the Price Grid
         * @param {string} priceGridId 
         * @param {SellerPriceGridEOIWrite} sellerPriceGridEOIWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerGridEoiCreate(priceGridId: string, sellerPriceGridEOIWrite: SellerPriceGridEOIWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerPriceGridEOI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerGridEoiCreate(priceGridId, sellerPriceGridEOIWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes an Enquiry
         * @param {string} eoiId 
         * @param {string} priceGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerGridEoiDestroy(eoiId: string, priceGridId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerGridEoiDestroy(eoiId, priceGridId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SellerPriceGridsEOIApi - factory interface
 * @export
 */
export const SellerPriceGridsEOIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerPriceGridsEOIApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new Enquiry against the Price Grid
         * @param {string} priceGridId 
         * @param {SellerPriceGridEOIWrite} sellerPriceGridEOIWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridEoiCreate(priceGridId: string, sellerPriceGridEOIWrite: SellerPriceGridEOIWrite, options?: any): AxiosPromise<SellerPriceGridEOI> {
            return localVarFp.sellerGridEoiCreate(priceGridId, sellerPriceGridEOIWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an Enquiry
         * @param {string} eoiId 
         * @param {string} priceGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridEoiDestroy(eoiId: string, priceGridId: string, options?: any): AxiosPromise<void> {
            return localVarFp.sellerGridEoiDestroy(eoiId, priceGridId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sellerGridEoiCreate operation in SellerPriceGridsEOIApi.
 * @export
 * @interface SellerPriceGridsEOIApiSellerGridEoiCreateRequest
 */
export interface SellerPriceGridsEOIApiSellerGridEoiCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerPriceGridsEOIApiSellerGridEoiCreate
     */
    readonly priceGridId: string

    /**
     * 
     * @type {SellerPriceGridEOIWrite}
     * @memberof SellerPriceGridsEOIApiSellerGridEoiCreate
     */
    readonly sellerPriceGridEOIWrite: SellerPriceGridEOIWrite
}

/**
 * Request parameters for sellerGridEoiDestroy operation in SellerPriceGridsEOIApi.
 * @export
 * @interface SellerPriceGridsEOIApiSellerGridEoiDestroyRequest
 */
export interface SellerPriceGridsEOIApiSellerGridEoiDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerPriceGridsEOIApiSellerGridEoiDestroy
     */
    readonly eoiId: string

    /**
     * 
     * @type {string}
     * @memberof SellerPriceGridsEOIApiSellerGridEoiDestroy
     */
    readonly priceGridId: string
}

/**
 * SellerPriceGridsEOIApi - object-oriented interface
 * @export
 * @class SellerPriceGridsEOIApi
 * @extends {BaseAPI}
 */
export class SellerPriceGridsEOIApi extends BaseAPI {
    /**
     * 
     * @summary Create a new Enquiry against the Price Grid
     * @param {SellerPriceGridsEOIApiSellerGridEoiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerPriceGridsEOIApi
     */
    public sellerGridEoiCreate(requestParameters: SellerPriceGridsEOIApiSellerGridEoiCreateRequest, options?: any) {
        return SellerPriceGridsEOIApiFp(this.configuration).sellerGridEoiCreate(requestParameters.priceGridId, requestParameters.sellerPriceGridEOIWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an Enquiry
     * @param {SellerPriceGridsEOIApiSellerGridEoiDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerPriceGridsEOIApi
     */
    public sellerGridEoiDestroy(requestParameters: SellerPriceGridsEOIApiSellerGridEoiDestroyRequest, options?: any) {
        return SellerPriceGridsEOIApiFp(this.configuration).sellerGridEoiDestroy(requestParameters.eoiId, requestParameters.priceGridId, options).then((request) => request(this.axios, this.basePath));
    }
}
