import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';
import { Button } from '../button';
import { PageWrapper } from '../page-wrapper';
import { ScrollableContent } from '../scrollable-content';

export const ListPane = styled.div`
  height: 100%;
  max-height: inherit;
  overflow: hidden;
  &:not([hidden]) {
    display: grid;
  }

  @media screen and (min-width: ${breakpoints.large}) {
    grid-template-columns: minmax(380px, 1.25fr) 4fr;
  }
`;

export const ListPaneList = styled.ul`
  display: grid;
  gap: ${globalTheme.space[1]};
  padding-bottom: ${globalTheme.space[6]};
  background: none;
`;

export const ListPaneColumn = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: min-content 1fr;
  gap: ${globalTheme.space[2]};
  height: 100%;
  max-height: inherit;
  padding: ${globalTheme.space[1]};
  background-color: ${globalTheme.elements.listPane.columnBackgroundColor};

  ${ScrollableContent} {
    --scrollbar-color: ${globalTheme.colors.white};
  }
`;

export const ListPaneContent = styled.div`
  position: fixed;
  top: ${globalTheme.sizes.header};
  z-index: ${globalTheme.zIndices.dialog};
  display: block;
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: 100%;
  max-height: calc(100% - ${globalTheme.sizes.header});
  padding: 0;
  overflow: auto;
  background-color: ${globalTheme.elements.listPane.contentBackgroundColor};
  transform: scale(1);
  visibility: visible;
  opacity: 1;
  transition: ${globalTheme.transitions.inOutBack};

  &[hidden] {
    transform: scale(0.95);
    visibility: hidden;
    opacity: 0;
  }

  @media screen and (min-width: ${breakpoints.large}) {
    position: static;
    max-height: 100%;
    transition: none;
  }
`;

export const ListPaneHeader = styled.div`
  display: grid;
  gap: ${globalTheme.space[2]};
  align-items: center;
  padding: ${globalTheme.space[1]};
  background-color: ${globalTheme.elements.listPane.headerBackgroundColor};

  @media screen and (min-width: ${breakpoints.small}) {
    padding: ${globalTheme.space[2]};
  }

  @media screen and (min-width: ${breakpoints.medium}) {
    padding: ${globalTheme.space[3]};
  }

  @media screen and (min-width: ${breakpoints.large}) {
    grid-template-columns: 1fr max-content;
    padding: ${globalTheme.space[4]};
  }

  @media screen and (min-width: ${breakpoints.xLarge}) {
    padding: ${globalTheme.space[5]};
  }

  /* Nav */
  > :nth-child(1) {
    @media screen and (min-width: ${breakpoints.large}) {
      grid-row: 1 / span 1;
      grid-column: 2 / span 1;
    }
  }

  /* Heading/Content */
  > :nth-child(2) {
    @media screen and (min-width: ${breakpoints.large}) {
      grid-row: 1 / span 1;
      grid-column: 1 / span 1;
    }
  }

  & + ${PageWrapper} {
    padding-top: 0;
  }
`;

export const ListPaneActionButton = styled(Button).attrs({
  size: 'small',
})`
  position: absolute;
  bottom: ${globalTheme.space[1]};
  left: 50%;
  min-width: calc(100% - ${globalTheme.space[3]});
  transform: translateX(-50%);
`;
