import { FC } from 'react';
import { Column, useTable } from 'react-table';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';

export interface TableProps {
  columns: Column[];
  data: any[];
}

const Wrapper = styled.div`
  overflow: auto;
`;

const TableEl = styled.table`
  width: 100%;
  font-size: ${globalTheme.fontSizes.default};
  border-collapse: collapse;

  & + & {
    border-top: 0;
  }
`;

const Cell = styled.td`
  padding: ${globalTheme.space[0]} ${globalTheme.space[2]};
  white-space: nowrap;
  border: 1px solid ${globalTheme.elements.table.cellBorderColor};
  border-top: 0;
  border-bottom: 0;

  &:first-child {
    border-left: 0;
    border-radius: 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-right: 0;
    border-radius: 20px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const CellHeader = styled(Cell)`
  padding: ${globalTheme.space[2]};
  font-weight: ${globalTheme.fontWeights.semiBold};
  text-align: left;
  border-top: 0;
  border-bottom: 0;
`;

const Row = styled.tr`
  position: relative;
  border-top: 1px solid ${globalTheme.elements.table.cellBorderColor};

  &[data-highlight='true'] {
    padding: ${globalTheme.space[2]};
    font-weight: ${globalTheme.fontWeights.semiBold};
    border-top: 0;

    ${Cell}, ${CellHeader} {
      background-color: ${globalTheme.elements.table.headerBackgroundColor};
    }
  }

  [data-highlight='true'] + & {
    border-top: 0;
  }

  &:first-child {
    border-top: 0;
  }
`;

export const Table: FC<TableProps> = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <Wrapper>
      <TableEl {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <Row {...headerGroup.getHeaderGroupProps()} data-highlight>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <CellHeader
                  as='th'
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width },
                  })}
                >
                  {column.render('Header')}
                </CellHeader>
              ))}
            </Row>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <Row
                {...row.getRowProps()}
                // Treat object key "highlight" as a style property for this row
                data-highlight={!!(row as any).original.highlight}
              >
                {row.cells.map((cell) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <Cell
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                        },
                      })}
                    >
                      {cell.render('Cell')}
                    </Cell>
                  );
                })}
              </Row>
            );
          })}
        </tbody>
      </TableEl>
    </Wrapper>
  );
};
