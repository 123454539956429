import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';

export const MinMax = styled.div<{ hasLabel?: boolean }>`
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  gap: ${globalTheme.space[0]};
  align-items: center;

  &::after {
    display: block;
    grid-row: 1;
    grid-column: 2 / span 1;
    width: 8px;
    height: 2px;
    background-color: ${globalTheme.colors.black};
    content: '';
    margin-top: ${(props) => (props.hasLabel ? '30px' : 'unset')};
  }

  > * {
    &:first-child {
      grid-column: 1 / span 1;
    }

    &:last-child {
      grid-column: 3 / span 1;
    }
  }
`;
