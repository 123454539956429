/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { BookingConfirmation } from '../model';
// @ts-ignore
import { BookingConfirmationSMSPreview } from '../model';
// @ts-ignore
import { PaginatedBookingConfirmationList } from '../model';
/**
 * OrganisationBookingConfirmationOutgoingApi - axios parameter creator
 * @export
 */
export const OrganisationBookingConfirmationOutgoingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This can only occur when a Confirmations status == OPEN and it is the latest Confirmation against the Booking
         * @summary Cancel a confirmation
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {string} confirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationCancelCreate: async (bookingId: string, buyerOrgId: string, confirmationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingConfirmationCancelCreate', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingConfirmationCancelCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'confirmationId' is not null or undefined
            assertParamExists('orgBookingConfirmationCancelCreate', 'confirmationId', confirmationId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/confirmation/{confirmation_id}/cancel`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"confirmation_id"}}`, encodeURIComponent(String(confirmationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A Confirmation can only be created when the status of the Booking == OPEN
         * @summary Creates a Confirmation and sends out an SMS to the recipient of the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationCreate: async (bookingId: string, buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingConfirmationCreate', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingConfirmationCreate', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/confirmation`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a list of all the Confirmations associated with the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationList: async (bookingId: string, buyerOrgId: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingConfirmationList', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingConfirmationList', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/confirmation`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of a single Confirmation
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {string} confirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationRetrieve: async (bookingId: string, buyerOrgId: string, confirmationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingConfirmationRetrieve', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingConfirmationRetrieve', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'confirmationId' is not null or undefined
            assertParamExists('orgBookingConfirmationRetrieve', 'confirmationId', confirmationId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/confirmation/{confirmation_id}`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"confirmation_id"}}`, encodeURIComponent(String(confirmationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a preview of the SMS content and the recipients details
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationSmsPreviewRetrieve: async (bookingId: string, buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingConfirmationSmsPreviewRetrieve', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingConfirmationSmsPreviewRetrieve', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/confirmation/sms-preview`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationBookingConfirmationOutgoingApi - functional programming interface
 * @export
 */
export const OrganisationBookingConfirmationOutgoingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationBookingConfirmationOutgoingApiAxiosParamCreator(configuration)
    return {
        /**
         * This can only occur when a Confirmations status == OPEN and it is the latest Confirmation against the Booking
         * @summary Cancel a confirmation
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {string} confirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingConfirmationCancelCreate(bookingId: string, buyerOrgId: string, confirmationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingConfirmation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingConfirmationCancelCreate(bookingId, buyerOrgId, confirmationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A Confirmation can only be created when the status of the Booking == OPEN
         * @summary Creates a Confirmation and sends out an SMS to the recipient of the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingConfirmationCreate(bookingId: string, buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingConfirmation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingConfirmationCreate(bookingId, buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a list of all the Confirmations associated with the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingConfirmationList(bookingId: string, buyerOrgId: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedBookingConfirmationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingConfirmationList(bookingId, buyerOrgId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of a single Confirmation
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {string} confirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingConfirmationRetrieve(bookingId: string, buyerOrgId: string, confirmationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingConfirmation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingConfirmationRetrieve(bookingId, buyerOrgId, confirmationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a preview of the SMS content and the recipients details
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingConfirmationSmsPreviewRetrieve(bookingId: string, buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingConfirmationSMSPreview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingConfirmationSmsPreviewRetrieve(bookingId, buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationBookingConfirmationOutgoingApi - factory interface
 * @export
 */
export const OrganisationBookingConfirmationOutgoingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationBookingConfirmationOutgoingApiFp(configuration)
    return {
        /**
         * This can only occur when a Confirmations status == OPEN and it is the latest Confirmation against the Booking
         * @summary Cancel a confirmation
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {string} confirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationCancelCreate(bookingId: string, buyerOrgId: string, confirmationId: string, options?: any): AxiosPromise<BookingConfirmation> {
            return localVarFp.orgBookingConfirmationCancelCreate(bookingId, buyerOrgId, confirmationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A Confirmation can only be created when the status of the Booking == OPEN
         * @summary Creates a Confirmation and sends out an SMS to the recipient of the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationCreate(bookingId: string, buyerOrgId: string, options?: any): AxiosPromise<BookingConfirmation> {
            return localVarFp.orgBookingConfirmationCreate(bookingId, buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a list of all the Confirmations associated with the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationList(bookingId: string, buyerOrgId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedBookingConfirmationList> {
            return localVarFp.orgBookingConfirmationList(bookingId, buyerOrgId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of a single Confirmation
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {string} confirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationRetrieve(bookingId: string, buyerOrgId: string, confirmationId: string, options?: any): AxiosPromise<BookingConfirmation> {
            return localVarFp.orgBookingConfirmationRetrieve(bookingId, buyerOrgId, confirmationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a preview of the SMS content and the recipients details
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationSmsPreviewRetrieve(bookingId: string, buyerOrgId: string, options?: any): AxiosPromise<BookingConfirmationSMSPreview> {
            return localVarFp.orgBookingConfirmationSmsPreviewRetrieve(bookingId, buyerOrgId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgBookingConfirmationCancelCreate operation in OrganisationBookingConfirmationOutgoingApi.
 * @export
 * @interface OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationCancelCreateRequest
 */
export interface OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationCancelCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationCancelCreate
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationCancelCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationCancelCreate
     */
    readonly confirmationId: string
}

/**
 * Request parameters for orgBookingConfirmationCreate operation in OrganisationBookingConfirmationOutgoingApi.
 * @export
 * @interface OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationCreateRequest
 */
export interface OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationCreate
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationCreate
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgBookingConfirmationList operation in OrganisationBookingConfirmationOutgoingApi.
 * @export
 * @interface OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationListRequest
 */
export interface OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationList
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationList
     */
    readonly buyerOrgId: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for orgBookingConfirmationRetrieve operation in OrganisationBookingConfirmationOutgoingApi.
 * @export
 * @interface OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationRetrieveRequest
 */
export interface OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationRetrieve
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationRetrieve
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationRetrieve
     */
    readonly confirmationId: string
}

/**
 * Request parameters for orgBookingConfirmationSmsPreviewRetrieve operation in OrganisationBookingConfirmationOutgoingApi.
 * @export
 * @interface OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationSmsPreviewRetrieveRequest
 */
export interface OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationSmsPreviewRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationSmsPreviewRetrieve
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationSmsPreviewRetrieve
     */
    readonly buyerOrgId: string
}

/**
 * OrganisationBookingConfirmationOutgoingApi - object-oriented interface
 * @export
 * @class OrganisationBookingConfirmationOutgoingApi
 * @extends {BaseAPI}
 */
export class OrganisationBookingConfirmationOutgoingApi extends BaseAPI {
    /**
     * This can only occur when a Confirmations status == OPEN and it is the latest Confirmation against the Booking
     * @summary Cancel a confirmation
     * @param {OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationCancelCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingConfirmationOutgoingApi
     */
    public orgBookingConfirmationCancelCreate(requestParameters: OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationCancelCreateRequest, options?: any) {
        return OrganisationBookingConfirmationOutgoingApiFp(this.configuration).orgBookingConfirmationCancelCreate(requestParameters.bookingId, requestParameters.buyerOrgId, requestParameters.confirmationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A Confirmation can only be created when the status of the Booking == OPEN
     * @summary Creates a Confirmation and sends out an SMS to the recipient of the Booking
     * @param {OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingConfirmationOutgoingApi
     */
    public orgBookingConfirmationCreate(requestParameters: OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationCreateRequest, options?: any) {
        return OrganisationBookingConfirmationOutgoingApiFp(this.configuration).orgBookingConfirmationCreate(requestParameters.bookingId, requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a list of all the Confirmations associated with the Booking
     * @param {OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingConfirmationOutgoingApi
     */
    public orgBookingConfirmationList(requestParameters: OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationListRequest, options?: any) {
        return OrganisationBookingConfirmationOutgoingApiFp(this.configuration).orgBookingConfirmationList(requestParameters.bookingId, requestParameters.buyerOrgId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of a single Confirmation
     * @param {OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingConfirmationOutgoingApi
     */
    public orgBookingConfirmationRetrieve(requestParameters: OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationRetrieveRequest, options?: any) {
        return OrganisationBookingConfirmationOutgoingApiFp(this.configuration).orgBookingConfirmationRetrieve(requestParameters.bookingId, requestParameters.buyerOrgId, requestParameters.confirmationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a preview of the SMS content and the recipients details
     * @param {OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationSmsPreviewRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingConfirmationOutgoingApi
     */
    public orgBookingConfirmationSmsPreviewRetrieve(requestParameters: OrganisationBookingConfirmationOutgoingApiOrgBookingConfirmationSmsPreviewRetrieveRequest, options?: any) {
        return OrganisationBookingConfirmationOutgoingApiFp(this.configuration).orgBookingConfirmationSmsPreviewRetrieve(requestParameters.bookingId, requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }
}
