import { useRect } from '@reach/rect';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import { AnimatePresence } from 'framer-motion';
import debounce from 'lodash/debounce';
import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { IconButton } from '../icon-button';
import {
  getStateValues,
  InputProps,
  InputStyled,
  InputWrapper,
  Label,
  StateIcon,
  StyledIcon,
  variants,
  Wrapper,
} from '../input';
import { InputMessage } from '../input-message';

export type ComboboxItem = { id: string; value: string };

export interface ComboboxProps extends Omit<InputProps, 'as' | 'id' | 'value'> {
  label: string;
  items: ComboboxItem[];
  onInputValueChange: (changes: UseComboboxStateChange<ComboboxItem>) => void;
  onSelectedItemChange?: (
    changes: UseComboboxStateChange<ComboboxItem>
  ) => void;
  onReset?: () => void;
  isLoading?: boolean;
  initialValue?: ComboboxItem;
  value?: ComboboxItem;
}

export const ComboboxList = styled.ul`
  position: fixed;
  z-index: ${globalTheme.zIndices.listBox};
  max-height: 200px;
  overflow: auto;
  font-size: ${globalTheme.fontSizes.default};
  background-color: ${globalTheme.elements.combobox.backgroundColor};
  border: 0;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(17, 17, 17, 0.2);
  transform: translateY(calc(${globalTheme.space[0]} / 2));
`;

export const ComboboxListItem = styled.li`
  padding: ${globalTheme.space[1]};
  line-height: ${globalTheme.lineHeights.heading};
  cursor: default;
  transition: ${globalTheme.transitions.fast};

  &[aria-selected='true'] {
    color: ${globalTheme.elements.combobox.resultHoverColor};
    background-color: ${globalTheme.elements.combobox
      .resultHoverBackgroundColor};
  }
`;

const ComboboxWrapper = styled(InputWrapper)`
  position: relative;

  /* TODO: Fix this, z-index with input wrapper */
  &::before {
    position: absolute;
    top: ${globalTheme.space[0]};
    left: -2px;
    z-index: -1;
    width: calc(100% + 4px);
    height: 100%;
    background-color: ${globalTheme.elements.combobox.inputBackgroundColor};
    opacity: 0;
    content: '';
    pointer-events: none;
  }

  &[aria-expanded='true']::before {
    opacity: 1;
  }
`;

const Selection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  gap: ${globalTheme.space[0]};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: ${globalTheme.space[1]};
  background-color: ${globalTheme.elements.combobox
    .selectedInputBackgroundColor};
  border-radius: inherit;
`;

const SelectionItem = styled.span`
  display: inline-block;
  padding: 4px ${globalTheme.space[1]};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: ${globalTheme.elements.combobox
    .selectedItemBackgroundColor};
  border-radius: 40px;
`;

export const Combobox: FC<ComboboxProps> = ({
  label,
  message,
  state,
  icon,
  items,
  onInputValueChange,
  onSelectedItemChange,
  onReset,
  isLoading,
  initialValue: passedInitialValue,
  value,
}) => {
  // Set initial selection
  const initialValue = useRef(passedInitialValue);

  const {
    isOpen,
    selectedItem,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    inputValue,
    reset,
    selectItem,
  } = useCombobox({
    items,
    onInputValueChange,
    onSelectedItemChange,
    itemToString: (item) => item?.value || '',
    initialSelectedItem: initialValue.current,
  });

  const { stateColor, stateIcon } = getStateValues(state);

  // Popper reference element
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  // Observe the bounding rect of the input wrapper so that the list can be positioned
  const wrapperRef = useRef<HTMLDivElement>(null);
  const rect = useRect(wrapperRef, { observe: isOpen });

  // Maintain a reference to the selected item for use in onBlur since the call is debounced
  const selectedItemRef = useRef<ComboboxItem | null>(selectedItem);
  useEffect(() => {
    if (selectedItem?.id !== selectedItemRef.current?.id) {
      selectedItemRef.current = selectedItem;
    }
  }, [selectedItem]);

  // Reset the input
  const handleReset = () => {
    reset();
    onReset && onReset();
  };

  // Reset on blur if the user has not made a selection
  const handleBlur = () => {
    if (!selectedItemRef.current) {
      reset();
    }
  };

  // Handle selected item based on external changes to the value prop
  useEffect(() => {
    if (value !== undefined && selectedItem?.id !== value?.id) {
      if (value?.id) {
        // Set value
        selectItem(value);
      } else {
        // Reset if value is empty
        reset();
      }
    }
  }, [reset, selectItem, selectedItem?.id, value]);

  return (
    <Wrapper ref={setReferenceElement}>
      {label && <Label {...getLabelProps()}>{label}</Label>}
      <div
        ref={wrapperRef}
        data-cy={`${label.replace(/\s+|[,\/]/g, '')}InputBox`}
      >
        <ComboboxWrapper color={stateColor}>
          {icon && <StyledIcon component={icon} size={2} />}
          <InputStyled
            {...getInputProps()}
            data-state={state}
            onBlur={debounce(handleBlur, 250)}
          />
          {selectedItem && (
            <Selection>
              <SelectionItem>{selectedItem?.value}</SelectionItem>
              <IconButton
                icon='CancelIcon'
                size={0}
                onClick={handleReset}
                type='button'
                buttonColor={false}
                aria-label='Clear selection'
              />
            </Selection>
          )}
          <AnimatePresence>
            {state && stateIcon && !selectedItem && (
              <StateIcon
                component={stateIcon}
                color={stateColor}
                size={1}
                variants={variants}
                initial='hidden'
                exit='hidden'
                animate='visible'
              />
            )}
          </AnimatePresence>
        </ComboboxWrapper>
      </div>

      <ComboboxList
        {...getMenuProps()}
        style={{
          width: rect?.width,
          top: (rect?.top || 0) + (rect?.height || 0),
          left: rect?.left,
        }}
      >
        {isLoading && !selectedItem && (
          <ComboboxListItem>Loading...</ComboboxListItem>
        )}
        {inputValue && items.length === 0 && !isLoading && !selectedItem && (
          <ComboboxListItem>No results</ComboboxListItem>
        )}
        {isOpen &&
          !isLoading &&
          items.map((item, index) => (
            <ComboboxListItem
              key={`${item.id}`}
              {...getItemProps({ item, index })}
            >
              {item.value}
            </ComboboxListItem>
          ))}
      </ComboboxList>

      <AnimatePresence>
        {message && (
          <InputMessage
            backgroundColor={stateColor}
            referenceElement={referenceElement}
          >
            {message}
          </InputMessage>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};
