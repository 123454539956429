import { FC } from 'react';
import styled, { css } from 'styled-components';
import { globalTheme } from 'styles/global-theme';

export interface BasicTableProps {
  className?: string;
  /** Switch table layout to fixed */
  fixed?: boolean;
}

const Wrapper = styled.div`
  overflow: auto;
`;

const Table = styled.table<{ fixed: boolean }>`
  width: 100%;
  font-size: ${globalTheme.fontSizes.default};
  table-layout: ${({ fixed }) => (fixed ? 'fixed' : 'auto')};
  border-collapse: collapse;
`;

export interface CellProps {
  textWrap?: boolean;
}

export const Cell = styled.td<CellProps>`
  padding: ${globalTheme.space[0]} ${globalTheme.space[1]};
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  /* By default cell with show an ellipsis when content is to long
  textWrap flag removes the ellipsis and wraps the text within the cell */
  ${(props) =>
    props.textWrap &&
    css`
      white-space: pre-wrap;
      text-overflow: unset;
    `}

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const CellHeader = styled(Cell).attrs({ as: 'th' })`
  padding-top: 0;
  font-weight: ${globalTheme.fontWeights.semiBold};
  text-align: left;
`;

export const Row = styled.tr`
  border-top: 1px solid ${globalTheme.elements.table.cellBorderColor};

  &:first-child {
    border-top: 0;
  }
`;

export const BasicTable: FC<BasicTableProps> = ({
  children,
  className,
  fixed = false,
}) => {
  return (
    <Wrapper className={className}>
      <Table fixed={fixed}>{children}</Table>
    </Wrapper>
  );
};
