import { motion, Variants } from 'framer-motion';
import { rgba } from 'polished';
import { FC, useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Text } from '../text';

export interface InputMessageProps {
  referenceElement: HTMLDivElement | null;
  backgroundColor: keyof typeof globalTheme.colors;
  color?: keyof typeof globalTheme.colors;
}

const variants: Variants = {
  visible: {
    opacity: 1,
    translateY: 0,
  },
  hidden: {
    opacity: 0,
    translateY: 16,
  },
};

const Arrow = styled.span<{ backgroundColor: keyof typeof globalTheme.colors }>`
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;

  &::before {
    display: block;
    width: inherit;
    height: inherit;
    background-color: ${({ backgroundColor }) =>
      globalTheme.colors[backgroundColor]};
    transform: rotate(45deg);
    content: '';
  }
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: ${globalTheme.zIndices.inputMessage};
  pointer-events: none;
`;

const Message = styled(motion(Text))`
  max-width: 200px;
  padding: ${globalTheme.space[0]} ${globalTheme.space[1]};
  line-height: 1.2;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 ${rgba(globalTheme.colors.darkGrey, 0.05)};

  &[data-popper-placement^='top'] ${Arrow} {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] ${Arrow} {
    top: -4px;
  }

  &[data-popper-placement^='left'] ${Arrow} {
    right: -4px;
  }

  &[data-popper-placement^='right'] ${Arrow} {
    left: -4px;
  }
`;

export const InputMessage: FC<InputMessageProps> = ({
  referenceElement,
  backgroundColor,
  color,
  children,
}) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLSpanElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'offset', options: { offset: [0, 16] } },
      { name: 'arrow', options: { element: arrowElement, padding: 16 } },
    ],
  });

  return (
    <Wrapper ref={setPopperElement} style={styles.popper}>
      <Message
        backgroundColor={backgroundColor}
        color={color || 'white'}
        fontSize={0}
        fontWeight='bold'
        variants={variants}
        initial='hidden'
        exit='hidden'
        animate='visible'
        {...attributes.popper}
      >
        {children}
        <Arrow
          ref={setArrowElement}
          style={styles.arrow}
          backgroundColor={backgroundColor}
        />
      </Message>
    </Wrapper>
  );
};
