import { WarningImage } from 'components/images';
import { Button, Heading, SectionCard, Stack, Text } from 'components/ui';
import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;

  svg {
    max-width: 100px;
    height: auto;
  }
`;

export const ErrorFallback: FC = () => {
  return (
    <Wrapper>
      <SectionCard>
        <Stack space={1}>
          <WarningImage />
          <Heading level='h2'>Something went wrong</Heading>
          <Text>Please try reloading the page</Text>
          <Button onClick={() => window.location.reload()} size='medium'>
            Reload
          </Button>
        </Stack>
      </SectionCard>
    </Wrapper>
  );
};
