import { logger } from '@hatchd/utils';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SuccessImage } from 'components/images';
import { PlanPicker } from 'components/plan-picker';
import { StripeCard } from 'components/stripe-card';
import { SubscriptionPlanSummary } from 'components/subscription-plan-summary';
import {
  AnimatedDialogImageWrapper,
  Button,
  Dialog,
  DialogProps,
  ErrorMessage,
  Input,
  Loader,
  Stack,
  Text,
} from 'components/ui';
import { useBuyerOrg } from 'hooks/use-buyer-org';
import { useSubscriptionPlans } from 'hooks/use-subscription-plans';
import { ApiClient } from 'lib/api/client';
import {
  BillingCycleEnum,
  NullEnum,
  PlanEnum,
  SubscriptionChange,
  SubscriptionStatusEnum,
} from 'lib/api/generated';
import { getSubscriptionPriceAUD } from 'lib/helpers';
import { useStore } from 'lib/store';
import { handleSubscriptionPayment } from 'lib/stripe-helpers';
import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { PUBLIC_CONTACT_PAGE, QUERY_KEYS } from 'settings/config';
import { sendCompleteMessageToApp } from '../../lib/app-utils';

export interface ChangePlanDialogProps
  extends Pick<DialogProps, 'isOpen' | 'onDismiss'> {
  cancelPlanDialogOpen?: () => void;
  upgrade?: boolean;
}

type FormData = {
  plan: string;
  card_name?: string;
};

enum Step {
  Select,
  Confirm,
  Success,
}

export const ChangePlanDialog: FC<ChangePlanDialogProps> = ({
  onDismiss,
  isOpen,
  cancelPlanDialogOpen,
  upgrade,
}) => {
  const [stripeLoading, setStripeLoading] = useState<boolean>(false);
  // Base data
  const { data, isLoading, isError } = useBuyerOrg();
  const { data: plans } = useSubscriptionPlans();
  const buyerOrgId = useStore((store) => store.activeBuyerOrgId);
  const queryClient = useQueryClient();
  const [isFreePlan, setIsFreePlan] = useState<boolean>(false);

  // Form setup
  const { control, watch, handleSubmit, reset } = useForm<FormData>();

  // Keep track of selected plan
  const selectedPlanTier = watch('plan');

  const userCardName = watch('card_name');

  const [activeBillingCycle, setActiveBillingCycle] = useState(
    data?.subscription?.plan?.billing_cycle || BillingCycleEnum.Monthly
  );

  const selectedPlanName = useMemo(
    () =>
      plans?.find(
        (plan) =>
          plan.plan_tier === selectedPlanTier &&
          plan.billing_cycle === activeBillingCycle
      ),
    [selectedPlanTier, activeBillingCycle]
  );

  const FREE_PLAN_FEATURES = [
    'Saleyard reports',
    'Price updates',
    'Marketplace',
  ];

  // Dialog step
  const [currentStep, setCurrentStep] = useState<Step>(Step.Select);

  // Stripe card completion status + lib
  const [cardComplete, setCardComplete] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const createSetupIntentMutation = useMutation(() =>
    new ApiClient().subscriptionApi.orgSubscriptionCreateSetupIntentCreate({
      buyerOrgId: buyerOrgId || '',
    })
  );

  const updateSubscriptionMutation = useMutation(
    (subscriptionChange: SubscriptionChange) =>
      new ApiClient().subscriptionApi.orgSubscriptionChangeCreate({
        buyerOrgId: buyerOrgId || '',
        subscriptionChange,
      }),
    {
      onError: () => {
        toast.error('Error updating subscription');
      },
      onSettled: () => {
        queryClient.invalidateQueries([QUERY_KEYS.org]);
      },
    }
  );

  const createSubscriptionMutation = useMutation(
    (plan: NullEnum | PlanEnum | null) =>
      new ApiClient().subscriptionApi.orgSubscriptionCreateCreate({
        buyerOrgId: buyerOrgId || '',
        subscriptionCreate: { plan } || { plan: null },
      }),
    {
      onError: () => {
        toast.error('Error creating new subscription');
      },
    }
  );

  const hasActiveSubscription = data?.active_subscription;

  // In the event that the the org already has a sub but hasn't yet paid for it
  const hasUnpaidSubscription =
    !hasActiveSubscription && !data?.subscription?.default_payment_method?.id;

  // Something probably went wrong here! There is no subscription tied to this org or it has expired
  const hasNoSubscription =
    !data?.subscription?.created ||
    data?.subscription?.status === SubscriptionStatusEnum.IncompleteExpired;

  // Reset when opened
  useEffect(() => {
    if (isOpen) {
      if (hasUnpaidSubscription && data?.subscription?.plan?.plan_id) {
        reset({ plan: data?.subscription.plan?.plan_id || '' });
      } else {
        reset();
      }
    }
  }, [data?.subscription?.plan?.plan_id, hasUnpaidSubscription, isOpen, reset]);

  // Set to initial step when opened
  useEffect(() => {
    if (isOpen) {
      setCurrentStep(Step.Select);
    }
  }, [isOpen]);

  const onSubmit = async (data: FormData) => {
    if (selectedPlanName) {
      // Update an active subscription
      if (hasActiveSubscription) {
        logger.info('Updating existing subscription');
        await updateSubscriptionMutation.mutateAsync({
          plan: selectedPlanName.plan_id as PlanEnum,
        });
        setCurrentStep(Step.Success);
        sendCompleteMessageToApp();
      } else {
        // No active subscription, also handle payment with stripe

        if (!stripe || !elements) {
          return;
        }
        setStripeLoading(true);
        const cardNumberElement = elements.getElement(CardNumberElement);
        if (cardNumberElement && buyerOrgId) {
          try {
            let clientSecret: string | undefined;
            let freeTrial = false;

            // Has no subscription, create a new one first
            if (hasNoSubscription) {
              logger.info('No subscription, creating new subscription');
              const {
                data: { subscription_setup_intent, subscription_client_secret },
              } = await createSubscriptionMutation.mutateAsync(
                selectedPlanName.plan_id as PlanEnum
              );

              // Set the client secret
              clientSecret =
                subscription_client_secret ||
                subscription_setup_intent?.client_secret;

              // Presence of setup intent client secret indicates the user selected the free trial
              if (subscription_setup_intent?.client_secret) {
                freeTrial = true;
              }
            } else {
              // Has an existing subscription, but no payment attached
              const {
                data: { client_secret },
              } = await createSetupIntentMutation.mutateAsync();
              clientSecret = client_secret;
            }

            if (clientSecret) {
              logger.info('Attaching payment to existing subscription');
              const { error, intent } = await handleSubscriptionPayment({
                stripe,
                isTrial: freeTrial,
                clientSecret,
                cardNumberElement,
                billingName: data.card_name,
              });
              // Setup intent successfully created, try and update the subscription with the new payment method (free trial)
              if (
                intent?.status === 'succeeded' &&
                typeof intent.payment_method === 'string' &&
                freeTrial
              ) {
                await updateSubscriptionMutation.mutateAsync({
                  plan: selectedPlanName.plan_id as PlanEnum,
                  payment_method: intent.payment_method,
                });
                setCurrentStep(Step.Success);
                setStripeLoading(false);
                sendCompleteMessageToApp();
              }

              // Payment intent successfully processed (no trial), show success step
              if (intent?.status === 'succeeded' && !freeTrial) {
                queryClient.invalidateQueries([QUERY_KEYS.org]);
                setCurrentStep(Step.Success);
                setStripeLoading(false);
                sendCompleteMessageToApp();
              }

              // Card related errors
              if (error) {
                toast.error(error.message);
                setStripeLoading(false);
              }
            }
          } catch (error) {
            toast.error('Error updating subscription');
          }
        }
      }
    } else if (isFreePlan) {
      cancelPlanDialogOpen?.();
    }
  };

  return (
    <Dialog
      title={
        hasNoSubscription
          ? 'Select plan'
          : selectedPlanName
          ? 'Change plan'
          : currentStep === Step.Confirm && isFreePlan
          ? 'Are you sure?'
          : 'Change plan'
      }
      largeDialog={currentStep !== Step.Success}
      onDismiss={onDismiss}
      isOpen={isOpen}
      actions={
        <>
          {currentStep === Step.Select && (
            <>
              <Button
                size='small'
                variant='outline'
                onClick={onDismiss}
                type='button'
              >
                Back
              </Button>
              <Button
                size='small'
                disabled={!selectedPlanTier}
                onClick={() => setCurrentStep(Step.Confirm)}
                type='button'
              >
                Next
              </Button>
            </>
          )}
          {currentStep === Step.Confirm && (
            <>
              <Button
                size='small'
                variant='outline'
                onClick={() => setCurrentStep(Step.Select)}
                type='button'
                disabled={
                  updateSubscriptionMutation.isLoading ||
                  createSetupIntentMutation.isLoading ||
                  createSubscriptionMutation.isLoading ||
                  stripeLoading
                }
              >
                Back
              </Button>
              <Button
                size='small'
                disabled={
                  hasActiveSubscription
                    ? !selectedPlanName && !isFreePlan
                    : !selectedPlanTier || !cardComplete || !userCardName
                }
                isLoading={
                  updateSubscriptionMutation.isLoading ||
                  createSetupIntentMutation.isLoading ||
                  createSubscriptionMutation.isLoading ||
                  stripeLoading
                }
                form='changePlanForm'
                type='submit'
              >
                Confirm plan change
              </Button>
            </>
          )}
          {currentStep === Step.Success && (
            <Button type='button' onClick={onDismiss}>
              Go back
            </Button>
          )}
        </>
      }
    >
      {isLoading && <Loader />}
      {isError && <ErrorMessage>Error loading plan details</ErrorMessage>}
      <form id='changePlanForm' onSubmit={handleSubmit(onSubmit)}>
        <Stack space={3}>
          {currentStep === Step.Select && (
            <>
              <Controller
                name='plan'
                control={control}
                render={({ field: { onChange } }) => (
                  <PlanPicker
                    defaultPlanTier={
                      selectedPlanName?.plan_tier ||
                      data?.subscription?.plan?.plan_tier
                    }
                    defaultBillingCycle={
                      selectedPlanName?.billing_cycle ||
                      data?.subscription?.plan?.billing_cycle
                    }
                    disabledPlanId={
                      !hasUnpaidSubscription
                        ? data?.subscription?.plan?.plan_id
                        : undefined
                    }
                    enableFrequencyChange={true}
                    onChange={(plan) => {
                      onChange(plan ? plan.plan_tier : 'FREE_PLAN');
                      plan && setIsFreePlan(false);
                      !plan && setIsFreePlan(true);
                    }}
                    includeFreeTrial={data?.trial_period_available}
                    upgrade={upgrade}
                    selectedTierPlans={plans}
                  />
                )}
              />
              <Text textAlign='center'>
                Can't find something that suits?{' '}
                <Text as='a' href={PUBLIC_CONTACT_PAGE} color='blue'>
                  Get in touch with the Agora team!
                </Text>
              </Text>
            </>
          )}
          {currentStep === Step.Confirm && isFreePlan && (
            <>
              <SubscriptionPlanSummary
                name={
                  'Switching to the free subscription will only give you access to'
                }
                details={FREE_PLAN_FEATURES}
                onChangePlanClick={() => setCurrentStep(Step.Select)}
              />
            </>
          )}
          {currentStep === Step.Confirm && selectedPlanName && (
            <>
              <SubscriptionPlanSummary
                name={selectedPlanName.name}
                details={[
                  `You will be billed ${getSubscriptionPriceAUD(
                    selectedPlanName.price
                  )} every ${
                    selectedPlanName.billing_cycle === BillingCycleEnum.Monthly
                      ? 'month + GST'
                      : 'year + GST'
                  }.`,
                  'Your first payment is due today.',
                  'You can cancel your subscription at any time.',
                ]}
                onChangePlanClick={() => setCurrentStep(Step.Select)}
                setActiveBillingCycle={setActiveBillingCycle}
                selectedBillingCycle={activeBillingCycle}
                priceDescription={`per ${
                  activeBillingCycle === BillingCycleEnum.Monthly
                    ? 'month'
                    : 'year'
                }

                `}
                hasGSTPrice={true}
                price={getSubscriptionPriceAUD(selectedPlanName.price)}
              />
              {!hasActiveSubscription && (
                <>
                  {hasNoSubscription && (
                    <Controller
                      control={control}
                      name='card_name'
                      render={({ field: { onChange, value } }) => (
                        <Input
                          label='Name on card*'
                          onChange={onChange}
                          value={value}
                          data-cy='nameField'
                        />
                      )}
                    />
                  )}
                  <StripeCard
                    onComplete={() => setCardComplete?.(true)}
                    onIncomplete={() => setCardComplete?.(false)}
                  />
                </>
              )}
            </>
          )}
          {currentStep === Step.Success && (
            <Stack space={4}>
              <AnimatedDialogImageWrapper>
                <SuccessImage />
              </AnimatedDialogImageWrapper>
              <Text textAlign='center' fontSize={4} fontWeight='light'>
                Your plan has been changed! Thank you for subscribing!
              </Text>
            </Stack>
          )}
        </Stack>
      </form>
    </Dialog>
  );
};
