import chevron from 'components/icons/chevron-next.svg';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Box } from '../box';

export const ListCard = styled(Box)`
  position: relative;
  list-style-type: none;
  border: 0;
  border-radius: ${globalTheme.elements.listCard.borderRadius};
  outline: 0;
  cursor: pointer;
  transition: ${globalTheme.transitions.fast};

  &[aria-selected='true'],
  &:focus {
    box-shadow: inset 0 0 0 2px ${globalTheme.colors.blue};
  }

  &:active {
    background-color: ${globalTheme.elements.listCard.clickedBackgroundColor};
  }
`;

export const ListCardFooter = styled.div`
  position: relative;
  display: flex;
  gap: ${globalTheme.space[0]};
  align-items: center;
  margin-top: ${globalTheme.space[1]};
  padding-top: ${globalTheme.space[1]};
  font-size: ${globalTheme.fontSizes[0]};

  &::before {
    position: absolute;
    top: 0;
    left: -${globalTheme.space[1]};
    width: calc(100% + ${globalTheme.space[3]});
    content: '';
  }

  &::after {
    display: block;
    width: 14px;
    height: 14px;
    margin-left: auto;
    background-image: url(${chevron});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    content: '';
  }
`;

ListCard.defaultProps = {
  padding: 1,
  color: 'darkGrey',
  backgroundColor: 'white',
};
