import useIntersectionObserver from '@react-hook/intersection-observer';
import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Loader } from '../loader';
import { ScrollableContent } from '../scrollable-content';

export interface InfiniteScrollListProps {
  loading?: boolean;
  onIntersect?: () => void;
  tableMode?: boolean;
  removeScrollBar?: boolean;
}

const Wrapper = styled(ScrollableContent)<{
  removeScrollBar: boolean | undefined;
}>`
  width: 100%;
  max-height: 100%;
  --scrollbar-color: ${globalTheme.colors.darkGrey};
  padding: ${(props) => (props.removeScrollBar ? '0px' : '')};
  ::-webkit-scrollbar {
    display: ${(props) => (props.removeScrollBar ? 'none' : 'block')};
  }
`;

const LoaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  > * {
    margin-top: ${globalTheme.space[2]};
  }
`;

export const InfiniteScrollList: FC<InfiniteScrollListProps> = ({
  children,
  loading,
  onIntersect,
  tableMode,
  removeScrollBar,
}) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const scrollTarget = useRef<HTMLDivElement>(null);
  const { isIntersecting } = useIntersectionObserver(scrollTarget, {
    root: wrapper.current,
    rootMargin: '400px',
  });

  // User has reached the bottom of the list, fire the isIntersecting function
  useEffect(() => {
    if (isIntersecting) {
      onIntersect && onIntersect();
    }
  }, [isIntersecting, onIntersect]);

  return (
    <Wrapper
      removeScrollBar={removeScrollBar}
      ref={wrapper}
      aria-busy={loading}
      as={tableMode ? 'tbody' : 'div'}
    >
      {children}
      <LoaderWrapper ref={scrollTarget} as={tableMode ? 'tr' : 'div'}>
        {loading && (
          <>
            {tableMode ? (
              <td>
                <Loader />
              </td>
            ) : (
              <Loader />
            )}
          </>
        )}
      </LoaderWrapper>
    </Wrapper>
  );
};
