/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum DataTypeEnum {
    PriceGrid = 'PRICE_GRID',
    PriceGridBroadcast = 'PRICE_GRID_BROADCAST',
    Offer = 'OFFER',
    OfferCounter = 'OFFER_COUNTER',
    OfferRejected = 'OFFER_REJECTED',
    OfferAccepted = 'OFFER_ACCEPTED',
    SavedSearch = 'SAVED_SEARCH',
    Contact = 'CONTACT',
    Profile = 'PROFILE',
    SaleyardReport = 'SALEYARD_REPORT',
    Listing = 'LISTING'
}



