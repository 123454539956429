/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PaginatedSellerBuyerOrgList } from '../model';
/**
 * SellerBuyerOrgApi - axios parameter creator
 * @export
 */
export const SellerBuyerOrgApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of the Buyer Organisations.  Only those that are approved will be returned.  # Filtering Options  ## Fields - search str: case insensitive contains query. This will search on the Buyer Orgs name, email and phone  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=name` To reverse, simply place a hyphen in front. eg. `?ordering=-name`  - name: The buyer orgs name - email: The buyer orgs contact email - phone: The buyer orgs contact number - created: The time the org was created  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isnt one     previous: null <-- previous page number, or null if there isnt one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of the Buyer Organisations
         * @param {Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>} [ordering] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgList: async (ordering?: Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>, page?: number, pageSize?: number, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/seller/buyer_org`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ordering) {
                localVarQueryParameter['ordering'] = ordering.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerBuyerOrgApi - functional programming interface
 * @export
 */
export const SellerBuyerOrgApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerBuyerOrgApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of the Buyer Organisations.  Only those that are approved will be returned.  # Filtering Options  ## Fields - search str: case insensitive contains query. This will search on the Buyer Orgs name, email and phone  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=name` To reverse, simply place a hyphen in front. eg. `?ordering=-name`  - name: The buyer orgs name - email: The buyer orgs contact email - phone: The buyer orgs contact number - created: The time the org was created  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isnt one     previous: null <-- previous page number, or null if there isnt one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of the Buyer Organisations
         * @param {Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>} [ordering] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerBuyerOrgList(ordering?: Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>, page?: number, pageSize?: number, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSellerBuyerOrgList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerBuyerOrgList(ordering, page, pageSize, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SellerBuyerOrgApi - factory interface
 * @export
 */
export const SellerBuyerOrgApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerBuyerOrgApiFp(configuration)
    return {
        /**
         * Returns a list of the Buyer Organisations.  Only those that are approved will be returned.  # Filtering Options  ## Fields - search str: case insensitive contains query. This will search on the Buyer Orgs name, email and phone  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=name` To reverse, simply place a hyphen in front. eg. `?ordering=-name`  - name: The buyer orgs name - email: The buyer orgs contact email - phone: The buyer orgs contact number - created: The time the org was created  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isnt one     previous: null <-- previous page number, or null if there isnt one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of the Buyer Organisations
         * @param {Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>} [ordering] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerBuyerOrgList(ordering?: Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>, page?: number, pageSize?: number, search?: string, options?: any): AxiosPromise<PaginatedSellerBuyerOrgList> {
            return localVarFp.sellerBuyerOrgList(ordering, page, pageSize, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sellerBuyerOrgList operation in SellerBuyerOrgApi.
 * @export
 * @interface SellerBuyerOrgApiSellerBuyerOrgListRequest
 */
export interface SellerBuyerOrgApiSellerBuyerOrgListRequest {
    /**
     * Ordering
     * @type {Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>}
     * @memberof SellerBuyerOrgApiSellerBuyerOrgList
     */
    readonly ordering?: Array<'-created' | '-email' | '-name' | '-phone' | 'created' | 'email' | 'name' | 'phone'>

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof SellerBuyerOrgApiSellerBuyerOrgList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SellerBuyerOrgApiSellerBuyerOrgList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof SellerBuyerOrgApiSellerBuyerOrgList
     */
    readonly search?: string
}

/**
 * SellerBuyerOrgApi - object-oriented interface
 * @export
 * @class SellerBuyerOrgApi
 * @extends {BaseAPI}
 */
export class SellerBuyerOrgApi extends BaseAPI {
    /**
     * Returns a list of the Buyer Organisations.  Only those that are approved will be returned.  # Filtering Options  ## Fields - search str: case insensitive contains query. This will search on the Buyer Orgs name, email and phone  ## Ordering The following fields can be used for ordering, it can be done by passing in the ordering query string param. eg. `?ordering=name` To reverse, simply place a hyphen in front. eg. `?ordering=-name`  - name: The buyer orgs name - email: The buyer orgs contact email - phone: The buyer orgs contact number - created: The time the org was created  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isnt one     previous: null <-- previous page number, or null if there isnt one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
     * @summary Get a list of the Buyer Organisations
     * @param {SellerBuyerOrgApiSellerBuyerOrgListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerBuyerOrgApi
     */
    public sellerBuyerOrgList(requestParameters: SellerBuyerOrgApiSellerBuyerOrgListRequest = {}, options?: any) {
        return SellerBuyerOrgApiFp(this.configuration).sellerBuyerOrgList(requestParameters.ordering, requestParameters.page, requestParameters.pageSize, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }
}
