/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Product } from '../model';
// @ts-ignore
import { ProductCalculateFee } from '../model';
// @ts-ignore
import { ProductFee } from '../model';
// @ts-ignore
import { ProductGrid } from '../model';
// @ts-ignore
import { ProductProperty } from '../model';
/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the booking fee details
         * @summary Calculate the booking fee for a given amount of the product
         * @param {string} productId 
         * @param {ProductCalculateFee} productCalculateFee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductCalculateFee: async (productId: string, productCalculateFee: ProductCalculateFee, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('apiProductCalculateFee', 'productId', productId)
            // verify required parameter 'productCalculateFee' is not null or undefined
            assertParamExists('apiProductCalculateFee', 'productCalculateFee', productCalculateFee)
            const localVarPath = `/v2/product/{product_id}/calculate-fee`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productCalculateFee, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate\'s all the requirements needed to generate a Price Grid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductGridConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/product/grid-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * No pagination
         * @summary List of all the products available
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Each property describes extra information associated with the product.  eg. A property can be created called Breed, and below that it will return a list of values which can be used.  ## fields - name: name of the property - plural: plural name - description: more descriptive information about the property - values: a list of values  ### There is no pagination on this listing. 
         * @summary List of all the properties associated with the product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPropertyList: async (productId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productPropertyList', 'productId', productId)
            const localVarPath = `/v2/product/{product_id}/property`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of a property
         * @param {string} productId 
         * @param {string} productPropertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPropertyRetrieve: async (productId: string, productPropertyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productPropertyRetrieve', 'productId', productId)
            // verify required parameter 'productPropertyId' is not null or undefined
            assertParamExists('productPropertyRetrieve', 'productPropertyId', productPropertyId)
            const localVarPath = `/v2/product/{product_id}/property/{product_property_id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"product_property_id"}}`, encodeURIComponent(String(productPropertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of a product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRetrieve: async (productId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productRetrieve', 'productId', productId)
            const localVarPath = `/v2/product/{product_id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the booking fee details
         * @summary Calculate the booking fee for a given amount of the product
         * @param {string} productId 
         * @param {ProductCalculateFee} productCalculateFee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductCalculateFee(productId: string, productCalculateFee: ProductCalculateFee, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductFee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductCalculateFee(productId, productCalculateFee, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate\'s all the requirements needed to generate a Price Grid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductGridConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductGrid>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductGridConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * No pagination
         * @summary List of all the products available
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Each property describes extra information associated with the product.  eg. A property can be created called Breed, and below that it will return a list of values which can be used.  ## fields - name: name of the property - plural: plural name - description: more descriptive information about the property - values: a list of values  ### There is no pagination on this listing. 
         * @summary List of all the properties associated with the product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productPropertyList(productId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductProperty>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productPropertyList(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of a property
         * @param {string} productId 
         * @param {string} productPropertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productPropertyRetrieve(productId: string, productPropertyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductProperty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productPropertyRetrieve(productId, productPropertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of a product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productRetrieve(productId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productRetrieve(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * Returns the booking fee details
         * @summary Calculate the booking fee for a given amount of the product
         * @param {string} productId 
         * @param {ProductCalculateFee} productCalculateFee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductCalculateFee(productId: string, productCalculateFee: ProductCalculateFee, options?: any): AxiosPromise<ProductFee> {
            return localVarFp.apiProductCalculateFee(productId, productCalculateFee, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate\'s all the requirements needed to generate a Price Grid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductGridConfig(options?: any): AxiosPromise<Array<ProductGrid>> {
            return localVarFp.apiProductGridConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * No pagination
         * @summary List of all the products available
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productList(options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.productList(options).then((request) => request(axios, basePath));
        },
        /**
         * Each property describes extra information associated with the product.  eg. A property can be created called Breed, and below that it will return a list of values which can be used.  ## fields - name: name of the property - plural: plural name - description: more descriptive information about the property - values: a list of values  ### There is no pagination on this listing. 
         * @summary List of all the properties associated with the product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPropertyList(productId: string, options?: any): AxiosPromise<Array<ProductProperty>> {
            return localVarFp.productPropertyList(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of a property
         * @param {string} productId 
         * @param {string} productPropertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPropertyRetrieve(productId: string, productPropertyId: string, options?: any): AxiosPromise<ProductProperty> {
            return localVarFp.productPropertyRetrieve(productId, productPropertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of a product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRetrieve(productId: string, options?: any): AxiosPromise<Product> {
            return localVarFp.productRetrieve(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiProductCalculateFee operation in ProductsApi.
 * @export
 * @interface ProductsApiApiProductCalculateFeeRequest
 */
export interface ProductsApiApiProductCalculateFeeRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiApiProductCalculateFee
     */
    readonly productId: string

    /**
     * 
     * @type {ProductCalculateFee}
     * @memberof ProductsApiApiProductCalculateFee
     */
    readonly productCalculateFee: ProductCalculateFee
}

/**
 * Request parameters for productPropertyList operation in ProductsApi.
 * @export
 * @interface ProductsApiProductPropertyListRequest
 */
export interface ProductsApiProductPropertyListRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductPropertyList
     */
    readonly productId: string
}

/**
 * Request parameters for productPropertyRetrieve operation in ProductsApi.
 * @export
 * @interface ProductsApiProductPropertyRetrieveRequest
 */
export interface ProductsApiProductPropertyRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductPropertyRetrieve
     */
    readonly productId: string

    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductPropertyRetrieve
     */
    readonly productPropertyId: string
}

/**
 * Request parameters for productRetrieve operation in ProductsApi.
 * @export
 * @interface ProductsApiProductRetrieveRequest
 */
export interface ProductsApiProductRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductRetrieve
     */
    readonly productId: string
}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * Returns the booking fee details
     * @summary Calculate the booking fee for a given amount of the product
     * @param {ProductsApiApiProductCalculateFeeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductCalculateFee(requestParameters: ProductsApiApiProductCalculateFeeRequest, options?: any) {
        return ProductsApiFp(this.configuration).apiProductCalculateFee(requestParameters.productId, requestParameters.productCalculateFee, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate\'s all the requirements needed to generate a Price Grid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductGridConfig(options?: any) {
        return ProductsApiFp(this.configuration).apiProductGridConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * No pagination
     * @summary List of all the products available
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productList(options?: any) {
        return ProductsApiFp(this.configuration).productList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Each property describes extra information associated with the product.  eg. A property can be created called Breed, and below that it will return a list of values which can be used.  ## fields - name: name of the property - plural: plural name - description: more descriptive information about the property - values: a list of values  ### There is no pagination on this listing. 
     * @summary List of all the properties associated with the product
     * @param {ProductsApiProductPropertyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productPropertyList(requestParameters: ProductsApiProductPropertyListRequest, options?: any) {
        return ProductsApiFp(this.configuration).productPropertyList(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of a property
     * @param {ProductsApiProductPropertyRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productPropertyRetrieve(requestParameters: ProductsApiProductPropertyRetrieveRequest, options?: any) {
        return ProductsApiFp(this.configuration).productPropertyRetrieve(requestParameters.productId, requestParameters.productPropertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of a product
     * @param {ProductsApiProductRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productRetrieve(requestParameters: ProductsApiProductRetrieveRequest, options?: any) {
        return ProductsApiFp(this.configuration).productRetrieve(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }
}
