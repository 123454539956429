/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * SellerPriceGridsBookmarkApi - axios parameter creator
 * @export
 */
export const SellerPriceGridsBookmarkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a bookmark against the grid
         * @param {string} priceGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridBookmarkCreate: async (priceGridId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'priceGridId' is not null or undefined
            assertParamExists('sellerGridBookmarkCreate', 'priceGridId', priceGridId)
            const localVarPath = `/v2/seller/grid/{price_grid_id}/bookmark`
                .replace(`{${"price_grid_id"}}`, encodeURIComponent(String(priceGridId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a bookmark against the grid
         * @param {string} priceGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridBookmarkDestroy: async (priceGridId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'priceGridId' is not null or undefined
            assertParamExists('sellerGridBookmarkDestroy', 'priceGridId', priceGridId)
            const localVarPath = `/v2/seller/grid/{price_grid_id}/bookmark`
                .replace(`{${"price_grid_id"}}`, encodeURIComponent(String(priceGridId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerPriceGridsBookmarkApi - functional programming interface
 * @export
 */
export const SellerPriceGridsBookmarkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerPriceGridsBookmarkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a bookmark against the grid
         * @param {string} priceGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerGridBookmarkCreate(priceGridId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerGridBookmarkCreate(priceGridId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a bookmark against the grid
         * @param {string} priceGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerGridBookmarkDestroy(priceGridId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerGridBookmarkDestroy(priceGridId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SellerPriceGridsBookmarkApi - factory interface
 * @export
 */
export const SellerPriceGridsBookmarkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerPriceGridsBookmarkApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a bookmark against the grid
         * @param {string} priceGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridBookmarkCreate(priceGridId: string, options?: any): AxiosPromise<void> {
            return localVarFp.sellerGridBookmarkCreate(priceGridId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a bookmark against the grid
         * @param {string} priceGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerGridBookmarkDestroy(priceGridId: string, options?: any): AxiosPromise<void> {
            return localVarFp.sellerGridBookmarkDestroy(priceGridId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sellerGridBookmarkCreate operation in SellerPriceGridsBookmarkApi.
 * @export
 * @interface SellerPriceGridsBookmarkApiSellerGridBookmarkCreateRequest
 */
export interface SellerPriceGridsBookmarkApiSellerGridBookmarkCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerPriceGridsBookmarkApiSellerGridBookmarkCreate
     */
    readonly priceGridId: string
}

/**
 * Request parameters for sellerGridBookmarkDestroy operation in SellerPriceGridsBookmarkApi.
 * @export
 * @interface SellerPriceGridsBookmarkApiSellerGridBookmarkDestroyRequest
 */
export interface SellerPriceGridsBookmarkApiSellerGridBookmarkDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerPriceGridsBookmarkApiSellerGridBookmarkDestroy
     */
    readonly priceGridId: string
}

/**
 * SellerPriceGridsBookmarkApi - object-oriented interface
 * @export
 * @class SellerPriceGridsBookmarkApi
 * @extends {BaseAPI}
 */
export class SellerPriceGridsBookmarkApi extends BaseAPI {
    /**
     * 
     * @summary Creates a bookmark against the grid
     * @param {SellerPriceGridsBookmarkApiSellerGridBookmarkCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerPriceGridsBookmarkApi
     */
    public sellerGridBookmarkCreate(requestParameters: SellerPriceGridsBookmarkApiSellerGridBookmarkCreateRequest, options?: any) {
        return SellerPriceGridsBookmarkApiFp(this.configuration).sellerGridBookmarkCreate(requestParameters.priceGridId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a bookmark against the grid
     * @param {SellerPriceGridsBookmarkApiSellerGridBookmarkDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerPriceGridsBookmarkApi
     */
    public sellerGridBookmarkDestroy(requestParameters: SellerPriceGridsBookmarkApiSellerGridBookmarkDestroyRequest, options?: any) {
        return SellerPriceGridsBookmarkApiFp(this.configuration).sellerGridBookmarkDestroy(requestParameters.priceGridId, options).then((request) => request(this.axios, this.basePath));
    }
}
