import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';

export const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${globalTheme.space[0]};
  align-items: center;
  justify-content: center;

  &:empty {
    display: none;
  }
`;
