/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { StripeBuyerOrgCreateSetupIntent } from '../model';
// @ts-ignore
import { Subscription } from '../model';
// @ts-ignore
import { SubscriptionCancel } from '../model';
// @ts-ignore
import { SubscriptionChange } from '../model';
// @ts-ignore
import { SubscriptionCreate } from '../model';
// @ts-ignore
import { SubscriptionCreateResponse } from '../model';
// @ts-ignore
import { SubscriptionPlan } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * OrganisationSubscriptionApi - axios parameter creator
 * @export
 */
export const OrganisationSubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the cancelled subscription details.
         * @summary Cancels the subscription of a buyer org
         * @param {string} buyerOrgId 
         * @param {SubscriptionCancel} subscriptionCancel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSubscriptionCancelCreate: async (buyerOrgId: string, subscriptionCancel: SubscriptionCancel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgSubscriptionCancelCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'subscriptionCancel' is not null or undefined
            assertParamExists('orgSubscriptionCancelCreate', 'subscriptionCancel', subscriptionCancel)
            const localVarPath = `/v2/org/{buyer_org_id}/subscription/cancel`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionCancel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the updated subscription details.
         * @summary Update the subscription plan or payment method for a buyer org
         * @param {string} buyerOrgId 
         * @param {SubscriptionChange} [subscriptionChange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSubscriptionChangeCreate: async (buyerOrgId: string, subscriptionChange?: SubscriptionChange, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgSubscriptionChangeCreate', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/subscription/change`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionChange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the created subscription details.
         * @summary Create a subscription for a buyer org
         * @param {string} buyerOrgId 
         * @param {SubscriptionCreate} subscriptionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSubscriptionCreateCreate: async (buyerOrgId: string, subscriptionCreate: SubscriptionCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgSubscriptionCreateCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'subscriptionCreate' is not null or undefined
            assertParamExists('orgSubscriptionCreateCreate', 'subscriptionCreate', subscriptionCreate)
            const localVarPath = `/v2/org/{buyer_org_id}/subscription/create`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns setup intent, along with the customer and an ephemeral key
         * @summary Creates a new setup intent
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSubscriptionCreateSetupIntentCreate: async (buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgSubscriptionCreateSetupIntentCreate', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/subscription/create-setup-intent`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the list of subscription plans
         * @summary List of all the available subscription plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSubscriptionList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/org/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationSubscriptionApi - functional programming interface
 * @export
 */
export const OrganisationSubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationSubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the cancelled subscription details.
         * @summary Cancels the subscription of a buyer org
         * @param {string} buyerOrgId 
         * @param {SubscriptionCancel} subscriptionCancel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgSubscriptionCancelCreate(buyerOrgId: string, subscriptionCancel: SubscriptionCancel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgSubscriptionCancelCreate(buyerOrgId, subscriptionCancel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the updated subscription details.
         * @summary Update the subscription plan or payment method for a buyer org
         * @param {string} buyerOrgId 
         * @param {SubscriptionChange} [subscriptionChange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgSubscriptionChangeCreate(buyerOrgId: string, subscriptionChange?: SubscriptionChange, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgSubscriptionChangeCreate(buyerOrgId, subscriptionChange, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the created subscription details.
         * @summary Create a subscription for a buyer org
         * @param {string} buyerOrgId 
         * @param {SubscriptionCreate} subscriptionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgSubscriptionCreateCreate(buyerOrgId: string, subscriptionCreate: SubscriptionCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgSubscriptionCreateCreate(buyerOrgId, subscriptionCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns setup intent, along with the customer and an ephemeral key
         * @summary Creates a new setup intent
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgSubscriptionCreateSetupIntentCreate(buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeBuyerOrgCreateSetupIntent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgSubscriptionCreateSetupIntentCreate(buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the list of subscription plans
         * @summary List of all the available subscription plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgSubscriptionList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionPlan>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgSubscriptionList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationSubscriptionApi - factory interface
 * @export
 */
export const OrganisationSubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationSubscriptionApiFp(configuration)
    return {
        /**
         * Returns the cancelled subscription details.
         * @summary Cancels the subscription of a buyer org
         * @param {string} buyerOrgId 
         * @param {SubscriptionCancel} subscriptionCancel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSubscriptionCancelCreate(buyerOrgId: string, subscriptionCancel: SubscriptionCancel, options?: any): AxiosPromise<Subscription> {
            return localVarFp.orgSubscriptionCancelCreate(buyerOrgId, subscriptionCancel, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the updated subscription details.
         * @summary Update the subscription plan or payment method for a buyer org
         * @param {string} buyerOrgId 
         * @param {SubscriptionChange} [subscriptionChange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSubscriptionChangeCreate(buyerOrgId: string, subscriptionChange?: SubscriptionChange, options?: any): AxiosPromise<Subscription> {
            return localVarFp.orgSubscriptionChangeCreate(buyerOrgId, subscriptionChange, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the created subscription details.
         * @summary Create a subscription for a buyer org
         * @param {string} buyerOrgId 
         * @param {SubscriptionCreate} subscriptionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSubscriptionCreateCreate(buyerOrgId: string, subscriptionCreate: SubscriptionCreate, options?: any): AxiosPromise<SubscriptionCreateResponse> {
            return localVarFp.orgSubscriptionCreateCreate(buyerOrgId, subscriptionCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns setup intent, along with the customer and an ephemeral key
         * @summary Creates a new setup intent
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSubscriptionCreateSetupIntentCreate(buyerOrgId: string, options?: any): AxiosPromise<StripeBuyerOrgCreateSetupIntent> {
            return localVarFp.orgSubscriptionCreateSetupIntentCreate(buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the list of subscription plans
         * @summary List of all the available subscription plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSubscriptionList(options?: any): AxiosPromise<Array<SubscriptionPlan>> {
            return localVarFp.orgSubscriptionList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgSubscriptionCancelCreate operation in OrganisationSubscriptionApi.
 * @export
 * @interface OrganisationSubscriptionApiOrgSubscriptionCancelCreateRequest
 */
export interface OrganisationSubscriptionApiOrgSubscriptionCancelCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationSubscriptionApiOrgSubscriptionCancelCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {SubscriptionCancel}
     * @memberof OrganisationSubscriptionApiOrgSubscriptionCancelCreate
     */
    readonly subscriptionCancel: SubscriptionCancel
}

/**
 * Request parameters for orgSubscriptionChangeCreate operation in OrganisationSubscriptionApi.
 * @export
 * @interface OrganisationSubscriptionApiOrgSubscriptionChangeCreateRequest
 */
export interface OrganisationSubscriptionApiOrgSubscriptionChangeCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationSubscriptionApiOrgSubscriptionChangeCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {SubscriptionChange}
     * @memberof OrganisationSubscriptionApiOrgSubscriptionChangeCreate
     */
    readonly subscriptionChange?: SubscriptionChange
}

/**
 * Request parameters for orgSubscriptionCreateCreate operation in OrganisationSubscriptionApi.
 * @export
 * @interface OrganisationSubscriptionApiOrgSubscriptionCreateCreateRequest
 */
export interface OrganisationSubscriptionApiOrgSubscriptionCreateCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationSubscriptionApiOrgSubscriptionCreateCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {SubscriptionCreate}
     * @memberof OrganisationSubscriptionApiOrgSubscriptionCreateCreate
     */
    readonly subscriptionCreate: SubscriptionCreate
}

/**
 * Request parameters for orgSubscriptionCreateSetupIntentCreate operation in OrganisationSubscriptionApi.
 * @export
 * @interface OrganisationSubscriptionApiOrgSubscriptionCreateSetupIntentCreateRequest
 */
export interface OrganisationSubscriptionApiOrgSubscriptionCreateSetupIntentCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationSubscriptionApiOrgSubscriptionCreateSetupIntentCreate
     */
    readonly buyerOrgId: string
}

/**
 * OrganisationSubscriptionApi - object-oriented interface
 * @export
 * @class OrganisationSubscriptionApi
 * @extends {BaseAPI}
 */
export class OrganisationSubscriptionApi extends BaseAPI {
    /**
     * Returns the cancelled subscription details.
     * @summary Cancels the subscription of a buyer org
     * @param {OrganisationSubscriptionApiOrgSubscriptionCancelCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationSubscriptionApi
     */
    public orgSubscriptionCancelCreate(requestParameters: OrganisationSubscriptionApiOrgSubscriptionCancelCreateRequest, options?: any) {
        return OrganisationSubscriptionApiFp(this.configuration).orgSubscriptionCancelCreate(requestParameters.buyerOrgId, requestParameters.subscriptionCancel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the updated subscription details.
     * @summary Update the subscription plan or payment method for a buyer org
     * @param {OrganisationSubscriptionApiOrgSubscriptionChangeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationSubscriptionApi
     */
    public orgSubscriptionChangeCreate(requestParameters: OrganisationSubscriptionApiOrgSubscriptionChangeCreateRequest, options?: any) {
        return OrganisationSubscriptionApiFp(this.configuration).orgSubscriptionChangeCreate(requestParameters.buyerOrgId, requestParameters.subscriptionChange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the created subscription details.
     * @summary Create a subscription for a buyer org
     * @param {OrganisationSubscriptionApiOrgSubscriptionCreateCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationSubscriptionApi
     */
    public orgSubscriptionCreateCreate(requestParameters: OrganisationSubscriptionApiOrgSubscriptionCreateCreateRequest, options?: any) {
        return OrganisationSubscriptionApiFp(this.configuration).orgSubscriptionCreateCreate(requestParameters.buyerOrgId, requestParameters.subscriptionCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns setup intent, along with the customer and an ephemeral key
     * @summary Creates a new setup intent
     * @param {OrganisationSubscriptionApiOrgSubscriptionCreateSetupIntentCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationSubscriptionApi
     */
    public orgSubscriptionCreateSetupIntentCreate(requestParameters: OrganisationSubscriptionApiOrgSubscriptionCreateSetupIntentCreateRequest, options?: any) {
        return OrganisationSubscriptionApiFp(this.configuration).orgSubscriptionCreateSetupIntentCreate(requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the list of subscription plans
     * @summary List of all the available subscription plans
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationSubscriptionApi
     */
    public orgSubscriptionList(options?: any) {
        return OrganisationSubscriptionApiFp(this.configuration).orgSubscriptionList(options).then((request) => request(this.axios, this.basePath));
    }
}
