import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
  useMenuButtonContext,
} from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import { Transition, Variants, motion } from 'framer-motion';
import { rgba } from 'polished';
import { FC } from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';
import { Icon } from '../icon';

const transition: Transition = {
  type: 'spring',
  bounce: 0,
  duration: 0.5,
};

const popoverVariants: Variants = {
  visible: { opacity: 1, transition },
  hidden: { opacity: 0, transition },
};

const listVariants: Variants = {
  visible: { opacity: 1, translateY: 0, transition },
  hidden: { opacity: 0, translateY: 32, transition },
};

const Button = styled(MenuButton)`
  position: relative;
  width: ${globalTheme.space[2]};
  height: ${globalTheme.space[2]};
  padding: 0;
  background: none;
  border: 0;
  outline: 0;

  &::after {
    --offset: ${globalTheme.space[1]};

    position: absolute;
    top: calc(var(--offset) * -0.5);
    left: calc(var(--offset) * -0.5);
    width: calc(100% + var(--offset));
    height: calc(100% + var(--offset));
    border: 2px solid ${globalTheme.colors.black};
    border-radius: 100%;
    opacity: 0;
    transition: ${globalTheme.transitions.fast};
    content: '';
  }

  &:focus::after {
    opacity: 1;
  }

  &:focus:not(:focus-visible)::after {
    opacity: 0;
  }
`;

const List = styled(MenuItems)`
  padding: ${globalTheme.space[0]} 0;
  border: 0;
  border-radius: ${globalTheme.elements.moreMenu.listBorderRadius};
`;

const Popover = styled(motion(MenuPopover))`
  z-index: ${globalTheme.zIndices.dialog};

  &[hidden] {
    display: block !important;
    pointer-events: none;

    @media screen and (max-width: ${breakpoints.medium}) {
      display: flex !important;
    }
  }

  @media screen and (max-width: ${breakpoints.medium}) {
    top: 0 !important;
    left: 0 !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: ${rgba(
      globalTheme.elements.moreMenu.mobileBackgroundColor,
      0.7
    )};
    backdrop-filter: blur(45px);
  }
`;

const ListWrapper = styled(motion.div)`
  min-width: 280px;
  height: 50vh;
  margin-top: auto;
  overflow: auto;
  background-color: ${globalTheme.elements.moreMenu.contentBackgroundColor};
  box-shadow: 0 8px 16px 0 rgba(17, 17, 17, 0.2);

  @media screen and (min-width: ${breakpoints.medium}) {
    position: relative;
    top: ${globalTheme.space[1]};
    height: auto;
    overflow: hidden;
    border-radius: ${globalTheme.elements.moreMenu.listBorderRadius};
  }
`;

export interface MoreMenuContentProps {
  className?: string;
}

const MoreMenuContent: FC<MoreMenuContentProps> = ({ children, className }) => {
  const { isExpanded } = useMenuButtonContext();

  return (
    <>
      <Button
        aria-label='More'
        data-cy='menuButton'
        className={className}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Icon component='KebabIcon' size={2} />
      </Button>
      <Popover
        variants={popoverVariants}
        initial='hidden'
        animate={isExpanded ? 'visible' : 'hidden'}
      >
        <ListWrapper variants={listVariants}>
          <List>{children}</List>
        </ListWrapper>
      </Popover>
    </>
  );
};

export interface MoreMenuProps {
  className?: string;
}

export const MoreMenu: FC<MoreMenuProps> = ({ children, className }) => {
  return (
    <Menu>
      <MoreMenuContent className={className}>{children}</MoreMenuContent>
    </Menu>
  );
};

export const MoreMenuItem = styled(MenuItem)`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: ${globalTheme.space[2]};
  align-items: center;
  height: 48px;
  padding: 0 ${globalTheme.space[2]};
  font-size: ${globalTheme.fontSizes.default};

  &[data-selected] {
    background-color: ${globalTheme.elements.moreMenu.itemHoverBackgroundColor};

    ${Icon} * {
      fill: ${globalTheme.elements.moreMenu.itemIconHoverColor};
    }
  }
`;
