import { FC } from 'react';
import styled, { CSSObject, keyframes } from 'styled-components';
import { Icon, IconProps } from '../icon';

export interface LoaderProps extends Pick<IconProps, 'color' | 'size'> {
  floating?: boolean;
  className?: string;
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

function getPosition(props: LoaderProps): CSSObject {
  if (props.floating) {
    return {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
  }
  return {};
}

const LoaderWrapper = styled.div`
  ${getPosition};

  > * {
    animation: ${rotate} 1s infinite;
  }
`;

export const Loader: FC<LoaderProps> = ({
  color = 'black',
  size = 2,
  floating,
  className,
}) => {
  return (
    <LoaderWrapper floating={floating} className={className}>
      <Icon component='LoadingIcon' color={color} size={size} />
    </LoaderWrapper>
  );
};
