import { OrgText } from 'components/create-org-form/steps/org-type';
import {
  ButtonGroup,
  Grid,
  Icon,
  IconButton,
  RadioBox,
  Stack,
  Text,
} from 'components/ui';
import { FC } from 'react';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { BillingCycleEnum } from '../../lib/api/generated';

const PlanSummaryContainer = styled(Grid)`
  position: relative;
  border-radius: 20px;
`;

const ChangePlanButton = styled(IconButton)`
  position: absolute;
  top: ${globalTheme.space[1]};
  right: ${globalTheme.space[2]};
  ${Text} {
    font-size: ${globalTheme.fontSizes[0]};
    @media screen and (max-width: 768px) {
      font-size: ${globalTheme.space[1]};
    }
  }
`;
const PlanText = styled(Text)`
  @media screen and (max-width: 768px) {
    font-size: ${globalTheme.space[3]};
  }
`;

const DetailText = styled(Text)`
  display: flex;
  @media screen and (max-width: 768px) {
    font-size: ${globalTheme.space[2]};
  }
`;

const Highlight = styled.div`
  background-color: ${globalTheme.colors.lightPink};
  padding: ${globalTheme.space[1]};
  border-radius: 20px;
`;

const PriceWrapper = styled.div`
  line-height: ${globalTheme.space[2]};
  width: auto;
  @media screen and (max-width: 768px) {
    font-size: ${globalTheme.space[2]};
  }
`;

const PriceDescription = styled(Text)`
  line-height: ${globalTheme.space[2]};
  width: max-content;
  @media screen and (max-width: 768px) {
    font-size: ${globalTheme.space[2]};
  }
`;

const PriceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${globalTheme.space[0]};
  align-items: flex-end;
  justify-content: center;
`;

const Price = styled(Text)`
  line-height: 42px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export interface SubscriptionPlanSummaryProps {
  name: string;
  details: string[];
  onChangePlanClick?: VoidFunction;
  setActiveBillingCycle?: Function;
  selectedBillingCycle?: BillingCycleEnum;
  price?: number | string;
  priceDescription?: string;
  hasGSTPrice?: boolean;
}

export const SubscriptionPlanSummary: FC<SubscriptionPlanSummaryProps> = ({
  name,
  details,
  onChangePlanClick = () => undefined,
  setActiveBillingCycle,
  selectedBillingCycle,
  price,
  priceDescription,
  hasGSTPrice,
}) => {
  return (
    <>
      {setActiveBillingCycle && (
        <>
          <Stack space={0}>
            <OrgText textAlign='center' fontWeight='bold'>
              Payment frequency
            </OrgText>
            <ButtonGroup>
              <RadioBox
                label='Monthly'
                name='billingCycle'
                checked={selectedBillingCycle === BillingCycleEnum.Monthly}
                onChange={() => setActiveBillingCycle(BillingCycleEnum.Monthly)}
                data-cy='paymentFreqMonthly'
                orgFontSize={3}
                isMobile={true}
              />
              <RadioBox
                label='Annual'
                name='billingCycle'
                checked={selectedBillingCycle === BillingCycleEnum.Annual}
                onChange={() => setActiveBillingCycle(BillingCycleEnum.Annual)}
                data-cy='paymentFreqAnnual'
                orgFontSize={3}
                isMobile={true}
              />
            </ButtonGroup>
          </Stack>
          <Highlight>
            <PlanText
              textAlign={'center'}
              fontSize={2}
              fontWeight={'medium'}
              color={'pink'}
            >
              Save 20% on Annual subscriptions
            </PlanText>
          </Highlight>
        </>
      )}
      <PlanSummaryContainer
        padding={3}
        gap={1}
        backgroundColor='neutralLight'
        justifyContent='center'
      >
        <ChangePlanButton
          icon='EditIcon'
          size={1}
          text='Change plan'
          buttonColor={false}
          onClick={onChangePlanClick}
          type='button'
        />
        <PlanText fontSize={3} fontWeight='semiBold' textAlign='center'>
          {name}
        </PlanText>
        <PriceContainer>
          <Price fontSize={8} fontWeight={'bold'} color={'darkBlue'}>
            {price}
          </Price>
          <PriceWrapper>
            <PriceDescription
              fontSize={0}
              fontWeight={'bold'}
              color={'blue'}
              data-cy='priceDescription'
            >
              {priceDescription}
            </PriceDescription>
            {hasGSTPrice && (
              <PriceDescription
                fontSize={0}
                fontWeight={'bold'}
                color={'blue'}
                data-cy='priceDescription'
              >
                plus GST
              </PriceDescription>
            )}
          </PriceWrapper>
        </PriceContainer>
        <Stack space={0}>
          {details &&
            details.length > 0 &&
            details.map((detail) => (
              <Grid
                key={detail}
                columns='min-content 1fr'
                alignItems='center'
                gap={0}
              >
                <Icon component='CheckCircleIcon' />
                <DetailText>{detail}</DetailText>
              </Grid>
            ))}
        </Stack>
      </PlanSummaryContainer>
    </>
  );
};
