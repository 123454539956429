import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useNanoID } from 'hooks/use-nanoid';
import {
  ElementType,
  FC,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactChild,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import styled, { CSSObject } from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Icon, IconProps } from '../icon';
import { InputMessage } from '../input-message';

export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  id?: string;
  label?: string | ReactElement;
  as?: ElementType;
  ref?: ForwardedRef<HTMLInputElement>;
  icon?: IconProps['component'];
  message?: string;
  state?: 'error' | 'success' | 'default';
  className?: string;
  actionButton?: ReactChild;
  iconAlign?: string;
  orgFontSize?: any;
  isMobile?: boolean;
}

export const variants: Variants = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.25,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.25,
    },
  },
};

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 80px;
  font-size: ${globalTheme.fontSizes.default};
`;

export const Label = styled.label<{ orgFontSize?: any; isMobile?: any }>`
  display: block;
  margin-bottom: ${globalTheme.space[0]};
  font-weight: ${globalTheme.fontWeights.bold};
  font-size: ${(props) => {
    return props?.orgFontSize && props?.isMobile
      ? globalTheme.fontSizes[4]
      : globalTheme.fontSizes[1];
  }};
`;

export const InputStyled = styled.input<{
  orgFontSize?: any;
  isMobile?: any;
}>`
  width: 100%;
  height: 44px;
  padding: ${globalTheme.space[0]} ${globalTheme.space[1]};
  font-size: ${(props) =>
    props?.orgFontSize && props?.isMobile
      ? globalTheme.fontSizes[3]
      : 'inherit'};
  border: 0;
  border-radius: inherit;
  outline: 0;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  textarea& {
    height: auto;
    min-height: 184px;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${globalTheme.space[1]};
`;

export const StyledRightIcon = styled(Icon)`
  margin-right: ${globalTheme.space[1]};
`;

export const StateIcon = styled(motion(Icon))`
  position: absolute;
  right: ${globalTheme.space[0]};
  background-color: ${globalTheme.elements.input.stateIconBackgroundColor};
  pointer-events: none;
`;

const getBorderColor = (props: {
  color: keyof typeof globalTheme.colors;
}): CSSObject => ({
  borderColor: globalTheme.colors[props.color],
});

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${globalTheme.colors.white};
  border: 2px solid ${globalTheme.colors.neutral};
  border-radius: 8px;
  transition: border-color ${globalTheme.transitions.fast};

  /* Border color override */
  ${getBorderColor}

  &:focus-within {
    border-color: ${globalTheme.colors.darkGrey};
  }

  input[type='number'] {
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    -moz-appearance: textfield;
  }
`;

export function getStateValues(state: InputProps['state']) {
  let stateColor: keyof typeof globalTheme.colors;
  let stateTextColor: keyof typeof globalTheme.colors | undefined = undefined;
  let stateIcon: IconProps['component'] = 'AlertIcon';

  switch (state) {
    case 'error':
      stateColor = 'orange';
      stateTextColor = 'white';
      stateIcon = 'AlertIcon';
      break;
    case 'success':
      stateColor = 'green';
      stateTextColor = 'white';
      stateIcon = 'CheckIcon';
      break;
    default:
      stateColor = 'neutral';
      break;
  }

  return { stateIcon, stateTextColor, stateColor };
}

export const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      id: passedId,
      as,
      message,
      state,
      icon,
      className,
      actionButton,
      iconAlign,
      orgFontSize,
      isMobile,
      ...props
    },
    ref
  ) => {
    const fallbackId = useNanoID();
    const id = passedId || `id${fallbackId}`;

    // Field colour state
    const { stateColor, stateTextColor, stateIcon } = getStateValues(state);

    // Popper reference element
    const [referenceElement, setReferenceElement] =
      useState<HTMLDivElement | null>(null);

    const onWheel = (e: any) => {
      e.target.blur();
      e.stopPropagation();
    };

    return (
      <Wrapper className={className}>
        {label && (
          <Label htmlFor={id} orgFontSize={orgFontSize} isMobile={isMobile}>
            {label}
          </Label>
        )}
        <InputWrapper color={stateColor} ref={setReferenceElement}>
          {icon && !iconAlign && <StyledIcon component={icon} size={2} />}
          <InputStyled
            id={id}
            as={as}
            {...props}
            data-state={state}
            ref={ref}
            orgFontSize={orgFontSize}
            isMobile={isMobile}
            onWheel={props?.type && props.type === 'number' && onWheel}
          />
          {icon && iconAlign && <StyledRightIcon component={icon} size={2} />}
          <AnimatePresence>
            {state && stateIcon && (
              <StateIcon
                component={stateIcon}
                color={stateTextColor || stateColor}
                size={1}
                variants={variants}
                initial='hidden'
                exit='hidden'
                animate='visible'
              />
            )}
          </AnimatePresence>
          {actionButton}
        </InputWrapper>
        <AnimatePresence>
          {message && (
            <InputMessage
              backgroundColor={stateColor}
              referenceElement={referenceElement}
              color={stateTextColor}
            >
              {message}
            </InputMessage>
          )}
        </AnimatePresence>
      </Wrapper>
    );
  }
);

const StyledInput = styled(Input)`
  ${InputWrapper} {
    border-radius: ${globalTheme.elements.curvedInput.borderRadius};
  }
`;

export const CurvedInput: FC<InputProps> = (props) => (
  <StyledInput {...props} />
);
