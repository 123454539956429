/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { ListingNetworksNotify } from '../model';
// @ts-ignore
import { ListingOrgArchive } from '../model';
// @ts-ignore
import { ListingOrgSold } from '../model';
// @ts-ignore
import { ListingOrgWrite } from '../model';
// @ts-ignore
import { ListingPriceGridBroadcast } from '../model';
// @ts-ignore
import { ListingPublishAction } from '../model';
// @ts-ignore
import { ListingRead } from '../model';
// @ts-ignore
import { PaginatedListingReadList } from '../model';
// @ts-ignore
import { PatchedListingOrgWrite } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * MarketplaceOrgListingApi - axios parameter creator
 * @export
 */
export const MarketplaceOrgListingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive the Listing
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingOrgArchive} listingOrgArchive 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingArchiveCreate: async (buyerOrgId: string, listingId: string, listingOrgArchive: ListingOrgArchive, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingArchiveCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingArchiveCreate', 'listingId', listingId)
            // verify required parameter 'listingOrgArchive' is not null or undefined
            assertParamExists('orgMarketplaceListingArchiveCreate', 'listingOrgArchive', listingOrgArchive)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}/archive`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingOrgArchive, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the ARCHIVED status, returning it to DRAFT.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingArchiveDestroy: async (buyerOrgId: string, listingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingArchiveDestroy', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingArchiveDestroy', 'listingId', listingId)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}/archive`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * NOTE: A user can have the Grid bookmarked but if their visibility access changes on the grid then they will no longer be able to see it, and thus not get a notification.  eg. User is following the grid and the grid is Public, if the grid changes to Private Groups only and the user is NOT a Contact and in one of the Networks of the Org then they no longer have access and will NOT received a notification
         * @summary Broadcasts a message to the people following (bookmarked) the Listing\'s Grid
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingPriceGridBroadcast} listingPriceGridBroadcast 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingBroadcastCreate: async (buyerOrgId: string, listingId: string, listingPriceGridBroadcast: ListingPriceGridBroadcast, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingBroadcastCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingBroadcastCreate', 'listingId', listingId)
            // verify required parameter 'listingPriceGridBroadcast' is not null or undefined
            assertParamExists('orgMarketplaceListingBroadcastCreate', 'listingPriceGridBroadcast', listingPriceGridBroadcast)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}/broadcast`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingPriceGridBroadcast, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  Creating and updating Listings  # Media  ## Create  To create a new media item the ListingMediaCreate schema must be passed through in the media list as it includes the Cloudinary data.  ## Update  When updating via a PUT then all the media items need to be passed through as its updating the complete object. They need to be in the format of ListingMedia schema so that the items can be updated (order, description, cover etc). This includes the internal id.  Via a PATCH if the media key is not passed through then it is not modified. Passing through an empty value like [] will update it and remove all media.  ### Creation on update  If you would like to add a new piece of Media with an update then the ListingMediaCreate schema can be passed through and does the same as when creating media in the first place.  ### Delete media  If upon update (PUT and PATCH) a media item in the listing is no  longer present then it will be deleted.  For example, if the current media data for the Listing looks like. ```json media: [     {         \"id\": 1,         \"name\": \"image_1\",         ...     },     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` but when updating the listing and the following is passed through ``` media: [     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` Then the first media item will be deleted.  
         * @summary Create a new Listing
         * @param {string} buyerOrgId 
         * @param {ListingOrgWrite} listingOrgWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingCreate: async (buyerOrgId: string, listingOrgWrite: ListingOrgWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingOrgWrite' is not null or undefined
            assertParamExists('orgMarketplaceListingCreate', 'listingOrgWrite', listingOrgWrite)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingOrgWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a Listing
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingDestroy: async (buyerOrgId: string, listingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingDestroy', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingDestroy', 'listingId', listingId)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * By default, if no visibility filter is passed through all ARCHIVED ones are not returned.
         * @summary Get a list of the Organisations Listings
         * @param {string} buyerOrgId 
         * @param {string} [live] Listings where visibility&#x3D;PRIVATE or PUBLIC
         * @param {string} [network] Filter by Network id. Multiple values must be comma separated. Use &#x60;all&#x60; to return Listings that are only shared with a Network.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [_private] Listings where visibility&#x3D;PRIVATE
         * @param {string} [product] Product
         * @param {string} [_public] Listings where visibility&#x3D;PUBLIC
         * @param {'REVIEW' | 'REJECTED' | 'APPROVED'} [publicStatus] Listings by the Public Status. Multiple values must be comma separated
         * @param {string} [sold] Listings that are sold
         * @param {'SELL' | 'BUY'} [type] Listing type
         * @param {'DELETED' | 'ARCHIVED' | 'EXPIRED' | 'HIDDEN' | 'PRIVATE' | 'PUBLIC'} [visibility] Listing visibility. Multiple values must be comma separated. By default, if no visibility parameter is passed through, the ARCHIVED state will be excluded.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingList: async (buyerOrgId: string, live?: string, network?: string, page?: number, pageSize?: number, _private?: string, product?: string, _public?: string, publicStatus?: 'REVIEW' | 'REJECTED' | 'APPROVED', sold?: string, type?: 'SELL' | 'BUY', visibility?: 'DELETED' | 'ARCHIVED' | 'EXPIRED' | 'HIDDEN' | 'PRIVATE' | 'PUBLIC', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingList', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (live !== undefined) {
                localVarQueryParameter['live'] = live;
            }

            if (network !== undefined) {
                localVarQueryParameter['network'] = network;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (_private !== undefined) {
                localVarQueryParameter['private'] = _private;
            }

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (_public !== undefined) {
                localVarQueryParameter['public'] = _public;
            }

            if (publicStatus !== undefined) {
                localVarQueryParameter['public_status'] = publicStatus;
            }

            if (sold !== undefined) {
                localVarQueryParameter['sold'] = sold;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (visibility !== undefined) {
                localVarQueryParameter['visibility'] = visibility;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends a push notification out to the Users who are within the Networks passed through.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingNetworksNotify} listingNetworksNotify 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingNotifyCreate: async (buyerOrgId: string, listingId: string, listingNetworksNotify: ListingNetworksNotify, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingNotifyCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingNotifyCreate', 'listingId', listingId)
            // verify required parameter 'listingNetworksNotify' is not null or undefined
            assertParamExists('orgMarketplaceListingNotifyCreate', 'listingNetworksNotify', listingNetworksNotify)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}/notify`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingNetworksNotify, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Only the keys passed through will be updated   Creating and updating Listings  # Media  ## Create  To create a new media item the ListingMediaCreate schema must be passed through in the media list as it includes the Cloudinary data.  ## Update  When updating via a PUT then all the media items need to be passed through as its updating the complete object. They need to be in the format of ListingMedia schema so that the items can be updated (order, description, cover etc). This includes the internal id.  Via a PATCH if the media key is not passed through then it is not modified. Passing through an empty value like [] will update it and remove all media.  ### Creation on update  If you would like to add a new piece of Media with an update then the ListingMediaCreate schema can be passed through and does the same as when creating media in the first place.  ### Delete media  If upon update (PUT and PATCH) a media item in the listing is no  longer present then it will be deleted.  For example, if the current media data for the Listing looks like. ```json media: [     {         \"id\": 1,         \"name\": \"image_1\",         ...     },     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` but when updating the listing and the following is passed through ``` media: [     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` Then the first media item will be deleted.  
         * @summary Partially update the details of a Listing
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {PatchedListingOrgWrite} [patchedListingOrgWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingPartialUpdate: async (buyerOrgId: string, listingId: string, patchedListingOrgWrite?: PatchedListingOrgWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingPartialUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingPartialUpdate', 'listingId', listingId)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedListingOrgWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transition a Listing between PUBLIC and PRIVATE. It can go in either direction.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingPublishAction} listingPublishAction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingPublishCreate: async (buyerOrgId: string, listingId: string, listingPublishAction: ListingPublishAction, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingPublishCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingPublishCreate', 'listingId', listingId)
            // verify required parameter 'listingPublishAction' is not null or undefined
            assertParamExists('orgMarketplaceListingPublishCreate', 'listingPublishAction', listingPublishAction)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}/publish`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingPublishAction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of a single Listing
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingRetrieve: async (buyerOrgId: string, listingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingRetrieve', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingRetrieve', 'listingId', listingId)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the sold status of the listing.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingOrgSold} listingOrgSold 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingSoldCreate: async (buyerOrgId: string, listingId: string, listingOrgSold: ListingOrgSold, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingSoldCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingSoldCreate', 'listingId', listingId)
            // verify required parameter 'listingOrgSold' is not null or undefined
            assertParamExists('orgMarketplaceListingSoldCreate', 'listingOrgSold', listingOrgSold)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}/sold`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingOrgSold, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the sold status.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingSoldDestroy: async (buyerOrgId: string, listingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingSoldDestroy', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingSoldDestroy', 'listingId', listingId)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}/sold`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  Creating and updating Listings  # Media  ## Create  To create a new media item the ListingMediaCreate schema must be passed through in the media list as it includes the Cloudinary data.  ## Update  When updating via a PUT then all the media items need to be passed through as its updating the complete object. They need to be in the format of ListingMedia schema so that the items can be updated (order, description, cover etc). This includes the internal id.  Via a PATCH if the media key is not passed through then it is not modified. Passing through an empty value like [] will update it and remove all media.  ### Creation on update  If you would like to add a new piece of Media with an update then the ListingMediaCreate schema can be passed through and does the same as when creating media in the first place.  ### Delete media  If upon update (PUT and PATCH) a media item in the listing is no  longer present then it will be deleted.  For example, if the current media data for the Listing looks like. ```json media: [     {         \"id\": 1,         \"name\": \"image_1\",         ...     },     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` but when updating the listing and the following is passed through ``` media: [     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` Then the first media item will be deleted.  
         * @summary Update the details of a Listing
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingOrgWrite} listingOrgWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingUpdate: async (buyerOrgId: string, listingId: string, listingOrgWrite: ListingOrgWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingUpdate', 'listingId', listingId)
            // verify required parameter 'listingOrgWrite' is not null or undefined
            assertParamExists('orgMarketplaceListingUpdate', 'listingOrgWrite', listingOrgWrite)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingOrgWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketplaceOrgListingApi - functional programming interface
 * @export
 */
export const MarketplaceOrgListingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketplaceOrgListingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive the Listing
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingOrgArchive} listingOrgArchive 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingArchiveCreate(buyerOrgId: string, listingId: string, listingOrgArchive: ListingOrgArchive, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingArchiveCreate(buyerOrgId, listingId, listingOrgArchive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the ARCHIVED status, returning it to DRAFT.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingArchiveDestroy(buyerOrgId: string, listingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingArchiveDestroy(buyerOrgId, listingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * NOTE: A user can have the Grid bookmarked but if their visibility access changes on the grid then they will no longer be able to see it, and thus not get a notification.  eg. User is following the grid and the grid is Public, if the grid changes to Private Groups only and the user is NOT a Contact and in one of the Networks of the Org then they no longer have access and will NOT received a notification
         * @summary Broadcasts a message to the people following (bookmarked) the Listing\'s Grid
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingPriceGridBroadcast} listingPriceGridBroadcast 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingBroadcastCreate(buyerOrgId: string, listingId: string, listingPriceGridBroadcast: ListingPriceGridBroadcast, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingBroadcastCreate(buyerOrgId, listingId, listingPriceGridBroadcast, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  Creating and updating Listings  # Media  ## Create  To create a new media item the ListingMediaCreate schema must be passed through in the media list as it includes the Cloudinary data.  ## Update  When updating via a PUT then all the media items need to be passed through as its updating the complete object. They need to be in the format of ListingMedia schema so that the items can be updated (order, description, cover etc). This includes the internal id.  Via a PATCH if the media key is not passed through then it is not modified. Passing through an empty value like [] will update it and remove all media.  ### Creation on update  If you would like to add a new piece of Media with an update then the ListingMediaCreate schema can be passed through and does the same as when creating media in the first place.  ### Delete media  If upon update (PUT and PATCH) a media item in the listing is no  longer present then it will be deleted.  For example, if the current media data for the Listing looks like. ```json media: [     {         \"id\": 1,         \"name\": \"image_1\",         ...     },     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` but when updating the listing and the following is passed through ``` media: [     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` Then the first media item will be deleted.  
         * @summary Create a new Listing
         * @param {string} buyerOrgId 
         * @param {ListingOrgWrite} listingOrgWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingCreate(buyerOrgId: string, listingOrgWrite: ListingOrgWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingCreate(buyerOrgId, listingOrgWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a Listing
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingDestroy(buyerOrgId: string, listingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingDestroy(buyerOrgId, listingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * By default, if no visibility filter is passed through all ARCHIVED ones are not returned.
         * @summary Get a list of the Organisations Listings
         * @param {string} buyerOrgId 
         * @param {string} [live] Listings where visibility&#x3D;PRIVATE or PUBLIC
         * @param {string} [network] Filter by Network id. Multiple values must be comma separated. Use &#x60;all&#x60; to return Listings that are only shared with a Network.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [_private] Listings where visibility&#x3D;PRIVATE
         * @param {string} [product] Product
         * @param {string} [_public] Listings where visibility&#x3D;PUBLIC
         * @param {'REVIEW' | 'REJECTED' | 'APPROVED'} [publicStatus] Listings by the Public Status. Multiple values must be comma separated
         * @param {string} [sold] Listings that are sold
         * @param {'SELL' | 'BUY'} [type] Listing type
         * @param {'DELETED' | 'ARCHIVED' | 'EXPIRED' | 'HIDDEN' | 'PRIVATE' | 'PUBLIC'} [visibility] Listing visibility. Multiple values must be comma separated. By default, if no visibility parameter is passed through, the ARCHIVED state will be excluded.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingList(buyerOrgId: string, live?: string, network?: string, page?: number, pageSize?: number, _private?: string, product?: string, _public?: string, publicStatus?: 'REVIEW' | 'REJECTED' | 'APPROVED', sold?: string, type?: 'SELL' | 'BUY', visibility?: 'DELETED' | 'ARCHIVED' | 'EXPIRED' | 'HIDDEN' | 'PRIVATE' | 'PUBLIC', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListingReadList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingList(buyerOrgId, live, network, page, pageSize, _private, product, _public, publicStatus, sold, type, visibility, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends a push notification out to the Users who are within the Networks passed through.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingNetworksNotify} listingNetworksNotify 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingNotifyCreate(buyerOrgId: string, listingId: string, listingNetworksNotify: ListingNetworksNotify, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingNotifyCreate(buyerOrgId, listingId, listingNetworksNotify, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Only the keys passed through will be updated   Creating and updating Listings  # Media  ## Create  To create a new media item the ListingMediaCreate schema must be passed through in the media list as it includes the Cloudinary data.  ## Update  When updating via a PUT then all the media items need to be passed through as its updating the complete object. They need to be in the format of ListingMedia schema so that the items can be updated (order, description, cover etc). This includes the internal id.  Via a PATCH if the media key is not passed through then it is not modified. Passing through an empty value like [] will update it and remove all media.  ### Creation on update  If you would like to add a new piece of Media with an update then the ListingMediaCreate schema can be passed through and does the same as when creating media in the first place.  ### Delete media  If upon update (PUT and PATCH) a media item in the listing is no  longer present then it will be deleted.  For example, if the current media data for the Listing looks like. ```json media: [     {         \"id\": 1,         \"name\": \"image_1\",         ...     },     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` but when updating the listing and the following is passed through ``` media: [     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` Then the first media item will be deleted.  
         * @summary Partially update the details of a Listing
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {PatchedListingOrgWrite} [patchedListingOrgWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingPartialUpdate(buyerOrgId: string, listingId: string, patchedListingOrgWrite?: PatchedListingOrgWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingPartialUpdate(buyerOrgId, listingId, patchedListingOrgWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transition a Listing between PUBLIC and PRIVATE. It can go in either direction.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingPublishAction} listingPublishAction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingPublishCreate(buyerOrgId: string, listingId: string, listingPublishAction: ListingPublishAction, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingPublishAction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingPublishCreate(buyerOrgId, listingId, listingPublishAction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of a single Listing
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingRetrieve(buyerOrgId: string, listingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingRetrieve(buyerOrgId, listingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the sold status of the listing.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingOrgSold} listingOrgSold 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingSoldCreate(buyerOrgId: string, listingId: string, listingOrgSold: ListingOrgSold, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingSoldCreate(buyerOrgId, listingId, listingOrgSold, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the sold status.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingSoldDestroy(buyerOrgId: string, listingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingSoldDestroy(buyerOrgId, listingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  Creating and updating Listings  # Media  ## Create  To create a new media item the ListingMediaCreate schema must be passed through in the media list as it includes the Cloudinary data.  ## Update  When updating via a PUT then all the media items need to be passed through as its updating the complete object. They need to be in the format of ListingMedia schema so that the items can be updated (order, description, cover etc). This includes the internal id.  Via a PATCH if the media key is not passed through then it is not modified. Passing through an empty value like [] will update it and remove all media.  ### Creation on update  If you would like to add a new piece of Media with an update then the ListingMediaCreate schema can be passed through and does the same as when creating media in the first place.  ### Delete media  If upon update (PUT and PATCH) a media item in the listing is no  longer present then it will be deleted.  For example, if the current media data for the Listing looks like. ```json media: [     {         \"id\": 1,         \"name\": \"image_1\",         ...     },     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` but when updating the listing and the following is passed through ``` media: [     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` Then the first media item will be deleted.  
         * @summary Update the details of a Listing
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingOrgWrite} listingOrgWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingUpdate(buyerOrgId: string, listingId: string, listingOrgWrite: ListingOrgWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingUpdate(buyerOrgId, listingId, listingOrgWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketplaceOrgListingApi - factory interface
 * @export
 */
export const MarketplaceOrgListingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketplaceOrgListingApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive the Listing
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingOrgArchive} listingOrgArchive 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingArchiveCreate(buyerOrgId: string, listingId: string, listingOrgArchive: ListingOrgArchive, options?: any): AxiosPromise<ListingRead> {
            return localVarFp.orgMarketplaceListingArchiveCreate(buyerOrgId, listingId, listingOrgArchive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the ARCHIVED status, returning it to DRAFT.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingArchiveDestroy(buyerOrgId: string, listingId: string, options?: any): AxiosPromise<ListingRead> {
            return localVarFp.orgMarketplaceListingArchiveDestroy(buyerOrgId, listingId, options).then((request) => request(axios, basePath));
        },
        /**
         * NOTE: A user can have the Grid bookmarked but if their visibility access changes on the grid then they will no longer be able to see it, and thus not get a notification.  eg. User is following the grid and the grid is Public, if the grid changes to Private Groups only and the user is NOT a Contact and in one of the Networks of the Org then they no longer have access and will NOT received a notification
         * @summary Broadcasts a message to the people following (bookmarked) the Listing\'s Grid
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingPriceGridBroadcast} listingPriceGridBroadcast 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingBroadcastCreate(buyerOrgId: string, listingId: string, listingPriceGridBroadcast: ListingPriceGridBroadcast, options?: any): AxiosPromise<void> {
            return localVarFp.orgMarketplaceListingBroadcastCreate(buyerOrgId, listingId, listingPriceGridBroadcast, options).then((request) => request(axios, basePath));
        },
        /**
         *  Creating and updating Listings  # Media  ## Create  To create a new media item the ListingMediaCreate schema must be passed through in the media list as it includes the Cloudinary data.  ## Update  When updating via a PUT then all the media items need to be passed through as its updating the complete object. They need to be in the format of ListingMedia schema so that the items can be updated (order, description, cover etc). This includes the internal id.  Via a PATCH if the media key is not passed through then it is not modified. Passing through an empty value like [] will update it and remove all media.  ### Creation on update  If you would like to add a new piece of Media with an update then the ListingMediaCreate schema can be passed through and does the same as when creating media in the first place.  ### Delete media  If upon update (PUT and PATCH) a media item in the listing is no  longer present then it will be deleted.  For example, if the current media data for the Listing looks like. ```json media: [     {         \"id\": 1,         \"name\": \"image_1\",         ...     },     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` but when updating the listing and the following is passed through ``` media: [     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` Then the first media item will be deleted.  
         * @summary Create a new Listing
         * @param {string} buyerOrgId 
         * @param {ListingOrgWrite} listingOrgWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingCreate(buyerOrgId: string, listingOrgWrite: ListingOrgWrite, options?: any): AxiosPromise<ListingRead> {
            return localVarFp.orgMarketplaceListingCreate(buyerOrgId, listingOrgWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a Listing
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingDestroy(buyerOrgId: string, listingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.orgMarketplaceListingDestroy(buyerOrgId, listingId, options).then((request) => request(axios, basePath));
        },
        /**
         * By default, if no visibility filter is passed through all ARCHIVED ones are not returned.
         * @summary Get a list of the Organisations Listings
         * @param {string} buyerOrgId 
         * @param {string} [live] Listings where visibility&#x3D;PRIVATE or PUBLIC
         * @param {string} [network] Filter by Network id. Multiple values must be comma separated. Use &#x60;all&#x60; to return Listings that are only shared with a Network.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [_private] Listings where visibility&#x3D;PRIVATE
         * @param {string} [product] Product
         * @param {string} [_public] Listings where visibility&#x3D;PUBLIC
         * @param {'REVIEW' | 'REJECTED' | 'APPROVED'} [publicStatus] Listings by the Public Status. Multiple values must be comma separated
         * @param {string} [sold] Listings that are sold
         * @param {'SELL' | 'BUY'} [type] Listing type
         * @param {'DELETED' | 'ARCHIVED' | 'EXPIRED' | 'HIDDEN' | 'PRIVATE' | 'PUBLIC'} [visibility] Listing visibility. Multiple values must be comma separated. By default, if no visibility parameter is passed through, the ARCHIVED state will be excluded.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingList(buyerOrgId: string, live?: string, network?: string, page?: number, pageSize?: number, _private?: string, product?: string, _public?: string, publicStatus?: 'REVIEW' | 'REJECTED' | 'APPROVED', sold?: string, type?: 'SELL' | 'BUY', visibility?: 'DELETED' | 'ARCHIVED' | 'EXPIRED' | 'HIDDEN' | 'PRIVATE' | 'PUBLIC', options?: any): AxiosPromise<PaginatedListingReadList> {
            return localVarFp.orgMarketplaceListingList(buyerOrgId, live, network, page, pageSize, _private, product, _public, publicStatus, sold, type, visibility, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends a push notification out to the Users who are within the Networks passed through.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingNetworksNotify} listingNetworksNotify 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingNotifyCreate(buyerOrgId: string, listingId: string, listingNetworksNotify: ListingNetworksNotify, options?: any): AxiosPromise<ListingRead> {
            return localVarFp.orgMarketplaceListingNotifyCreate(buyerOrgId, listingId, listingNetworksNotify, options).then((request) => request(axios, basePath));
        },
        /**
         * Only the keys passed through will be updated   Creating and updating Listings  # Media  ## Create  To create a new media item the ListingMediaCreate schema must be passed through in the media list as it includes the Cloudinary data.  ## Update  When updating via a PUT then all the media items need to be passed through as its updating the complete object. They need to be in the format of ListingMedia schema so that the items can be updated (order, description, cover etc). This includes the internal id.  Via a PATCH if the media key is not passed through then it is not modified. Passing through an empty value like [] will update it and remove all media.  ### Creation on update  If you would like to add a new piece of Media with an update then the ListingMediaCreate schema can be passed through and does the same as when creating media in the first place.  ### Delete media  If upon update (PUT and PATCH) a media item in the listing is no  longer present then it will be deleted.  For example, if the current media data for the Listing looks like. ```json media: [     {         \"id\": 1,         \"name\": \"image_1\",         ...     },     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` but when updating the listing and the following is passed through ``` media: [     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` Then the first media item will be deleted.  
         * @summary Partially update the details of a Listing
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {PatchedListingOrgWrite} [patchedListingOrgWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingPartialUpdate(buyerOrgId: string, listingId: string, patchedListingOrgWrite?: PatchedListingOrgWrite, options?: any): AxiosPromise<ListingRead> {
            return localVarFp.orgMarketplaceListingPartialUpdate(buyerOrgId, listingId, patchedListingOrgWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transition a Listing between PUBLIC and PRIVATE. It can go in either direction.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingPublishAction} listingPublishAction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingPublishCreate(buyerOrgId: string, listingId: string, listingPublishAction: ListingPublishAction, options?: any): AxiosPromise<ListingPublishAction> {
            return localVarFp.orgMarketplaceListingPublishCreate(buyerOrgId, listingId, listingPublishAction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of a single Listing
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingRetrieve(buyerOrgId: string, listingId: string, options?: any): AxiosPromise<ListingRead> {
            return localVarFp.orgMarketplaceListingRetrieve(buyerOrgId, listingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the sold status of the listing.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingOrgSold} listingOrgSold 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingSoldCreate(buyerOrgId: string, listingId: string, listingOrgSold: ListingOrgSold, options?: any): AxiosPromise<ListingRead> {
            return localVarFp.orgMarketplaceListingSoldCreate(buyerOrgId, listingId, listingOrgSold, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the sold status.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingSoldDestroy(buyerOrgId: string, listingId: string, options?: any): AxiosPromise<ListingRead> {
            return localVarFp.orgMarketplaceListingSoldDestroy(buyerOrgId, listingId, options).then((request) => request(axios, basePath));
        },
        /**
         *  Creating and updating Listings  # Media  ## Create  To create a new media item the ListingMediaCreate schema must be passed through in the media list as it includes the Cloudinary data.  ## Update  When updating via a PUT then all the media items need to be passed through as its updating the complete object. They need to be in the format of ListingMedia schema so that the items can be updated (order, description, cover etc). This includes the internal id.  Via a PATCH if the media key is not passed through then it is not modified. Passing through an empty value like [] will update it and remove all media.  ### Creation on update  If you would like to add a new piece of Media with an update then the ListingMediaCreate schema can be passed through and does the same as when creating media in the first place.  ### Delete media  If upon update (PUT and PATCH) a media item in the listing is no  longer present then it will be deleted.  For example, if the current media data for the Listing looks like. ```json media: [     {         \"id\": 1,         \"name\": \"image_1\",         ...     },     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` but when updating the listing and the following is passed through ``` media: [     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` Then the first media item will be deleted.  
         * @summary Update the details of a Listing
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {ListingOrgWrite} listingOrgWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingUpdate(buyerOrgId: string, listingId: string, listingOrgWrite: ListingOrgWrite, options?: any): AxiosPromise<ListingRead> {
            return localVarFp.orgMarketplaceListingUpdate(buyerOrgId, listingId, listingOrgWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgMarketplaceListingArchiveCreate operation in MarketplaceOrgListingApi.
 * @export
 * @interface MarketplaceOrgListingApiOrgMarketplaceListingArchiveCreateRequest
 */
export interface MarketplaceOrgListingApiOrgMarketplaceListingArchiveCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingArchiveCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingArchiveCreate
     */
    readonly listingId: string

    /**
     * 
     * @type {ListingOrgArchive}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingArchiveCreate
     */
    readonly listingOrgArchive: ListingOrgArchive
}

/**
 * Request parameters for orgMarketplaceListingArchiveDestroy operation in MarketplaceOrgListingApi.
 * @export
 * @interface MarketplaceOrgListingApiOrgMarketplaceListingArchiveDestroyRequest
 */
export interface MarketplaceOrgListingApiOrgMarketplaceListingArchiveDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingArchiveDestroy
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingArchiveDestroy
     */
    readonly listingId: string
}

/**
 * Request parameters for orgMarketplaceListingBroadcastCreate operation in MarketplaceOrgListingApi.
 * @export
 * @interface MarketplaceOrgListingApiOrgMarketplaceListingBroadcastCreateRequest
 */
export interface MarketplaceOrgListingApiOrgMarketplaceListingBroadcastCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingBroadcastCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingBroadcastCreate
     */
    readonly listingId: string

    /**
     * 
     * @type {ListingPriceGridBroadcast}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingBroadcastCreate
     */
    readonly listingPriceGridBroadcast: ListingPriceGridBroadcast
}

/**
 * Request parameters for orgMarketplaceListingCreate operation in MarketplaceOrgListingApi.
 * @export
 * @interface MarketplaceOrgListingApiOrgMarketplaceListingCreateRequest
 */
export interface MarketplaceOrgListingApiOrgMarketplaceListingCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {ListingOrgWrite}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingCreate
     */
    readonly listingOrgWrite: ListingOrgWrite
}

/**
 * Request parameters for orgMarketplaceListingDestroy operation in MarketplaceOrgListingApi.
 * @export
 * @interface MarketplaceOrgListingApiOrgMarketplaceListingDestroyRequest
 */
export interface MarketplaceOrgListingApiOrgMarketplaceListingDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingDestroy
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingDestroy
     */
    readonly listingId: string
}

/**
 * Request parameters for orgMarketplaceListingList operation in MarketplaceOrgListingApi.
 * @export
 * @interface MarketplaceOrgListingApiOrgMarketplaceListingListRequest
 */
export interface MarketplaceOrgListingApiOrgMarketplaceListingListRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingList
     */
    readonly buyerOrgId: string

    /**
     * Listings where visibility&#x3D;PRIVATE or PUBLIC
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingList
     */
    readonly live?: string

    /**
     * Filter by Network id. Multiple values must be comma separated. Use &#x60;all&#x60; to return Listings that are only shared with a Network.
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingList
     */
    readonly network?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingList
     */
    readonly pageSize?: number

    /**
     * Listings where visibility&#x3D;PRIVATE
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingList
     */
    readonly _private?: string

    /**
     * Product
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingList
     */
    readonly product?: string

    /**
     * Listings where visibility&#x3D;PUBLIC
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingList
     */
    readonly _public?: string

    /**
     * Listings by the Public Status. Multiple values must be comma separated
     * @type {'REVIEW' | 'REJECTED' | 'APPROVED'}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingList
     */
    readonly publicStatus?: 'REVIEW' | 'REJECTED' | 'APPROVED'

    /**
     * Listings that are sold
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingList
     */
    readonly sold?: string

    /**
     * Listing type
     * @type {'SELL' | 'BUY'}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingList
     */
    readonly type?: 'SELL' | 'BUY'

    /**
     * Listing visibility. Multiple values must be comma separated. By default, if no visibility parameter is passed through, the ARCHIVED state will be excluded.
     * @type {'DELETED' | 'ARCHIVED' | 'EXPIRED' | 'HIDDEN' | 'PRIVATE' | 'PUBLIC'}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingList
     */
    readonly visibility?: 'DELETED' | 'ARCHIVED' | 'EXPIRED' | 'HIDDEN' | 'PRIVATE' | 'PUBLIC'
}

/**
 * Request parameters for orgMarketplaceListingNotifyCreate operation in MarketplaceOrgListingApi.
 * @export
 * @interface MarketplaceOrgListingApiOrgMarketplaceListingNotifyCreateRequest
 */
export interface MarketplaceOrgListingApiOrgMarketplaceListingNotifyCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingNotifyCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingNotifyCreate
     */
    readonly listingId: string

    /**
     * 
     * @type {ListingNetworksNotify}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingNotifyCreate
     */
    readonly listingNetworksNotify: ListingNetworksNotify
}

/**
 * Request parameters for orgMarketplaceListingPartialUpdate operation in MarketplaceOrgListingApi.
 * @export
 * @interface MarketplaceOrgListingApiOrgMarketplaceListingPartialUpdateRequest
 */
export interface MarketplaceOrgListingApiOrgMarketplaceListingPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingPartialUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingPartialUpdate
     */
    readonly listingId: string

    /**
     * 
     * @type {PatchedListingOrgWrite}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingPartialUpdate
     */
    readonly patchedListingOrgWrite?: PatchedListingOrgWrite
}

/**
 * Request parameters for orgMarketplaceListingPublishCreate operation in MarketplaceOrgListingApi.
 * @export
 * @interface MarketplaceOrgListingApiOrgMarketplaceListingPublishCreateRequest
 */
export interface MarketplaceOrgListingApiOrgMarketplaceListingPublishCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingPublishCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingPublishCreate
     */
    readonly listingId: string

    /**
     * 
     * @type {ListingPublishAction}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingPublishCreate
     */
    readonly listingPublishAction: ListingPublishAction
}

/**
 * Request parameters for orgMarketplaceListingRetrieve operation in MarketplaceOrgListingApi.
 * @export
 * @interface MarketplaceOrgListingApiOrgMarketplaceListingRetrieveRequest
 */
export interface MarketplaceOrgListingApiOrgMarketplaceListingRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingRetrieve
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingRetrieve
     */
    readonly listingId: string
}

/**
 * Request parameters for orgMarketplaceListingSoldCreate operation in MarketplaceOrgListingApi.
 * @export
 * @interface MarketplaceOrgListingApiOrgMarketplaceListingSoldCreateRequest
 */
export interface MarketplaceOrgListingApiOrgMarketplaceListingSoldCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingSoldCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingSoldCreate
     */
    readonly listingId: string

    /**
     * 
     * @type {ListingOrgSold}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingSoldCreate
     */
    readonly listingOrgSold: ListingOrgSold
}

/**
 * Request parameters for orgMarketplaceListingSoldDestroy operation in MarketplaceOrgListingApi.
 * @export
 * @interface MarketplaceOrgListingApiOrgMarketplaceListingSoldDestroyRequest
 */
export interface MarketplaceOrgListingApiOrgMarketplaceListingSoldDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingSoldDestroy
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingSoldDestroy
     */
    readonly listingId: string
}

/**
 * Request parameters for orgMarketplaceListingUpdate operation in MarketplaceOrgListingApi.
 * @export
 * @interface MarketplaceOrgListingApiOrgMarketplaceListingUpdateRequest
 */
export interface MarketplaceOrgListingApiOrgMarketplaceListingUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingUpdate
     */
    readonly listingId: string

    /**
     * 
     * @type {ListingOrgWrite}
     * @memberof MarketplaceOrgListingApiOrgMarketplaceListingUpdate
     */
    readonly listingOrgWrite: ListingOrgWrite
}

/**
 * MarketplaceOrgListingApi - object-oriented interface
 * @export
 * @class MarketplaceOrgListingApi
 * @extends {BaseAPI}
 */
export class MarketplaceOrgListingApi extends BaseAPI {
    /**
     * 
     * @summary Archive the Listing
     * @param {MarketplaceOrgListingApiOrgMarketplaceListingArchiveCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingApi
     */
    public orgMarketplaceListingArchiveCreate(requestParameters: MarketplaceOrgListingApiOrgMarketplaceListingArchiveCreateRequest, options?: any) {
        return MarketplaceOrgListingApiFp(this.configuration).orgMarketplaceListingArchiveCreate(requestParameters.buyerOrgId, requestParameters.listingId, requestParameters.listingOrgArchive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the ARCHIVED status, returning it to DRAFT.
     * @param {MarketplaceOrgListingApiOrgMarketplaceListingArchiveDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingApi
     */
    public orgMarketplaceListingArchiveDestroy(requestParameters: MarketplaceOrgListingApiOrgMarketplaceListingArchiveDestroyRequest, options?: any) {
        return MarketplaceOrgListingApiFp(this.configuration).orgMarketplaceListingArchiveDestroy(requestParameters.buyerOrgId, requestParameters.listingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * NOTE: A user can have the Grid bookmarked but if their visibility access changes on the grid then they will no longer be able to see it, and thus not get a notification.  eg. User is following the grid and the grid is Public, if the grid changes to Private Groups only and the user is NOT a Contact and in one of the Networks of the Org then they no longer have access and will NOT received a notification
     * @summary Broadcasts a message to the people following (bookmarked) the Listing\'s Grid
     * @param {MarketplaceOrgListingApiOrgMarketplaceListingBroadcastCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingApi
     */
    public orgMarketplaceListingBroadcastCreate(requestParameters: MarketplaceOrgListingApiOrgMarketplaceListingBroadcastCreateRequest, options?: any) {
        return MarketplaceOrgListingApiFp(this.configuration).orgMarketplaceListingBroadcastCreate(requestParameters.buyerOrgId, requestParameters.listingId, requestParameters.listingPriceGridBroadcast, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  Creating and updating Listings  # Media  ## Create  To create a new media item the ListingMediaCreate schema must be passed through in the media list as it includes the Cloudinary data.  ## Update  When updating via a PUT then all the media items need to be passed through as its updating the complete object. They need to be in the format of ListingMedia schema so that the items can be updated (order, description, cover etc). This includes the internal id.  Via a PATCH if the media key is not passed through then it is not modified. Passing through an empty value like [] will update it and remove all media.  ### Creation on update  If you would like to add a new piece of Media with an update then the ListingMediaCreate schema can be passed through and does the same as when creating media in the first place.  ### Delete media  If upon update (PUT and PATCH) a media item in the listing is no  longer present then it will be deleted.  For example, if the current media data for the Listing looks like. ```json media: [     {         \"id\": 1,         \"name\": \"image_1\",         ...     },     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` but when updating the listing and the following is passed through ``` media: [     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` Then the first media item will be deleted.  
     * @summary Create a new Listing
     * @param {MarketplaceOrgListingApiOrgMarketplaceListingCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingApi
     */
    public orgMarketplaceListingCreate(requestParameters: MarketplaceOrgListingApiOrgMarketplaceListingCreateRequest, options?: any) {
        return MarketplaceOrgListingApiFp(this.configuration).orgMarketplaceListingCreate(requestParameters.buyerOrgId, requestParameters.listingOrgWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a Listing
     * @param {MarketplaceOrgListingApiOrgMarketplaceListingDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingApi
     */
    public orgMarketplaceListingDestroy(requestParameters: MarketplaceOrgListingApiOrgMarketplaceListingDestroyRequest, options?: any) {
        return MarketplaceOrgListingApiFp(this.configuration).orgMarketplaceListingDestroy(requestParameters.buyerOrgId, requestParameters.listingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * By default, if no visibility filter is passed through all ARCHIVED ones are not returned.
     * @summary Get a list of the Organisations Listings
     * @param {MarketplaceOrgListingApiOrgMarketplaceListingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingApi
     */
    public orgMarketplaceListingList(requestParameters: MarketplaceOrgListingApiOrgMarketplaceListingListRequest, options?: any) {
        return MarketplaceOrgListingApiFp(this.configuration).orgMarketplaceListingList(requestParameters.buyerOrgId, requestParameters.live, requestParameters.network, requestParameters.page, requestParameters.pageSize, requestParameters._private, requestParameters.product, requestParameters._public, requestParameters.publicStatus, requestParameters.sold, requestParameters.type, requestParameters.visibility, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends a push notification out to the Users who are within the Networks passed through.
     * @param {MarketplaceOrgListingApiOrgMarketplaceListingNotifyCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingApi
     */
    public orgMarketplaceListingNotifyCreate(requestParameters: MarketplaceOrgListingApiOrgMarketplaceListingNotifyCreateRequest, options?: any) {
        return MarketplaceOrgListingApiFp(this.configuration).orgMarketplaceListingNotifyCreate(requestParameters.buyerOrgId, requestParameters.listingId, requestParameters.listingNetworksNotify, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Only the keys passed through will be updated   Creating and updating Listings  # Media  ## Create  To create a new media item the ListingMediaCreate schema must be passed through in the media list as it includes the Cloudinary data.  ## Update  When updating via a PUT then all the media items need to be passed through as its updating the complete object. They need to be in the format of ListingMedia schema so that the items can be updated (order, description, cover etc). This includes the internal id.  Via a PATCH if the media key is not passed through then it is not modified. Passing through an empty value like [] will update it and remove all media.  ### Creation on update  If you would like to add a new piece of Media with an update then the ListingMediaCreate schema can be passed through and does the same as when creating media in the first place.  ### Delete media  If upon update (PUT and PATCH) a media item in the listing is no  longer present then it will be deleted.  For example, if the current media data for the Listing looks like. ```json media: [     {         \"id\": 1,         \"name\": \"image_1\",         ...     },     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` but when updating the listing and the following is passed through ``` media: [     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` Then the first media item will be deleted.  
     * @summary Partially update the details of a Listing
     * @param {MarketplaceOrgListingApiOrgMarketplaceListingPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingApi
     */
    public orgMarketplaceListingPartialUpdate(requestParameters: MarketplaceOrgListingApiOrgMarketplaceListingPartialUpdateRequest, options?: any) {
        return MarketplaceOrgListingApiFp(this.configuration).orgMarketplaceListingPartialUpdate(requestParameters.buyerOrgId, requestParameters.listingId, requestParameters.patchedListingOrgWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transition a Listing between PUBLIC and PRIVATE. It can go in either direction.
     * @param {MarketplaceOrgListingApiOrgMarketplaceListingPublishCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingApi
     */
    public orgMarketplaceListingPublishCreate(requestParameters: MarketplaceOrgListingApiOrgMarketplaceListingPublishCreateRequest, options?: any) {
        return MarketplaceOrgListingApiFp(this.configuration).orgMarketplaceListingPublishCreate(requestParameters.buyerOrgId, requestParameters.listingId, requestParameters.listingPublishAction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of a single Listing
     * @param {MarketplaceOrgListingApiOrgMarketplaceListingRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingApi
     */
    public orgMarketplaceListingRetrieve(requestParameters: MarketplaceOrgListingApiOrgMarketplaceListingRetrieveRequest, options?: any) {
        return MarketplaceOrgListingApiFp(this.configuration).orgMarketplaceListingRetrieve(requestParameters.buyerOrgId, requestParameters.listingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the sold status of the listing.
     * @param {MarketplaceOrgListingApiOrgMarketplaceListingSoldCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingApi
     */
    public orgMarketplaceListingSoldCreate(requestParameters: MarketplaceOrgListingApiOrgMarketplaceListingSoldCreateRequest, options?: any) {
        return MarketplaceOrgListingApiFp(this.configuration).orgMarketplaceListingSoldCreate(requestParameters.buyerOrgId, requestParameters.listingId, requestParameters.listingOrgSold, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the sold status.
     * @param {MarketplaceOrgListingApiOrgMarketplaceListingSoldDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingApi
     */
    public orgMarketplaceListingSoldDestroy(requestParameters: MarketplaceOrgListingApiOrgMarketplaceListingSoldDestroyRequest, options?: any) {
        return MarketplaceOrgListingApiFp(this.configuration).orgMarketplaceListingSoldDestroy(requestParameters.buyerOrgId, requestParameters.listingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  Creating and updating Listings  # Media  ## Create  To create a new media item the ListingMediaCreate schema must be passed through in the media list as it includes the Cloudinary data.  ## Update  When updating via a PUT then all the media items need to be passed through as its updating the complete object. They need to be in the format of ListingMedia schema so that the items can be updated (order, description, cover etc). This includes the internal id.  Via a PATCH if the media key is not passed through then it is not modified. Passing through an empty value like [] will update it and remove all media.  ### Creation on update  If you would like to add a new piece of Media with an update then the ListingMediaCreate schema can be passed through and does the same as when creating media in the first place.  ### Delete media  If upon update (PUT and PATCH) a media item in the listing is no  longer present then it will be deleted.  For example, if the current media data for the Listing looks like. ```json media: [     {         \"id\": 1,         \"name\": \"image_1\",         ...     },     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` but when updating the listing and the following is passed through ``` media: [     {         \"id\": 2,         \"name\": \"image_2\",         ...     } ] ``` Then the first media item will be deleted.  
     * @summary Update the details of a Listing
     * @param {MarketplaceOrgListingApiOrgMarketplaceListingUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingApi
     */
    public orgMarketplaceListingUpdate(requestParameters: MarketplaceOrgListingApiOrgMarketplaceListingUpdateRequest, options?: any) {
        return MarketplaceOrgListingApiFp(this.configuration).orgMarketplaceListingUpdate(requestParameters.buyerOrgId, requestParameters.listingId, requestParameters.listingOrgWrite, options).then((request) => request(this.axios, this.basePath));
    }
}
