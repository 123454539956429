/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PreferencesSearch } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * UserPreferencesApi - axios parameter creator
 * @export
 */
export const UserPreferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create/Update the Users search preferences
         * @param {PreferencesSearch} preferencesSearch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerPreferencesSearchCreate: async (preferencesSearch: PreferencesSearch, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'preferencesSearch' is not null or undefined
            assertParamExists('sellerPreferencesSearchCreate', 'preferencesSearch', preferencesSearch)
            const localVarPath = `/v2/seller/preferences/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(preferencesSearch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch the Users search preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerPreferencesSearchRetrieve: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/seller/preferences/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPreferencesApi - functional programming interface
 * @export
 */
export const UserPreferencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPreferencesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create/Update the Users search preferences
         * @param {PreferencesSearch} preferencesSearch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerPreferencesSearchCreate(preferencesSearch: PreferencesSearch, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreferencesSearch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerPreferencesSearchCreate(preferencesSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch the Users search preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerPreferencesSearchRetrieve(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreferencesSearch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerPreferencesSearchRetrieve(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserPreferencesApi - factory interface
 * @export
 */
export const UserPreferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPreferencesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create/Update the Users search preferences
         * @param {PreferencesSearch} preferencesSearch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerPreferencesSearchCreate(preferencesSearch: PreferencesSearch, options?: any): AxiosPromise<PreferencesSearch> {
            return localVarFp.sellerPreferencesSearchCreate(preferencesSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch the Users search preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerPreferencesSearchRetrieve(options?: any): AxiosPromise<PreferencesSearch> {
            return localVarFp.sellerPreferencesSearchRetrieve(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sellerPreferencesSearchCreate operation in UserPreferencesApi.
 * @export
 * @interface UserPreferencesApiSellerPreferencesSearchCreateRequest
 */
export interface UserPreferencesApiSellerPreferencesSearchCreateRequest {
    /**
     * 
     * @type {PreferencesSearch}
     * @memberof UserPreferencesApiSellerPreferencesSearchCreate
     */
    readonly preferencesSearch: PreferencesSearch
}

/**
 * UserPreferencesApi - object-oriented interface
 * @export
 * @class UserPreferencesApi
 * @extends {BaseAPI}
 */
export class UserPreferencesApi extends BaseAPI {
    /**
     * 
     * @summary Create/Update the Users search preferences
     * @param {UserPreferencesApiSellerPreferencesSearchCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferencesApi
     */
    public sellerPreferencesSearchCreate(requestParameters: UserPreferencesApiSellerPreferencesSearchCreateRequest, options?: any) {
        return UserPreferencesApiFp(this.configuration).sellerPreferencesSearchCreate(requestParameters.preferencesSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch the Users search preferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferencesApi
     */
    public sellerPreferencesSearchRetrieve(options?: any) {
        return UserPreferencesApiFp(this.configuration).sellerPreferencesSearchRetrieve(options).then((request) => request(this.axios, this.basePath));
    }
}
