import { useState } from 'react';
import { Step } from '.';

function buildSteps(stepTitles: string[]): Step[] {
  return stepTitles.map((title, index) => ({
    title,
    complete: false,
    active: index === 0,
  }));
}

/** Helper hook for handling step state */
export function useSteps(stepTitles: string[]) {
  const [steps, setSteps] = useState(buildSteps(stepTitles));

  const setStepComplete = (title: string, complete: boolean) => {
    setSteps((currentSteps) =>
      currentSteps.map((step) => ({
        ...step,
        complete: step.title === title ? complete : step.complete,
      }))
    );
  };

  const setStepActive = (title: string) => {
    setSteps((currentSteps) =>
      currentSteps.map((step) => ({
        ...step,
        active: step.title === title || false,
      }))
    );
  };

  return { steps, setStepComplete, setStepActive };
}
