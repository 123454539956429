import {
  Icon,
  IconButton,
  IconProps,
  Text,
  Tooltip,
  VisuallyHidden,
} from 'components/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { useStore } from 'lib/store';
import { useRouter } from 'next/router';
import { FC, useEffect, useRef, useState } from 'react';
import { useMedia } from 'react-use';
import { NAVBAR } from 'settings/config';
import styled, { keyframes } from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';

export type NavBarItem = {
  title: string;
  icon: IconProps['component'];
  href: string;
};

export interface NavBarProps {
  buyerItems?: NavBarItem[];
  sellerItems?: NavBarItem[];
  mainItems?: NavBarItem[];
}

const Item = styled.a`
  position: relative;
  z-index: ${globalTheme.zIndices.nav};
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: ${globalTheme.space[0]};
  align-items: center;
  justify-items: center;
  height: ${globalTheme.sizes.navItem};
  color: ${globalTheme.elements.global.menuText};
  text-decoration: none;
  border: 0;
  outline: 0;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: calc(100% - ${globalTheme.space[0]});
    height: 100%;
    background-color: ${globalTheme.elements.navbar.hoverColor};
    border-radius: 40px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    visibility: hidden;
    opacity: 0;
    transition: ${globalTheme.transitions.fast};
    content: '';
  }

  &:hover::before,
  &:focus::before {
    visibility: visible;
    opacity: 0.5;
  }

  &[data-active='true'] {
    color: ${globalTheme.colors.white};

    &::before {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const ItemTitle = styled(motion(Text))`
  justify-self: start;
  white-space: nowrap;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${globalTheme.sizes.navItem};
  height: ${globalTheme.sizes.navItem};
`;

const Wrapper = styled(motion.nav)<{ $isWide: boolean }>`
  position: ${(props) => (props.$isWide ? 'relative' : 'absolute')};
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${globalTheme.elements.navbar.backgroundColor};

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-right: 1px solid ${globalTheme.elements.navbar.borderRightColor};
    content: '';
    pointer-events: none;
  }
`;

const ItemWrapper = styled(motion.div)`
  height: calc(100vh - 116px);
  padding-top: ${globalTheme.space[0]};
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ToggleButton = styled(IconButton)<{ isWide: boolean }>`
  position: absolute;
  right: 0;
  z-index: 11;
  display: ${(props) => (props.isWide ? 'block' : 'none')};
  margin-top: ${globalTheme.space[1]};
  margin-left: auto;
  transform: translateX(50%);
  [aria-expanded] {
    transition: ${globalTheme.transitions.fast};
  }

  [aria-expanded='true'] {
    transform: rotate(180deg);
  }
`;

const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
`;

const ScrollIndicator = styled.div<{ hideArrow?: boolean }>`
  display: ${(props) => (props.hideArrow ? 'none' : 'block')};
  position: absolute;
  bottom: 120px;
  z-index: 12;
  background-color: #ffffffa9;
  left: 50%;
  transform: translate(-50%);
  border-radius: 50%;
  cursor: pointer;
  padding: ${globalTheme.space[0]};
`;
const BottomArrowIcon = styled(Icon)`
  animation: ${bounceAnimation} 1s infinite;
`;

const SubHeading = styled(Text)`
  padding-top: ${globalTheme.space[0]};
  padding-bottom: 4px;
  padding-left: ${globalTheme.space[1]};
  color: ${globalTheme.colors.neutralLight};
  font-size: ${globalTheme.fontSizes['0']};
  opacity: 0.75;
`;

const NavItem = styled.div`
  cursor: pointer;
`;

export const NavBar: FC<NavBarProps> = ({
  buyerItems,
  sellerItems,
  mainItems,
}) => {
  const isWide = useMedia(`(min-width: ${breakpoints.medium})`, true);
  const isNavOpen = useStore((store) => store.isNavOpen);
  const toggleNav = useStore((store) => store.toggleNav);
  const setNavbar = useStore((store) => store.setNavbar);

  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const containerRef = useRef<HTMLDivElement>(null);
  const [showArrow, setShowArrow] = useState(false);
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      // Check if scroll is available and determine whether to show the arrow initially
      setShowArrow(container.scrollHeight > container.clientHeight);

      // Add event listener for scrolling
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      // Remove event listener on cleanup
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isOpen]);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      // Check if the scroll position is at the bottom
      setShowArrow(
        container.scrollTop + container.clientHeight < container.scrollHeight
      );
    }
  };

  const scrollToBottom = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
      setShowArrow(false); // Hide the arrow after scrolling to bottom
    }
  };

  useEffect(() => {
    if (isNavOpen || isWide === false) setIsOpen(true);
    if (!isNavOpen) setIsOpen(false);
  }, [isWide, isNavOpen]);

  useEffect(() => {
    if (isOpen) {
      localStorage.setItem(NAVBAR, 'open');
    } else {
      localStorage.setItem(NAVBAR, 'close');
    }
  }, [isOpen]);

  const handleItemClick = (path: string) => {
    router.push(path);
    !isWide && toggleNav();
  };

  const handleIconClick = () => {
    setIsOpen((open) => !open);
    if (isOpen) {
      setNavbar(false);
    } else {
      setNavbar(true);
    }
  };

  const navItemDisplay = (nav: NavBarItem[]) => {
    return nav?.map((item) => {
      const routeActive = router.pathname === item.href;
      return (
        <NavItem onClick={() => handleItemClick(item.href)} key={item.title}>
          <Item
            data-active={routeActive}
            data-cy={'navButton' + item.title.replace(/\s/g, '')}
          >
            <Tooltip label={item.title}>
              <IconWrapper>
                <Icon component={item.icon} size='18px' />
              </IconWrapper>
            </Tooltip>
            <AnimatePresence>
              {isOpen ? (
                <ItemTitle
                  fontSize={0}
                  key={item.title}
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                >
                  {item.title}
                </ItemTitle>
              ) : (
                <VisuallyHidden>{item.title}</VisuallyHidden>
              )}
            </AnimatePresence>
          </Item>
        </NavItem>
      );
    });
  };

  return (
    <Wrapper
      initial='closed'
      animate={isOpen ? 'open' : 'closed'}
      data-cy={'navBarWrapper'}
      data-cy-navopen={isOpen}
      $isWide={isWide}
      variants={{
        closed: { width: isWide ? globalTheme.sizes.header : 0 },
        open: { width: globalTheme.sizes.nav },
      }}
    >
      <ToggleButton
        icon='ChevronNextIcon'
        buttonColor='blue'
        iconColor='white'
        size={2}
        onClick={() => {
          handleIconClick();
        }}
        aria-expanded={isOpen}
        isWide={isWide}
        aria-label={isOpen ? 'Close Navigation' : 'Open Navigation'}
        data-cy='toggleButton'
      />
      {showArrow && (
        <Tooltip label='Scroll To Bottom'>
          <ScrollIndicator
            onClick={scrollToBottom}
            hideArrow={!isOpen && !isWide}
          >
            <BottomArrowIcon component='ArrowDownIcon' size={1} />
          </ScrollIndicator>
        </Tooltip>
      )}
      <ItemWrapper ref={containerRef}>
        <SubHeading>{isOpen && ''}</SubHeading>
        {sellerItems && navItemDisplay(sellerItems)}
        <SubHeading>{isOpen && 'Stockbook tools'}</SubHeading>
        {buyerItems && navItemDisplay(buyerItems)}
        <SubHeading>{isOpen && 'General'}</SubHeading>
        {mainItems && navItemDisplay(mainItems)}
      </ItemWrapper>
    </Wrapper>
  );
};
