/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { AbnSearchResponse } from '../model';
// @ts-ignore
import { AcnSearchResponse } from '../model';
// @ts-ignore
import { PicSearchResponse } from '../model';
// @ts-ignore
import { Suburb } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Search for company details using its ABN.
         * @summary ABN Search
         * @param {string} [abn] ABN of the company to search for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAbnRetrieve: async (abn?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/search/abn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (abn !== undefined) {
                localVarQueryParameter['abn'] = abn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search for company details using its ACN.
         * @summary ACN Search
         * @param {string} [acn] ACN of the company to search for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAcnRetrieve: async (acn?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/search/acn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (acn !== undefined) {
                localVarQueryParameter['acn'] = acn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search the NLIS database via the PIC.
         * @summary PIC Search
         * @param {string} [pic] Property Identification Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPicList: async (pic?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/search/pic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pic !== undefined) {
                localVarQueryParameter['pic'] = pic;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The postcode can be 3 or 4 characters because postcodes in the NT are only 3 digits. A postcode of 3 digits can also be passed through with a 0 at the beginning
         * @summary Suburb search by postcode
         * @param {string} [q] Postcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPostcodeRetrieve: async (q?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/search/postcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Minimum length for the suburb name is 3 characters, otherwise it returns an empty list
         * @summary Suburb search by Suburb name
         * @param {string} [q] Suburb name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSuburbRetrieve: async (q?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/search/suburb`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * Search for company details using its ABN.
         * @summary ABN Search
         * @param {string} [abn] ABN of the company to search for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAbnRetrieve(abn?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbnSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAbnRetrieve(abn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search for company details using its ACN.
         * @summary ACN Search
         * @param {string} [acn] ACN of the company to search for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAcnRetrieve(acn?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcnSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAcnRetrieve(acn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search the NLIS database via the PIC.
         * @summary PIC Search
         * @param {string} [pic] Property Identification Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPicList(pic?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PicSearchResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPicList(pic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The postcode can be 3 or 4 characters because postcodes in the NT are only 3 digits. A postcode of 3 digits can also be passed through with a 0 at the beginning
         * @summary Suburb search by postcode
         * @param {string} [q] Postcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPostcodeRetrieve(q?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Suburb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPostcodeRetrieve(q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Minimum length for the suburb name is 3 characters, otherwise it returns an empty list
         * @summary Suburb search by Suburb name
         * @param {string} [q] Suburb name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchSuburbRetrieve(q?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Suburb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchSuburbRetrieve(q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * Search for company details using its ABN.
         * @summary ABN Search
         * @param {string} [abn] ABN of the company to search for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAbnRetrieve(abn?: string, options?: any): AxiosPromise<AbnSearchResponse> {
            return localVarFp.searchAbnRetrieve(abn, options).then((request) => request(axios, basePath));
        },
        /**
         * Search for company details using its ACN.
         * @summary ACN Search
         * @param {string} [acn] ACN of the company to search for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAcnRetrieve(acn?: string, options?: any): AxiosPromise<AcnSearchResponse> {
            return localVarFp.searchAcnRetrieve(acn, options).then((request) => request(axios, basePath));
        },
        /**
         * Search the NLIS database via the PIC.
         * @summary PIC Search
         * @param {string} [pic] Property Identification Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPicList(pic?: string, options?: any): AxiosPromise<Array<PicSearchResponse>> {
            return localVarFp.searchPicList(pic, options).then((request) => request(axios, basePath));
        },
        /**
         * The postcode can be 3 or 4 characters because postcodes in the NT are only 3 digits. A postcode of 3 digits can also be passed through with a 0 at the beginning
         * @summary Suburb search by postcode
         * @param {string} [q] Postcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPostcodeRetrieve(q?: string, options?: any): AxiosPromise<Suburb> {
            return localVarFp.searchPostcodeRetrieve(q, options).then((request) => request(axios, basePath));
        },
        /**
         * Minimum length for the suburb name is 3 characters, otherwise it returns an empty list
         * @summary Suburb search by Suburb name
         * @param {string} [q] Suburb name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSuburbRetrieve(q?: string, options?: any): AxiosPromise<Suburb> {
            return localVarFp.searchSuburbRetrieve(q, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for searchAbnRetrieve operation in SearchApi.
 * @export
 * @interface SearchApiSearchAbnRetrieveRequest
 */
export interface SearchApiSearchAbnRetrieveRequest {
    /**
     * ABN of the company to search for.
     * @type {string}
     * @memberof SearchApiSearchAbnRetrieve
     */
    readonly abn?: string
}

/**
 * Request parameters for searchAcnRetrieve operation in SearchApi.
 * @export
 * @interface SearchApiSearchAcnRetrieveRequest
 */
export interface SearchApiSearchAcnRetrieveRequest {
    /**
     * ACN of the company to search for.
     * @type {string}
     * @memberof SearchApiSearchAcnRetrieve
     */
    readonly acn?: string
}

/**
 * Request parameters for searchPicList operation in SearchApi.
 * @export
 * @interface SearchApiSearchPicListRequest
 */
export interface SearchApiSearchPicListRequest {
    /**
     * Property Identification Code
     * @type {string}
     * @memberof SearchApiSearchPicList
     */
    readonly pic?: string
}

/**
 * Request parameters for searchPostcodeRetrieve operation in SearchApi.
 * @export
 * @interface SearchApiSearchPostcodeRetrieveRequest
 */
export interface SearchApiSearchPostcodeRetrieveRequest {
    /**
     * Postcode
     * @type {string}
     * @memberof SearchApiSearchPostcodeRetrieve
     */
    readonly q?: string
}

/**
 * Request parameters for searchSuburbRetrieve operation in SearchApi.
 * @export
 * @interface SearchApiSearchSuburbRetrieveRequest
 */
export interface SearchApiSearchSuburbRetrieveRequest {
    /**
     * Suburb name
     * @type {string}
     * @memberof SearchApiSearchSuburbRetrieve
     */
    readonly q?: string
}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * Search for company details using its ABN.
     * @summary ABN Search
     * @param {SearchApiSearchAbnRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchAbnRetrieve(requestParameters: SearchApiSearchAbnRetrieveRequest = {}, options?: any) {
        return SearchApiFp(this.configuration).searchAbnRetrieve(requestParameters.abn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search for company details using its ACN.
     * @summary ACN Search
     * @param {SearchApiSearchAcnRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchAcnRetrieve(requestParameters: SearchApiSearchAcnRetrieveRequest = {}, options?: any) {
        return SearchApiFp(this.configuration).searchAcnRetrieve(requestParameters.acn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search the NLIS database via the PIC.
     * @summary PIC Search
     * @param {SearchApiSearchPicListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchPicList(requestParameters: SearchApiSearchPicListRequest = {}, options?: any) {
        return SearchApiFp(this.configuration).searchPicList(requestParameters.pic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The postcode can be 3 or 4 characters because postcodes in the NT are only 3 digits. A postcode of 3 digits can also be passed through with a 0 at the beginning
     * @summary Suburb search by postcode
     * @param {SearchApiSearchPostcodeRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchPostcodeRetrieve(requestParameters: SearchApiSearchPostcodeRetrieveRequest = {}, options?: any) {
        return SearchApiFp(this.configuration).searchPostcodeRetrieve(requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Minimum length for the suburb name is 3 characters, otherwise it returns an empty list
     * @summary Suburb search by Suburb name
     * @param {SearchApiSearchSuburbRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchSuburbRetrieve(requestParameters: SearchApiSearchSuburbRetrieveRequest = {}, options?: any) {
        return SearchApiFp(this.configuration).searchSuburbRetrieve(requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }
}
