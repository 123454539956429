import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';

export const RoundedTag = styled.div`
  display: inline-flex;
  gap: ${globalTheme.space[1]};
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 ${globalTheme.space[2]};
  font-size: ${globalTheme.fontSizes.default};
  background-color: ${globalTheme.elements.roundedTag.backgroundColor};
  border-radius: 40px;
`;
