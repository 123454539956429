import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { AppLayout } from 'components/app-layout';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import { pageView } from 'lib/analytics';
import { isBrowser } from 'lib/helpers';
import { useStore } from 'lib/store';
import debounce from 'lodash/debounce';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { setCookie } from 'nookies';
import 'normalize.css';
import React, { useEffect, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BUYER_ORG_STORAGE_KEY } from 'settings/config';
import { GlobalStyle } from 'styles/global';
import { useRouteTrackingInitializer } from '../hooks/use-previous-routes';
import { LicenseManager } from 'ag-grid-enterprise';

// Set up Stripe
const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_API_KEY as string
);

if (process.env.NODE_ENV !== 'production' && isBrowser) {
  import('react-dom').then((ReactDOM) => {
    import('@axe-core/react').then((axe) => {
      axe.default(React, ReactDOM, 1000, {});
    });
  });
}

// Set Ag-grid license key
LicenseManager.setLicenseKey(
  process.env.NEXT_PUBLIC_AG_GRID_LICENSE_TOKEN as string
);

// FIXME: Remove me and update cypress tests
// Cypress context specific overrides
if (isBrowser && (window as any).Cypress) {
  const mockOrgId = 'R0CV';
  setCookie(null, BUYER_ORG_STORAGE_KEY, mockOrgId);
  useStore.setState((state) => ({ ...state, activeBuyerOrgId: mockOrgId }));
}

interface Props extends AppProps {
  /**
   * Not part of the publicly exposed Next API.
   * Workaround for https://github.com/vercel/next.js/issues/8592
   */
  err: any;
}

function handleExitComplete() {
  if (isBrowser) {
    window.scrollTo({ top: 0 });
  }
}

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
function setVhProperty() {
  // Viewport height
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = visualViewport.height * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

function CustomApp({ Component, pageProps, err }: Props) {
  const router = useRouter();

  // React-query setup
  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    });
  }

  // Set a custom property on the root element that represents 1vh in pixels, this is a fallback for inconsistent "100vh" handling across browsers
  useEffect(() => {
    setVhProperty();
    const debouncedSetVhProperty = debounce(setVhProperty, 250);
    visualViewport.addEventListener('resize', debouncedSetVhProperty);

    return () => {
      visualViewport.removeEventListener('resize', debouncedSetVhProperty);
    };
  }, []);

  // GA Page tracking
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageView(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // Track page routes in session and send to store
  useRouteTrackingInitializer();

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, maximum-scale=1.0' />
        <script
          defer
          src='https://upload-widget.cloudinary.com/global/all.js'
          type='text/javascript'
        ></script>
      </Head>
      <SessionProvider refetchInterval={300} session={pageProps.session}>
        <GlobalStyle />
        <QueryClientProvider client={queryClientRef.current}>
          <Hydrate state={pageProps.dehydratedState}>
            <MotionConfig
              transition={{ type: 'spring', duration: 0.5, bounce: 0.4 }}
            >
              <Elements stripe={stripePromise}>
                <AppLayout
                  overrideHeaderContent={
                    (Component as any)?.overrideHeaderContent
                  }
                  hideHeader={(Component as any)?.hideHeader}
                  hideNav={(Component as any)?.hideNav}
                  hideHomeLink={(Component as any)?.hideHomeLink}
                  marketplace={(Component as any)?.marketplace}
                  app={(Component as any)?.app}
                >
                  <AnimatePresence
                    exitBeforeEnter
                    onExitComplete={handleExitComplete}
                    initial={false}
                  >
                    <Component {...pageProps} err={err} key={router.route} />
                  </AnimatePresence>
                </AppLayout>
              </Elements>
            </MotionConfig>
          </Hydrate>
        </QueryClientProvider>
      </SessionProvider>
    </>
  );
}

export default CustomApp;
