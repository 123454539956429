import * as icons from 'components/icons';
import { FC, forwardRef } from 'react';
import styled, { CSSObject } from 'styled-components';
import { globalTheme } from 'styles/global-theme';

export interface IconProps {
  /** The icon component to render */
  component: keyof typeof icons;
  color?: keyof typeof globalTheme.colors;
  /** Number = increments of space unit, string = user defined space value with unit */
  size?: keyof typeof globalTheme.space | string;
  dontFill?: boolean;
  className?: string;
}

type StyledIconProps = {
  $color: IconProps['color'];
  $size: IconProps['size'];
  dontFill: boolean;
};

const getSize = (props: StyledIconProps): CSSObject => {
  const size =
    (props.$size !== undefined &&
      (typeof props.$size === 'string'
        ? props.$size
        : globalTheme.space[props.$size])) ||
    '1em';

  return {
    width: size,
    height: size,
  };
};

const getFill = (props: StyledIconProps): CSSObject | undefined =>
  props.dontFill ? undefined : { fill: 'currentColor' };

const getColor = (props: StyledIconProps): CSSObject => ({
  color: (props.$color && globalTheme.colors[props.$color]) || 'currentColor',
});

const IconWrapper = styled.div`
  ${getSize}

  position: relative;

  * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${getFill};
  }

  /** make sure that the svg color isn't overridden by precedence */
  svg {
    ${getColor}
  }
`;

/**
 * A handy wrapper around SVG icons, providing props to set size and color.
 * Takes a component based on the SVG components exported from 'components/icons'.
 */
const IconBase: FC<IconProps> = forwardRef<HTMLDivElement, IconProps>(
  ({ component, color, size, className, dontFill = false }, ref) => {
    const IconComponent = icons[component];

    return (
      <IconWrapper
        className={className}
        $color={color}
        $size={size}
        ref={ref}
        dontFill={dontFill}
      >
        <IconComponent />
      </IconWrapper>
    );
  }
);

export const Icon = styled(IconBase)`
  /* Icon */
`;

Icon.defaultProps = {
  size: '1em',
};
