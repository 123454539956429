import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';

export const CenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: ${globalTheme.space[1]};

  > * {
    width: 100%;
    max-width: 1080px;
  }
`;
