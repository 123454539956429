import { useQuery } from '@tanstack/react-query';
import { ApiClient } from 'lib/api/client';
import { QUERY_KEYS } from 'settings/config';
import { useApiReady } from './use-api-ready';

/** Get the products list */
export function useProducts() {
  const enabled = useApiReady();

  return useQuery(
    [QUERY_KEYS.products],
    () => new ApiClient().productsApi.productList(),
    {
      select: (data) => data.data,
      enabled,
    }
  );
}
