import { useNanoID } from 'hooks/use-nanoid';
import {
  FC,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
} from 'react';
import styled, { css } from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import tick from './tick.svg';
import { isMobile } from 'react-device-detect';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: string | ReactElement;
  ref?: ForwardedRef<HTMLInputElement>;
  bookingStyle?: boolean;
  isMobile?: boolean;
}

const Label = styled.label<{ isMobile?: boolean }>`
  display: block;
  padding-inline-start: ${globalTheme.space[0]};
  font-size: ${(props) =>
    props.isMobile ? globalTheme.fontSizes[5] : globalTheme.fontSizes.default};
`;

export const CheckboxStyled = styled.input<{ bookingStyle?: boolean }>`
  position: relative;
  width: 24px;
  height: 24px;
  background-color: ${globalTheme.elements.checkbox.backgroundColor};
  border: 1px solid ${globalTheme.elements.checkbox.borderColor};
  appearance: none;

  ${({ bookingStyle }) => {
    if (bookingStyle) {
      return css`
        && {
          background-color: ${globalTheme.colors.white};
          border: 2px solid ${globalTheme.colors.neutral};
        }
      `;
    }
  }}

  &:disabled,
  &.disabled {
    background-color: ${globalTheme.elements.checkbox.disabled.backgroundColor};
    border-color: ${globalTheme.elements.checkbox.disabled.borderColor};
  }

  &[type='checkbox'] {
    border-radius: 4px;
    transition: ${globalTheme.transitions.fast};

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(${tick}) no-repeat center;
      background-size: 12px;
      transform: scale(0);
      opacity: 0;
      transition: ${globalTheme.transitions.inOutBack};
      transition-delay: 0.05s;
      content: '';
    }

    &:checked {
      background-color: ${globalTheme.elements.checkbox.checkedBackgroundColor};

      &::after {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
`;

export const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
`;

export const Checkbox: FC<CheckboxProps> = forwardRef<
  HTMLInputElement,
  CheckboxProps
>(({ id: passedId, label, bookingStyle, isMobile, ...props }, ref) => {
  const fallbackId = useNanoID();
  const id = passedId || `id${fallbackId}`;

  return (
    <CheckboxWrapper>
      <CheckboxStyled
        id={id}
        {...props}
        ref={ref}
        bookingStyle={bookingStyle}
      />
      {label && (
        <Label htmlFor={id} isMobile={isMobile}>
          {label}
        </Label>
      )}
    </CheckboxWrapper>
  );
});

Checkbox.defaultProps = {
  type: 'checkbox',
};
