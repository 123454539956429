import { NavBarItem } from 'components/nav-bar';
import { Icon, Text } from 'components/ui';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { useMedia } from 'react-use';
import { PATHS } from 'settings/config';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';

const Wrapper = styled.div`
  background-color: ${globalTheme.colors.darkBlue};
  border-radius: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${globalTheme.space[0]};
  height: 80px;
`;

const Item = styled.a<{ $isSmallerDevice?: boolean }>`
  position: relative;
  z-index: ${globalTheme.zIndices.nav};
  color: ${globalTheme.elements.global.menuText};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  width: ${({ $isSmallerDevice }) => ($isSmallerDevice ? '52px' : '60px')};
  height: ${({ $isSmallerDevice }) => ($isSmallerDevice ? '52px' : '60px')};
  border-radius: 40px;
  color: ${globalTheme.colors.white};
  &[data-active='true'] {
    background-color: ${globalTheme.elements.navbar.hoverColor};
  }
  &.stockbook-menu {
    border: ${({ $isSmallerDevice }) =>
      $isSmallerDevice ? '0px' : `4px solid ${globalTheme.colors.white}`};
    width: ${({ $isSmallerDevice }) => ($isSmallerDevice ? '60px' : '90px')};
    height: ${({ $isSmallerDevice }) => ($isSmallerDevice ? '60px' : '90px')};
    border-radius: 100%;
    background-color: ${globalTheme.colors.darkBlue};
    &[data-active='true'] {
      background-color: ${globalTheme.elements.navbar.hoverColor};
    }
  }
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
`;

const Hamburgermenu = styled(FiMenu)<{ $isSmallerDevice?: boolean }>`
  width: ${({ $isSmallerDevice }) => ($isSmallerDevice ? '28px' : '32px')};
  height: ${({ $isSmallerDevice }) => ($isSmallerDevice ? '28px' : '32px')};
`;

const MoreMenu = styled.div`
  position: absolute;
  width: 100%;
  max-width: 250px;
  background-color: ${globalTheme.colors.white};
  box-shadow: 1px 1px 6px 6px #0000001c;
  bottom: 90px;
  right: 10px;
  z-index: 12 !important;
  border-radius: ${globalTheme.space[0]};
  padding: ${globalTheme.space[0]};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 4px;
  p {
    padding: ${globalTheme.space[0]};
    border-radius: ${globalTheme.space[0]};
    text-align: center;
    &[data-active='true'] {
      background-color: ${globalTheme.colors.blue};
      color: ${globalTheme.colors.white};
    }
  }
`;

const sellerItems: NavBarItem[] = [
  {
    title: 'Home',
    icon: 'HutIcon',
    href: PATHS.home,
  },
  {
    title: 'Marketplace',
    icon: 'Marketplace',
    href: PATHS.marketplace.listings,
  },
  {
    title: 'Stockbook',
    icon: 'StockbookNewIcon',
    href: PATHS.stockbook.home,
  },
  {
    title: 'My Buy listings',
    icon: 'MyListingsIcon',
    href: PATHS.marketplace.buyListings,
  },
];

const moreMenu = [
  {
    value: 'Stock Required',
    id: 'stock-required',
    href: PATHS.stockRequired.home,
  },
  {
    value: 'My Sell listings',
    id: 'MySellListings',
    href: PATHS.marketplace.sellListings,
  },
  { value: 'Pic manager', id: 'pic-manager', href: PATHS.picManager.home },
  { value: 'Contacts', id: 'contacts', href: PATHS.contacts.home },
  { value: 'Networks', id: 'networks', href: PATHS.privateGroups.home },
  {
    value: 'Price grid PDF creator',
    id: 'pdf-creator',
    href: PATHS.summaryGrids.home,
  },
  {
    value: 'Company Profile',
    id: 'company-profile',
    href: PATHS.organisation.profile,
  },
  { value: 'Help', id: 'help', href: PATHS.help },
];

export const BottomNavBar = () => {
  const ref = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuActive, setMenuActive] = useState<boolean>(false);
  const isSmallerDevice = useMedia(`(max-width: 323px)`, true);
  const router = useRouter();

  const handleItemClick = (path: string) => {
    setMenuActive(false);
    router.push(path);
  };

  const navItemDisplay = (nav: NavBarItem[]) => {
    return nav?.map((item) => {
      const routeActive = router.pathname === item.href;
      return (
        <NavItem onClick={() => handleItemClick(item.href)} key={item.title}>
          <Item
            data-active={routeActive}
            data-cy={'navButton' + item.title.replace(/\s/g, '')}
            $isSmallerDevice={isSmallerDevice}
            className={item.title === 'Stockbook' ? 'stockbook-menu' : ''}
          >
            <Icon
              component={item.icon}
              size={isSmallerDevice ? '28px' : '32px'}
            />
          </Item>
        </NavItem>
      );
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuActive &&
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setMenuActive(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [menuActive]);

  return (
    <Wrapper>
      {navItemDisplay(sellerItems)}
      <NavItem ref={menuRef}>
        <Item
          $isSmallerDevice={isSmallerDevice}
          data-active={
            moreMenu.some((item) => item.href === router.pathname)
              ? 'true'
              : 'false'
          }
        >
          <Hamburgermenu
            $isSmallerDevice={isSmallerDevice}
            onClick={() => setMenuActive(!menuActive)}
          />
        </Item>
      </NavItem>
      {menuActive && (
        <MoreMenu ref={ref}>
          {moreMenu.map((menu, idx) => (
            <>
              <Text
                key={idx}
                fontSize={2}
                onClick={() => handleItemClick(menu.href)}
                data-active={router.pathname === menu.href}
              >
                {menu.value}
              </Text>
              {moreMenu.length !== idx + 1 && <hr />}
            </>
          ))}
        </MoreMenu>
      )}
    </Wrapper>
  );
};

export default BottomNavBar;
