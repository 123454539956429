import { FC } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';

export type CodeInputProps = React.ComponentProps<typeof ReactCodeInput>;

export const StyledCodeInput = styled(ReactCodeInput)`
  width: auto !important;

  & div {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
  }

  div input {
    box-sizing: border-box;
    color: black;
    font-size: 32px;
    font-family: inherit;
    text-align: center;
    background-color: white;
    border: 2px solid ${globalTheme.colors.neutral};
    border-radius: 4px;
    appearance: textfield;

    &:first-child {
      border-radius: 4px;
    }

    &:last-child {
      border: 2px solid ${globalTheme.colors.neutral};
      border-radius: 4px;
    }

    &:focus {
      border: 1px solid blue;
      outline: none;
      caret-color: blue;
    }

    &:focus + input {
      border-left: 2px solid ${globalTheme.colors.neutral};
    }
  }
`;

export const CodeInput: FC<CodeInputProps> = (props) => (
  <StyledCodeInput fieldHeight={60} fieldWidth={42} {...props} />
);
