import { Button, Icon, Text } from 'components/ui';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { BillingCycleEnum, SubscriptionPlan } from '../../lib/api/generated';
import { getSubscriptionPriceAUD } from '../../lib/helpers';
import { TextToggleDisplay } from '../ui/text-display-toggle';

export const SUBSCRIPTION_PRICE_CARD_BORDER_RADIUS = '32px';

const Container = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  grid-template-rows: auto 42px auto 1fr;
  grid-template-columns: 1fr;
  align-content: start;
  padding: 28px;
  background: ${globalTheme.colors.white};
  border: 2px solid transparent;
  border-color: ${({ selected }) =>
    selected ? globalTheme.colors.pink : `transparent`};
  border-radius: ${SUBSCRIPTION_PRICE_CARD_BORDER_RADIUS};
  box-shadow: 0 32px 64px rgba(17, 17, 17, 0.08);
  transition: ${globalTheme.transitions.fast};
`;

const Name = styled(Text)`
  margin: 0 0 ${globalTheme.space[0]} 0;
  @media screen and (max-width: 768px) {
    font-size: ${globalTheme.space[3]};
  }
`;

const PriceContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: ${globalTheme.space[0]};
  align-items: flex-end;
`;

const Price = styled(Text)`
  line-height: 42px;
  width: max-content;
  @media screen and (max-width: 768px) {
    font-size: ${globalTheme.space[2]};
  }
`;

const PriceWrapper = styled.div`
  line-height: ${globalTheme.space[2]};
`;

const PriceDescription = styled(Text)`
  line-height: ${globalTheme.space[2]};
  width: max-content;
  @media screen and (max-width: 768px) {
    font-size: ${globalTheme.space[2]};
  }
`;

const FeatureText = styled(Text)`
  font-size: ${globalTheme.space[1]};
  @media screen and (max-width: 768px) {
    font-size: ${globalTheme.space[2]};
  }
`;

const FeaturesContainer = styled.ul`
  display: grid;
  gap: ${globalTheme.space[0]};
  margin: ${globalTheme.space[0]} 0 0;
`;

const FeatureContainer = styled.li`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${globalTheme.space[1]};
  align-items: center;
  list-style-type: disc;
`;

const SelectedText = styled(Text)`
  font-size: ${globalTheme.space[1]};
  @media screen and (max-width: 768px) {
    font-size: ${globalTheme.space[2]};
  }
`;

const UnstyledFeatureContainer = styled(FeatureContainer)<{
  noFeatureItem: boolean;
}>`
  ${(props) =>
    !props.noFeatureItem &&
    css`
      &:first-of-type {
        > div:first-of-type {
          display: none;
        }
        p {
          font-weight: ${globalTheme.fontWeights.medium};
        }
      }
    `}
`;

const Disc = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${globalTheme.colors.black}; ;
`;

const ButtonContainer = styled.div`
  display: grid;
  align-items: end;
  justify-content: center;
  margin-top: ${globalTheme.space[1]};
`;

const StyledButton = styled(Button)`
  min-width: 128px;
  @media screen and (max-width: 768px) {
    min-width: 158px;
    font-size: ${globalTheme.space[2]};
  }
`;

const SelectedContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto 1fr;
  gap: ${globalTheme.space[0]};
  align-items: center;
  height: 40px;
`;

const CheckIcon = styled(Icon)`
  background: ${globalTheme.colors.pink};
  border-radius: 50%;
`;

const MoreInfoText = styled(Text)`
  font-size: ${globalTheme.fontSizes[1]};
  color: ${globalTheme.colors['darkGrey']};
`;

export interface SubscriptionPriceCardProps {
  name: string;
  features?: {
    target: string;
    values: { feature: string; info?: string }[];
  };
  selectable?: boolean;
  selected?: boolean;
  onSelect?: VoidFunction;
  className?: string;
  additionalFeatures?: {
    plan: string;
    target: string;
    values: { feature: string; info?: string }[];
  }[];
  selectedTierPlans?: SubscriptionPlan[];
}

export const SubscriptionPriceCard: FC<SubscriptionPriceCardProps> = ({
  name,
  features,
  selectable = true,
  selected = false,
  onSelect,
  className,
  additionalFeatures,
  selectedTierPlans,
}) => {
  const priceGetter = (
    plans: SubscriptionPlan[] | undefined,
    billingCycle: BillingCycleEnum
  ): number | undefined => {
    if (!plans) {
      return undefined;
    }

    return plans
      ?.filter((plan: SubscriptionPlan) => plan.billing_cycle === billingCycle)
      .map((target: SubscriptionPlan) => target.price)[0];
  };

  return (
    <Container className={className} selected={selected}>
      {name !== 'Free Plan' ? (
        <Name
          fontSize={2}
          fontWeight={'semiBold'}
          color={'darkBlue'}
          style={{ textTransform: 'uppercase' }}
        >
          {name}
        </Name>
      ) : (
        <Name
          fontSize={2}
          fontWeight={'semiBold'}
          color={'darkBlue'}
          style={{ textTransform: 'uppercase' }}
        >
          {name}
        </Name>
      )}
      {name !== 'Free Plan' && (
        <>
          <Name fontSize={3} fontWeight={'semiBold'} color={'pink'}>
            {getSubscriptionPriceAUD(
              priceGetter(selectedTierPlans, BillingCycleEnum.Monthly)
            )}
            /month or{' '}
            {getSubscriptionPriceAUD(
              priceGetter(selectedTierPlans, BillingCycleEnum.Annual)
            )}
            /year
          </Name>
          {/*<Name fontSize={5} fontWeight={'semiBold'} color={'pink'}>*/}
          {/*  */}
          {/*</Name>*/}
        </>
      )}
      <FeaturesContainer>
        <FeatureText color={'darkBlue'} fontWeight={'semiBold'}>
          {additionalFeatures && additionalFeatures[0].target}
        </FeatureText>
        {additionalFeatures &&
          additionalFeatures?.length > 0 &&
          additionalFeatures[0].values.map(
            (additional: { feature: string; info?: string }) => {
              return (
                <UnstyledFeatureContainer
                  key={additional.feature}
                  noFeatureItem={name === 'Premium Plan'}
                >
                  <Disc></Disc>{' '}
                  <TextToggleDisplay mainText={additional.feature}>
                    <MoreInfoText fontWeight={'light'}>
                      {additional.info}
                    </MoreInfoText>
                  </TextToggleDisplay>
                </UnstyledFeatureContainer>
              );
            }
          )}
        {name === 'Free Plan' ? (
          <>
            <FeatureText color={'darkBlue'} fontWeight={'semiBold'}>
              {features?.target}
            </FeatureText>
            {features?.values.map((feature) => {
              return (
                <FeatureContainer key={feature.feature}>
                  <Disc></Disc>
                  <TextToggleDisplay mainText={feature.feature}>
                    <MoreInfoText fontWeight={'light'}>
                      {feature.info}
                    </MoreInfoText>
                  </TextToggleDisplay>
                </FeatureContainer>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </FeaturesContainer>
      <ButtonContainer>
        {selectable && !selected && (
          <StyledButton
            type='button'
            size='small'
            variant='primary'
            onClick={() => onSelect?.()}
            data-cy={`${name.replace(/\s+|[,\/]/g, '')}SelectButton`}
          >
            Select
          </StyledButton>
        )}
        {selectable && selected && (
          <SelectedContainer>
            <CheckIcon component={'CheckIcon'} color={'white'} size={2} />
            <SelectedText fontSize={1}>Selected</SelectedText>
          </SelectedContainer>
        )}
        {!selectable && (
          <Text color='pink' fontWeight='bold'>
            Your current plan
          </Text>
        )}
      </ButtonContainer>
    </Container>
  );
};
