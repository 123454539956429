import { logger } from '@hatchd/utils';
import { useRect } from '@reach/rect';
import { useMultipleSelection, useSelect } from 'downshift';
import { AnimatePresence } from 'framer-motion';
import { isEqual } from 'lodash';
import {
  FC,
  HTMLAttributes,
  Key,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Checkbox } from '../checkbox';
import { ComboboxList, ComboboxListItem } from '../combobox';
import { Icon } from '../icon';
import {
  InputProps,
  InputWrapper,
  Label,
  Wrapper,
  getStateValues,
} from '../input';
import { InputMessage } from '../input-message';
import { SelectItem } from '../select';

export interface MultiSelectProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  items: SelectItem[];
  label?: string;
  labelledBy?: string;
  state?: InputProps['state'];
  message?: InputProps['message'];
  initialSelectedOptions?: SelectItem['id'][];
  disabled?: boolean;
  bookingStyle?: boolean;
  onChange?: (value: SelectItem[]) => void;
  placeholder?: string;
  value?: any;
  isOptionVisible?: boolean;
}

const SelectWrapper = styled(InputWrapper)<{ bookingStyle?: boolean }>`
  ${({ bookingStyle }) => {
    if (bookingStyle) {
      return css`
        padding: 4px 8px;
        font-weight: ${globalTheme.fontWeights.light};
        border-radius: 6px;

        && {
          height: 32px;
          min-height: auto;
        }
      `;
    }
  }}
`;

const List = styled(ComboboxList)`
  /* Overrides */
  outline: 0;
`;

const ListItem = styled(ComboboxListItem)`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: ${globalTheme.space[0]};
  align-items: center;
`;

const Button = styled.button`
  position: relative;
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: ${globalTheme.space[0]};
  align-items: center;
  width: 100%;
  min-height: 44px;
  padding: ${globalTheme.space[0]} ${globalTheme.space[1]};
  font-size: inherit;
  text-align: left;
  background-color: transparent;
  border: 0;
  outline: 0;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &::before {
    position: absolute;
    top: ${globalTheme.space[0]};
    left: -2px;
    z-index: -1;
    width: calc(100% + 4px);
    height: 100%;
    background-color: ${globalTheme.colors.white};
    transform: translateY(${globalTheme.space[0]});
    opacity: 0;
    transition: ${globalTheme.transitions.fast};
    content: '';
    pointer-events: none;
  }

  &[aria-expanded='true']::before {
    transform: translateY(0);
    opacity: 1;
  }
`;

const SelectedItems = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const MultiSelect: FC<MultiSelectProps> = ({
  items,
  label,
  state,
  initialSelectedOptions,
  onChange,
  placeholder,
  message,
  labelledBy,
  disabled,
  bookingStyle,
  value,
  isOptionVisible = false,
  ...divProps
}) => {
  // Memo the initial selection on mount
  const initialSelectedOptionsRef = useRef(initialSelectedOptions);

  const {
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
    setSelectedItems,
  } = useMultipleSelection<SelectItem['id']>({
    initialSelectedItems: initialSelectedOptionsRef.current || [],
    onSelectedItemsChange: ({ selectedItems }) => {
      const selectedItemsFull = selectedItems?.map(
        (selectedItem) =>
          items.find((item) => item.id === selectedItem) || {
            id: '',
            value: '',
          }
      );
      onChange && selectedItemsFull && onChange(selectedItemsFull);
    },
  });

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    selectItem,
  } = useSelect<SelectItem['id'] | null>({
    items: items.map((item) => item.id),
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          };
        case useSelect.stateChangeTypes.ToggleButtonBlur:
          return {
            ...changes,
            isOpen: false, // close the menu after blur event on menu.
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem) {
            // Remove previously selected item
            if (checkItemIsSelected(selectedItem)) {
              removeSelectedItem(selectedItem);
            } else {
              logger.info('Adding item', selectedItem);
              if (selectedItems.includes('all')) {
                //remove all checked
                removeSelectedItem('all');
              }
              if (selectedItem === 'all') {
                //remove other checkeditems
                selectedItems?.map((item) => {
                  removeSelectedItem(item);
                });
                addSelectedItem(selectedItem);
              } else {
                // Add new selected item
                addSelectedItem(selectedItem);
              }
            }
            // Reset the selection
            selectItem(null);
          }

          break;
        default:
          break;
      }
    },
  });

  const { stateColor } = getStateValues(state);
  // update selectedItems when the value prop changes

  useEffect(() => {
    if (value) {
      if (!isEqual(value, selectedItems)) {
        setSelectedItems(value);
      }
    }
  }, [value, setSelectedItems]);

  // Popper reference element
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  const toggleButtonProps = getToggleButtonProps(
    getDropdownProps({ preventKeyAction: isOpen || isOptionVisible })
  );

  const checkItemIsSelected = (item: SelectItem['id']) =>
    Array.isArray(selectedItems) &&
    selectedItems.some((selectedItem) => selectedItem === item);

  // Observe the bounding rect of the input wrapper so that the list can be positioned
  const wrapperRef = useRef<HTMLDivElement>(null);
  const rect: DOMRect | null = useRect(wrapperRef, {
    observe: isOpen || isOptionVisible,
  });

  //get the y-axis position of menulist according to its position
  const getListTopPosition = useMemo(() => {
    if (isOpen || isOptionVisible) {
      const { innerHeight: height } = window;
      const heightOfItem = 60;
      if (rect && height / 2 < rect?.y) {
        if (items.length <= 3) {
          return (rect?.top || 0) - heightOfItem * items.length;
        } else {
          return (rect?.top || 0) - 210;
        }
      }
    }
    return (rect?.top || 0) + (rect?.height || 0);
  }, [isOpen, isOptionVisible, rect, items]);

  return (
    <Wrapper ref={setReferenceElement} {...divProps}>
      {label && <Label {...getLabelProps()}>{label}</Label>}
      <SelectWrapper
        color={stateColor}
        ref={wrapperRef}
        data-state={state}
        bookingStyle={bookingStyle}
      >
        <Button
          type='button'
          {...toggleButtonProps}
          aria-labelledby={labelledBy || toggleButtonProps['aria-labelledby']}
          disabled={disabled}
        >
          <SelectedItems>
            {(Array.isArray(selectedItems) &&
              selectedItems
                .map(
                  (selectedItem) =>
                    items.find((item) => item.id === selectedItem)?.value
                )
                .join(', ')) ||
              placeholder}
          </SelectedItems>

          <Icon component='ChevronDownIcon' />
        </Button>
      </SelectWrapper>

      <List
        {...getMenuProps()}
        style={{
          width: 'fit-content',
          minWidth: rect?.width,
          top: getListTopPosition,
          left: rect?.left,
        }}
      >
        {(isOptionVisible || isOpen) &&
          items.map((item, index) => (
            <ListItem
              key={item.id as Key}
              {...getItemProps({ item: item.id, index })}
              data-cy={`listItem${item.id}`}
            >
              <Checkbox checked={checkItemIsSelected(item.id)} readOnly />
              {item.value}
            </ListItem>
          ))}
      </List>

      <AnimatePresence>
        {message && (
          <InputMessage
            backgroundColor={stateColor}
            referenceElement={referenceElement}
          >
            {message}
          </InputMessage>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};
