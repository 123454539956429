/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { BuyerContactAddSearchResult } from '../model';
// @ts-ignore
import { BuyerContactCreate } from '../model';
// @ts-ignore
import { BuyerContactInviteSearch } from '../model';
// @ts-ignore
import { BuyerContactInviteSearchResult } from '../model';
// @ts-ignore
import { BuyerContactUpdate } from '../model';
// @ts-ignore
import { ContactDetailed } from '../model';
// @ts-ignore
import { PaginatedContactDetailedList } from '../model';
// @ts-ignore
import { PatchedBuyerContactUpdate } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * OrganisationContactApi - axios parameter creator
 * @export
 */
export const OrganisationContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The data passed in through here would have been validated from the invite-search step so an error         shouldn\'t occur, however it can.  If a user_id is passed through then a 400 error on this field can occur for a few reasons. Contact already exists for that User. Contact is blocked.  At least one of the email or mobile fields must match that of the user_id, otherwise an error will occur on that field.  If a user_id is not passed through, then any errors of a Contact being found to exists or blocked will be returned on the email/mobile fields. 
         * @summary Create a new Contact
         * @param {string} buyerOrgId 
         * @param {BuyerContactCreate} [buyerContactCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactCreate: async (buyerOrgId: string, buyerContactCreate?: BuyerContactCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgContactCreate', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/contact`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerContactCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a Contact from the organisation  If the status is currently blocked, then a field will mark it as deleted so it won\'t appear in the buyers listing anymore but it will still show for the sellers.
         * @summary Delete a Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactDestroy: async (buyerOrgId: string, contactId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgContactDestroy', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('orgContactDestroy', 'contactId', contactId)
            const localVarPath = `/v2/org/{buyer_org_id}/contact/{contact_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This will send out a PUSH, Email, or SMS notification to the Contact inviting them to the Organisation.  If an invite has already been sent out, this cannot be sent out again.
         * @summary Send out an invite to the Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactInviteCreate: async (buyerOrgId: string, contactId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgContactInviteCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('orgContactInviteCreate', 'contactId', contactId)
            const localVarPath = `/v2/org/{buyer_org_id}/contact/{contact_id}/invite`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * At least one email or mobile is required to perform the search.  user_id will be populated if a User was found matching. contact_id will be populated if a matching Contact record is already found.  If no User or Contact record has been found, \'result\' with be None
         * @summary Search the system for new contacts via their mobile and/or email.
         * @param {string} buyerOrgId 
         * @param {BuyerContactInviteSearch} [buyerContactInviteSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactInviteSearchCreate: async (buyerOrgId: string, buyerContactInviteSearch?: BuyerContactInviteSearch, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgContactInviteSearchCreate', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/contact/invite-search`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerContactInviteSearch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the list of Contacts beneath the organisation.  Only the contacts which are APPROVED will return the user record  # Filtering Options  ## Fields - search str: case insensitive contains query. This will search on the user firstname, lastname, email, mobile and phone eg ?search=Joe - status str: limit results to contacts with this states: valid values APPROVED, PENDING, REJECTED, or BLOCKED which     hasn\'t been deleted by the buyer  eg. ?status=APPROVED or ?status=BLOCKED - my_contacts int: \"1\" will return only the contacts they created, \"0\" will return all other groups. eg ?my_contacts=1  ## Ordering - firstname: based on the firstname, eg ?ordering=firstname, can be reversed. eg ?ordering=-firstname - lastname: based on the lastname, eg ?ordering=lastname, can be reversed. eg ?ordering=-lastname - email: based on the email, eg ?ordering=email, can be reversed. eg ?ordering=-email - mobile: based on the mobile, eg ?ordering=mobile, can be reversed. eg ?ordering=-mobile - phone: based on the phone, eg ?ordering=phone, can be reversed ?ordering=-phone - created: based on the datetime the contact record was created, eg ?ordering=created, can be reversed     ?ordering=-created  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of Contacts within the Organisation
         * @param {string} buyerOrgId 
         * @param {string} [myContacts] 
         * @param {Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>} [ordering] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactList: async (buyerOrgId: string, myContacts?: string, ordering?: Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>, page?: number, pageSize?: number, search?: string, status?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgContactList', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/contact`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (myContacts !== undefined) {
                localVarQueryParameter['my_contacts'] = myContacts;
            }

            if (ordering) {
                localVarQueryParameter['ordering'] = ordering.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the newly updated details  param: private_groups This list of Private group id\'s can be passed through to be updated. This WILL update the users list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"private_groups\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships 
         * @summary Partially update the details of the Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {PatchedBuyerContactUpdate} [patchedBuyerContactUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactPartialUpdate: async (buyerOrgId: string, contactId: string, patchedBuyerContactUpdate?: PatchedBuyerContactUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgContactPartialUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('orgContactPartialUpdate', 'contactId', contactId)
            const localVarPath = `/v2/org/{buyer_org_id}/contact/{contact_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedBuyerContactUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of the found Contact.  The user details will not be populated if the contact status is not APPROVED
         * @summary Get the details of a single Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactRetrieve: async (buyerOrgId: string, contactId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgContactRetrieve', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('orgContactRetrieve', 'contactId', contactId)
            const localVarPath = `/v2/org/{buyer_org_id}/contact/{contact_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the newly updated details  param: private_groups This list of Private group id\'s can be passed through to be updated. This WILL update the users list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"private_groups\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships 
         * @summary Update the details of the Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {BuyerContactUpdate} [buyerContactUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactUpdate: async (buyerOrgId: string, contactId: string, buyerContactUpdate?: BuyerContactUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgContactUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('orgContactUpdate', 'contactId', contactId)
            const localVarPath = `/v2/org/{buyer_org_id}/contact/{contact_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerContactUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The data passed in through here would have been validated from the invite-search step so an error         shouldn\'t occur, however it can.  If a user_id is passed through then a 400 error on this field can occur for a few reasons. Contact already exists for that User. Contact is blocked.  At least one of the email or mobile fields must match that of the user_id, otherwise an error will occur on that field.  If a user_id is not passed through, then any errors of a Contact being found to exists or blocked will be returned on the email/mobile fields. 
         * @summary Create a new Contact
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {BuyerContactCreate} [buyerContactCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactCreate: async (buyerOrgId: string, privateGroupId: string, buyerContactCreate?: BuyerContactCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPrivateContactCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'privateGroupId' is not null or undefined
            assertParamExists('orgPrivateContactCreate', 'privateGroupId', privateGroupId)
            const localVarPath = `/v2/org/{buyer_org_id}/private/{private_group_id}/contact`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"private_group_id"}}`, encodeURIComponent(String(privateGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerContactCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This will send out a PUSH, Email, or SMS notification to the Contact inviting them to the Organisation.  If an invite has already been sent out, this cannot be sent out again.
         * @summary Send out an invite to the Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {string} privateGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactInviteCreate: async (buyerOrgId: string, contactId: string, privateGroupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPrivateContactInviteCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('orgPrivateContactInviteCreate', 'contactId', contactId)
            // verify required parameter 'privateGroupId' is not null or undefined
            assertParamExists('orgPrivateContactInviteCreate', 'privateGroupId', privateGroupId)
            const localVarPath = `/v2/org/{buyer_org_id}/private/{private_group_id}/contact/{contact_id}/invite`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)))
                .replace(`{${"private_group_id"}}`, encodeURIComponent(String(privateGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * At least one email or mobile is required to perform the search.  user_id will be populated if a User was found matching. contact_id will be populated if a matching Contact record is already found.  If no User or Contact record has been found, \'result\' with be None
         * @summary Search the system for new contacts via their mobile and/or email.
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {BuyerContactInviteSearch} [buyerContactInviteSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactInviteSearchCreate: async (buyerOrgId: string, privateGroupId: string, buyerContactInviteSearch?: BuyerContactInviteSearch, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPrivateContactInviteSearchCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'privateGroupId' is not null or undefined
            assertParamExists('orgPrivateContactInviteSearchCreate', 'privateGroupId', privateGroupId)
            const localVarPath = `/v2/org/{buyer_org_id}/private/{private_group_id}/contact/invite-search`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"private_group_id"}}`, encodeURIComponent(String(privateGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerContactInviteSearch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the newly updated details  param: private_groups This list of Private group id\'s can be passed through to be updated. This WILL update the users list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"private_groups\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships 
         * @summary Update the details of the Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {string} privateGroupId 
         * @param {BuyerContactUpdate} [buyerContactUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactUpdate: async (buyerOrgId: string, contactId: string, privateGroupId: string, buyerContactUpdate?: BuyerContactUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPrivateContactUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('orgPrivateContactUpdate', 'contactId', contactId)
            // verify required parameter 'privateGroupId' is not null or undefined
            assertParamExists('orgPrivateContactUpdate', 'privateGroupId', privateGroupId)
            const localVarPath = `/v2/org/{buyer_org_id}/private/{private_group_id}/contact/{contact_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)))
                .replace(`{${"private_group_id"}}`, encodeURIComponent(String(privateGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerContactUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationContactApi - functional programming interface
 * @export
 */
export const OrganisationContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationContactApiAxiosParamCreator(configuration)
    return {
        /**
         * The data passed in through here would have been validated from the invite-search step so an error         shouldn\'t occur, however it can.  If a user_id is passed through then a 400 error on this field can occur for a few reasons. Contact already exists for that User. Contact is blocked.  At least one of the email or mobile fields must match that of the user_id, otherwise an error will occur on that field.  If a user_id is not passed through, then any errors of a Contact being found to exists or blocked will be returned on the email/mobile fields. 
         * @summary Create a new Contact
         * @param {string} buyerOrgId 
         * @param {BuyerContactCreate} [buyerContactCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgContactCreate(buyerOrgId: string, buyerContactCreate?: BuyerContactCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgContactCreate(buyerOrgId, buyerContactCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a Contact from the organisation  If the status is currently blocked, then a field will mark it as deleted so it won\'t appear in the buyers listing anymore but it will still show for the sellers.
         * @summary Delete a Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgContactDestroy(buyerOrgId: string, contactId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgContactDestroy(buyerOrgId, contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This will send out a PUSH, Email, or SMS notification to the Contact inviting them to the Organisation.  If an invite has already been sent out, this cannot be sent out again.
         * @summary Send out an invite to the Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgContactInviteCreate(buyerOrgId: string, contactId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgContactInviteCreate(buyerOrgId, contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * At least one email or mobile is required to perform the search.  user_id will be populated if a User was found matching. contact_id will be populated if a matching Contact record is already found.  If no User or Contact record has been found, \'result\' with be None
         * @summary Search the system for new contacts via their mobile and/or email.
         * @param {string} buyerOrgId 
         * @param {BuyerContactInviteSearch} [buyerContactInviteSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgContactInviteSearchCreate(buyerOrgId: string, buyerContactInviteSearch?: BuyerContactInviteSearch, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContactInviteSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgContactInviteSearchCreate(buyerOrgId, buyerContactInviteSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the list of Contacts beneath the organisation.  Only the contacts which are APPROVED will return the user record  # Filtering Options  ## Fields - search str: case insensitive contains query. This will search on the user firstname, lastname, email, mobile and phone eg ?search=Joe - status str: limit results to contacts with this states: valid values APPROVED, PENDING, REJECTED, or BLOCKED which     hasn\'t been deleted by the buyer  eg. ?status=APPROVED or ?status=BLOCKED - my_contacts int: \"1\" will return only the contacts they created, \"0\" will return all other groups. eg ?my_contacts=1  ## Ordering - firstname: based on the firstname, eg ?ordering=firstname, can be reversed. eg ?ordering=-firstname - lastname: based on the lastname, eg ?ordering=lastname, can be reversed. eg ?ordering=-lastname - email: based on the email, eg ?ordering=email, can be reversed. eg ?ordering=-email - mobile: based on the mobile, eg ?ordering=mobile, can be reversed. eg ?ordering=-mobile - phone: based on the phone, eg ?ordering=phone, can be reversed ?ordering=-phone - created: based on the datetime the contact record was created, eg ?ordering=created, can be reversed     ?ordering=-created  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of Contacts within the Organisation
         * @param {string} buyerOrgId 
         * @param {string} [myContacts] 
         * @param {Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>} [ordering] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgContactList(buyerOrgId: string, myContacts?: string, ordering?: Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>, page?: number, pageSize?: number, search?: string, status?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedContactDetailedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgContactList(buyerOrgId, myContacts, ordering, page, pageSize, search, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the newly updated details  param: private_groups This list of Private group id\'s can be passed through to be updated. This WILL update the users list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"private_groups\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships 
         * @summary Partially update the details of the Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {PatchedBuyerContactUpdate} [patchedBuyerContactUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgContactPartialUpdate(buyerOrgId: string, contactId: string, patchedBuyerContactUpdate?: PatchedBuyerContactUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgContactPartialUpdate(buyerOrgId, contactId, patchedBuyerContactUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of the found Contact.  The user details will not be populated if the contact status is not APPROVED
         * @summary Get the details of a single Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgContactRetrieve(buyerOrgId: string, contactId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgContactRetrieve(buyerOrgId, contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the newly updated details  param: private_groups This list of Private group id\'s can be passed through to be updated. This WILL update the users list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"private_groups\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships 
         * @summary Update the details of the Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {BuyerContactUpdate} [buyerContactUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgContactUpdate(buyerOrgId: string, contactId: string, buyerContactUpdate?: BuyerContactUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgContactUpdate(buyerOrgId, contactId, buyerContactUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The data passed in through here would have been validated from the invite-search step so an error         shouldn\'t occur, however it can.  If a user_id is passed through then a 400 error on this field can occur for a few reasons. Contact already exists for that User. Contact is blocked.  At least one of the email or mobile fields must match that of the user_id, otherwise an error will occur on that field.  If a user_id is not passed through, then any errors of a Contact being found to exists or blocked will be returned on the email/mobile fields. 
         * @summary Create a new Contact
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {BuyerContactCreate} [buyerContactCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPrivateContactCreate(buyerOrgId: string, privateGroupId: string, buyerContactCreate?: BuyerContactCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPrivateContactCreate(buyerOrgId, privateGroupId, buyerContactCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This will send out a PUSH, Email, or SMS notification to the Contact inviting them to the Organisation.  If an invite has already been sent out, this cannot be sent out again.
         * @summary Send out an invite to the Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {string} privateGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPrivateContactInviteCreate(buyerOrgId: string, contactId: string, privateGroupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPrivateContactInviteCreate(buyerOrgId, contactId, privateGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * At least one email or mobile is required to perform the search.  user_id will be populated if a User was found matching. contact_id will be populated if a matching Contact record is already found.  If no User or Contact record has been found, \'result\' with be None
         * @summary Search the system for new contacts via their mobile and/or email.
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {BuyerContactInviteSearch} [buyerContactInviteSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPrivateContactInviteSearchCreate(buyerOrgId: string, privateGroupId: string, buyerContactInviteSearch?: BuyerContactInviteSearch, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContactAddSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPrivateContactInviteSearchCreate(buyerOrgId, privateGroupId, buyerContactInviteSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the newly updated details  param: private_groups This list of Private group id\'s can be passed through to be updated. This WILL update the users list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"private_groups\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships 
         * @summary Update the details of the Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {string} privateGroupId 
         * @param {BuyerContactUpdate} [buyerContactUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPrivateContactUpdate(buyerOrgId: string, contactId: string, privateGroupId: string, buyerContactUpdate?: BuyerContactUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPrivateContactUpdate(buyerOrgId, contactId, privateGroupId, buyerContactUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationContactApi - factory interface
 * @export
 */
export const OrganisationContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationContactApiFp(configuration)
    return {
        /**
         * The data passed in through here would have been validated from the invite-search step so an error         shouldn\'t occur, however it can.  If a user_id is passed through then a 400 error on this field can occur for a few reasons. Contact already exists for that User. Contact is blocked.  At least one of the email or mobile fields must match that of the user_id, otherwise an error will occur on that field.  If a user_id is not passed through, then any errors of a Contact being found to exists or blocked will be returned on the email/mobile fields. 
         * @summary Create a new Contact
         * @param {string} buyerOrgId 
         * @param {BuyerContactCreate} [buyerContactCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactCreate(buyerOrgId: string, buyerContactCreate?: BuyerContactCreate, options?: any): AxiosPromise<ContactDetailed> {
            return localVarFp.orgContactCreate(buyerOrgId, buyerContactCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a Contact from the organisation  If the status is currently blocked, then a field will mark it as deleted so it won\'t appear in the buyers listing anymore but it will still show for the sellers.
         * @summary Delete a Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactDestroy(buyerOrgId: string, contactId: string, options?: any): AxiosPromise<void> {
            return localVarFp.orgContactDestroy(buyerOrgId, contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * This will send out a PUSH, Email, or SMS notification to the Contact inviting them to the Organisation.  If an invite has already been sent out, this cannot be sent out again.
         * @summary Send out an invite to the Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactInviteCreate(buyerOrgId: string, contactId: string, options?: any): AxiosPromise<void> {
            return localVarFp.orgContactInviteCreate(buyerOrgId, contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * At least one email or mobile is required to perform the search.  user_id will be populated if a User was found matching. contact_id will be populated if a matching Contact record is already found.  If no User or Contact record has been found, \'result\' with be None
         * @summary Search the system for new contacts via their mobile and/or email.
         * @param {string} buyerOrgId 
         * @param {BuyerContactInviteSearch} [buyerContactInviteSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactInviteSearchCreate(buyerOrgId: string, buyerContactInviteSearch?: BuyerContactInviteSearch, options?: any): AxiosPromise<BuyerContactInviteSearchResult> {
            return localVarFp.orgContactInviteSearchCreate(buyerOrgId, buyerContactInviteSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the list of Contacts beneath the organisation.  Only the contacts which are APPROVED will return the user record  # Filtering Options  ## Fields - search str: case insensitive contains query. This will search on the user firstname, lastname, email, mobile and phone eg ?search=Joe - status str: limit results to contacts with this states: valid values APPROVED, PENDING, REJECTED, or BLOCKED which     hasn\'t been deleted by the buyer  eg. ?status=APPROVED or ?status=BLOCKED - my_contacts int: \"1\" will return only the contacts they created, \"0\" will return all other groups. eg ?my_contacts=1  ## Ordering - firstname: based on the firstname, eg ?ordering=firstname, can be reversed. eg ?ordering=-firstname - lastname: based on the lastname, eg ?ordering=lastname, can be reversed. eg ?ordering=-lastname - email: based on the email, eg ?ordering=email, can be reversed. eg ?ordering=-email - mobile: based on the mobile, eg ?ordering=mobile, can be reversed. eg ?ordering=-mobile - phone: based on the phone, eg ?ordering=phone, can be reversed ?ordering=-phone - created: based on the datetime the contact record was created, eg ?ordering=created, can be reversed     ?ordering=-created  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of Contacts within the Organisation
         * @param {string} buyerOrgId 
         * @param {string} [myContacts] 
         * @param {Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>} [ordering] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactList(buyerOrgId: string, myContacts?: string, ordering?: Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>, page?: number, pageSize?: number, search?: string, status?: string, options?: any): AxiosPromise<PaginatedContactDetailedList> {
            return localVarFp.orgContactList(buyerOrgId, myContacts, ordering, page, pageSize, search, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the newly updated details  param: private_groups This list of Private group id\'s can be passed through to be updated. This WILL update the users list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"private_groups\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships 
         * @summary Partially update the details of the Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {PatchedBuyerContactUpdate} [patchedBuyerContactUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactPartialUpdate(buyerOrgId: string, contactId: string, patchedBuyerContactUpdate?: PatchedBuyerContactUpdate, options?: any): AxiosPromise<ContactDetailed> {
            return localVarFp.orgContactPartialUpdate(buyerOrgId, contactId, patchedBuyerContactUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of the found Contact.  The user details will not be populated if the contact status is not APPROVED
         * @summary Get the details of a single Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactRetrieve(buyerOrgId: string, contactId: string, options?: any): AxiosPromise<ContactDetailed> {
            return localVarFp.orgContactRetrieve(buyerOrgId, contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the newly updated details  param: private_groups This list of Private group id\'s can be passed through to be updated. This WILL update the users list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"private_groups\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships 
         * @summary Update the details of the Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {BuyerContactUpdate} [buyerContactUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgContactUpdate(buyerOrgId: string, contactId: string, buyerContactUpdate?: BuyerContactUpdate, options?: any): AxiosPromise<ContactDetailed> {
            return localVarFp.orgContactUpdate(buyerOrgId, contactId, buyerContactUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * The data passed in through here would have been validated from the invite-search step so an error         shouldn\'t occur, however it can.  If a user_id is passed through then a 400 error on this field can occur for a few reasons. Contact already exists for that User. Contact is blocked.  At least one of the email or mobile fields must match that of the user_id, otherwise an error will occur on that field.  If a user_id is not passed through, then any errors of a Contact being found to exists or blocked will be returned on the email/mobile fields. 
         * @summary Create a new Contact
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {BuyerContactCreate} [buyerContactCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactCreate(buyerOrgId: string, privateGroupId: string, buyerContactCreate?: BuyerContactCreate, options?: any): AxiosPromise<ContactDetailed> {
            return localVarFp.orgPrivateContactCreate(buyerOrgId, privateGroupId, buyerContactCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * This will send out a PUSH, Email, or SMS notification to the Contact inviting them to the Organisation.  If an invite has already been sent out, this cannot be sent out again.
         * @summary Send out an invite to the Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {string} privateGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactInviteCreate(buyerOrgId: string, contactId: string, privateGroupId: string, options?: any): AxiosPromise<void> {
            return localVarFp.orgPrivateContactInviteCreate(buyerOrgId, contactId, privateGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * At least one email or mobile is required to perform the search.  user_id will be populated if a User was found matching. contact_id will be populated if a matching Contact record is already found.  If no User or Contact record has been found, \'result\' with be None
         * @summary Search the system for new contacts via their mobile and/or email.
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {BuyerContactInviteSearch} [buyerContactInviteSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactInviteSearchCreate(buyerOrgId: string, privateGroupId: string, buyerContactInviteSearch?: BuyerContactInviteSearch, options?: any): AxiosPromise<BuyerContactAddSearchResult> {
            return localVarFp.orgPrivateContactInviteSearchCreate(buyerOrgId, privateGroupId, buyerContactInviteSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the newly updated details  param: private_groups This list of Private group id\'s can be passed through to be updated. This WILL update the users list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"private_groups\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships 
         * @summary Update the details of the Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {string} privateGroupId 
         * @param {BuyerContactUpdate} [buyerContactUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactUpdate(buyerOrgId: string, contactId: string, privateGroupId: string, buyerContactUpdate?: BuyerContactUpdate, options?: any): AxiosPromise<ContactDetailed> {
            return localVarFp.orgPrivateContactUpdate(buyerOrgId, contactId, privateGroupId, buyerContactUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgContactCreate operation in OrganisationContactApi.
 * @export
 * @interface OrganisationContactApiOrgContactCreateRequest
 */
export interface OrganisationContactApiOrgContactCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {BuyerContactCreate}
     * @memberof OrganisationContactApiOrgContactCreate
     */
    readonly buyerContactCreate?: BuyerContactCreate
}

/**
 * Request parameters for orgContactDestroy operation in OrganisationContactApi.
 * @export
 * @interface OrganisationContactApiOrgContactDestroyRequest
 */
export interface OrganisationContactApiOrgContactDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactDestroy
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactDestroy
     */
    readonly contactId: string
}

/**
 * Request parameters for orgContactInviteCreate operation in OrganisationContactApi.
 * @export
 * @interface OrganisationContactApiOrgContactInviteCreateRequest
 */
export interface OrganisationContactApiOrgContactInviteCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactInviteCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactInviteCreate
     */
    readonly contactId: string
}

/**
 * Request parameters for orgContactInviteSearchCreate operation in OrganisationContactApi.
 * @export
 * @interface OrganisationContactApiOrgContactInviteSearchCreateRequest
 */
export interface OrganisationContactApiOrgContactInviteSearchCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactInviteSearchCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {BuyerContactInviteSearch}
     * @memberof OrganisationContactApiOrgContactInviteSearchCreate
     */
    readonly buyerContactInviteSearch?: BuyerContactInviteSearch
}

/**
 * Request parameters for orgContactList operation in OrganisationContactApi.
 * @export
 * @interface OrganisationContactApiOrgContactListRequest
 */
export interface OrganisationContactApiOrgContactListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactList
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactList
     */
    readonly myContacts?: string

    /**
     * Ordering
     * @type {Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>}
     * @memberof OrganisationContactApiOrgContactList
     */
    readonly ordering?: Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof OrganisationContactApiOrgContactList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrganisationContactApiOrgContactList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactList
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactList
     */
    readonly status?: string
}

/**
 * Request parameters for orgContactPartialUpdate operation in OrganisationContactApi.
 * @export
 * @interface OrganisationContactApiOrgContactPartialUpdateRequest
 */
export interface OrganisationContactApiOrgContactPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactPartialUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactPartialUpdate
     */
    readonly contactId: string

    /**
     * 
     * @type {PatchedBuyerContactUpdate}
     * @memberof OrganisationContactApiOrgContactPartialUpdate
     */
    readonly patchedBuyerContactUpdate?: PatchedBuyerContactUpdate
}

/**
 * Request parameters for orgContactRetrieve operation in OrganisationContactApi.
 * @export
 * @interface OrganisationContactApiOrgContactRetrieveRequest
 */
export interface OrganisationContactApiOrgContactRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactRetrieve
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactRetrieve
     */
    readonly contactId: string
}

/**
 * Request parameters for orgContactUpdate operation in OrganisationContactApi.
 * @export
 * @interface OrganisationContactApiOrgContactUpdateRequest
 */
export interface OrganisationContactApiOrgContactUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgContactUpdate
     */
    readonly contactId: string

    /**
     * 
     * @type {BuyerContactUpdate}
     * @memberof OrganisationContactApiOrgContactUpdate
     */
    readonly buyerContactUpdate?: BuyerContactUpdate
}

/**
 * Request parameters for orgPrivateContactCreate operation in OrganisationContactApi.
 * @export
 * @interface OrganisationContactApiOrgPrivateContactCreateRequest
 */
export interface OrganisationContactApiOrgPrivateContactCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgPrivateContactCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgPrivateContactCreate
     */
    readonly privateGroupId: string

    /**
     * 
     * @type {BuyerContactCreate}
     * @memberof OrganisationContactApiOrgPrivateContactCreate
     */
    readonly buyerContactCreate?: BuyerContactCreate
}

/**
 * Request parameters for orgPrivateContactInviteCreate operation in OrganisationContactApi.
 * @export
 * @interface OrganisationContactApiOrgPrivateContactInviteCreateRequest
 */
export interface OrganisationContactApiOrgPrivateContactInviteCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgPrivateContactInviteCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgPrivateContactInviteCreate
     */
    readonly contactId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgPrivateContactInviteCreate
     */
    readonly privateGroupId: string
}

/**
 * Request parameters for orgPrivateContactInviteSearchCreate operation in OrganisationContactApi.
 * @export
 * @interface OrganisationContactApiOrgPrivateContactInviteSearchCreateRequest
 */
export interface OrganisationContactApiOrgPrivateContactInviteSearchCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgPrivateContactInviteSearchCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgPrivateContactInviteSearchCreate
     */
    readonly privateGroupId: string

    /**
     * 
     * @type {BuyerContactInviteSearch}
     * @memberof OrganisationContactApiOrgPrivateContactInviteSearchCreate
     */
    readonly buyerContactInviteSearch?: BuyerContactInviteSearch
}

/**
 * Request parameters for orgPrivateContactUpdate operation in OrganisationContactApi.
 * @export
 * @interface OrganisationContactApiOrgPrivateContactUpdateRequest
 */
export interface OrganisationContactApiOrgPrivateContactUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgPrivateContactUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgPrivateContactUpdate
     */
    readonly contactId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationContactApiOrgPrivateContactUpdate
     */
    readonly privateGroupId: string

    /**
     * 
     * @type {BuyerContactUpdate}
     * @memberof OrganisationContactApiOrgPrivateContactUpdate
     */
    readonly buyerContactUpdate?: BuyerContactUpdate
}

/**
 * OrganisationContactApi - object-oriented interface
 * @export
 * @class OrganisationContactApi
 * @extends {BaseAPI}
 */
export class OrganisationContactApi extends BaseAPI {
    /**
     * The data passed in through here would have been validated from the invite-search step so an error         shouldn\'t occur, however it can.  If a user_id is passed through then a 400 error on this field can occur for a few reasons. Contact already exists for that User. Contact is blocked.  At least one of the email or mobile fields must match that of the user_id, otherwise an error will occur on that field.  If a user_id is not passed through, then any errors of a Contact being found to exists or blocked will be returned on the email/mobile fields. 
     * @summary Create a new Contact
     * @param {OrganisationContactApiOrgContactCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationContactApi
     */
    public orgContactCreate(requestParameters: OrganisationContactApiOrgContactCreateRequest, options?: any) {
        return OrganisationContactApiFp(this.configuration).orgContactCreate(requestParameters.buyerOrgId, requestParameters.buyerContactCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a Contact from the organisation  If the status is currently blocked, then a field will mark it as deleted so it won\'t appear in the buyers listing anymore but it will still show for the sellers.
     * @summary Delete a Contact
     * @param {OrganisationContactApiOrgContactDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationContactApi
     */
    public orgContactDestroy(requestParameters: OrganisationContactApiOrgContactDestroyRequest, options?: any) {
        return OrganisationContactApiFp(this.configuration).orgContactDestroy(requestParameters.buyerOrgId, requestParameters.contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This will send out a PUSH, Email, or SMS notification to the Contact inviting them to the Organisation.  If an invite has already been sent out, this cannot be sent out again.
     * @summary Send out an invite to the Contact
     * @param {OrganisationContactApiOrgContactInviteCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationContactApi
     */
    public orgContactInviteCreate(requestParameters: OrganisationContactApiOrgContactInviteCreateRequest, options?: any) {
        return OrganisationContactApiFp(this.configuration).orgContactInviteCreate(requestParameters.buyerOrgId, requestParameters.contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * At least one email or mobile is required to perform the search.  user_id will be populated if a User was found matching. contact_id will be populated if a matching Contact record is already found.  If no User or Contact record has been found, \'result\' with be None
     * @summary Search the system for new contacts via their mobile and/or email.
     * @param {OrganisationContactApiOrgContactInviteSearchCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationContactApi
     */
    public orgContactInviteSearchCreate(requestParameters: OrganisationContactApiOrgContactInviteSearchCreateRequest, options?: any) {
        return OrganisationContactApiFp(this.configuration).orgContactInviteSearchCreate(requestParameters.buyerOrgId, requestParameters.buyerContactInviteSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the list of Contacts beneath the organisation.  Only the contacts which are APPROVED will return the user record  # Filtering Options  ## Fields - search str: case insensitive contains query. This will search on the user firstname, lastname, email, mobile and phone eg ?search=Joe - status str: limit results to contacts with this states: valid values APPROVED, PENDING, REJECTED, or BLOCKED which     hasn\'t been deleted by the buyer  eg. ?status=APPROVED or ?status=BLOCKED - my_contacts int: \"1\" will return only the contacts they created, \"0\" will return all other groups. eg ?my_contacts=1  ## Ordering - firstname: based on the firstname, eg ?ordering=firstname, can be reversed. eg ?ordering=-firstname - lastname: based on the lastname, eg ?ordering=lastname, can be reversed. eg ?ordering=-lastname - email: based on the email, eg ?ordering=email, can be reversed. eg ?ordering=-email - mobile: based on the mobile, eg ?ordering=mobile, can be reversed. eg ?ordering=-mobile - phone: based on the phone, eg ?ordering=phone, can be reversed ?ordering=-phone - created: based on the datetime the contact record was created, eg ?ordering=created, can be reversed     ?ordering=-created  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
     * @summary Get a list of Contacts within the Organisation
     * @param {OrganisationContactApiOrgContactListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationContactApi
     */
    public orgContactList(requestParameters: OrganisationContactApiOrgContactListRequest, options?: any) {
        return OrganisationContactApiFp(this.configuration).orgContactList(requestParameters.buyerOrgId, requestParameters.myContacts, requestParameters.ordering, requestParameters.page, requestParameters.pageSize, requestParameters.search, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the newly updated details  param: private_groups This list of Private group id\'s can be passed through to be updated. This WILL update the users list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"private_groups\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships 
     * @summary Partially update the details of the Contact
     * @param {OrganisationContactApiOrgContactPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationContactApi
     */
    public orgContactPartialUpdate(requestParameters: OrganisationContactApiOrgContactPartialUpdateRequest, options?: any) {
        return OrganisationContactApiFp(this.configuration).orgContactPartialUpdate(requestParameters.buyerOrgId, requestParameters.contactId, requestParameters.patchedBuyerContactUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of the found Contact.  The user details will not be populated if the contact status is not APPROVED
     * @summary Get the details of a single Contact
     * @param {OrganisationContactApiOrgContactRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationContactApi
     */
    public orgContactRetrieve(requestParameters: OrganisationContactApiOrgContactRetrieveRequest, options?: any) {
        return OrganisationContactApiFp(this.configuration).orgContactRetrieve(requestParameters.buyerOrgId, requestParameters.contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the newly updated details  param: private_groups This list of Private group id\'s can be passed through to be updated. This WILL update the users list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"private_groups\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships 
     * @summary Update the details of the Contact
     * @param {OrganisationContactApiOrgContactUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationContactApi
     */
    public orgContactUpdate(requestParameters: OrganisationContactApiOrgContactUpdateRequest, options?: any) {
        return OrganisationContactApiFp(this.configuration).orgContactUpdate(requestParameters.buyerOrgId, requestParameters.contactId, requestParameters.buyerContactUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The data passed in through here would have been validated from the invite-search step so an error         shouldn\'t occur, however it can.  If a user_id is passed through then a 400 error on this field can occur for a few reasons. Contact already exists for that User. Contact is blocked.  At least one of the email or mobile fields must match that of the user_id, otherwise an error will occur on that field.  If a user_id is not passed through, then any errors of a Contact being found to exists or blocked will be returned on the email/mobile fields. 
     * @summary Create a new Contact
     * @param {OrganisationContactApiOrgPrivateContactCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationContactApi
     */
    public orgPrivateContactCreate(requestParameters: OrganisationContactApiOrgPrivateContactCreateRequest, options?: any) {
        return OrganisationContactApiFp(this.configuration).orgPrivateContactCreate(requestParameters.buyerOrgId, requestParameters.privateGroupId, requestParameters.buyerContactCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This will send out a PUSH, Email, or SMS notification to the Contact inviting them to the Organisation.  If an invite has already been sent out, this cannot be sent out again.
     * @summary Send out an invite to the Contact
     * @param {OrganisationContactApiOrgPrivateContactInviteCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationContactApi
     */
    public orgPrivateContactInviteCreate(requestParameters: OrganisationContactApiOrgPrivateContactInviteCreateRequest, options?: any) {
        return OrganisationContactApiFp(this.configuration).orgPrivateContactInviteCreate(requestParameters.buyerOrgId, requestParameters.contactId, requestParameters.privateGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * At least one email or mobile is required to perform the search.  user_id will be populated if a User was found matching. contact_id will be populated if a matching Contact record is already found.  If no User or Contact record has been found, \'result\' with be None
     * @summary Search the system for new contacts via their mobile and/or email.
     * @param {OrganisationContactApiOrgPrivateContactInviteSearchCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationContactApi
     */
    public orgPrivateContactInviteSearchCreate(requestParameters: OrganisationContactApiOrgPrivateContactInviteSearchCreateRequest, options?: any) {
        return OrganisationContactApiFp(this.configuration).orgPrivateContactInviteSearchCreate(requestParameters.buyerOrgId, requestParameters.privateGroupId, requestParameters.buyerContactInviteSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the newly updated details  param: private_groups This list of Private group id\'s can be passed through to be updated. This WILL update the users list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"private_groups\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships 
     * @summary Update the details of the Contact
     * @param {OrganisationContactApiOrgPrivateContactUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationContactApi
     */
    public orgPrivateContactUpdate(requestParameters: OrganisationContactApiOrgPrivateContactUpdateRequest, options?: any) {
        return OrganisationContactApiFp(this.configuration).orgPrivateContactUpdate(requestParameters.buyerOrgId, requestParameters.contactId, requestParameters.privateGroupId, requestParameters.buyerContactUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
