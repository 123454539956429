import { useStore } from 'lib/store';
import { FC, useRef } from 'react';
import { createPortal } from 'react-dom';
import { HEADER_OVERRIDE_PORTAL_ID } from 'settings/config';

export const HeaderContentOverride: FC = ({ children }) => {
  const overrideHeaderContent = useStore(
    (store) => store?.overrideHeaderContent
  );
  const el = useRef<HTMLElement | null>(null);

  if (overrideHeaderContent) {
    el.current = document.getElementById(HEADER_OVERRIDE_PORTAL_ID);
    if (el.current) {
      return createPortal(children, el.current);
    }
  }

  return null;
};
