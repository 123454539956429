import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';
import { Box } from '../box';

export const ContentSection = styled(Box)`
  /* Padding */
  --content-section-padding: ${globalTheme.space[2]};

  padding: var(--content-section-padding) 0;
  border-top: 1px solid ${globalTheme.elements.contentSection.borderTopColor};
  border-bottom: 1px solid
    ${globalTheme.elements.contentSection.borderBottomColor};

  & + & {
    border-bottom: 0;
  }

  * > &:first-child {
    padding-top: 0;
    border-top: 0;
    border-bottom: 0;
  }

  * > &:last-child {
    padding-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.large}) {
    --content-section-padding: ${globalTheme.space[4]};
  }
`;
