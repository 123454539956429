/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { BuyerOrgConfig } from '../model';
// @ts-ignore
import { Org } from '../model';
// @ts-ignore
import { OrgCreate } from '../model';
// @ts-ignore
import { OrgCreateResponse } from '../model';
// @ts-ignore
import { OrgDetail } from '../model';
// @ts-ignore
import { OrgLogo } from '../model';
// @ts-ignore
import { OrgLogoPublicId } from '../model';
// @ts-ignore
import { OrgUpdate } from '../model';
// @ts-ignore
import { PaginatedOrgList } from '../model';
// @ts-ignore
import { PatchedOrgUpdate } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * OrganisationApi - axios parameter creator
 * @export
 */
export const OrganisationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The error code SUBSCRIPTION_UPGRADE_REQUIRED_USERS can be raised if the organisation         has hit their user limit
         * @summary Accept the Organisation invite
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgAcceptInviteCreate: async (buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgAcceptInviteCreate', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/accept-invite`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Outputs Buyer Org specific configuration.
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgConfigRetrieve: async (buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgConfigRetrieve', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/config`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the created organisation and details to complete the subscription.
         * @summary Create a new organisation
         * @param {OrgCreate} orgCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgCreate: async (orgCreate: OrgCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgCreate' is not null or undefined
            assertParamExists('orgCreate', 'orgCreate', orgCreate)
            const localVarPath = `/v2/org`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orgCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an Organisation, this is restricted to admins of the group only.
         * @summary Delete an Organisation
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgDestroy: async (buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgDestroy', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the list of organisations ordered by the current user status in the group.  This only includes approved and rejected Organisation invites.  Ordering: - APPROVED -> PENDING - Buyer Org Name asc 
         * @summary Get a list of Organisations the user has access to
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgList: async (page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/org`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or Update the Organisation\'s logo.
         * @param {string} buyerOrgId 
         * @param {OrgLogo} orgLogo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgLogoCreate: async (buyerOrgId: string, orgLogo: OrgLogo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgLogoCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'orgLogo' is not null or undefined
            assertParamExists('orgLogoCreate', 'orgLogo', orgLogo)
            const localVarPath = `/v2/org/{buyer_org_id}/logo`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orgLogo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the Organisation\'s logo.
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgLogoDestroy: async (buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgLogoDestroy', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/logo`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the newly updated details of the Organisation.
         * @summary Update the details of a single Organisation
         * @param {string} buyerOrgId 
         * @param {PatchedOrgUpdate} [patchedOrgUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPartialUpdate: async (buyerOrgId: string, patchedOrgUpdate?: PatchedOrgUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPartialUpdate', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedOrgUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reject the Organisation invite
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgRejectInviteCreate: async (buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgRejectInviteCreate', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/reject-invite`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of the found organisation.
         * @summary Get the details of a single Organisation
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgRetrieve: async (buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgRetrieve', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the newly updated details of the Organisation.
         * @summary Update the details of a single Organisation
         * @param {string} buyerOrgId 
         * @param {OrgUpdate} orgUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUpdate: async (buyerOrgId: string, orgUpdate: OrgUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'orgUpdate' is not null or undefined
            assertParamExists('orgUpdate', 'orgUpdate', orgUpdate)
            const localVarPath = `/v2/org/{buyer_org_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orgUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationApi - functional programming interface
 * @export
 */
export const OrganisationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationApiAxiosParamCreator(configuration)
    return {
        /**
         * The error code SUBSCRIPTION_UPGRADE_REQUIRED_USERS can be raised if the organisation         has hit their user limit
         * @summary Accept the Organisation invite
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgAcceptInviteCreate(buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgAcceptInviteCreate(buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Outputs Buyer Org specific configuration.
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgConfigRetrieve(buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerOrgConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgConfigRetrieve(buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the created organisation and details to complete the subscription.
         * @summary Create a new organisation
         * @param {OrgCreate} orgCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgCreate(orgCreate: OrgCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgCreate(orgCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an Organisation, this is restricted to admins of the group only.
         * @summary Delete an Organisation
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgDestroy(buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgDestroy(buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the list of organisations ordered by the current user status in the group.  This only includes approved and rejected Organisation invites.  Ordering: - APPROVED -> PENDING - Buyer Org Name asc 
         * @summary Get a list of Organisations the user has access to
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgList(page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedOrgList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgList(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create or Update the Organisation\'s logo.
         * @param {string} buyerOrgId 
         * @param {OrgLogo} orgLogo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgLogoCreate(buyerOrgId: string, orgLogo: OrgLogo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgLogoPublicId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgLogoCreate(buyerOrgId, orgLogo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the Organisation\'s logo.
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgLogoDestroy(buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgLogoDestroy(buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the newly updated details of the Organisation.
         * @summary Update the details of a single Organisation
         * @param {string} buyerOrgId 
         * @param {PatchedOrgUpdate} [patchedOrgUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPartialUpdate(buyerOrgId: string, patchedOrgUpdate?: PatchedOrgUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Org>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPartialUpdate(buyerOrgId, patchedOrgUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reject the Organisation invite
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgRejectInviteCreate(buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgRejectInviteCreate(buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of the found organisation.
         * @summary Get the details of a single Organisation
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgRetrieve(buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgRetrieve(buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the newly updated details of the Organisation.
         * @summary Update the details of a single Organisation
         * @param {string} buyerOrgId 
         * @param {OrgUpdate} orgUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgUpdate(buyerOrgId: string, orgUpdate: OrgUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Org>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgUpdate(buyerOrgId, orgUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationApi - factory interface
 * @export
 */
export const OrganisationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationApiFp(configuration)
    return {
        /**
         * The error code SUBSCRIPTION_UPGRADE_REQUIRED_USERS can be raised if the organisation         has hit their user limit
         * @summary Accept the Organisation invite
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgAcceptInviteCreate(buyerOrgId: string, options?: any): AxiosPromise<void> {
            return localVarFp.orgAcceptInviteCreate(buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Outputs Buyer Org specific configuration.
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgConfigRetrieve(buyerOrgId: string, options?: any): AxiosPromise<BuyerOrgConfig> {
            return localVarFp.orgConfigRetrieve(buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the created organisation and details to complete the subscription.
         * @summary Create a new organisation
         * @param {OrgCreate} orgCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgCreate(orgCreate: OrgCreate, options?: any): AxiosPromise<OrgCreateResponse> {
            return localVarFp.orgCreate(orgCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an Organisation, this is restricted to admins of the group only.
         * @summary Delete an Organisation
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgDestroy(buyerOrgId: string, options?: any): AxiosPromise<void> {
            return localVarFp.orgDestroy(buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the list of organisations ordered by the current user status in the group.  This only includes approved and rejected Organisation invites.  Ordering: - APPROVED -> PENDING - Buyer Org Name asc 
         * @summary Get a list of Organisations the user has access to
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgList(page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedOrgList> {
            return localVarFp.orgList(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or Update the Organisation\'s logo.
         * @param {string} buyerOrgId 
         * @param {OrgLogo} orgLogo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgLogoCreate(buyerOrgId: string, orgLogo: OrgLogo, options?: any): AxiosPromise<OrgLogoPublicId> {
            return localVarFp.orgLogoCreate(buyerOrgId, orgLogo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the Organisation\'s logo.
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgLogoDestroy(buyerOrgId: string, options?: any): AxiosPromise<void> {
            return localVarFp.orgLogoDestroy(buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the newly updated details of the Organisation.
         * @summary Update the details of a single Organisation
         * @param {string} buyerOrgId 
         * @param {PatchedOrgUpdate} [patchedOrgUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPartialUpdate(buyerOrgId: string, patchedOrgUpdate?: PatchedOrgUpdate, options?: any): AxiosPromise<Org> {
            return localVarFp.orgPartialUpdate(buyerOrgId, patchedOrgUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reject the Organisation invite
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgRejectInviteCreate(buyerOrgId: string, options?: any): AxiosPromise<void> {
            return localVarFp.orgRejectInviteCreate(buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of the found organisation.
         * @summary Get the details of a single Organisation
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgRetrieve(buyerOrgId: string, options?: any): AxiosPromise<OrgDetail> {
            return localVarFp.orgRetrieve(buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the newly updated details of the Organisation.
         * @summary Update the details of a single Organisation
         * @param {string} buyerOrgId 
         * @param {OrgUpdate} orgUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUpdate(buyerOrgId: string, orgUpdate: OrgUpdate, options?: any): AxiosPromise<Org> {
            return localVarFp.orgUpdate(buyerOrgId, orgUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgAcceptInviteCreate operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrgAcceptInviteCreateRequest
 */
export interface OrganisationApiOrgAcceptInviteCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationApiOrgAcceptInviteCreate
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgConfigRetrieve operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrgConfigRetrieveRequest
 */
export interface OrganisationApiOrgConfigRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationApiOrgConfigRetrieve
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgCreate operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrgCreateRequest
 */
export interface OrganisationApiOrgCreateRequest {
    /**
     * 
     * @type {OrgCreate}
     * @memberof OrganisationApiOrgCreate
     */
    readonly orgCreate: OrgCreate
}

/**
 * Request parameters for orgDestroy operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrgDestroyRequest
 */
export interface OrganisationApiOrgDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationApiOrgDestroy
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgList operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrgListRequest
 */
export interface OrganisationApiOrgListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof OrganisationApiOrgList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrganisationApiOrgList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for orgLogoCreate operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrgLogoCreateRequest
 */
export interface OrganisationApiOrgLogoCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationApiOrgLogoCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {OrgLogo}
     * @memberof OrganisationApiOrgLogoCreate
     */
    readonly orgLogo: OrgLogo
}

/**
 * Request parameters for orgLogoDestroy operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrgLogoDestroyRequest
 */
export interface OrganisationApiOrgLogoDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationApiOrgLogoDestroy
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgPartialUpdate operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrgPartialUpdateRequest
 */
export interface OrganisationApiOrgPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationApiOrgPartialUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {PatchedOrgUpdate}
     * @memberof OrganisationApiOrgPartialUpdate
     */
    readonly patchedOrgUpdate?: PatchedOrgUpdate
}

/**
 * Request parameters for orgRejectInviteCreate operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrgRejectInviteCreateRequest
 */
export interface OrganisationApiOrgRejectInviteCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationApiOrgRejectInviteCreate
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgRetrieve operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrgRetrieveRequest
 */
export interface OrganisationApiOrgRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationApiOrgRetrieve
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgUpdate operation in OrganisationApi.
 * @export
 * @interface OrganisationApiOrgUpdateRequest
 */
export interface OrganisationApiOrgUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationApiOrgUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {OrgUpdate}
     * @memberof OrganisationApiOrgUpdate
     */
    readonly orgUpdate: OrgUpdate
}

/**
 * OrganisationApi - object-oriented interface
 * @export
 * @class OrganisationApi
 * @extends {BaseAPI}
 */
export class OrganisationApi extends BaseAPI {
    /**
     * The error code SUBSCRIPTION_UPGRADE_REQUIRED_USERS can be raised if the organisation         has hit their user limit
     * @summary Accept the Organisation invite
     * @param {OrganisationApiOrgAcceptInviteCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public orgAcceptInviteCreate(requestParameters: OrganisationApiOrgAcceptInviteCreateRequest, options?: any) {
        return OrganisationApiFp(this.configuration).orgAcceptInviteCreate(requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Outputs Buyer Org specific configuration.
     * @param {OrganisationApiOrgConfigRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public orgConfigRetrieve(requestParameters: OrganisationApiOrgConfigRetrieveRequest, options?: any) {
        return OrganisationApiFp(this.configuration).orgConfigRetrieve(requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the created organisation and details to complete the subscription.
     * @summary Create a new organisation
     * @param {OrganisationApiOrgCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public orgCreate(requestParameters: OrganisationApiOrgCreateRequest, options?: any) {
        return OrganisationApiFp(this.configuration).orgCreate(requestParameters.orgCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an Organisation, this is restricted to admins of the group only.
     * @summary Delete an Organisation
     * @param {OrganisationApiOrgDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public orgDestroy(requestParameters: OrganisationApiOrgDestroyRequest, options?: any) {
        return OrganisationApiFp(this.configuration).orgDestroy(requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the list of organisations ordered by the current user status in the group.  This only includes approved and rejected Organisation invites.  Ordering: - APPROVED -> PENDING - Buyer Org Name asc 
     * @summary Get a list of Organisations the user has access to
     * @param {OrganisationApiOrgListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public orgList(requestParameters: OrganisationApiOrgListRequest = {}, options?: any) {
        return OrganisationApiFp(this.configuration).orgList(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create or Update the Organisation\'s logo.
     * @param {OrganisationApiOrgLogoCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public orgLogoCreate(requestParameters: OrganisationApiOrgLogoCreateRequest, options?: any) {
        return OrganisationApiFp(this.configuration).orgLogoCreate(requestParameters.buyerOrgId, requestParameters.orgLogo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the Organisation\'s logo.
     * @param {OrganisationApiOrgLogoDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public orgLogoDestroy(requestParameters: OrganisationApiOrgLogoDestroyRequest, options?: any) {
        return OrganisationApiFp(this.configuration).orgLogoDestroy(requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the newly updated details of the Organisation.
     * @summary Update the details of a single Organisation
     * @param {OrganisationApiOrgPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public orgPartialUpdate(requestParameters: OrganisationApiOrgPartialUpdateRequest, options?: any) {
        return OrganisationApiFp(this.configuration).orgPartialUpdate(requestParameters.buyerOrgId, requestParameters.patchedOrgUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reject the Organisation invite
     * @param {OrganisationApiOrgRejectInviteCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public orgRejectInviteCreate(requestParameters: OrganisationApiOrgRejectInviteCreateRequest, options?: any) {
        return OrganisationApiFp(this.configuration).orgRejectInviteCreate(requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of the found organisation.
     * @summary Get the details of a single Organisation
     * @param {OrganisationApiOrgRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public orgRetrieve(requestParameters: OrganisationApiOrgRetrieveRequest, options?: any) {
        return OrganisationApiFp(this.configuration).orgRetrieve(requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the newly updated details of the Organisation.
     * @summary Update the details of a single Organisation
     * @param {OrganisationApiOrgUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public orgUpdate(requestParameters: OrganisationApiOrgUpdateRequest, options?: any) {
        return OrganisationApiFp(this.configuration).orgUpdate(requestParameters.buyerOrgId, requestParameters.orgUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
