import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeElementChangeEvent,
  StripeElementStyle,
} from '@stripe/stripe-js';
import { PoweredByStripeImage } from 'components/images';
import { Grid, Stack, Text } from 'components/ui';
import { InputMessage } from 'components/ui/input-message';
import { AnimatePresence } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { globalTheme } from 'styles/global-theme';

export interface StripeCardProps {
  onComplete?: () => void;
  onIncomplete?: () => void;
}

const style: StripeElementStyle = {
  base: {
    fontFamily: globalTheme.fontFamily,
    fontSize: '14px',
    color: globalTheme.colors.darkGrey,
    lineHeight: '28px',
  },
};

const StripeLink = styled.a`
  display: block;
  width: 140px;
`;

const inputStyles = css`
  height: 48px;
  padding: ${globalTheme.space[0]} ${globalTheme.space[1]};
  background-color: ${globalTheme.colors.white};
  border: 2px solid ${globalTheme.colors.neutral};
  border-radius: 8px;
  transition: border-color ${globalTheme.transitions.fast};

  &.StripeElement--invalid {
    border-color: ${globalTheme.colors.orange};
  }

  &.StripeElement--focus {
    border-color: ${globalTheme.colors.darkGrey};
  }
`;

const Number = styled(CardNumberElement)`
  ${inputStyles}
`;

const Expiry = styled(CardExpiryElement)`
  ${inputStyles}
`;

const Cvc = styled(CardCvcElement)`
  ${inputStyles}
`;

const CardLabel = styled(Text)`
  display: flex;
  @media screen and (max-width: 768px) {
    font-size: 1.125rem;
  }
`;

export const StripeCard: FC<StripeCardProps> = ({
  onComplete,
  onIncomplete,
}) => {
  // Popper reference elements
  const [numberReferenceElement, setNumberReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [expiryReferenceElement, setExpiryReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [cvcReferenceElement, setCvcReferenceElement] =
    useState<HTMLDivElement | null>(null);

  // Error state
  const [errors, setErrors] = useState({ number: '', expiry: '', cvc: '' });

  // Overall card completion state
  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  // Fire off onComplete and onIncomplete events
  useEffect(() => {
    if (
      cardComplete.cardNumber &&
      cardComplete.cardExpiry &&
      cardComplete.cardCvc
    ) {
      onComplete?.();
    } else {
      onIncomplete?.();
    }
  }, [
    cardComplete.cardCvc,
    cardComplete.cardExpiry,
    cardComplete.cardNumber,
    onComplete,
    onIncomplete,
  ]);

  const handleChange = (
    field: keyof typeof errors,
    event: StripeElementChangeEvent
  ) => {
    setErrors((errors) => ({ ...errors, [field]: '' }));
    setCardComplete((complete) => ({
      ...complete,
      [event.elementType]: event.complete,
    }));

    if (event.error) {
      setErrors((errors) => ({
        ...errors,
        [field]: event.error?.message || '',
      }));
    }
  };

  const handleNumberChange = (event: StripeCardNumberElementChangeEvent) => {
    handleChange('number', event);
  };

  const handleExpiryChange = (event: StripeCardExpiryElementChangeEvent) => {
    handleChange('expiry', event);
  };

  const handleCvcChange = (event: StripeCardCvcElementChangeEvent) => {
    handleChange('cvc', event);
  };

  return (
    <Stack space={2}>
      <Grid
        columns='2fr repeat(2, 1fr)'
        stackOnSmall
        gap={2}
        data-cy='cardFields'
      >
        <Stack space={0}>
          <CardLabel fontWeight='bold'>Card number*</CardLabel>
          <div ref={setNumberReferenceElement}>
            <Number options={{ style }} onChange={handleNumberChange} />
          </div>
          <AnimatePresence>
            {errors.number && (
              <InputMessage
                color={'white'}
                backgroundColor={'orange'}
                referenceElement={numberReferenceElement}
              >
                {errors.number}
              </InputMessage>
            )}
          </AnimatePresence>
        </Stack>
        <Stack space={0}>
          <CardLabel fontWeight='bold'>Expiry*</CardLabel>
          <div ref={setExpiryReferenceElement}>
            <Expiry options={{ style }} onChange={handleExpiryChange} />
          </div>
          <AnimatePresence>
            {errors.expiry && (
              <InputMessage
                color={'white'}
                backgroundColor={'orange'}
                referenceElement={expiryReferenceElement}
              >
                {errors.expiry}
              </InputMessage>
            )}
          </AnimatePresence>
        </Stack>
        <Stack space={0}>
          <CardLabel fontWeight='bold'>CVC*</CardLabel>
          <div ref={setCvcReferenceElement}>
            <Cvc options={{ style }} onChange={handleCvcChange} />
          </div>
          <AnimatePresence>
            {errors.cvc && (
              <InputMessage
                color={'white'}
                backgroundColor={'orange'}
                referenceElement={cvcReferenceElement}
              >
                {errors.cvc}
              </InputMessage>
            )}
          </AnimatePresence>
        </Stack>
      </Grid>
      <StripeLink
        href='https://stripe.com'
        target='_blank'
        rel='noopener noreferrer'
      >
        <PoweredByStripeImage />
      </StripeLink>
    </Stack>
  );
};
