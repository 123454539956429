/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { BuyerContactInviteSearch } from '../model';
// @ts-ignore
import { BuyerOrgUser } from '../model';
// @ts-ignore
import { BuyerOrgUserAdd } from '../model';
// @ts-ignore
import { BuyerOrgUserInvite } from '../model';
// @ts-ignore
import { BuyerOrgUserUpdate } from '../model';
// @ts-ignore
import { PaginatedBuyerOrgUserList } from '../model';
// @ts-ignore
import { PatchedBuyerOrgUserUpdate } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * OrganisationAdminUsersApi - axios parameter creator
 * @export
 */
export const OrganisationAdminUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the created Buyer Org User record.          There are validation checks to see if the user is already a member, and if they have already been invited and         are in the PENDING state         
         * @summary Adds an already existing user to the Organisation
         * @param {string} buyerOrgId 
         * @param {BuyerOrgUserAdd} buyerOrgUserAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserCreate: async (buyerOrgId: string, buyerOrgUserAdd: BuyerOrgUserAdd, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgUserCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'buyerOrgUserAdd' is not null or undefined
            assertParamExists('orgUserCreate', 'buyerOrgUserAdd', buyerOrgUserAdd)
            const localVarPath = `/v2/org/{buyer_org_id}/user`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerOrgUserAdd, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a User (staff member) from the Organisation, changing all linked content beneath associated with the group to the logged in user.  The following data is updated:      Price Grids - creator and updater fields.     Contacts - creator field     Offers - Creator and buyer_user fields     EOIS - creator field     Listings - creator field   Then, removes the user from the Organisation
         * @summary Delete a user from the Organisation
         * @param {string} buyerOrgId 
         * @param {string} buyerOrgUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserDestroy: async (buyerOrgId: string, buyerOrgUserId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgUserDestroy', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'buyerOrgUserId' is not null or undefined
            assertParamExists('orgUserDestroy', 'buyerOrgUserId', buyerOrgUserId)
            const localVarPath = `/v2/org/{buyer_org_id}/user/{buyer_org_user_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"buyer_org_user_id"}}`, encodeURIComponent(String(buyerOrgUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invite a user who doesn\'t exist in the database already  An email or SMS will be sent out to the contact depending on what is passed through.  Upon the users signing up, if their email or phone match the search_text then their will be assigned to this Buyer Organisation
         * @summary Invites a user to the Organisation
         * @param {string} buyerOrgId 
         * @param {BuyerOrgUserInvite} buyerOrgUserInvite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserInviteCreate: async (buyerOrgId: string, buyerOrgUserInvite: BuyerOrgUserInvite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgUserInviteCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'buyerOrgUserInvite' is not null or undefined
            assertParamExists('orgUserInviteCreate', 'buyerOrgUserInvite', buyerOrgUserInvite)
            const localVarPath = `/v2/org/{buyer_org_id}/user/invite`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerOrgUserInvite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an ordered list of the users names and id\'s which match  They are scored by grouping them into the following categories, and ordered by lastname and firstname within:  1. Match exactly, 2. Then those which start with the search terms 3. If it contains the search terms.  The results in each group are ordered by the lastname, then firstname.  A minimum of 3 characters must be passed through before any results are returned.  Usage: ?search=Jim           NOTE: this filters out any users who are already in, or have been invited to the Organisation
         * @summary Search the system for users by name
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserInviteSearchList: async (buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgUserInviteSearchList', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/user/invite-search`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This list includes all users that are APPROVED, PENDING and REJECTED (ordered in that way). PENDING users are those which have been invited and have yet to accept it.  The entries which have no \'user\' detail record associated with them are because that user didn\'t exist in the system and as a result has been sent an invite by email or SMS based on the \'search_text\' value the user used to invite them.  # Ordering Status first, APPROVED -> PENDING -> REJECTED Users lastname, firstname, Then search_text
         * @summary Get a list of the users associated (staff) within the Organisation
         * @param {string} buyerOrgId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserList: async (buyerOrgId: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgUserList', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/user`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the newly updated details
         * @summary Update the details of Organisation user
         * @param {string} buyerOrgId 
         * @param {string} buyerOrgUserId 
         * @param {PatchedBuyerOrgUserUpdate} [patchedBuyerOrgUserUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserPartialUpdate: async (buyerOrgId: string, buyerOrgUserId: string, patchedBuyerOrgUserUpdate?: PatchedBuyerOrgUserUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgUserPartialUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'buyerOrgUserId' is not null or undefined
            assertParamExists('orgUserPartialUpdate', 'buyerOrgUserId', buyerOrgUserId)
            const localVarPath = `/v2/org/{buyer_org_id}/user/{buyer_org_user_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"buyer_org_user_id"}}`, encodeURIComponent(String(buyerOrgUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedBuyerOrgUserUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of a single buyer org user record.
         * @param {string} buyerOrgId 
         * @param {string} buyerOrgUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserRetrieve: async (buyerOrgId: string, buyerOrgUserId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgUserRetrieve', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'buyerOrgUserId' is not null or undefined
            assertParamExists('orgUserRetrieve', 'buyerOrgUserId', buyerOrgUserId)
            const localVarPath = `/v2/org/{buyer_org_id}/user/{buyer_org_user_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"buyer_org_user_id"}}`, encodeURIComponent(String(buyerOrgUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the newly updated details
         * @summary Update the details of Organisation user
         * @param {string} buyerOrgId 
         * @param {string} buyerOrgUserId 
         * @param {BuyerOrgUserUpdate} buyerOrgUserUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserUpdate: async (buyerOrgId: string, buyerOrgUserId: string, buyerOrgUserUpdate: BuyerOrgUserUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgUserUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'buyerOrgUserId' is not null or undefined
            assertParamExists('orgUserUpdate', 'buyerOrgUserId', buyerOrgUserId)
            // verify required parameter 'buyerOrgUserUpdate' is not null or undefined
            assertParamExists('orgUserUpdate', 'buyerOrgUserUpdate', buyerOrgUserUpdate)
            const localVarPath = `/v2/org/{buyer_org_id}/user/{buyer_org_user_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"buyer_org_user_id"}}`, encodeURIComponent(String(buyerOrgUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerOrgUserUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationAdminUsersApi - functional programming interface
 * @export
 */
export const OrganisationAdminUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationAdminUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the created Buyer Org User record.          There are validation checks to see if the user is already a member, and if they have already been invited and         are in the PENDING state         
         * @summary Adds an already existing user to the Organisation
         * @param {string} buyerOrgId 
         * @param {BuyerOrgUserAdd} buyerOrgUserAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgUserCreate(buyerOrgId: string, buyerOrgUserAdd: BuyerOrgUserAdd, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerOrgUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgUserCreate(buyerOrgId, buyerOrgUserAdd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a User (staff member) from the Organisation, changing all linked content beneath associated with the group to the logged in user.  The following data is updated:      Price Grids - creator and updater fields.     Contacts - creator field     Offers - Creator and buyer_user fields     EOIS - creator field     Listings - creator field   Then, removes the user from the Organisation
         * @summary Delete a user from the Organisation
         * @param {string} buyerOrgId 
         * @param {string} buyerOrgUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgUserDestroy(buyerOrgId: string, buyerOrgUserId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgUserDestroy(buyerOrgId, buyerOrgUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invite a user who doesn\'t exist in the database already  An email or SMS will be sent out to the contact depending on what is passed through.  Upon the users signing up, if their email or phone match the search_text then their will be assigned to this Buyer Organisation
         * @summary Invites a user to the Organisation
         * @param {string} buyerOrgId 
         * @param {BuyerOrgUserInvite} buyerOrgUserInvite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgUserInviteCreate(buyerOrgId: string, buyerOrgUserInvite: BuyerOrgUserInvite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerOrgUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgUserInviteCreate(buyerOrgId, buyerOrgUserInvite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns an ordered list of the users names and id\'s which match  They are scored by grouping them into the following categories, and ordered by lastname and firstname within:  1. Match exactly, 2. Then those which start with the search terms 3. If it contains the search terms.  The results in each group are ordered by the lastname, then firstname.  A minimum of 3 characters must be passed through before any results are returned.  Usage: ?search=Jim           NOTE: this filters out any users who are already in, or have been invited to the Organisation
         * @summary Search the system for users by name
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgUserInviteSearchList(buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BuyerContactInviteSearch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgUserInviteSearchList(buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This list includes all users that are APPROVED, PENDING and REJECTED (ordered in that way). PENDING users are those which have been invited and have yet to accept it.  The entries which have no \'user\' detail record associated with them are because that user didn\'t exist in the system and as a result has been sent an invite by email or SMS based on the \'search_text\' value the user used to invite them.  # Ordering Status first, APPROVED -> PENDING -> REJECTED Users lastname, firstname, Then search_text
         * @summary Get a list of the users associated (staff) within the Organisation
         * @param {string} buyerOrgId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgUserList(buyerOrgId: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedBuyerOrgUserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgUserList(buyerOrgId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the newly updated details
         * @summary Update the details of Organisation user
         * @param {string} buyerOrgId 
         * @param {string} buyerOrgUserId 
         * @param {PatchedBuyerOrgUserUpdate} [patchedBuyerOrgUserUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgUserPartialUpdate(buyerOrgId: string, buyerOrgUserId: string, patchedBuyerOrgUserUpdate?: PatchedBuyerOrgUserUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerOrgUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgUserPartialUpdate(buyerOrgId, buyerOrgUserId, patchedBuyerOrgUserUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of a single buyer org user record.
         * @param {string} buyerOrgId 
         * @param {string} buyerOrgUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgUserRetrieve(buyerOrgId: string, buyerOrgUserId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerOrgUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgUserRetrieve(buyerOrgId, buyerOrgUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the newly updated details
         * @summary Update the details of Organisation user
         * @param {string} buyerOrgId 
         * @param {string} buyerOrgUserId 
         * @param {BuyerOrgUserUpdate} buyerOrgUserUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgUserUpdate(buyerOrgId: string, buyerOrgUserId: string, buyerOrgUserUpdate: BuyerOrgUserUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerOrgUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgUserUpdate(buyerOrgId, buyerOrgUserId, buyerOrgUserUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationAdminUsersApi - factory interface
 * @export
 */
export const OrganisationAdminUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationAdminUsersApiFp(configuration)
    return {
        /**
         * Returns the created Buyer Org User record.          There are validation checks to see if the user is already a member, and if they have already been invited and         are in the PENDING state         
         * @summary Adds an already existing user to the Organisation
         * @param {string} buyerOrgId 
         * @param {BuyerOrgUserAdd} buyerOrgUserAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserCreate(buyerOrgId: string, buyerOrgUserAdd: BuyerOrgUserAdd, options?: any): AxiosPromise<BuyerOrgUser> {
            return localVarFp.orgUserCreate(buyerOrgId, buyerOrgUserAdd, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a User (staff member) from the Organisation, changing all linked content beneath associated with the group to the logged in user.  The following data is updated:      Price Grids - creator and updater fields.     Contacts - creator field     Offers - Creator and buyer_user fields     EOIS - creator field     Listings - creator field   Then, removes the user from the Organisation
         * @summary Delete a user from the Organisation
         * @param {string} buyerOrgId 
         * @param {string} buyerOrgUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserDestroy(buyerOrgId: string, buyerOrgUserId: string, options?: any): AxiosPromise<void> {
            return localVarFp.orgUserDestroy(buyerOrgId, buyerOrgUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * Invite a user who doesn\'t exist in the database already  An email or SMS will be sent out to the contact depending on what is passed through.  Upon the users signing up, if their email or phone match the search_text then their will be assigned to this Buyer Organisation
         * @summary Invites a user to the Organisation
         * @param {string} buyerOrgId 
         * @param {BuyerOrgUserInvite} buyerOrgUserInvite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserInviteCreate(buyerOrgId: string, buyerOrgUserInvite: BuyerOrgUserInvite, options?: any): AxiosPromise<BuyerOrgUser> {
            return localVarFp.orgUserInviteCreate(buyerOrgId, buyerOrgUserInvite, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an ordered list of the users names and id\'s which match  They are scored by grouping them into the following categories, and ordered by lastname and firstname within:  1. Match exactly, 2. Then those which start with the search terms 3. If it contains the search terms.  The results in each group are ordered by the lastname, then firstname.  A minimum of 3 characters must be passed through before any results are returned.  Usage: ?search=Jim           NOTE: this filters out any users who are already in, or have been invited to the Organisation
         * @summary Search the system for users by name
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserInviteSearchList(buyerOrgId: string, options?: any): AxiosPromise<Array<BuyerContactInviteSearch>> {
            return localVarFp.orgUserInviteSearchList(buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * This list includes all users that are APPROVED, PENDING and REJECTED (ordered in that way). PENDING users are those which have been invited and have yet to accept it.  The entries which have no \'user\' detail record associated with them are because that user didn\'t exist in the system and as a result has been sent an invite by email or SMS based on the \'search_text\' value the user used to invite them.  # Ordering Status first, APPROVED -> PENDING -> REJECTED Users lastname, firstname, Then search_text
         * @summary Get a list of the users associated (staff) within the Organisation
         * @param {string} buyerOrgId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserList(buyerOrgId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedBuyerOrgUserList> {
            return localVarFp.orgUserList(buyerOrgId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the newly updated details
         * @summary Update the details of Organisation user
         * @param {string} buyerOrgId 
         * @param {string} buyerOrgUserId 
         * @param {PatchedBuyerOrgUserUpdate} [patchedBuyerOrgUserUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserPartialUpdate(buyerOrgId: string, buyerOrgUserId: string, patchedBuyerOrgUserUpdate?: PatchedBuyerOrgUserUpdate, options?: any): AxiosPromise<BuyerOrgUser> {
            return localVarFp.orgUserPartialUpdate(buyerOrgId, buyerOrgUserId, patchedBuyerOrgUserUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of a single buyer org user record.
         * @param {string} buyerOrgId 
         * @param {string} buyerOrgUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserRetrieve(buyerOrgId: string, buyerOrgUserId: string, options?: any): AxiosPromise<BuyerOrgUser> {
            return localVarFp.orgUserRetrieve(buyerOrgId, buyerOrgUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the newly updated details
         * @summary Update the details of Organisation user
         * @param {string} buyerOrgId 
         * @param {string} buyerOrgUserId 
         * @param {BuyerOrgUserUpdate} buyerOrgUserUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgUserUpdate(buyerOrgId: string, buyerOrgUserId: string, buyerOrgUserUpdate: BuyerOrgUserUpdate, options?: any): AxiosPromise<BuyerOrgUser> {
            return localVarFp.orgUserUpdate(buyerOrgId, buyerOrgUserId, buyerOrgUserUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgUserCreate operation in OrganisationAdminUsersApi.
 * @export
 * @interface OrganisationAdminUsersApiOrgUserCreateRequest
 */
export interface OrganisationAdminUsersApiOrgUserCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationAdminUsersApiOrgUserCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {BuyerOrgUserAdd}
     * @memberof OrganisationAdminUsersApiOrgUserCreate
     */
    readonly buyerOrgUserAdd: BuyerOrgUserAdd
}

/**
 * Request parameters for orgUserDestroy operation in OrganisationAdminUsersApi.
 * @export
 * @interface OrganisationAdminUsersApiOrgUserDestroyRequest
 */
export interface OrganisationAdminUsersApiOrgUserDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationAdminUsersApiOrgUserDestroy
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationAdminUsersApiOrgUserDestroy
     */
    readonly buyerOrgUserId: string
}

/**
 * Request parameters for orgUserInviteCreate operation in OrganisationAdminUsersApi.
 * @export
 * @interface OrganisationAdminUsersApiOrgUserInviteCreateRequest
 */
export interface OrganisationAdminUsersApiOrgUserInviteCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationAdminUsersApiOrgUserInviteCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {BuyerOrgUserInvite}
     * @memberof OrganisationAdminUsersApiOrgUserInviteCreate
     */
    readonly buyerOrgUserInvite: BuyerOrgUserInvite
}

/**
 * Request parameters for orgUserInviteSearchList operation in OrganisationAdminUsersApi.
 * @export
 * @interface OrganisationAdminUsersApiOrgUserInviteSearchListRequest
 */
export interface OrganisationAdminUsersApiOrgUserInviteSearchListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationAdminUsersApiOrgUserInviteSearchList
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgUserList operation in OrganisationAdminUsersApi.
 * @export
 * @interface OrganisationAdminUsersApiOrgUserListRequest
 */
export interface OrganisationAdminUsersApiOrgUserListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationAdminUsersApiOrgUserList
     */
    readonly buyerOrgId: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof OrganisationAdminUsersApiOrgUserList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrganisationAdminUsersApiOrgUserList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for orgUserPartialUpdate operation in OrganisationAdminUsersApi.
 * @export
 * @interface OrganisationAdminUsersApiOrgUserPartialUpdateRequest
 */
export interface OrganisationAdminUsersApiOrgUserPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationAdminUsersApiOrgUserPartialUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationAdminUsersApiOrgUserPartialUpdate
     */
    readonly buyerOrgUserId: string

    /**
     * 
     * @type {PatchedBuyerOrgUserUpdate}
     * @memberof OrganisationAdminUsersApiOrgUserPartialUpdate
     */
    readonly patchedBuyerOrgUserUpdate?: PatchedBuyerOrgUserUpdate
}

/**
 * Request parameters for orgUserRetrieve operation in OrganisationAdminUsersApi.
 * @export
 * @interface OrganisationAdminUsersApiOrgUserRetrieveRequest
 */
export interface OrganisationAdminUsersApiOrgUserRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationAdminUsersApiOrgUserRetrieve
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationAdminUsersApiOrgUserRetrieve
     */
    readonly buyerOrgUserId: string
}

/**
 * Request parameters for orgUserUpdate operation in OrganisationAdminUsersApi.
 * @export
 * @interface OrganisationAdminUsersApiOrgUserUpdateRequest
 */
export interface OrganisationAdminUsersApiOrgUserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationAdminUsersApiOrgUserUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationAdminUsersApiOrgUserUpdate
     */
    readonly buyerOrgUserId: string

    /**
     * 
     * @type {BuyerOrgUserUpdate}
     * @memberof OrganisationAdminUsersApiOrgUserUpdate
     */
    readonly buyerOrgUserUpdate: BuyerOrgUserUpdate
}

/**
 * OrganisationAdminUsersApi - object-oriented interface
 * @export
 * @class OrganisationAdminUsersApi
 * @extends {BaseAPI}
 */
export class OrganisationAdminUsersApi extends BaseAPI {
    /**
     * Returns the created Buyer Org User record.          There are validation checks to see if the user is already a member, and if they have already been invited and         are in the PENDING state         
     * @summary Adds an already existing user to the Organisation
     * @param {OrganisationAdminUsersApiOrgUserCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAdminUsersApi
     */
    public orgUserCreate(requestParameters: OrganisationAdminUsersApiOrgUserCreateRequest, options?: any) {
        return OrganisationAdminUsersApiFp(this.configuration).orgUserCreate(requestParameters.buyerOrgId, requestParameters.buyerOrgUserAdd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a User (staff member) from the Organisation, changing all linked content beneath associated with the group to the logged in user.  The following data is updated:      Price Grids - creator and updater fields.     Contacts - creator field     Offers - Creator and buyer_user fields     EOIS - creator field     Listings - creator field   Then, removes the user from the Organisation
     * @summary Delete a user from the Organisation
     * @param {OrganisationAdminUsersApiOrgUserDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAdminUsersApi
     */
    public orgUserDestroy(requestParameters: OrganisationAdminUsersApiOrgUserDestroyRequest, options?: any) {
        return OrganisationAdminUsersApiFp(this.configuration).orgUserDestroy(requestParameters.buyerOrgId, requestParameters.buyerOrgUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invite a user who doesn\'t exist in the database already  An email or SMS will be sent out to the contact depending on what is passed through.  Upon the users signing up, if their email or phone match the search_text then their will be assigned to this Buyer Organisation
     * @summary Invites a user to the Organisation
     * @param {OrganisationAdminUsersApiOrgUserInviteCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAdminUsersApi
     */
    public orgUserInviteCreate(requestParameters: OrganisationAdminUsersApiOrgUserInviteCreateRequest, options?: any) {
        return OrganisationAdminUsersApiFp(this.configuration).orgUserInviteCreate(requestParameters.buyerOrgId, requestParameters.buyerOrgUserInvite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an ordered list of the users names and id\'s which match  They are scored by grouping them into the following categories, and ordered by lastname and firstname within:  1. Match exactly, 2. Then those which start with the search terms 3. If it contains the search terms.  The results in each group are ordered by the lastname, then firstname.  A minimum of 3 characters must be passed through before any results are returned.  Usage: ?search=Jim           NOTE: this filters out any users who are already in, or have been invited to the Organisation
     * @summary Search the system for users by name
     * @param {OrganisationAdminUsersApiOrgUserInviteSearchListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAdminUsersApi
     */
    public orgUserInviteSearchList(requestParameters: OrganisationAdminUsersApiOrgUserInviteSearchListRequest, options?: any) {
        return OrganisationAdminUsersApiFp(this.configuration).orgUserInviteSearchList(requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This list includes all users that are APPROVED, PENDING and REJECTED (ordered in that way). PENDING users are those which have been invited and have yet to accept it.  The entries which have no \'user\' detail record associated with them are because that user didn\'t exist in the system and as a result has been sent an invite by email or SMS based on the \'search_text\' value the user used to invite them.  # Ordering Status first, APPROVED -> PENDING -> REJECTED Users lastname, firstname, Then search_text
     * @summary Get a list of the users associated (staff) within the Organisation
     * @param {OrganisationAdminUsersApiOrgUserListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAdminUsersApi
     */
    public orgUserList(requestParameters: OrganisationAdminUsersApiOrgUserListRequest, options?: any) {
        return OrganisationAdminUsersApiFp(this.configuration).orgUserList(requestParameters.buyerOrgId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the newly updated details
     * @summary Update the details of Organisation user
     * @param {OrganisationAdminUsersApiOrgUserPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAdminUsersApi
     */
    public orgUserPartialUpdate(requestParameters: OrganisationAdminUsersApiOrgUserPartialUpdateRequest, options?: any) {
        return OrganisationAdminUsersApiFp(this.configuration).orgUserPartialUpdate(requestParameters.buyerOrgId, requestParameters.buyerOrgUserId, requestParameters.patchedBuyerOrgUserUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of a single buyer org user record.
     * @param {OrganisationAdminUsersApiOrgUserRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAdminUsersApi
     */
    public orgUserRetrieve(requestParameters: OrganisationAdminUsersApiOrgUserRetrieveRequest, options?: any) {
        return OrganisationAdminUsersApiFp(this.configuration).orgUserRetrieve(requestParameters.buyerOrgId, requestParameters.buyerOrgUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the newly updated details
     * @summary Update the details of Organisation user
     * @param {OrganisationAdminUsersApiOrgUserUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationAdminUsersApi
     */
    public orgUserUpdate(requestParameters: OrganisationAdminUsersApiOrgUserUpdateRequest, options?: any) {
        return OrganisationAdminUsersApiFp(this.configuration).orgUserUpdate(requestParameters.buyerOrgId, requestParameters.buyerOrgUserId, requestParameters.buyerOrgUserUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
