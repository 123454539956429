/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { PIC } from '../model';
// @ts-ignore
import { PICCreate } from '../model';
// @ts-ignore
import { PaginatedPICList } from '../model';
// @ts-ignore
import { PatchedPICCreate } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * OrganisationPICApi - axios parameter creator
 * @export
 */
export const OrganisationPICApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive a PIC
         * @param {string} buyerOrgId 
         * @param {string} picId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicArchiveCreate: async (buyerOrgId: string, picId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPicArchiveCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'picId' is not null or undefined
            assertParamExists('orgPicArchiveCreate', 'picId', picId)
            const localVarPath = `/v2/org/{buyer_org_id}/pic/{pic_id}/archive`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"pic_id"}}`, encodeURIComponent(String(picId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the archived state on a PIC.
         * @param {string} buyerOrgId 
         * @param {string} picId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicArchiveDestroy: async (buyerOrgId: string, picId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPicArchiveDestroy', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'picId' is not null or undefined
            assertParamExists('orgPicArchiveDestroy', 'picId', picId)
            const localVarPath = `/v2/org/{buyer_org_id}/pic/{pic_id}/archive`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"pic_id"}}`, encodeURIComponent(String(picId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Searches against the Organisations PICs using the following fields: - code - company_name - property_name - brands  The results are ordered Alphabetically by the PIC code, then the company name  When searching, those which have an exact match of the whole string are first, followed by those which start with the query, and finally those which contain the search param. 
         * @summary Autocomplete PIC Search
         * @param {string} buyerOrgId 
         * @param {string} [includeArchived] Includes archived PICs. values: \&#39;true\&#39; or \&#39;false\&#39;, default: \&#39;false\&#39;
         * @param {string} [q] Query parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAutocompleteRetrieve: async (buyerOrgId: string, includeArchived?: string, q?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPicAutocompleteRetrieve', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/pic/autocomplete`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeArchived !== undefined) {
                localVarQueryParameter['include_archived'] = includeArchived;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new PIC
         * @param {string} buyerOrgId 
         * @param {PICCreate} pICCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicCreate: async (buyerOrgId: string, pICCreate: PICCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPicCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'pICCreate' is not null or undefined
            assertParamExists('orgPicCreate', 'pICCreate', pICCreate)
            const localVarPath = `/v2/org/{buyer_org_id}/pic`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pICCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Defaults:  `include_archived=false` 
         * @summary Get a list of all PICs within an Organisation
         * @param {string} buyerOrgId 
         * @param {string} [includeArchived] To include archived PICs. Default: false
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicList: async (buyerOrgId: string, includeArchived?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPicList', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/pic`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeArchived !== undefined) {
                localVarQueryParameter['include_archived'] = includeArchived;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Partially update the details of a PIC
         * @param {string} buyerOrgId 
         * @param {string} picId 
         * @param {PatchedPICCreate} [patchedPICCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicPartialUpdate: async (buyerOrgId: string, picId: string, patchedPICCreate?: PatchedPICCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPicPartialUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'picId' is not null or undefined
            assertParamExists('orgPicPartialUpdate', 'picId', picId)
            const localVarPath = `/v2/org/{buyer_org_id}/pic/{pic_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"pic_id"}}`, encodeURIComponent(String(picId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedPICCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of a PIC
         * @param {string} buyerOrgId 
         * @param {string} picId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicRetrieve: async (buyerOrgId: string, picId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPicRetrieve', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'picId' is not null or undefined
            assertParamExists('orgPicRetrieve', 'picId', picId)
            const localVarPath = `/v2/org/{buyer_org_id}/pic/{pic_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"pic_id"}}`, encodeURIComponent(String(picId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the details of a PIC
         * @param {string} buyerOrgId 
         * @param {string} picId 
         * @param {PICCreate} pICCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicUpdate: async (buyerOrgId: string, picId: string, pICCreate: PICCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPicUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'picId' is not null or undefined
            assertParamExists('orgPicUpdate', 'picId', picId)
            // verify required parameter 'pICCreate' is not null or undefined
            assertParamExists('orgPicUpdate', 'pICCreate', pICCreate)
            const localVarPath = `/v2/org/{buyer_org_id}/pic/{pic_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"pic_id"}}`, encodeURIComponent(String(picId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pICCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationPICApi - functional programming interface
 * @export
 */
export const OrganisationPICApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationPICApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive a PIC
         * @param {string} buyerOrgId 
         * @param {string} picId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPicArchiveCreate(buyerOrgId: string, picId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PIC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPicArchiveCreate(buyerOrgId, picId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the archived state on a PIC.
         * @param {string} buyerOrgId 
         * @param {string} picId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPicArchiveDestroy(buyerOrgId: string, picId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PIC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPicArchiveDestroy(buyerOrgId, picId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Searches against the Organisations PICs using the following fields: - code - company_name - property_name - brands  The results are ordered Alphabetically by the PIC code, then the company name  When searching, those which have an exact match of the whole string are first, followed by those which start with the query, and finally those which contain the search param. 
         * @summary Autocomplete PIC Search
         * @param {string} buyerOrgId 
         * @param {string} [includeArchived] Includes archived PICs. values: \&#39;true\&#39; or \&#39;false\&#39;, default: \&#39;false\&#39;
         * @param {string} [q] Query parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPicAutocompleteRetrieve(buyerOrgId: string, includeArchived?: string, q?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PIC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPicAutocompleteRetrieve(buyerOrgId, includeArchived, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new PIC
         * @param {string} buyerOrgId 
         * @param {PICCreate} pICCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPicCreate(buyerOrgId: string, pICCreate: PICCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PIC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPicCreate(buyerOrgId, pICCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Defaults:  `include_archived=false` 
         * @summary Get a list of all PICs within an Organisation
         * @param {string} buyerOrgId 
         * @param {string} [includeArchived] To include archived PICs. Default: false
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPicList(buyerOrgId: string, includeArchived?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPICList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPicList(buyerOrgId, includeArchived, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Partially update the details of a PIC
         * @param {string} buyerOrgId 
         * @param {string} picId 
         * @param {PatchedPICCreate} [patchedPICCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPicPartialUpdate(buyerOrgId: string, picId: string, patchedPICCreate?: PatchedPICCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PIC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPicPartialUpdate(buyerOrgId, picId, patchedPICCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of a PIC
         * @param {string} buyerOrgId 
         * @param {string} picId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPicRetrieve(buyerOrgId: string, picId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PIC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPicRetrieve(buyerOrgId, picId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the details of a PIC
         * @param {string} buyerOrgId 
         * @param {string} picId 
         * @param {PICCreate} pICCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPicUpdate(buyerOrgId: string, picId: string, pICCreate: PICCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PIC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPicUpdate(buyerOrgId, picId, pICCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationPICApi - factory interface
 * @export
 */
export const OrganisationPICApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationPICApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive a PIC
         * @param {string} buyerOrgId 
         * @param {string} picId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicArchiveCreate(buyerOrgId: string, picId: string, options?: any): AxiosPromise<PIC> {
            return localVarFp.orgPicArchiveCreate(buyerOrgId, picId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the archived state on a PIC.
         * @param {string} buyerOrgId 
         * @param {string} picId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicArchiveDestroy(buyerOrgId: string, picId: string, options?: any): AxiosPromise<PIC> {
            return localVarFp.orgPicArchiveDestroy(buyerOrgId, picId, options).then((request) => request(axios, basePath));
        },
        /**
         * Searches against the Organisations PICs using the following fields: - code - company_name - property_name - brands  The results are ordered Alphabetically by the PIC code, then the company name  When searching, those which have an exact match of the whole string are first, followed by those which start with the query, and finally those which contain the search param. 
         * @summary Autocomplete PIC Search
         * @param {string} buyerOrgId 
         * @param {string} [includeArchived] Includes archived PICs. values: \&#39;true\&#39; or \&#39;false\&#39;, default: \&#39;false\&#39;
         * @param {string} [q] Query parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAutocompleteRetrieve(buyerOrgId: string, includeArchived?: string, q?: string, options?: any): AxiosPromise<PIC> {
            return localVarFp.orgPicAutocompleteRetrieve(buyerOrgId, includeArchived, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new PIC
         * @param {string} buyerOrgId 
         * @param {PICCreate} pICCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicCreate(buyerOrgId: string, pICCreate: PICCreate, options?: any): AxiosPromise<PIC> {
            return localVarFp.orgPicCreate(buyerOrgId, pICCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Defaults:  `include_archived=false` 
         * @summary Get a list of all PICs within an Organisation
         * @param {string} buyerOrgId 
         * @param {string} [includeArchived] To include archived PICs. Default: false
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicList(buyerOrgId: string, includeArchived?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedPICList> {
            return localVarFp.orgPicList(buyerOrgId, includeArchived, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Partially update the details of a PIC
         * @param {string} buyerOrgId 
         * @param {string} picId 
         * @param {PatchedPICCreate} [patchedPICCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicPartialUpdate(buyerOrgId: string, picId: string, patchedPICCreate?: PatchedPICCreate, options?: any): AxiosPromise<PIC> {
            return localVarFp.orgPicPartialUpdate(buyerOrgId, picId, patchedPICCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of a PIC
         * @param {string} buyerOrgId 
         * @param {string} picId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicRetrieve(buyerOrgId: string, picId: string, options?: any): AxiosPromise<PIC> {
            return localVarFp.orgPicRetrieve(buyerOrgId, picId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the details of a PIC
         * @param {string} buyerOrgId 
         * @param {string} picId 
         * @param {PICCreate} pICCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicUpdate(buyerOrgId: string, picId: string, pICCreate: PICCreate, options?: any): AxiosPromise<PIC> {
            return localVarFp.orgPicUpdate(buyerOrgId, picId, pICCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgPicArchiveCreate operation in OrganisationPICApi.
 * @export
 * @interface OrganisationPICApiOrgPicArchiveCreateRequest
 */
export interface OrganisationPICApiOrgPicArchiveCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPICApiOrgPicArchiveCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPICApiOrgPicArchiveCreate
     */
    readonly picId: string
}

/**
 * Request parameters for orgPicArchiveDestroy operation in OrganisationPICApi.
 * @export
 * @interface OrganisationPICApiOrgPicArchiveDestroyRequest
 */
export interface OrganisationPICApiOrgPicArchiveDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPICApiOrgPicArchiveDestroy
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPICApiOrgPicArchiveDestroy
     */
    readonly picId: string
}

/**
 * Request parameters for orgPicAutocompleteRetrieve operation in OrganisationPICApi.
 * @export
 * @interface OrganisationPICApiOrgPicAutocompleteRetrieveRequest
 */
export interface OrganisationPICApiOrgPicAutocompleteRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPICApiOrgPicAutocompleteRetrieve
     */
    readonly buyerOrgId: string

    /**
     * Includes archived PICs. values: \&#39;true\&#39; or \&#39;false\&#39;, default: \&#39;false\&#39;
     * @type {string}
     * @memberof OrganisationPICApiOrgPicAutocompleteRetrieve
     */
    readonly includeArchived?: string

    /**
     * Query parameter
     * @type {string}
     * @memberof OrganisationPICApiOrgPicAutocompleteRetrieve
     */
    readonly q?: string
}

/**
 * Request parameters for orgPicCreate operation in OrganisationPICApi.
 * @export
 * @interface OrganisationPICApiOrgPicCreateRequest
 */
export interface OrganisationPICApiOrgPicCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPICApiOrgPicCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {PICCreate}
     * @memberof OrganisationPICApiOrgPicCreate
     */
    readonly pICCreate: PICCreate
}

/**
 * Request parameters for orgPicList operation in OrganisationPICApi.
 * @export
 * @interface OrganisationPICApiOrgPicListRequest
 */
export interface OrganisationPICApiOrgPicListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPICApiOrgPicList
     */
    readonly buyerOrgId: string

    /**
     * To include archived PICs. Default: false
     * @type {string}
     * @memberof OrganisationPICApiOrgPicList
     */
    readonly includeArchived?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof OrganisationPICApiOrgPicList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrganisationPICApiOrgPicList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for orgPicPartialUpdate operation in OrganisationPICApi.
 * @export
 * @interface OrganisationPICApiOrgPicPartialUpdateRequest
 */
export interface OrganisationPICApiOrgPicPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPICApiOrgPicPartialUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPICApiOrgPicPartialUpdate
     */
    readonly picId: string

    /**
     * 
     * @type {PatchedPICCreate}
     * @memberof OrganisationPICApiOrgPicPartialUpdate
     */
    readonly patchedPICCreate?: PatchedPICCreate
}

/**
 * Request parameters for orgPicRetrieve operation in OrganisationPICApi.
 * @export
 * @interface OrganisationPICApiOrgPicRetrieveRequest
 */
export interface OrganisationPICApiOrgPicRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPICApiOrgPicRetrieve
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPICApiOrgPicRetrieve
     */
    readonly picId: string
}

/**
 * Request parameters for orgPicUpdate operation in OrganisationPICApi.
 * @export
 * @interface OrganisationPICApiOrgPicUpdateRequest
 */
export interface OrganisationPICApiOrgPicUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPICApiOrgPicUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPICApiOrgPicUpdate
     */
    readonly picId: string

    /**
     * 
     * @type {PICCreate}
     * @memberof OrganisationPICApiOrgPicUpdate
     */
    readonly pICCreate: PICCreate
}

/**
 * OrganisationPICApi - object-oriented interface
 * @export
 * @class OrganisationPICApi
 * @extends {BaseAPI}
 */
export class OrganisationPICApi extends BaseAPI {
    /**
     * 
     * @summary Archive a PIC
     * @param {OrganisationPICApiOrgPicArchiveCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPICApi
     */
    public orgPicArchiveCreate(requestParameters: OrganisationPICApiOrgPicArchiveCreateRequest, options?: any) {
        return OrganisationPICApiFp(this.configuration).orgPicArchiveCreate(requestParameters.buyerOrgId, requestParameters.picId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the archived state on a PIC.
     * @param {OrganisationPICApiOrgPicArchiveDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPICApi
     */
    public orgPicArchiveDestroy(requestParameters: OrganisationPICApiOrgPicArchiveDestroyRequest, options?: any) {
        return OrganisationPICApiFp(this.configuration).orgPicArchiveDestroy(requestParameters.buyerOrgId, requestParameters.picId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Searches against the Organisations PICs using the following fields: - code - company_name - property_name - brands  The results are ordered Alphabetically by the PIC code, then the company name  When searching, those which have an exact match of the whole string are first, followed by those which start with the query, and finally those which contain the search param. 
     * @summary Autocomplete PIC Search
     * @param {OrganisationPICApiOrgPicAutocompleteRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPICApi
     */
    public orgPicAutocompleteRetrieve(requestParameters: OrganisationPICApiOrgPicAutocompleteRetrieveRequest, options?: any) {
        return OrganisationPICApiFp(this.configuration).orgPicAutocompleteRetrieve(requestParameters.buyerOrgId, requestParameters.includeArchived, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new PIC
     * @param {OrganisationPICApiOrgPicCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPICApi
     */
    public orgPicCreate(requestParameters: OrganisationPICApiOrgPicCreateRequest, options?: any) {
        return OrganisationPICApiFp(this.configuration).orgPicCreate(requestParameters.buyerOrgId, requestParameters.pICCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Defaults:  `include_archived=false` 
     * @summary Get a list of all PICs within an Organisation
     * @param {OrganisationPICApiOrgPicListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPICApi
     */
    public orgPicList(requestParameters: OrganisationPICApiOrgPicListRequest, options?: any) {
        return OrganisationPICApiFp(this.configuration).orgPicList(requestParameters.buyerOrgId, requestParameters.includeArchived, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Partially update the details of a PIC
     * @param {OrganisationPICApiOrgPicPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPICApi
     */
    public orgPicPartialUpdate(requestParameters: OrganisationPICApiOrgPicPartialUpdateRequest, options?: any) {
        return OrganisationPICApiFp(this.configuration).orgPicPartialUpdate(requestParameters.buyerOrgId, requestParameters.picId, requestParameters.patchedPICCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of a PIC
     * @param {OrganisationPICApiOrgPicRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPICApi
     */
    public orgPicRetrieve(requestParameters: OrganisationPICApiOrgPicRetrieveRequest, options?: any) {
        return OrganisationPICApiFp(this.configuration).orgPicRetrieve(requestParameters.buyerOrgId, requestParameters.picId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the details of a PIC
     * @param {OrganisationPICApiOrgPicUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPICApi
     */
    public orgPicUpdate(requestParameters: OrganisationPICApiOrgPicUpdateRequest, options?: any) {
        return OrganisationPICApiFp(this.configuration).orgPicUpdate(requestParameters.buyerOrgId, requestParameters.picId, requestParameters.pICCreate, options).then((request) => request(this.axios, this.basePath));
    }
}
