import { Can } from 'components/can';
import { ChangePlanDialog } from 'components/change-plan-dialog';
import {
  Button,
  Dialog,
  DialogProps,
  Message,
  Stack,
  Text,
} from 'components/ui';
import { FC, ReactElement, useState } from 'react';

export interface UpgradeDialogProps extends Omit<DialogProps, 'actions'> {
  description: string | ReactElement;
  actionText?: string;
}

enum Step {
  Message,
  Plan,
}

export const UpgradeDialog: FC<UpgradeDialogProps> = ({
  children,
  actionText = 'Show me the other plans',
  description,
  ...props
}) => {
  const [currentStep, setCurrentStep] = useState<Step>(Step.Message);

  const handleDismiss = () => {
    props.onDismiss?.();
    setCurrentStep(Step.Message);
  };

  return (
    <>
      <Dialog
        {...props}
        isOpen={props.isOpen && currentStep === Step.Message}
        onDismiss={handleDismiss}
        actions={
          <Can I='update' an='Organisation'>
            <Button
              size='small'
              variant='outline'
              type='button'
              onClick={props.onDismiss}
            >
              No thanks
            </Button>
            <Button
              size='small'
              type='button'
              onClick={() => setCurrentStep(Step.Plan)}
            >
              {actionText}
            </Button>
          </Can>
        }
      >
        <Stack space={3}>
          <Text textAlign='center' fontSize={4} fontWeight='light'>
            {description}
          </Text>
          <Can not I='update' an='Organisation'>
            <Message backgroundColor='lightBlue' color='blue'>
              Contact your organisation admin to upgrade your subscription.
            </Message>
          </Can>
        </Stack>
      </Dialog>
      <ChangePlanDialog
        isOpen={props.isOpen && currentStep === Step.Plan}
        onDismiss={handleDismiss}
        upgrade={true}
      />
    </>
  );
};
