import styled, { CSSObject } from 'styled-components';
import { globalTheme } from 'styles/global-theme';

export interface DividerItemProps {
  space?: keyof typeof globalTheme.space;
}

function getPadding(props: DividerItemProps): CSSObject {
  return {
    '--divider-item-padding':
      props.space !== undefined
        ? globalTheme.space[props.space]
        : globalTheme.space[1],
  };
}

export const DividerItem = styled.div`
  /* Padding */
  ${getPadding}

  padding: var(--divider-item-padding) 0;
  border-top: 1px solid ${globalTheme.elements.contentSection.borderTopColor};

  &:last-of-type {
    padding: var(--divider-item-padding) 0;
    border-bottom: 1px solid
      ${globalTheme.elements.contentSection.borderBottomColor};
  }

  & + & {
    //border-bottom: 0;
  }

  * > &:first-child {
    padding-top: 0;
    border-top: 0;
    //border-bottom: 0;
    //border-bottom: none;
    //border-top: none;
  }
`;
