/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { PICAttribute } from '../model';
// @ts-ignore
import { PaginatedPICAttributeList } from '../model';
// @ts-ignore
import { PatchedPICAttribute } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * OrganisationPICAttributeApi - axios parameter creator
 * @export
 */
export const OrganisationPICAttributeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive an Attribute
         * @param {string} buyerOrgId 
         * @param {string} picAttributeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAttributeArchiveCreate: async (buyerOrgId: string, picAttributeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPicAttributeArchiveCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'picAttributeId' is not null or undefined
            assertParamExists('orgPicAttributeArchiveCreate', 'picAttributeId', picAttributeId)
            const localVarPath = `/v2/org/{buyer_org_id}/pic/attribute/{pic_attribute_id}/archive`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"pic_attribute_id"}}`, encodeURIComponent(String(picAttributeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the archived state on an Attribute.
         * @param {string} buyerOrgId 
         * @param {string} picAttributeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAttributeArchiveDestroy: async (buyerOrgId: string, picAttributeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPicAttributeArchiveDestroy', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'picAttributeId' is not null or undefined
            assertParamExists('orgPicAttributeArchiveDestroy', 'picAttributeId', picAttributeId)
            const localVarPath = `/v2/org/{buyer_org_id}/pic/attribute/{pic_attribute_id}/archive`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"pic_attribute_id"}}`, encodeURIComponent(String(picAttributeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new PIC Attribute
         * @param {string} buyerOrgId 
         * @param {PICAttribute} pICAttribute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAttributeCreate: async (buyerOrgId: string, pICAttribute: PICAttribute, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPicAttributeCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'pICAttribute' is not null or undefined
            assertParamExists('orgPicAttributeCreate', 'pICAttribute', pICAttribute)
            const localVarPath = `/v2/org/{buyer_org_id}/pic/attribute`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pICAttribute, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Defaults:  `include_archived=false`  `include_inactive=false` 
         * @summary Get a list of all Attributes within an Organisation
         * @param {string} buyerOrgId 
         * @param {string} [includeArchived] To include archived PICs. Default: false
         * @param {string} [includeInactive] To include inactive PICs. Default: false
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAttributeList: async (buyerOrgId: string, includeArchived?: string, includeInactive?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPicAttributeList', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/pic/attribute`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeArchived !== undefined) {
                localVarQueryParameter['include_archived'] = includeArchived;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['include_inactive'] = includeInactive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Partially update the details of a Attribute
         * @param {string} buyerOrgId 
         * @param {string} picAttributeId 
         * @param {PatchedPICAttribute} [patchedPICAttribute] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAttributePartialUpdate: async (buyerOrgId: string, picAttributeId: string, patchedPICAttribute?: PatchedPICAttribute, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPicAttributePartialUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'picAttributeId' is not null or undefined
            assertParamExists('orgPicAttributePartialUpdate', 'picAttributeId', picAttributeId)
            const localVarPath = `/v2/org/{buyer_org_id}/pic/attribute/{pic_attribute_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"pic_attribute_id"}}`, encodeURIComponent(String(picAttributeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedPICAttribute, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of an Attribute
         * @param {string} buyerOrgId 
         * @param {string} picAttributeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAttributeRetrieve: async (buyerOrgId: string, picAttributeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPicAttributeRetrieve', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'picAttributeId' is not null or undefined
            assertParamExists('orgPicAttributeRetrieve', 'picAttributeId', picAttributeId)
            const localVarPath = `/v2/org/{buyer_org_id}/pic/attribute/{pic_attribute_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"pic_attribute_id"}}`, encodeURIComponent(String(picAttributeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the details of an Attribute
         * @param {string} buyerOrgId 
         * @param {string} picAttributeId 
         * @param {PICAttribute} pICAttribute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAttributeUpdate: async (buyerOrgId: string, picAttributeId: string, pICAttribute: PICAttribute, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPicAttributeUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'picAttributeId' is not null or undefined
            assertParamExists('orgPicAttributeUpdate', 'picAttributeId', picAttributeId)
            // verify required parameter 'pICAttribute' is not null or undefined
            assertParamExists('orgPicAttributeUpdate', 'pICAttribute', pICAttribute)
            const localVarPath = `/v2/org/{buyer_org_id}/pic/attribute/{pic_attribute_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"pic_attribute_id"}}`, encodeURIComponent(String(picAttributeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pICAttribute, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationPICAttributeApi - functional programming interface
 * @export
 */
export const OrganisationPICAttributeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationPICAttributeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive an Attribute
         * @param {string} buyerOrgId 
         * @param {string} picAttributeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPicAttributeArchiveCreate(buyerOrgId: string, picAttributeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PICAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPicAttributeArchiveCreate(buyerOrgId, picAttributeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the archived state on an Attribute.
         * @param {string} buyerOrgId 
         * @param {string} picAttributeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPicAttributeArchiveDestroy(buyerOrgId: string, picAttributeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PICAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPicAttributeArchiveDestroy(buyerOrgId, picAttributeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new PIC Attribute
         * @param {string} buyerOrgId 
         * @param {PICAttribute} pICAttribute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPicAttributeCreate(buyerOrgId: string, pICAttribute: PICAttribute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PICAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPicAttributeCreate(buyerOrgId, pICAttribute, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Defaults:  `include_archived=false`  `include_inactive=false` 
         * @summary Get a list of all Attributes within an Organisation
         * @param {string} buyerOrgId 
         * @param {string} [includeArchived] To include archived PICs. Default: false
         * @param {string} [includeInactive] To include inactive PICs. Default: false
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPicAttributeList(buyerOrgId: string, includeArchived?: string, includeInactive?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPICAttributeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPicAttributeList(buyerOrgId, includeArchived, includeInactive, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Partially update the details of a Attribute
         * @param {string} buyerOrgId 
         * @param {string} picAttributeId 
         * @param {PatchedPICAttribute} [patchedPICAttribute] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPicAttributePartialUpdate(buyerOrgId: string, picAttributeId: string, patchedPICAttribute?: PatchedPICAttribute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PICAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPicAttributePartialUpdate(buyerOrgId, picAttributeId, patchedPICAttribute, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of an Attribute
         * @param {string} buyerOrgId 
         * @param {string} picAttributeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPicAttributeRetrieve(buyerOrgId: string, picAttributeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PICAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPicAttributeRetrieve(buyerOrgId, picAttributeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the details of an Attribute
         * @param {string} buyerOrgId 
         * @param {string} picAttributeId 
         * @param {PICAttribute} pICAttribute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPicAttributeUpdate(buyerOrgId: string, picAttributeId: string, pICAttribute: PICAttribute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PICAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPicAttributeUpdate(buyerOrgId, picAttributeId, pICAttribute, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationPICAttributeApi - factory interface
 * @export
 */
export const OrganisationPICAttributeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationPICAttributeApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive an Attribute
         * @param {string} buyerOrgId 
         * @param {string} picAttributeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAttributeArchiveCreate(buyerOrgId: string, picAttributeId: string, options?: any): AxiosPromise<PICAttribute> {
            return localVarFp.orgPicAttributeArchiveCreate(buyerOrgId, picAttributeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the archived state on an Attribute.
         * @param {string} buyerOrgId 
         * @param {string} picAttributeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAttributeArchiveDestroy(buyerOrgId: string, picAttributeId: string, options?: any): AxiosPromise<PICAttribute> {
            return localVarFp.orgPicAttributeArchiveDestroy(buyerOrgId, picAttributeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new PIC Attribute
         * @param {string} buyerOrgId 
         * @param {PICAttribute} pICAttribute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAttributeCreate(buyerOrgId: string, pICAttribute: PICAttribute, options?: any): AxiosPromise<PICAttribute> {
            return localVarFp.orgPicAttributeCreate(buyerOrgId, pICAttribute, options).then((request) => request(axios, basePath));
        },
        /**
         * Defaults:  `include_archived=false`  `include_inactive=false` 
         * @summary Get a list of all Attributes within an Organisation
         * @param {string} buyerOrgId 
         * @param {string} [includeArchived] To include archived PICs. Default: false
         * @param {string} [includeInactive] To include inactive PICs. Default: false
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAttributeList(buyerOrgId: string, includeArchived?: string, includeInactive?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedPICAttributeList> {
            return localVarFp.orgPicAttributeList(buyerOrgId, includeArchived, includeInactive, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Partially update the details of a Attribute
         * @param {string} buyerOrgId 
         * @param {string} picAttributeId 
         * @param {PatchedPICAttribute} [patchedPICAttribute] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAttributePartialUpdate(buyerOrgId: string, picAttributeId: string, patchedPICAttribute?: PatchedPICAttribute, options?: any): AxiosPromise<PICAttribute> {
            return localVarFp.orgPicAttributePartialUpdate(buyerOrgId, picAttributeId, patchedPICAttribute, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of an Attribute
         * @param {string} buyerOrgId 
         * @param {string} picAttributeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAttributeRetrieve(buyerOrgId: string, picAttributeId: string, options?: any): AxiosPromise<PICAttribute> {
            return localVarFp.orgPicAttributeRetrieve(buyerOrgId, picAttributeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the details of an Attribute
         * @param {string} buyerOrgId 
         * @param {string} picAttributeId 
         * @param {PICAttribute} pICAttribute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPicAttributeUpdate(buyerOrgId: string, picAttributeId: string, pICAttribute: PICAttribute, options?: any): AxiosPromise<PICAttribute> {
            return localVarFp.orgPicAttributeUpdate(buyerOrgId, picAttributeId, pICAttribute, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgPicAttributeArchiveCreate operation in OrganisationPICAttributeApi.
 * @export
 * @interface OrganisationPICAttributeApiOrgPicAttributeArchiveCreateRequest
 */
export interface OrganisationPICAttributeApiOrgPicAttributeArchiveCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeArchiveCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeArchiveCreate
     */
    readonly picAttributeId: string
}

/**
 * Request parameters for orgPicAttributeArchiveDestroy operation in OrganisationPICAttributeApi.
 * @export
 * @interface OrganisationPICAttributeApiOrgPicAttributeArchiveDestroyRequest
 */
export interface OrganisationPICAttributeApiOrgPicAttributeArchiveDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeArchiveDestroy
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeArchiveDestroy
     */
    readonly picAttributeId: string
}

/**
 * Request parameters for orgPicAttributeCreate operation in OrganisationPICAttributeApi.
 * @export
 * @interface OrganisationPICAttributeApiOrgPicAttributeCreateRequest
 */
export interface OrganisationPICAttributeApiOrgPicAttributeCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {PICAttribute}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeCreate
     */
    readonly pICAttribute: PICAttribute
}

/**
 * Request parameters for orgPicAttributeList operation in OrganisationPICAttributeApi.
 * @export
 * @interface OrganisationPICAttributeApiOrgPicAttributeListRequest
 */
export interface OrganisationPICAttributeApiOrgPicAttributeListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeList
     */
    readonly buyerOrgId: string

    /**
     * To include archived PICs. Default: false
     * @type {string}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeList
     */
    readonly includeArchived?: string

    /**
     * To include inactive PICs. Default: false
     * @type {string}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeList
     */
    readonly includeInactive?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for orgPicAttributePartialUpdate operation in OrganisationPICAttributeApi.
 * @export
 * @interface OrganisationPICAttributeApiOrgPicAttributePartialUpdateRequest
 */
export interface OrganisationPICAttributeApiOrgPicAttributePartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPICAttributeApiOrgPicAttributePartialUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPICAttributeApiOrgPicAttributePartialUpdate
     */
    readonly picAttributeId: string

    /**
     * 
     * @type {PatchedPICAttribute}
     * @memberof OrganisationPICAttributeApiOrgPicAttributePartialUpdate
     */
    readonly patchedPICAttribute?: PatchedPICAttribute
}

/**
 * Request parameters for orgPicAttributeRetrieve operation in OrganisationPICAttributeApi.
 * @export
 * @interface OrganisationPICAttributeApiOrgPicAttributeRetrieveRequest
 */
export interface OrganisationPICAttributeApiOrgPicAttributeRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeRetrieve
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeRetrieve
     */
    readonly picAttributeId: string
}

/**
 * Request parameters for orgPicAttributeUpdate operation in OrganisationPICAttributeApi.
 * @export
 * @interface OrganisationPICAttributeApiOrgPicAttributeUpdateRequest
 */
export interface OrganisationPICAttributeApiOrgPicAttributeUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeUpdate
     */
    readonly picAttributeId: string

    /**
     * 
     * @type {PICAttribute}
     * @memberof OrganisationPICAttributeApiOrgPicAttributeUpdate
     */
    readonly pICAttribute: PICAttribute
}

/**
 * OrganisationPICAttributeApi - object-oriented interface
 * @export
 * @class OrganisationPICAttributeApi
 * @extends {BaseAPI}
 */
export class OrganisationPICAttributeApi extends BaseAPI {
    /**
     * 
     * @summary Archive an Attribute
     * @param {OrganisationPICAttributeApiOrgPicAttributeArchiveCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPICAttributeApi
     */
    public orgPicAttributeArchiveCreate(requestParameters: OrganisationPICAttributeApiOrgPicAttributeArchiveCreateRequest, options?: any) {
        return OrganisationPICAttributeApiFp(this.configuration).orgPicAttributeArchiveCreate(requestParameters.buyerOrgId, requestParameters.picAttributeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the archived state on an Attribute.
     * @param {OrganisationPICAttributeApiOrgPicAttributeArchiveDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPICAttributeApi
     */
    public orgPicAttributeArchiveDestroy(requestParameters: OrganisationPICAttributeApiOrgPicAttributeArchiveDestroyRequest, options?: any) {
        return OrganisationPICAttributeApiFp(this.configuration).orgPicAttributeArchiveDestroy(requestParameters.buyerOrgId, requestParameters.picAttributeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new PIC Attribute
     * @param {OrganisationPICAttributeApiOrgPicAttributeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPICAttributeApi
     */
    public orgPicAttributeCreate(requestParameters: OrganisationPICAttributeApiOrgPicAttributeCreateRequest, options?: any) {
        return OrganisationPICAttributeApiFp(this.configuration).orgPicAttributeCreate(requestParameters.buyerOrgId, requestParameters.pICAttribute, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Defaults:  `include_archived=false`  `include_inactive=false` 
     * @summary Get a list of all Attributes within an Organisation
     * @param {OrganisationPICAttributeApiOrgPicAttributeListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPICAttributeApi
     */
    public orgPicAttributeList(requestParameters: OrganisationPICAttributeApiOrgPicAttributeListRequest, options?: any) {
        return OrganisationPICAttributeApiFp(this.configuration).orgPicAttributeList(requestParameters.buyerOrgId, requestParameters.includeArchived, requestParameters.includeInactive, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Partially update the details of a Attribute
     * @param {OrganisationPICAttributeApiOrgPicAttributePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPICAttributeApi
     */
    public orgPicAttributePartialUpdate(requestParameters: OrganisationPICAttributeApiOrgPicAttributePartialUpdateRequest, options?: any) {
        return OrganisationPICAttributeApiFp(this.configuration).orgPicAttributePartialUpdate(requestParameters.buyerOrgId, requestParameters.picAttributeId, requestParameters.patchedPICAttribute, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of an Attribute
     * @param {OrganisationPICAttributeApiOrgPicAttributeRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPICAttributeApi
     */
    public orgPicAttributeRetrieve(requestParameters: OrganisationPICAttributeApiOrgPicAttributeRetrieveRequest, options?: any) {
        return OrganisationPICAttributeApiFp(this.configuration).orgPicAttributeRetrieve(requestParameters.buyerOrgId, requestParameters.picAttributeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the details of an Attribute
     * @param {OrganisationPICAttributeApiOrgPicAttributeUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPICAttributeApi
     */
    public orgPicAttributeUpdate(requestParameters: OrganisationPICAttributeApiOrgPicAttributeUpdateRequest, options?: any) {
        return OrganisationPICAttributeApiFp(this.configuration).orgPicAttributeUpdate(requestParameters.buyerOrgId, requestParameters.picAttributeId, requestParameters.pICAttribute, options).then((request) => request(this.axios, this.basePath));
    }
}
