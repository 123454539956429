import { rgba } from 'polished';
import { FC } from 'react';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Text } from '../text';
import { TextButton } from '../text-button';

export interface MessageProps {}

export const Message = styled(Text)<MessageProps>`
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 ${rgba(globalTheme.colors.darkGrey, 0.05)};

  ${TextButton} {
    color: inherit;
    font-weight: inherit;
    font-size: 100%;
  }
`;

export enum ErrorMessageType {
  pdfError = 'pdfError',
}

export const ErrorMessage: FC<{
  className?: string;
  type?: ErrorMessageType;
}> = ({ children, className, type }) => {
  return (
    <Message
      className={className}
      color='pink'
      backgroundColor='lightPink'
      data-cy='error-message'
      data-state='error'
      data-type={type}
    >
      {children}
    </Message>
  );
};

Message.defaultProps = {
  padding: 2,
  fontWeight: 'bold',
  color: 'darkGrey',
  backgroundColor: 'neutral',
  fontSize: 2,
  // @ts-ignore
  as: 'div',
};
