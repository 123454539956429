import { motion, Variants } from 'framer-motion';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { useMedia } from 'react-use';
import { PATHS } from 'settings/config';
import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { globalTheme } from '../../styles/global-theme';
import { LogoLight } from '../images';

const StyledLogo = styled.div`
  width: 70px;
  margin-right: ${globalTheme.space[4]};

  @media screen and (max-width: ${breakpoints.large}) {
    margin: 0 ${globalTheme.space[2]};
  }
`;

const StyledHeader = styled.header`
  height: ${globalTheme.sizes.header};
  background-color: ${globalTheme.colors.darkBlue};
  z-index: 1;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.12) 2px 0px 10px 0px;
  gap: ${globalTheme.space[5]};
`;

const NavWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: inherit;
  width: 100%;
  padding: 0 ${globalTheme.space[4]};
  background-color: ${globalTheme.colors.darkBlue};
  position: relative;
  z-index: 1;
`;

const MobileNav = styled(motion.nav)`
  display: flex;
  flex-direction: column;
  background-color: ${globalTheme.colors.purple};
  width: 100%;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${globalTheme.space[2]};
`;
const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${globalTheme.space[2]};
`;

const LiveStockLink = styled.p`
  font-size: ${globalTheme.fontSizes[2]};
  color: white;
  cursor: pointer;
`;

const RightLink = styled.p`
  font-size: ${globalTheme.fontSizes[1]};
  color: white;
  cursor: pointer;
  text-decoration: underline;
  @media screen and (max-width: 992px) {
    padding: ${globalTheme.space[1]};
    text-decoration: none;
    font-size: 1rem;
    border-bottom: #951ca8 1px solid;
  }
`;

const variants: Variants = {
  closed: {
    y: '-100%',
    transition: { duration: 0.15 },
  },
  open: {
    y: 0,
    transition: { duration: 0.15 },
  },
};

const PublicHeader: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const isMobile = useMedia(`(max-width: 992px)`);
  const router = useRouter();

  // TODO: it works for now but see & remove the client side api call
  // const [hydrated, setHydrated] = useState(false);

  // useEffect(() => {
  //   // this forces a rerender
  //   setHydrated(true);
  // }, []);

  // if (!hydrated) {
  //   // this returns null on first render, so the client and server match
  //   return null;
  // }

  const handleMenuClick = (path: string) => {
    router.push(path);
    setIsMenuOpen(false);
  };

  return (
    <StyledHeader>
      <NavWrap>
        {isMobile &&
          (isMenuOpen ? (
            <FiX
              color={'white'}
              size={globalTheme.fontSizes[5]}
              onClick={() => setIsMenuOpen((isMenuOpen) => !isMenuOpen)}
            />
          ) : (
            <FiMenu
              color={'white'}
              size={globalTheme.fontSizes[5]}
              onClick={() => setIsMenuOpen((isMenuOpen) => !isMenuOpen)}
            />
          ))}

        {isMobile && (
          <StyledLogo>
            <LogoLight />
          </StyledLogo>
        )}
        {!isMobile && (
          <>
            <LeftSection>
              <StyledLogo>
                <LogoLight />
              </StyledLogo>
            </LeftSection>
            <RightSection>
              <RightLink onClick={() => handleMenuClick(PATHS.auth.logIn)}>
                Login
              </RightLink>
              <RightLink onClick={() => handleMenuClick(PATHS.auth.signUp)}>
                SignUp
              </RightLink>
            </RightSection>
          </>
        )}
      </NavWrap>
      {isMobile && (
        <MobileNav
          variants={variants}
          initial={'closed'}
          animate={isMenuOpen ? 'open' : 'closed'}
        >
          <RightLink onClick={() => handleMenuClick(PATHS.auth.logIn)}>
            Login
          </RightLink>
          <RightLink onClick={() => handleMenuClick(PATHS.auth.signUp)}>
            SignUp
          </RightLink>
        </MobileNav>
      )}
    </StyledHeader>
  );
};

export default PublicHeader;
