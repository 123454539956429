/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { BuyerSummaryGrid } from '../model';
// @ts-ignore
import { BuyerSummaryGridDuplicate } from '../model';
// @ts-ignore
import { BuyerSummaryGridWrite } from '../model';
// @ts-ignore
import { PaginatedBuyerSummaryGridList } from '../model';
// @ts-ignore
import { PatchedBuyerSummaryGridWrite } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * OrganisationSummaryGridApi - axios parameter creator
 * @export
 */
export const OrganisationSummaryGridApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new Summary Grid
         * @param {string} buyerOrgId 
         * @param {BuyerSummaryGridWrite} buyerSummaryGridWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridCreate: async (buyerOrgId: string, buyerSummaryGridWrite: BuyerSummaryGridWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgSummaryGridCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'buyerSummaryGridWrite' is not null or undefined
            assertParamExists('orgSummaryGridCreate', 'buyerSummaryGridWrite', buyerSummaryGridWrite)
            const localVarPath = `/v2/org/{buyer_org_id}/summary-grid`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerSummaryGridWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Summary Grid
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridDestroy: async (buyerOrgId: string, summaryGridId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgSummaryGridDestroy', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'summaryGridId' is not null or undefined
            assertParamExists('orgSummaryGridDestroy', 'summaryGridId', summaryGridId)
            const localVarPath = `/v2/org/{buyer_org_id}/summary-grid/{summary_grid_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"summary_grid_id"}}`, encodeURIComponent(String(summaryGridId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Duplicate the Summary Grid
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {BuyerSummaryGridDuplicate} buyerSummaryGridDuplicate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridDuplicateCreate: async (buyerOrgId: string, summaryGridId: string, buyerSummaryGridDuplicate: BuyerSummaryGridDuplicate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgSummaryGridDuplicateCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'summaryGridId' is not null or undefined
            assertParamExists('orgSummaryGridDuplicateCreate', 'summaryGridId', summaryGridId)
            // verify required parameter 'buyerSummaryGridDuplicate' is not null or undefined
            assertParamExists('orgSummaryGridDuplicateCreate', 'buyerSummaryGridDuplicate', buyerSummaryGridDuplicate)
            const localVarPath = `/v2/org/{buyer_org_id}/summary-grid/{summary_grid_id}/duplicate`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"summary_grid_id"}}`, encodeURIComponent(String(summaryGridId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerSummaryGridDuplicate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the list of Summary Grids under the organisation.  # Filtering Options  ## Fields - search str: case insensitive against the name and grid reference, eg ?search=cattle  ## Ordering default: -created  - created: on the created timestamp - name: based on the name, eg ?ordering=name, can be reversed. eg ?ordering=-name  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of Summary Grids under the Organisation
         * @param {string} buyerOrgId 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridList: async (buyerOrgId: string, ordering?: string, page?: number, pageSize?: number, search?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgSummaryGridList', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/summary-grid`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the newly updated details of the Summary Grid
         * @summary Update partial details of the Summary Grid
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {PatchedBuyerSummaryGridWrite} [patchedBuyerSummaryGridWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridPartialUpdate: async (buyerOrgId: string, summaryGridId: string, patchedBuyerSummaryGridWrite?: PatchedBuyerSummaryGridWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgSummaryGridPartialUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'summaryGridId' is not null or undefined
            assertParamExists('orgSummaryGridPartialUpdate', 'summaryGridId', summaryGridId)
            const localVarPath = `/v2/org/{buyer_org_id}/summary-grid/{summary_grid_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"summary_grid_id"}}`, encodeURIComponent(String(summaryGridId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedBuyerSummaryGridWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate the Summary Grid PDF and force it to download
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridPdfCreate: async (buyerOrgId: string, summaryGridId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgSummaryGridPdfCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'summaryGridId' is not null or undefined
            assertParamExists('orgSummaryGridPdfCreate', 'summaryGridId', summaryGridId)
            const localVarPath = `/v2/org/{buyer_org_id}/summary-grid/{summary_grid_id}/pdf`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"summary_grid_id"}}`, encodeURIComponent(String(summaryGridId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of a Summary Grid
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridRetrieve: async (buyerOrgId: string, summaryGridId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgSummaryGridRetrieve', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'summaryGridId' is not null or undefined
            assertParamExists('orgSummaryGridRetrieve', 'summaryGridId', summaryGridId)
            const localVarPath = `/v2/org/{buyer_org_id}/summary-grid/{summary_grid_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"summary_grid_id"}}`, encodeURIComponent(String(summaryGridId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the newly updated details of the Summary Grid
         * @summary Update the details of the Summary Grid
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {BuyerSummaryGridWrite} buyerSummaryGridWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridUpdate: async (buyerOrgId: string, summaryGridId: string, buyerSummaryGridWrite: BuyerSummaryGridWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgSummaryGridUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'summaryGridId' is not null or undefined
            assertParamExists('orgSummaryGridUpdate', 'summaryGridId', summaryGridId)
            // verify required parameter 'buyerSummaryGridWrite' is not null or undefined
            assertParamExists('orgSummaryGridUpdate', 'buyerSummaryGridWrite', buyerSummaryGridWrite)
            const localVarPath = `/v2/org/{buyer_org_id}/summary-grid/{summary_grid_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"summary_grid_id"}}`, encodeURIComponent(String(summaryGridId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerSummaryGridWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationSummaryGridApi - functional programming interface
 * @export
 */
export const OrganisationSummaryGridApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationSummaryGridApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new Summary Grid
         * @param {string} buyerOrgId 
         * @param {BuyerSummaryGridWrite} buyerSummaryGridWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgSummaryGridCreate(buyerOrgId: string, buyerSummaryGridWrite: BuyerSummaryGridWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerSummaryGrid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgSummaryGridCreate(buyerOrgId, buyerSummaryGridWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Summary Grid
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgSummaryGridDestroy(buyerOrgId: string, summaryGridId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgSummaryGridDestroy(buyerOrgId, summaryGridId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Duplicate the Summary Grid
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {BuyerSummaryGridDuplicate} buyerSummaryGridDuplicate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgSummaryGridDuplicateCreate(buyerOrgId: string, summaryGridId: string, buyerSummaryGridDuplicate: BuyerSummaryGridDuplicate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerSummaryGrid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgSummaryGridDuplicateCreate(buyerOrgId, summaryGridId, buyerSummaryGridDuplicate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the list of Summary Grids under the organisation.  # Filtering Options  ## Fields - search str: case insensitive against the name and grid reference, eg ?search=cattle  ## Ordering default: -created  - created: on the created timestamp - name: based on the name, eg ?ordering=name, can be reversed. eg ?ordering=-name  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of Summary Grids under the Organisation
         * @param {string} buyerOrgId 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgSummaryGridList(buyerOrgId: string, ordering?: string, page?: number, pageSize?: number, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedBuyerSummaryGridList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgSummaryGridList(buyerOrgId, ordering, page, pageSize, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the newly updated details of the Summary Grid
         * @summary Update partial details of the Summary Grid
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {PatchedBuyerSummaryGridWrite} [patchedBuyerSummaryGridWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgSummaryGridPartialUpdate(buyerOrgId: string, summaryGridId: string, patchedBuyerSummaryGridWrite?: PatchedBuyerSummaryGridWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerSummaryGrid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgSummaryGridPartialUpdate(buyerOrgId, summaryGridId, patchedBuyerSummaryGridWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate the Summary Grid PDF and force it to download
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgSummaryGridPdfCreate(buyerOrgId: string, summaryGridId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgSummaryGridPdfCreate(buyerOrgId, summaryGridId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of a Summary Grid
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgSummaryGridRetrieve(buyerOrgId: string, summaryGridId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerSummaryGrid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgSummaryGridRetrieve(buyerOrgId, summaryGridId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the newly updated details of the Summary Grid
         * @summary Update the details of the Summary Grid
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {BuyerSummaryGridWrite} buyerSummaryGridWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgSummaryGridUpdate(buyerOrgId: string, summaryGridId: string, buyerSummaryGridWrite: BuyerSummaryGridWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerSummaryGrid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgSummaryGridUpdate(buyerOrgId, summaryGridId, buyerSummaryGridWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationSummaryGridApi - factory interface
 * @export
 */
export const OrganisationSummaryGridApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationSummaryGridApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new Summary Grid
         * @param {string} buyerOrgId 
         * @param {BuyerSummaryGridWrite} buyerSummaryGridWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridCreate(buyerOrgId: string, buyerSummaryGridWrite: BuyerSummaryGridWrite, options?: any): AxiosPromise<BuyerSummaryGrid> {
            return localVarFp.orgSummaryGridCreate(buyerOrgId, buyerSummaryGridWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Summary Grid
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridDestroy(buyerOrgId: string, summaryGridId: string, options?: any): AxiosPromise<void> {
            return localVarFp.orgSummaryGridDestroy(buyerOrgId, summaryGridId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Duplicate the Summary Grid
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {BuyerSummaryGridDuplicate} buyerSummaryGridDuplicate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridDuplicateCreate(buyerOrgId: string, summaryGridId: string, buyerSummaryGridDuplicate: BuyerSummaryGridDuplicate, options?: any): AxiosPromise<BuyerSummaryGrid> {
            return localVarFp.orgSummaryGridDuplicateCreate(buyerOrgId, summaryGridId, buyerSummaryGridDuplicate, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the list of Summary Grids under the organisation.  # Filtering Options  ## Fields - search str: case insensitive against the name and grid reference, eg ?search=cattle  ## Ordering default: -created  - created: on the created timestamp - name: based on the name, eg ?ordering=name, can be reversed. eg ?ordering=-name  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of Summary Grids under the Organisation
         * @param {string} buyerOrgId 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridList(buyerOrgId: string, ordering?: string, page?: number, pageSize?: number, search?: string, options?: any): AxiosPromise<PaginatedBuyerSummaryGridList> {
            return localVarFp.orgSummaryGridList(buyerOrgId, ordering, page, pageSize, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the newly updated details of the Summary Grid
         * @summary Update partial details of the Summary Grid
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {PatchedBuyerSummaryGridWrite} [patchedBuyerSummaryGridWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridPartialUpdate(buyerOrgId: string, summaryGridId: string, patchedBuyerSummaryGridWrite?: PatchedBuyerSummaryGridWrite, options?: any): AxiosPromise<BuyerSummaryGrid> {
            return localVarFp.orgSummaryGridPartialUpdate(buyerOrgId, summaryGridId, patchedBuyerSummaryGridWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate the Summary Grid PDF and force it to download
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridPdfCreate(buyerOrgId: string, summaryGridId: string, options?: any): AxiosPromise<any> {
            return localVarFp.orgSummaryGridPdfCreate(buyerOrgId, summaryGridId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of a Summary Grid
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridRetrieve(buyerOrgId: string, summaryGridId: string, options?: any): AxiosPromise<BuyerSummaryGrid> {
            return localVarFp.orgSummaryGridRetrieve(buyerOrgId, summaryGridId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the newly updated details of the Summary Grid
         * @summary Update the details of the Summary Grid
         * @param {string} buyerOrgId 
         * @param {string} summaryGridId 
         * @param {BuyerSummaryGridWrite} buyerSummaryGridWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgSummaryGridUpdate(buyerOrgId: string, summaryGridId: string, buyerSummaryGridWrite: BuyerSummaryGridWrite, options?: any): AxiosPromise<BuyerSummaryGrid> {
            return localVarFp.orgSummaryGridUpdate(buyerOrgId, summaryGridId, buyerSummaryGridWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgSummaryGridCreate operation in OrganisationSummaryGridApi.
 * @export
 * @interface OrganisationSummaryGridApiOrgSummaryGridCreateRequest
 */
export interface OrganisationSummaryGridApiOrgSummaryGridCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {BuyerSummaryGridWrite}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridCreate
     */
    readonly buyerSummaryGridWrite: BuyerSummaryGridWrite
}

/**
 * Request parameters for orgSummaryGridDestroy operation in OrganisationSummaryGridApi.
 * @export
 * @interface OrganisationSummaryGridApiOrgSummaryGridDestroyRequest
 */
export interface OrganisationSummaryGridApiOrgSummaryGridDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridDestroy
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridDestroy
     */
    readonly summaryGridId: string
}

/**
 * Request parameters for orgSummaryGridDuplicateCreate operation in OrganisationSummaryGridApi.
 * @export
 * @interface OrganisationSummaryGridApiOrgSummaryGridDuplicateCreateRequest
 */
export interface OrganisationSummaryGridApiOrgSummaryGridDuplicateCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridDuplicateCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridDuplicateCreate
     */
    readonly summaryGridId: string

    /**
     * 
     * @type {BuyerSummaryGridDuplicate}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridDuplicateCreate
     */
    readonly buyerSummaryGridDuplicate: BuyerSummaryGridDuplicate
}

/**
 * Request parameters for orgSummaryGridList operation in OrganisationSummaryGridApi.
 * @export
 * @interface OrganisationSummaryGridApiOrgSummaryGridListRequest
 */
export interface OrganisationSummaryGridApiOrgSummaryGridListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridList
     */
    readonly buyerOrgId: string

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridList
     */
    readonly pageSize?: number

    /**
     * A search term.
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridList
     */
    readonly search?: string
}

/**
 * Request parameters for orgSummaryGridPartialUpdate operation in OrganisationSummaryGridApi.
 * @export
 * @interface OrganisationSummaryGridApiOrgSummaryGridPartialUpdateRequest
 */
export interface OrganisationSummaryGridApiOrgSummaryGridPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridPartialUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridPartialUpdate
     */
    readonly summaryGridId: string

    /**
     * 
     * @type {PatchedBuyerSummaryGridWrite}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridPartialUpdate
     */
    readonly patchedBuyerSummaryGridWrite?: PatchedBuyerSummaryGridWrite
}

/**
 * Request parameters for orgSummaryGridPdfCreate operation in OrganisationSummaryGridApi.
 * @export
 * @interface OrganisationSummaryGridApiOrgSummaryGridPdfCreateRequest
 */
export interface OrganisationSummaryGridApiOrgSummaryGridPdfCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridPdfCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridPdfCreate
     */
    readonly summaryGridId: string
}

/**
 * Request parameters for orgSummaryGridRetrieve operation in OrganisationSummaryGridApi.
 * @export
 * @interface OrganisationSummaryGridApiOrgSummaryGridRetrieveRequest
 */
export interface OrganisationSummaryGridApiOrgSummaryGridRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridRetrieve
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridRetrieve
     */
    readonly summaryGridId: string
}

/**
 * Request parameters for orgSummaryGridUpdate operation in OrganisationSummaryGridApi.
 * @export
 * @interface OrganisationSummaryGridApiOrgSummaryGridUpdateRequest
 */
export interface OrganisationSummaryGridApiOrgSummaryGridUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridUpdate
     */
    readonly summaryGridId: string

    /**
     * 
     * @type {BuyerSummaryGridWrite}
     * @memberof OrganisationSummaryGridApiOrgSummaryGridUpdate
     */
    readonly buyerSummaryGridWrite: BuyerSummaryGridWrite
}

/**
 * OrganisationSummaryGridApi - object-oriented interface
 * @export
 * @class OrganisationSummaryGridApi
 * @extends {BaseAPI}
 */
export class OrganisationSummaryGridApi extends BaseAPI {
    /**
     * 
     * @summary Create a new Summary Grid
     * @param {OrganisationSummaryGridApiOrgSummaryGridCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationSummaryGridApi
     */
    public orgSummaryGridCreate(requestParameters: OrganisationSummaryGridApiOrgSummaryGridCreateRequest, options?: any) {
        return OrganisationSummaryGridApiFp(this.configuration).orgSummaryGridCreate(requestParameters.buyerOrgId, requestParameters.buyerSummaryGridWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Summary Grid
     * @param {OrganisationSummaryGridApiOrgSummaryGridDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationSummaryGridApi
     */
    public orgSummaryGridDestroy(requestParameters: OrganisationSummaryGridApiOrgSummaryGridDestroyRequest, options?: any) {
        return OrganisationSummaryGridApiFp(this.configuration).orgSummaryGridDestroy(requestParameters.buyerOrgId, requestParameters.summaryGridId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Duplicate the Summary Grid
     * @param {OrganisationSummaryGridApiOrgSummaryGridDuplicateCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationSummaryGridApi
     */
    public orgSummaryGridDuplicateCreate(requestParameters: OrganisationSummaryGridApiOrgSummaryGridDuplicateCreateRequest, options?: any) {
        return OrganisationSummaryGridApiFp(this.configuration).orgSummaryGridDuplicateCreate(requestParameters.buyerOrgId, requestParameters.summaryGridId, requestParameters.buyerSummaryGridDuplicate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the list of Summary Grids under the organisation.  # Filtering Options  ## Fields - search str: case insensitive against the name and grid reference, eg ?search=cattle  ## Ordering default: -created  - created: on the created timestamp - name: based on the name, eg ?ordering=name, can be reversed. eg ?ordering=-name  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
     * @summary Get a list of Summary Grids under the Organisation
     * @param {OrganisationSummaryGridApiOrgSummaryGridListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationSummaryGridApi
     */
    public orgSummaryGridList(requestParameters: OrganisationSummaryGridApiOrgSummaryGridListRequest, options?: any) {
        return OrganisationSummaryGridApiFp(this.configuration).orgSummaryGridList(requestParameters.buyerOrgId, requestParameters.ordering, requestParameters.page, requestParameters.pageSize, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the newly updated details of the Summary Grid
     * @summary Update partial details of the Summary Grid
     * @param {OrganisationSummaryGridApiOrgSummaryGridPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationSummaryGridApi
     */
    public orgSummaryGridPartialUpdate(requestParameters: OrganisationSummaryGridApiOrgSummaryGridPartialUpdateRequest, options?: any) {
        return OrganisationSummaryGridApiFp(this.configuration).orgSummaryGridPartialUpdate(requestParameters.buyerOrgId, requestParameters.summaryGridId, requestParameters.patchedBuyerSummaryGridWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate the Summary Grid PDF and force it to download
     * @param {OrganisationSummaryGridApiOrgSummaryGridPdfCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationSummaryGridApi
     */
    public orgSummaryGridPdfCreate(requestParameters: OrganisationSummaryGridApiOrgSummaryGridPdfCreateRequest, options?: any) {
        return OrganisationSummaryGridApiFp(this.configuration).orgSummaryGridPdfCreate(requestParameters.buyerOrgId, requestParameters.summaryGridId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of a Summary Grid
     * @param {OrganisationSummaryGridApiOrgSummaryGridRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationSummaryGridApi
     */
    public orgSummaryGridRetrieve(requestParameters: OrganisationSummaryGridApiOrgSummaryGridRetrieveRequest, options?: any) {
        return OrganisationSummaryGridApiFp(this.configuration).orgSummaryGridRetrieve(requestParameters.buyerOrgId, requestParameters.summaryGridId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the newly updated details of the Summary Grid
     * @summary Update the details of the Summary Grid
     * @param {OrganisationSummaryGridApiOrgSummaryGridUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationSummaryGridApi
     */
    public orgSummaryGridUpdate(requestParameters: OrganisationSummaryGridApiOrgSummaryGridUpdateRequest, options?: any) {
        return OrganisationSummaryGridApiFp(this.configuration).orgSummaryGridUpdate(requestParameters.buyerOrgId, requestParameters.summaryGridId, requestParameters.buyerSummaryGridWrite, options).then((request) => request(this.axios, this.basePath));
    }
}
