import { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Icon } from '../icon';

export interface NavigationButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  direction: 'back' | 'next';
}

const Button = styled.button`
  display: inline-flex;
  gap: ${globalTheme.space[0]};
  align-items: center;
  font-size: ${globalTheme.fontSizes.default};
  text-transform: capitalize;
  background: none;
  border: 0;
  cursor: pointer;
`;

export const NavigationButton: FC<NavigationButtonProps> = ({
  direction,
  ...buttonProps
}) => {
  return (
    <Button {...buttonProps}>
      {direction === 'back' && <Icon component='ChevronBackIcon' />} {direction}{' '}
      {direction === 'next' && <Icon component='ChevronNextIcon' />}
    </Button>
  );
};
