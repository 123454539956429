import { FC, HTMLAttributes } from 'react';
import styled, { css, StyledComponent } from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';

export type HeadingLevels = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  level: HeadingLevels;
  as?: HeadingLevels;
}

type StyledHeadingProps = Omit<HeadingProps, 'level'>;

const baseStyles = css`
  margin: 0;
  font-weight: ${globalTheme.fontWeights.regular};
  line-height: ${globalTheme.lineHeights.heading};
`;

const H1 = styled.h1<StyledHeadingProps>`
  ${baseStyles}

  font-size: ${globalTheme.fontSizes[7]};

  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: ${globalTheme.fontSizes[8]};
  }
`;

const H2 = styled.h2<StyledHeadingProps>`
  ${baseStyles}

  font-size: ${globalTheme.fontSizes[6]};

  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: ${globalTheme.fontSizes[7]};
  }
`;

const H3 = styled.h3<StyledHeadingProps>`
  ${baseStyles}

  font-size: ${globalTheme.fontSizes[5]};

  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: ${globalTheme.fontSizes[6]};
  }
`;

const H4 = styled.h4<StyledHeadingProps>`
  ${baseStyles}

  font-size: ${globalTheme.fontSizes[4]};

  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: ${globalTheme.fontSizes[5]};
  }
`;

const H5 = styled.h5<StyledHeadingProps>`
  ${baseStyles}

  font-size: ${globalTheme.fontSizes[2]};

  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: ${globalTheme.fontSizes[3]};
  }
`;

export const Heading: FC<HeadingProps> = ({
  level = 'h1',
  as,
  children,
  ...rest
}) => {
  let HeadingComponent: StyledComponent<typeof level, any, StyledHeadingProps>;

  switch (level) {
    case 'h1':
      HeadingComponent = H1;
      break;
    case 'h2':
      HeadingComponent = H2;
      break;
    case 'h3':
      HeadingComponent = H3;
      break;
    case 'h4':
      HeadingComponent = H4;
      break;
    case 'h5':
      HeadingComponent = H5;
      break;
    default:
      HeadingComponent = H1;
      break;
  }

  return (
    <HeadingComponent as={as} {...rest}>
      {children}
    </HeadingComponent>
  );
};
