import { motion, Variants } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { PATHS } from '../../settings/config';
import { breakpoints } from '../../styles/breakpoints';
import { globalTheme } from '../../styles/global-theme';
import { LogoLight } from '../images';
import { LetterIcon, Text } from '../ui';
import { UserNav } from '../user-nav';
import { UserNavLinks } from './header';

export interface MarketplaceHeaderProps {
  userName: string;
  businessName?: string;
}

const StyledLogo = styled.div`
  width: 87px;
  margin-right: ${globalTheme.space[4]};

  @media screen and (max-width: ${breakpoints.large}) {
    margin: 0 ${globalTheme.space[2]};
  }
`;

const StyledHeader = styled.header`
  height: ${globalTheme.sizes.header};
  background-color: ${globalTheme.colors.darkBlue};
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.12) 2px 0px 10px 0px;
  gap: ${globalTheme.space[5]};
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: ${breakpoints.large}) {
    flex: 1;
    justify-content: flex-end;

    button {
      min-width: inherit;
      grid-template-columns: inherit;

      p {
        display: none;
      }
    }
  }
`;

const NavWrap = styled.div`
  display: flex;
  align-items: center;
  height: inherit;
  width: 100%;
  padding: 0 ${globalTheme.space[4]};
  background-color: ${globalTheme.colors.darkBlue};
  position: relative;
  z-index: 1;
`;

const StyledNav = styled.nav`
  display: flex;
  flex-direction: row;
  width: 100%;

  & > * {
    color: white;
    padding-right: ${globalTheme.space[2]};
    align-self: center;
    text-decoration: none;
  }
`;

const MobileNav = styled(motion.nav)`
  display: flex;
  flex-direction: column;
  background-color: ${globalTheme.colors.purple};
  width: 100%;
  grid-row-start: 2;

  & > a,
  & > p {
    color: white;
    padding-right: ${globalTheme.space[2]};
    padding: ${globalTheme.space[1]};
    text-decoration: none;
    font-size: ${globalTheme.fontSizes[2]};
    border-bottom: #951ca8 1px solid;
  }
`;

const BusinessName = styled(Text)`
  display: flex;
  align-items: center;
  color: white;
  width: auto;
  min-width: max-content;
  margin-right: ${globalTheme.space[1]};

  > * {
    margin-right: ${globalTheme.space[0]};
  }
`;

const variants: Variants = {
  closed: {
    y: '-100%',
    transition: { duration: 0.15 },
  },
  open: {
    y: 0,
    transition: { duration: 0.15 },
  },
};

const MarketplaceNavigationItems = (handleMenuClose: () => void) => {
  const router = useRouter();

  const getLinkStyle = (path: string) => {
    return {
      cursor: 'pointer',
      fontWeight: router.pathname === path ? '600' : 'normal',
    };
  };

  return (
    <>
      <Link href={PATHS.marketplace.sellListings} legacyBehavior>
        <p
          style={getLinkStyle('/marketplace/my-sell-listings')}
          onClick={handleMenuClose}
        >
          My Listings
        </p>
      </Link>
      <Link href='/' legacyBehavior>
        <p style={getLinkStyle('/')} onClick={handleMenuClose}>
          Buyer tools
        </p>
      </Link>
    </>
  );
};

const HeaderMarketplace: FC<MarketplaceHeaderProps> = ({
  businessName,
  userName,
}) => {
  const [isMenuIsOpen, setIsMenuIsOpen] = useState<boolean>(false);
  const isMobile = useMedia(`(max-width: ${breakpoints.large})`);

  const handleMenuClose = () => {
    setIsMenuIsOpen(false);
  };

  return (
    <StyledHeader>
      <NavWrap>
        {isMobile &&
          (isMenuIsOpen ? (
            <FiX
              color={'white'}
              size={globalTheme.fontSizes[5]}
              onClick={() => setIsMenuIsOpen((isMenuIsOpen) => !isMenuIsOpen)}
            />
          ) : (
            <FiMenu
              color={'white'}
              size={globalTheme.fontSizes[5]}
              onClick={() => setIsMenuIsOpen((isMenuIsOpen) => !isMenuIsOpen)}
            />
          ))}
        <StyledLogo>
          <LogoLight />
        </StyledLogo>
        <Content>
          {!isMobile && (
            <StyledNav>{MarketplaceNavigationItems(handleMenuClose)}</StyledNav>
          )}
          <BusinessName as='div'>
            {businessName && (
              <LetterIcon
                letter={businessName}
                size={2}
                fontSize='default'
                backgroundColor='neutral'
                color='darkGrey'
                fontWeight='extraBold'
              />
            )}
            {!isMobile && businessName}
          </BusinessName>
          <UserNav links={UserNavLinks} userName={userName} />
        </Content>
      </NavWrap>
      {isMobile && (
        <MobileNav
          variants={variants}
          initial={'closed'}
          animate={isMenuIsOpen ? 'open' : 'closed'}
        >
          {MarketplaceNavigationItems(handleMenuClose)}
        </MobileNav>
      )}
    </StyledHeader>
  );
};

export default HeaderMarketplace;
