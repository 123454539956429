/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { PaginatedPrivateGroupList } from '../model';
// @ts-ignore
import { PatchedPrivateGroupCreate } from '../model';
// @ts-ignore
import { PrivateGroup } from '../model';
// @ts-ignore
import { PrivateGroupCreate } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * OrganisationPrivateGroupApi - axios parameter creator
 * @export
 */
export const OrganisationPrivateGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the created private group.
         * @summary Create a new private group
         * @param {string} buyerOrgId 
         * @param {PrivateGroupCreate} privateGroupCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateCreate: async (buyerOrgId: string, privateGroupCreate: PrivateGroupCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPrivateCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'privateGroupCreate' is not null or undefined
            assertParamExists('orgPrivateCreate', 'privateGroupCreate', privateGroupCreate)
            const localVarPath = `/v2/org/{buyer_org_id}/private`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateGroupCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a Private group
         * @summary Delete a Private group
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateDestroy: async (buyerOrgId: string, privateGroupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPrivateDestroy', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'privateGroupId' is not null or undefined
            assertParamExists('orgPrivateDestroy', 'privateGroupId', privateGroupId)
            const localVarPath = `/v2/org/{buyer_org_id}/private/{private_group_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"private_group_id"}}`, encodeURIComponent(String(privateGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the list of private groups beneath the organisation.  # Filtering Options  ## Fields - name str: case insensitive contains query. eg ?name=cattle - my_groups int: \"1\" will return only the groups they created, \"0\" will return all other groups. eg ?my_groups=1  ## Ordering - name: based on the name, eg ?ordering=name, can be reversed. eg ?ordering=-name  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of Private Groups in the Organisation
         * @param {string} buyerOrgId 
         * @param {string} [myGroups] 
         * @param {string} [name] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateList: async (buyerOrgId: string, myGroups?: string, name?: string, ordering?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPrivateList', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/private`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (myGroups !== undefined) {
                localVarQueryParameter['my_groups'] = myGroups;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the newly updated details of the Private group.  param: contacts This list of contact id\'s can be passed through to be updated. This WILL update the contact list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"contacts\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships
         * @summary Update the details of a single Private group
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {PatchedPrivateGroupCreate} [patchedPrivateGroupCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivatePartialUpdate: async (buyerOrgId: string, privateGroupId: string, patchedPrivateGroupCreate?: PatchedPrivateGroupCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPrivatePartialUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'privateGroupId' is not null or undefined
            assertParamExists('orgPrivatePartialUpdate', 'privateGroupId', privateGroupId)
            const localVarPath = `/v2/org/{buyer_org_id}/private/{private_group_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"private_group_id"}}`, encodeURIComponent(String(privateGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedPrivateGroupCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of the found private group.
         * @summary Get the details of a single Private group
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateRetrieve: async (buyerOrgId: string, privateGroupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPrivateRetrieve', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'privateGroupId' is not null or undefined
            assertParamExists('orgPrivateRetrieve', 'privateGroupId', privateGroupId)
            const localVarPath = `/v2/org/{buyer_org_id}/private/{private_group_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"private_group_id"}}`, encodeURIComponent(String(privateGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationPrivateGroupApi - functional programming interface
 * @export
 */
export const OrganisationPrivateGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationPrivateGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the created private group.
         * @summary Create a new private group
         * @param {string} buyerOrgId 
         * @param {PrivateGroupCreate} privateGroupCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPrivateCreate(buyerOrgId: string, privateGroupCreate: PrivateGroupCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPrivateCreate(buyerOrgId, privateGroupCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a Private group
         * @summary Delete a Private group
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPrivateDestroy(buyerOrgId: string, privateGroupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPrivateDestroy(buyerOrgId, privateGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the list of private groups beneath the organisation.  # Filtering Options  ## Fields - name str: case insensitive contains query. eg ?name=cattle - my_groups int: \"1\" will return only the groups they created, \"0\" will return all other groups. eg ?my_groups=1  ## Ordering - name: based on the name, eg ?ordering=name, can be reversed. eg ?ordering=-name  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of Private Groups in the Organisation
         * @param {string} buyerOrgId 
         * @param {string} [myGroups] 
         * @param {string} [name] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPrivateList(buyerOrgId: string, myGroups?: string, name?: string, ordering?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPrivateGroupList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPrivateList(buyerOrgId, myGroups, name, ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the newly updated details of the Private group.  param: contacts This list of contact id\'s can be passed through to be updated. This WILL update the contact list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"contacts\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships
         * @summary Update the details of a single Private group
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {PatchedPrivateGroupCreate} [patchedPrivateGroupCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPrivatePartialUpdate(buyerOrgId: string, privateGroupId: string, patchedPrivateGroupCreate?: PatchedPrivateGroupCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPrivatePartialUpdate(buyerOrgId, privateGroupId, patchedPrivateGroupCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of the found private group.
         * @summary Get the details of a single Private group
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPrivateRetrieve(buyerOrgId: string, privateGroupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPrivateRetrieve(buyerOrgId, privateGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationPrivateGroupApi - factory interface
 * @export
 */
export const OrganisationPrivateGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationPrivateGroupApiFp(configuration)
    return {
        /**
         * Returns the created private group.
         * @summary Create a new private group
         * @param {string} buyerOrgId 
         * @param {PrivateGroupCreate} privateGroupCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateCreate(buyerOrgId: string, privateGroupCreate: PrivateGroupCreate, options?: any): AxiosPromise<PrivateGroup> {
            return localVarFp.orgPrivateCreate(buyerOrgId, privateGroupCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a Private group
         * @summary Delete a Private group
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateDestroy(buyerOrgId: string, privateGroupId: string, options?: any): AxiosPromise<void> {
            return localVarFp.orgPrivateDestroy(buyerOrgId, privateGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the list of private groups beneath the organisation.  # Filtering Options  ## Fields - name str: case insensitive contains query. eg ?name=cattle - my_groups int: \"1\" will return only the groups they created, \"0\" will return all other groups. eg ?my_groups=1  ## Ordering - name: based on the name, eg ?ordering=name, can be reversed. eg ?ordering=-name  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
         * @summary Get a list of Private Groups in the Organisation
         * @param {string} buyerOrgId 
         * @param {string} [myGroups] 
         * @param {string} [name] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateList(buyerOrgId: string, myGroups?: string, name?: string, ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedPrivateGroupList> {
            return localVarFp.orgPrivateList(buyerOrgId, myGroups, name, ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the newly updated details of the Private group.  param: contacts This list of contact id\'s can be passed through to be updated. This WILL update the contact list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"contacts\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships
         * @summary Update the details of a single Private group
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {PatchedPrivateGroupCreate} [patchedPrivateGroupCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivatePartialUpdate(buyerOrgId: string, privateGroupId: string, patchedPrivateGroupCreate?: PatchedPrivateGroupCreate, options?: any): AxiosPromise<PrivateGroup> {
            return localVarFp.orgPrivatePartialUpdate(buyerOrgId, privateGroupId, patchedPrivateGroupCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of the found private group.
         * @summary Get the details of a single Private group
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateRetrieve(buyerOrgId: string, privateGroupId: string, options?: any): AxiosPromise<PrivateGroup> {
            return localVarFp.orgPrivateRetrieve(buyerOrgId, privateGroupId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgPrivateCreate operation in OrganisationPrivateGroupApi.
 * @export
 * @interface OrganisationPrivateGroupApiOrgPrivateCreateRequest
 */
export interface OrganisationPrivateGroupApiOrgPrivateCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupApiOrgPrivateCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {PrivateGroupCreate}
     * @memberof OrganisationPrivateGroupApiOrgPrivateCreate
     */
    readonly privateGroupCreate: PrivateGroupCreate
}

/**
 * Request parameters for orgPrivateDestroy operation in OrganisationPrivateGroupApi.
 * @export
 * @interface OrganisationPrivateGroupApiOrgPrivateDestroyRequest
 */
export interface OrganisationPrivateGroupApiOrgPrivateDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupApiOrgPrivateDestroy
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupApiOrgPrivateDestroy
     */
    readonly privateGroupId: string
}

/**
 * Request parameters for orgPrivateList operation in OrganisationPrivateGroupApi.
 * @export
 * @interface OrganisationPrivateGroupApiOrgPrivateListRequest
 */
export interface OrganisationPrivateGroupApiOrgPrivateListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupApiOrgPrivateList
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupApiOrgPrivateList
     */
    readonly myGroups?: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupApiOrgPrivateList
     */
    readonly name?: string

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof OrganisationPrivateGroupApiOrgPrivateList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof OrganisationPrivateGroupApiOrgPrivateList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrganisationPrivateGroupApiOrgPrivateList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for orgPrivatePartialUpdate operation in OrganisationPrivateGroupApi.
 * @export
 * @interface OrganisationPrivateGroupApiOrgPrivatePartialUpdateRequest
 */
export interface OrganisationPrivateGroupApiOrgPrivatePartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupApiOrgPrivatePartialUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupApiOrgPrivatePartialUpdate
     */
    readonly privateGroupId: string

    /**
     * 
     * @type {PatchedPrivateGroupCreate}
     * @memberof OrganisationPrivateGroupApiOrgPrivatePartialUpdate
     */
    readonly patchedPrivateGroupCreate?: PatchedPrivateGroupCreate
}

/**
 * Request parameters for orgPrivateRetrieve operation in OrganisationPrivateGroupApi.
 * @export
 * @interface OrganisationPrivateGroupApiOrgPrivateRetrieveRequest
 */
export interface OrganisationPrivateGroupApiOrgPrivateRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupApiOrgPrivateRetrieve
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupApiOrgPrivateRetrieve
     */
    readonly privateGroupId: string
}

/**
 * OrganisationPrivateGroupApi - object-oriented interface
 * @export
 * @class OrganisationPrivateGroupApi
 * @extends {BaseAPI}
 */
export class OrganisationPrivateGroupApi extends BaseAPI {
    /**
     * Returns the created private group.
     * @summary Create a new private group
     * @param {OrganisationPrivateGroupApiOrgPrivateCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPrivateGroupApi
     */
    public orgPrivateCreate(requestParameters: OrganisationPrivateGroupApiOrgPrivateCreateRequest, options?: any) {
        return OrganisationPrivateGroupApiFp(this.configuration).orgPrivateCreate(requestParameters.buyerOrgId, requestParameters.privateGroupCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a Private group
     * @summary Delete a Private group
     * @param {OrganisationPrivateGroupApiOrgPrivateDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPrivateGroupApi
     */
    public orgPrivateDestroy(requestParameters: OrganisationPrivateGroupApiOrgPrivateDestroyRequest, options?: any) {
        return OrganisationPrivateGroupApiFp(this.configuration).orgPrivateDestroy(requestParameters.buyerOrgId, requestParameters.privateGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the list of private groups beneath the organisation.  # Filtering Options  ## Fields - name str: case insensitive contains query. eg ?name=cattle - my_groups int: \"1\" will return only the groups they created, \"0\" will return all other groups. eg ?my_groups=1  ## Ordering - name: based on the name, eg ?ordering=name, can be reversed. eg ?ordering=-name  # Results  These are paginated in the following format. With 20 results per page. ``` {     count: 12, <-- total results.     results: [..list of results..],     next: 2 <-- next page number, or null if there isn\'t one     previous: null <-- previous page number, or null if there isn\'t one     page: 1 <-- current page     pages: 3 <-- total number of pages. } ``` 
     * @summary Get a list of Private Groups in the Organisation
     * @param {OrganisationPrivateGroupApiOrgPrivateListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPrivateGroupApi
     */
    public orgPrivateList(requestParameters: OrganisationPrivateGroupApiOrgPrivateListRequest, options?: any) {
        return OrganisationPrivateGroupApiFp(this.configuration).orgPrivateList(requestParameters.buyerOrgId, requestParameters.myGroups, requestParameters.name, requestParameters.ordering, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the newly updated details of the Private group.  param: contacts This list of contact id\'s can be passed through to be updated. This WILL update the contact list to reflect those passed through which will cause any relationship to that group to be removed.  Setting the \"contacts\" value to an empty list WILL delete all relationships with the private group. Not sending the contacts key through will not affect the relationships
     * @summary Update the details of a single Private group
     * @param {OrganisationPrivateGroupApiOrgPrivatePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPrivateGroupApi
     */
    public orgPrivatePartialUpdate(requestParameters: OrganisationPrivateGroupApiOrgPrivatePartialUpdateRequest, options?: any) {
        return OrganisationPrivateGroupApiFp(this.configuration).orgPrivatePartialUpdate(requestParameters.buyerOrgId, requestParameters.privateGroupId, requestParameters.patchedPrivateGroupCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of the found private group.
     * @summary Get the details of a single Private group
     * @param {OrganisationPrivateGroupApiOrgPrivateRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPrivateGroupApi
     */
    public orgPrivateRetrieve(requestParameters: OrganisationPrivateGroupApiOrgPrivateRetrieveRequest, options?: any) {
        return OrganisationPrivateGroupApiFp(this.configuration).orgPrivateRetrieve(requestParameters.buyerOrgId, requestParameters.privateGroupId, options).then((request) => request(this.axios, this.basePath));
    }
}
