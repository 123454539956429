import { FC, MouseEvent, useState } from 'react';
import styled from 'styled-components';
import { Dialog } from '../dialog';
import { IconButton, IconButtonProps } from '../icon-button';
import { Text } from '../text';

export interface HelpDialogProps {
  title: string;
  content: string;
  buttonAs?: IconButtonProps['buttonAs'];
}

const Content = styled(Text)`
  text-align: center;
`;

const Button = styled(IconButton)`
  display: inline-block;
  vertical-align: top;
  margin-inline-start: 0.5ch;
  margin-block-start: -0.5ex;

  * {
    cursor: help;
  }
`;

export const HelpDialog: FC<HelpDialogProps> = ({
  title,
  content,
  buttonAs,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsOpen(true);
  };

  const close = () => setIsOpen(false);

  return (
    <>
      <Button
        icon='HelpCircleIcon'
        buttonColor={false}
        size={1}
        onClick={open}
        aria-label={`Open dialog: ${title}`}
        buttonAs={buttonAs}
      />
      <Dialog title={title} onDismiss={close} isOpen={isOpen}>
        <Content fontSize={3}>{content}</Content>
      </Dialog>
    </>
  );
};
