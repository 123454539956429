import { useDialogToggle } from 'hooks/use-dialog-toggle';
import { createContext, FC, useState } from 'react';
import { UpgradeDialogProps } from '.';
import { UpgradeDialog } from './upgrade-dialog';

type UpgradeDialogState = Omit<UpgradeDialogProps, 'onDismiss' | 'isOpen'>;

export const UpgradeDialogContext = createContext({
  openUpgradeDialog: (props: UpgradeDialogState) => {},
});

export const UpgradeDialogProvider: FC = ({ children }) => {
  const [isOpen, open, close] = useDialogToggle(false);
  const [dialogProps, setDialogProps] = useState<UpgradeDialogState>({
    title: '',
    description: '',
    actionText: '',
  });

  const openUpgradeDialog = (props: UpgradeDialogState) => {
    open();
    setDialogProps(props);
  };

  return (
    <UpgradeDialogContext.Provider value={{ openUpgradeDialog }}>
      <>
        {children}
        <UpgradeDialog {...dialogProps} isOpen={isOpen} onDismiss={close} />
      </>
    </UpgradeDialogContext.Provider>
  );
};
