import { useNanoID } from 'hooks/use-nanoid';
import {
  ChangeEvent,
  FC,
  forwardRef,
  InputHTMLAttributes,
  useState,
} from 'react';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Icon, IconProps } from '../icon';
import { Stack } from '../stack';
import { Text } from '../text';

export interface FileInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  showFiles?: boolean;
  icon?: IconProps['component'];
}

const Label = styled.label`
  display: inline-flex;
  gap: ${globalTheme.space[1]};
  align-items: center;
  justify-content: center;
  min-width: 80px;
  height: 40px;
  padding: 0 ${globalTheme.space[2]};
  font-size: ${globalTheme.fontSizes[1]};
  text-decoration: none;
  background-color: ${globalTheme.elements.fileInput.backgroundColor};
  border: 0;
  border-radius: 20px;
  outline: 0;
  transition: ${globalTheme.transitions.fast};
`;

const Input = styled.input`
  width: 0;
  height: 0;
  opacity: 0;

  ${Icon} {
    transition: ${globalTheme.transitions.fast};
  }

  &:hover + ${Label} {
    color: ${globalTheme.elements.fileInput.hoverColor};
    background-color: ${globalTheme.elements.fileInput.hoverBackgroundColor};

    ${Icon} {
      * {
        fill: ${globalTheme.elements.fileInput.hoverIconColor};
      }
    }
  }

  &:focus + ${Label} {
    box-shadow: inset 0 0 0 2px transparent,
      0 0 0 2px ${globalTheme.colors.white},
      0 0 0 4px ${globalTheme.colors.black};
  }

  &:focus:not(:focus-visible) + ${Label} {
    box-shadow: inset 0 0 0 2px transparent;
  }
`;

const FileList = styled(Stack)`
  margin-top: ${globalTheme.space[0]};

  li {
    margin-left: ${globalTheme.space[1]};
  }
`;

export const FileInput: FC<FileInputProps> = forwardRef<
  HTMLInputElement,
  FileInputProps
>(({ label, onChange, showFiles, icon, ...inputProps }, ref) => {
  const id = useNanoID();
  const [fileNames, setFileNames] = useState<string[]>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Original onChange prop
    onChange && onChange(event);

    const files = event.target.files;
    if (files) {
      const fileNames = Array.from(files).map(
        (file) => `${file.name} - ${(file.size / 1000).toFixed(2)}KB`
      );
      setFileNames(fileNames);
    }
  };

  return (
    <div>
      <Input
        id={id}
        type='file'
        onChange={handleChange}
        {...inputProps}
        ref={ref}
      />
      <Label htmlFor={id}>
        <Icon component={icon || 'PaperClipIcon'} />
        {label}
      </Label>
      {showFiles && (
        <FileList forwardedAs='ul' space={0}>
          {fileNames?.map((fileName) => (
            <Text key={fileName} as='li'>
              {fileName}
            </Text>
          ))}
        </FileList>
      )}
    </div>
  );
});

FileInput.defaultProps = {
  label: 'Add attachment',
};
