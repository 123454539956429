/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum ListingVisibilityEnum {
    Deleted = 'DELETED',
    Archived = 'ARCHIVED',
    Expired = 'EXPIRED',
    Hidden = 'HIDDEN',
    Private = 'PRIVATE',
    Public = 'PUBLIC'
}



