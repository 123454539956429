/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ListingEnquiry } from '../model';
// @ts-ignore
import { PaginatedListingEnquiryList } from '../model';
/**
 * MarketplaceOrgListingEnquiryApi - axios parameter creator
 * @export
 */
export const MarketplaceOrgListingEnquiryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes an Enquiry
         * @param {string} buyerOrgId 
         * @param {string} listingEnquiryId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingEnquiryDestroy: async (buyerOrgId: string, listingEnquiryId: string, listingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingEnquiryDestroy', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingEnquiryId' is not null or undefined
            assertParamExists('orgMarketplaceListingEnquiryDestroy', 'listingEnquiryId', listingEnquiryId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingEnquiryDestroy', 'listingId', listingId)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}/enquiry/{listing_enquiry_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_enquiry_id"}}`, encodeURIComponent(String(listingEnquiryId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of the enquiries for a single listing.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {boolean} [read] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingEnquiryList: async (buyerOrgId: string, listingId: string, page?: number, pageSize?: number, read?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingEnquiryList', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingEnquiryList', 'listingId', listingId)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}/enquiry`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (read !== undefined) {
                localVarQueryParameter['read'] = read;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark the enquiry read (POST) OR remove read_timestamp (DELETE).
         * @param {string} buyerOrgId 
         * @param {string} listingEnquiryId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingEnquiryReadCreate: async (buyerOrgId: string, listingEnquiryId: string, listingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingEnquiryReadCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingEnquiryId' is not null or undefined
            assertParamExists('orgMarketplaceListingEnquiryReadCreate', 'listingEnquiryId', listingEnquiryId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingEnquiryReadCreate', 'listingId', listingId)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}/enquiry/{listing_enquiry_id}/read`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_enquiry_id"}}`, encodeURIComponent(String(listingEnquiryId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark the enquiry read (POST) OR remove read_timestamp (DELETE).
         * @param {string} buyerOrgId 
         * @param {string} listingEnquiryId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingEnquiryReadDestroy: async (buyerOrgId: string, listingEnquiryId: string, listingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingEnquiryReadDestroy', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingEnquiryId' is not null or undefined
            assertParamExists('orgMarketplaceListingEnquiryReadDestroy', 'listingEnquiryId', listingEnquiryId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingEnquiryReadDestroy', 'listingId', listingId)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}/enquiry/{listing_enquiry_id}/read`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_enquiry_id"}}`, encodeURIComponent(String(listingEnquiryId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of a single Enquiry
         * @param {string} buyerOrgId 
         * @param {string} listingEnquiryId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingEnquiryRetrieve: async (buyerOrgId: string, listingEnquiryId: string, listingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMarketplaceListingEnquiryRetrieve', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'listingEnquiryId' is not null or undefined
            assertParamExists('orgMarketplaceListingEnquiryRetrieve', 'listingEnquiryId', listingEnquiryId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('orgMarketplaceListingEnquiryRetrieve', 'listingId', listingId)
            const localVarPath = `/v2/org/{buyer_org_id}/marketplace/listing/{listing_id}/enquiry/{listing_enquiry_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"listing_enquiry_id"}}`, encodeURIComponent(String(listingEnquiryId)))
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketplaceOrgListingEnquiryApi - functional programming interface
 * @export
 */
export const MarketplaceOrgListingEnquiryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketplaceOrgListingEnquiryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes an Enquiry
         * @param {string} buyerOrgId 
         * @param {string} listingEnquiryId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingEnquiryDestroy(buyerOrgId: string, listingEnquiryId: string, listingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingEnquiryDestroy(buyerOrgId, listingEnquiryId, listingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of the enquiries for a single listing.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {boolean} [read] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingEnquiryList(buyerOrgId: string, listingId: string, page?: number, pageSize?: number, read?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListingEnquiryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingEnquiryList(buyerOrgId, listingId, page, pageSize, read, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark the enquiry read (POST) OR remove read_timestamp (DELETE).
         * @param {string} buyerOrgId 
         * @param {string} listingEnquiryId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingEnquiryReadCreate(buyerOrgId: string, listingEnquiryId: string, listingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingEnquiry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingEnquiryReadCreate(buyerOrgId, listingEnquiryId, listingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark the enquiry read (POST) OR remove read_timestamp (DELETE).
         * @param {string} buyerOrgId 
         * @param {string} listingEnquiryId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingEnquiryReadDestroy(buyerOrgId: string, listingEnquiryId: string, listingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingEnquiry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingEnquiryReadDestroy(buyerOrgId, listingEnquiryId, listingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of a single Enquiry
         * @param {string} buyerOrgId 
         * @param {string} listingEnquiryId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMarketplaceListingEnquiryRetrieve(buyerOrgId: string, listingEnquiryId: string, listingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingEnquiry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMarketplaceListingEnquiryRetrieve(buyerOrgId, listingEnquiryId, listingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketplaceOrgListingEnquiryApi - factory interface
 * @export
 */
export const MarketplaceOrgListingEnquiryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketplaceOrgListingEnquiryApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes an Enquiry
         * @param {string} buyerOrgId 
         * @param {string} listingEnquiryId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingEnquiryDestroy(buyerOrgId: string, listingEnquiryId: string, listingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.orgMarketplaceListingEnquiryDestroy(buyerOrgId, listingEnquiryId, listingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of the enquiries for a single listing.
         * @param {string} buyerOrgId 
         * @param {string} listingId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {boolean} [read] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingEnquiryList(buyerOrgId: string, listingId: string, page?: number, pageSize?: number, read?: boolean, options?: any): AxiosPromise<PaginatedListingEnquiryList> {
            return localVarFp.orgMarketplaceListingEnquiryList(buyerOrgId, listingId, page, pageSize, read, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark the enquiry read (POST) OR remove read_timestamp (DELETE).
         * @param {string} buyerOrgId 
         * @param {string} listingEnquiryId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingEnquiryReadCreate(buyerOrgId: string, listingEnquiryId: string, listingId: string, options?: any): AxiosPromise<ListingEnquiry> {
            return localVarFp.orgMarketplaceListingEnquiryReadCreate(buyerOrgId, listingEnquiryId, listingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark the enquiry read (POST) OR remove read_timestamp (DELETE).
         * @param {string} buyerOrgId 
         * @param {string} listingEnquiryId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingEnquiryReadDestroy(buyerOrgId: string, listingEnquiryId: string, listingId: string, options?: any): AxiosPromise<ListingEnquiry> {
            return localVarFp.orgMarketplaceListingEnquiryReadDestroy(buyerOrgId, listingEnquiryId, listingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of a single Enquiry
         * @param {string} buyerOrgId 
         * @param {string} listingEnquiryId 
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMarketplaceListingEnquiryRetrieve(buyerOrgId: string, listingEnquiryId: string, listingId: string, options?: any): AxiosPromise<ListingEnquiry> {
            return localVarFp.orgMarketplaceListingEnquiryRetrieve(buyerOrgId, listingEnquiryId, listingId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgMarketplaceListingEnquiryDestroy operation in MarketplaceOrgListingEnquiryApi.
 * @export
 * @interface MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryDestroyRequest
 */
export interface MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryDestroy
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryDestroy
     */
    readonly listingEnquiryId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryDestroy
     */
    readonly listingId: string
}

/**
 * Request parameters for orgMarketplaceListingEnquiryList operation in MarketplaceOrgListingEnquiryApi.
 * @export
 * @interface MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryListRequest
 */
export interface MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryListRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryList
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryList
     */
    readonly listingId: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {boolean}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryList
     */
    readonly read?: boolean
}

/**
 * Request parameters for orgMarketplaceListingEnquiryReadCreate operation in MarketplaceOrgListingEnquiryApi.
 * @export
 * @interface MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryReadCreateRequest
 */
export interface MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryReadCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryReadCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryReadCreate
     */
    readonly listingEnquiryId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryReadCreate
     */
    readonly listingId: string
}

/**
 * Request parameters for orgMarketplaceListingEnquiryReadDestroy operation in MarketplaceOrgListingEnquiryApi.
 * @export
 * @interface MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryReadDestroyRequest
 */
export interface MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryReadDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryReadDestroy
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryReadDestroy
     */
    readonly listingEnquiryId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryReadDestroy
     */
    readonly listingId: string
}

/**
 * Request parameters for orgMarketplaceListingEnquiryRetrieve operation in MarketplaceOrgListingEnquiryApi.
 * @export
 * @interface MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryRetrieveRequest
 */
export interface MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryRetrieve
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryRetrieve
     */
    readonly listingEnquiryId: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryRetrieve
     */
    readonly listingId: string
}

/**
 * MarketplaceOrgListingEnquiryApi - object-oriented interface
 * @export
 * @class MarketplaceOrgListingEnquiryApi
 * @extends {BaseAPI}
 */
export class MarketplaceOrgListingEnquiryApi extends BaseAPI {
    /**
     * 
     * @summary Deletes an Enquiry
     * @param {MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingEnquiryApi
     */
    public orgMarketplaceListingEnquiryDestroy(requestParameters: MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryDestroyRequest, options?: any) {
        return MarketplaceOrgListingEnquiryApiFp(this.configuration).orgMarketplaceListingEnquiryDestroy(requestParameters.buyerOrgId, requestParameters.listingEnquiryId, requestParameters.listingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of the enquiries for a single listing.
     * @param {MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingEnquiryApi
     */
    public orgMarketplaceListingEnquiryList(requestParameters: MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryListRequest, options?: any) {
        return MarketplaceOrgListingEnquiryApiFp(this.configuration).orgMarketplaceListingEnquiryList(requestParameters.buyerOrgId, requestParameters.listingId, requestParameters.page, requestParameters.pageSize, requestParameters.read, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark the enquiry read (POST) OR remove read_timestamp (DELETE).
     * @param {MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryReadCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingEnquiryApi
     */
    public orgMarketplaceListingEnquiryReadCreate(requestParameters: MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryReadCreateRequest, options?: any) {
        return MarketplaceOrgListingEnquiryApiFp(this.configuration).orgMarketplaceListingEnquiryReadCreate(requestParameters.buyerOrgId, requestParameters.listingEnquiryId, requestParameters.listingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark the enquiry read (POST) OR remove read_timestamp (DELETE).
     * @param {MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryReadDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingEnquiryApi
     */
    public orgMarketplaceListingEnquiryReadDestroy(requestParameters: MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryReadDestroyRequest, options?: any) {
        return MarketplaceOrgListingEnquiryApiFp(this.configuration).orgMarketplaceListingEnquiryReadDestroy(requestParameters.buyerOrgId, requestParameters.listingEnquiryId, requestParameters.listingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of a single Enquiry
     * @param {MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceOrgListingEnquiryApi
     */
    public orgMarketplaceListingEnquiryRetrieve(requestParameters: MarketplaceOrgListingEnquiryApiOrgMarketplaceListingEnquiryRetrieveRequest, options?: any) {
        return MarketplaceOrgListingEnquiryApiFp(this.configuration).orgMarketplaceListingEnquiryRetrieve(requestParameters.buyerOrgId, requestParameters.listingEnquiryId, requestParameters.listingId, options).then((request) => request(this.axios, this.basePath));
    }
}
