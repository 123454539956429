import { useRect } from '@reach/rect';
import { useSelect } from 'downshift';
import { AnimatePresence } from 'framer-motion';
import {
  FC,
  HTMLAttributes,
  Key,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { ComboboxList, ComboboxListItem } from '../combobox';
import { Icon } from '../icon';
import {
  getStateValues,
  InputProps,
  InputWrapper,
  Label,
  Wrapper,
} from '../input';
import { InputMessage } from '../input-message';

export type SelectItem = {
  id: string | null | boolean;
  value: string;
};

export interface SelectProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  items: SelectItem[];
  label?: string;
  placeholder?: string;
  labelledBy?: string;
  state?: InputProps['state'];
  message?: InputProps['message'];
  initialSelectedOption?: SelectItem['id'];
  value?: SelectItem['id'];
  disabled?: boolean;
  bookingStyle?: boolean;
  onChange?: (value: SelectItem) => void;
  triggerReset?: boolean;
  isOptionVisible?: boolean;
  orgFontSize?: any;
  isMobile?: any;
}

const SelectWrapper = styled(InputWrapper)<{ bookingStyle?: boolean }>`
  ${({ bookingStyle }) => {
    if (bookingStyle) {
      return css`
        padding: 4px 8px;
        font-weight: ${globalTheme.fontWeights.light};
        border-radius: 6px;
        && {
          height: 32px;
          min-height: auto;
        }
      `;
    }
  }}
`;

const List = styled(ComboboxList)`
  max-height: 150px;
  outline: 0;
`;

const ListItem = styled(ComboboxListItem)`
  /* Overrides */
`;

export const SelectButton = styled.button`
  position: relative;
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: ${globalTheme.space[0]};
  align-items: center;
  width: 100%;
  height: 44px;
  padding: ${globalTheme.space[0]} ${globalTheme.space[1]};
  font-size: inherit;
  text-align: left;
  background-color: transparent;
  border: 0;
  outline: 0;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &::before {
    position: absolute;
    top: ${globalTheme.space[2]};
    left: -2px;
    z-index: -1;
    width: calc(100% + 4px);
    height: 100%;
    background-color: ${globalTheme.colors.white};
    transform: translateY(${globalTheme.space[0]});
    opacity: 0;
    content: '';
    pointer-events: none;
  }

  &[aria-expanded='true']::before {
    transform: translateY(0);
    opacity: 1;
  }
`;

const SelectedItem = styled.span<{ orgFontSize: any }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${(props) =>
    props?.orgFontSize !== undefined ? globalTheme.fontSizes[2] : 'inherit'};
`;

export const Select: FC<SelectProps> = ({
  items,
  label,
  state,
  initialSelectedOption,
  onChange,
  message,
  labelledBy,
  disabled,
  placeholder,
  value,
  bookingStyle,
  triggerReset,
  isOptionVisible,
  orgFontSize,
  isMobile,
  ...divProps
}) => {
  // Memo the initial selection on mount
  const initialSelectedOptionRef = useRef(initialSelectedOption);

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    selectItem,
    reset,
  } = useSelect({
    items,
    itemToString: (item) => item?.value || '',
    initialSelectedItem: items.find(
      (item) => item.id === initialSelectedOptionRef.current
    ),
    onSelectedItemChange: ({ selectedItem }) => {
      onChange &&
        selectedItem &&
        selectedItem.id !== value &&
        onChange(selectedItem);
    },
  });

  const { stateColor } = getStateValues(state);

  // Popper reference element
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  const toggleButtonProps = getToggleButtonProps();

  // Observe the bounding rect of the input wrapper so that the list can be positioned
  const wrapperRef = useRef<HTMLDivElement>(null);
  const rect = useRect(wrapperRef, { observe: isOpen || isOptionVisible });

  // Reset the list when the triggerReset prop changes
  useEffect(() => {
    if (triggerReset) {
      selectItem({ id: null, value: '' });
    }
  }, [triggerReset]);

  // When the current selection gets removed from the list, reset the list
  useEffect(() => {
    if (!selectedItem?.id) {
      return;
    }

    const selectedItemRemoved = !items.find(
      (item) => item.id === selectedItem?.id
    );

    if (selectedItemRemoved) {
      reset();
    }
  }, [items, reset, selectedItem?.id]);

  // Handle selected item based on external changes to the value prop
  useEffect(() => {
    if (value !== undefined && selectedItem?.id !== value) {
      const selection = items.find((item) => item.id === value);

      if (selection) {
        selectItem(selection);
      }
    }
  }, [items, selectItem, selectedItem?.id, value]);

  const getListTopPosition = useMemo(() => {
    if (isOpen || isOptionVisible) {
      const { innerHeight: height } = window;
      const heightOfItem = 50;
      if (rect && height / 2 < rect?.y) {
        if (items.length < 3) {
          return (rect?.top || 0) - (heightOfItem * items.length + 5);
        } else {
          return (rect?.top || 0) - 160;
        }
      }
    }
    return (rect?.top || 0) + (rect?.height || 0);
  }, [isOpen, isOptionVisible, rect, items]);

  return (
    <Wrapper ref={setReferenceElement} {...divProps}>
      {label && (
        <Label
          {...getLabelProps()}
          orgFontSize={orgFontSize}
          isMobile={isMobile}
        >
          {label}
        </Label>
      )}
      <SelectWrapper
        color={stateColor}
        ref={wrapperRef}
        data-state={state}
        bookingStyle={bookingStyle}
      >
        <SelectButton
          type='button'
          {...toggleButtonProps}
          aria-labelledby={labelledBy || toggleButtonProps['aria-labelledby']}
          disabled={disabled}
        >
          <SelectedItem orgFontSize={orgFontSize}>
            {selectedItem?.value || placeholder}
          </SelectedItem>
          <Icon component='ChevronDownIcon' />
        </SelectButton>
      </SelectWrapper>

      <List
        {...getMenuProps()}
        style={{
          width: rect?.width,
          top: getListTopPosition,
          left: rect?.left,
        }}
      >
        {(isOptionVisible || isOpen) &&
          items.map((item, index) => (
            <ListItem
              {...getItemProps({ item, index })}
              key={item.id as Key}
              data-cy={`listItem${item.id}`}
            >
              {item.value}
            </ListItem>
          ))}
      </List>

      <AnimatePresence>
        {message && (
          <InputMessage
            backgroundColor={stateColor}
            referenceElement={referenceElement}
          >
            {message}
          </InputMessage>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};
