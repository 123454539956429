import { useQuery } from '@tanstack/react-query';
import { ApiClient } from 'lib/api/client';
import { QUERY_KEYS, STATUS_REFETCH_INTERVAL } from 'settings/config';

export function useApiStatus() {
  return useQuery(
    [QUERY_KEYS.apiStatus],
    () => new ApiClient().defaultApi.rootRetrieve(),
    {
      select: (data) => data.data,
      refetchInterval: STATUS_REFETCH_INTERVAL,
    }
  );
}
