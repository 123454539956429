import { useQuery } from '@tanstack/react-query';
import { ApiClient } from 'lib/api/client';
import { useStore } from 'lib/store';
import { QUERY_KEYS } from 'settings/config';
import { useApiReady } from './use-api-ready';

/** Get the active buyer org */
export function useBuyerOrg() {
  const enabled = useApiReady();
  const activeBuyerOrgId = useStore((store) => store.activeBuyerOrgId);
  const removeActiveBuyerOrgId = useStore(
    (store) => store.removeActiveBuyerOrgId
  );

  return useQuery(
    [QUERY_KEYS.org, activeBuyerOrgId],
    () =>
      new ApiClient().organisationApi.orgRetrieve({
        buyerOrgId: activeBuyerOrgId || '',
      }),
    {
      select: (data) => data.data,
      enabled: enabled && !!activeBuyerOrgId,
      onError: () => {
        removeActiveBuyerOrgId();
      },
    }
  );
}
