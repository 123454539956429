/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { StripeSellerCreateSetupIntent } from '../model';
// @ts-ignore
import { StripeSellerRetrieveSetupIntent } from '../model';
// @ts-ignore
import { StripeSetupIntent } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * SellerStripeApi - axios parameter creator
 * @export
 */
export const SellerStripeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns setup intent, along with the customer and an ephemeral key
         * @summary Creates a new setup intent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerStripeCreateSetupIntentCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/seller/stripe/create-setup-intent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the SetupIntent and attached PaymentMethod.
         * @summary Gets the details of a setup intent
         * @param {StripeSellerRetrieveSetupIntent} stripeSellerRetrieveSetupIntent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerStripeRetrieveSetupIntentCreate: async (stripeSellerRetrieveSetupIntent: StripeSellerRetrieveSetupIntent, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSellerRetrieveSetupIntent' is not null or undefined
            assertParamExists('sellerStripeRetrieveSetupIntentCreate', 'stripeSellerRetrieveSetupIntent', stripeSellerRetrieveSetupIntent)
            const localVarPath = `/v2/seller/stripe/retrieve-setup-intent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stripeSellerRetrieveSetupIntent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerStripeApi - functional programming interface
 * @export
 */
export const SellerStripeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerStripeApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns setup intent, along with the customer and an ephemeral key
         * @summary Creates a new setup intent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerStripeCreateSetupIntentCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeSellerCreateSetupIntent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerStripeCreateSetupIntentCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the SetupIntent and attached PaymentMethod.
         * @summary Gets the details of a setup intent
         * @param {StripeSellerRetrieveSetupIntent} stripeSellerRetrieveSetupIntent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerStripeRetrieveSetupIntentCreate(stripeSellerRetrieveSetupIntent: StripeSellerRetrieveSetupIntent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeSetupIntent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerStripeRetrieveSetupIntentCreate(stripeSellerRetrieveSetupIntent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SellerStripeApi - factory interface
 * @export
 */
export const SellerStripeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerStripeApiFp(configuration)
    return {
        /**
         * Returns setup intent, along with the customer and an ephemeral key
         * @summary Creates a new setup intent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerStripeCreateSetupIntentCreate(options?: any): AxiosPromise<StripeSellerCreateSetupIntent> {
            return localVarFp.sellerStripeCreateSetupIntentCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the SetupIntent and attached PaymentMethod.
         * @summary Gets the details of a setup intent
         * @param {StripeSellerRetrieveSetupIntent} stripeSellerRetrieveSetupIntent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerStripeRetrieveSetupIntentCreate(stripeSellerRetrieveSetupIntent: StripeSellerRetrieveSetupIntent, options?: any): AxiosPromise<StripeSetupIntent> {
            return localVarFp.sellerStripeRetrieveSetupIntentCreate(stripeSellerRetrieveSetupIntent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sellerStripeRetrieveSetupIntentCreate operation in SellerStripeApi.
 * @export
 * @interface SellerStripeApiSellerStripeRetrieveSetupIntentCreateRequest
 */
export interface SellerStripeApiSellerStripeRetrieveSetupIntentCreateRequest {
    /**
     * 
     * @type {StripeSellerRetrieveSetupIntent}
     * @memberof SellerStripeApiSellerStripeRetrieveSetupIntentCreate
     */
    readonly stripeSellerRetrieveSetupIntent: StripeSellerRetrieveSetupIntent
}

/**
 * SellerStripeApi - object-oriented interface
 * @export
 * @class SellerStripeApi
 * @extends {BaseAPI}
 */
export class SellerStripeApi extends BaseAPI {
    /**
     * Returns setup intent, along with the customer and an ephemeral key
     * @summary Creates a new setup intent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerStripeApi
     */
    public sellerStripeCreateSetupIntentCreate(options?: any) {
        return SellerStripeApiFp(this.configuration).sellerStripeCreateSetupIntentCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the SetupIntent and attached PaymentMethod.
     * @summary Gets the details of a setup intent
     * @param {SellerStripeApiSellerStripeRetrieveSetupIntentCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerStripeApi
     */
    public sellerStripeRetrieveSetupIntentCreate(requestParameters: SellerStripeApiSellerStripeRetrieveSetupIntentCreateRequest, options?: any) {
        return SellerStripeApiFp(this.configuration).sellerStripeRetrieveSetupIntentCreate(requestParameters.stripeSellerRetrieveSetupIntent, options).then((request) => request(this.axios, this.basePath));
    }
}
