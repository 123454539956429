import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';

// TODO: Watch for overflow and set padding right programmatically
export const ScrollableContent = styled.div`
  padding-right: ${globalTheme.space[1]};
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-color, ${globalTheme.colors.white})
    transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color, ${globalTheme.colors.white});
    border-radius: 16px;
  }
`;
