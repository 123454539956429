/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PatchedUserUpdateProfile } from '../model';
// @ts-ignore
import { SellerInfoCreate } from '../model';
// @ts-ignore
import { User } from '../model';
// @ts-ignore
import { UserWithOrgMemberships } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes the user from the system, making their account inactive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerDeleteDestroy: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/seller/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user profile details
         * @param {PatchedUserUpdateProfile} [patchedUserUpdateProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProfilePartialUpdate: async (patchedUserUpdateProfile?: PatchedUserUpdateProfile, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/seller/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUserUpdateProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the users profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProfileRetrieve: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/seller/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the sellers info details, returning the created data
         * @summary Create/Update the sellers info
         * @param {SellerInfoCreate} sellerInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerSellerInfoCreate: async (sellerInfoCreate: SellerInfoCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sellerInfoCreate' is not null or undefined
            assertParamExists('sellerSellerInfoCreate', 'sellerInfoCreate', sellerInfoCreate)
            const localVarPath = `/v2/seller/seller-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerInfoCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the user from the system, making their account inactive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDestroy: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user profile details
         * @param {PatchedUserUpdateProfile} [patchedUserUpdateProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPartialUpdate: async (patchedUserUpdateProfile?: PatchedUserUpdateProfile, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUserUpdateProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the users profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRetrieve: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes the user from the system, making their account inactive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerDeleteDestroy(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerDeleteDestroy(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user profile details
         * @param {PatchedUserUpdateProfile} [patchedUserUpdateProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerProfilePartialUpdate(patchedUserUpdateProfile?: PatchedUserUpdateProfile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWithOrgMemberships>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerProfilePartialUpdate(patchedUserUpdateProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the users profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerProfileRetrieve(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWithOrgMemberships>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerProfileRetrieve(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the sellers info details, returning the created data
         * @summary Create/Update the sellers info
         * @param {SellerInfoCreate} sellerInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerSellerInfoCreate(sellerInfoCreate: SellerInfoCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerInfoCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerSellerInfoCreate(sellerInfoCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the user from the system, making their account inactive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDestroy(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDestroy(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user profile details
         * @param {PatchedUserUpdateProfile} [patchedUserUpdateProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPartialUpdate(patchedUserUpdateProfile?: PatchedUserUpdateProfile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPartialUpdate(patchedUserUpdateProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the users profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRetrieve(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRetrieve(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes the user from the system, making their account inactive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerDeleteDestroy(options?: any): AxiosPromise<void> {
            return localVarFp.sellerDeleteDestroy(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user profile details
         * @param {PatchedUserUpdateProfile} [patchedUserUpdateProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProfilePartialUpdate(patchedUserUpdateProfile?: PatchedUserUpdateProfile, options?: any): AxiosPromise<UserWithOrgMemberships> {
            return localVarFp.sellerProfilePartialUpdate(patchedUserUpdateProfile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the users profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProfileRetrieve(options?: any): AxiosPromise<UserWithOrgMemberships> {
            return localVarFp.sellerProfileRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the sellers info details, returning the created data
         * @summary Create/Update the sellers info
         * @param {SellerInfoCreate} sellerInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerSellerInfoCreate(sellerInfoCreate: SellerInfoCreate, options?: any): AxiosPromise<SellerInfoCreate> {
            return localVarFp.sellerSellerInfoCreate(sellerInfoCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the user from the system, making their account inactive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDestroy(options?: any): AxiosPromise<void> {
            return localVarFp.userDestroy(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user profile details
         * @param {PatchedUserUpdateProfile} [patchedUserUpdateProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPartialUpdate(patchedUserUpdateProfile?: PatchedUserUpdateProfile, options?: any): AxiosPromise<User> {
            return localVarFp.userPartialUpdate(patchedUserUpdateProfile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the users profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRetrieve(options?: any): AxiosPromise<User> {
            return localVarFp.userRetrieve(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sellerProfilePartialUpdate operation in UserApi.
 * @export
 * @interface UserApiSellerProfilePartialUpdateRequest
 */
export interface UserApiSellerProfilePartialUpdateRequest {
    /**
     * 
     * @type {PatchedUserUpdateProfile}
     * @memberof UserApiSellerProfilePartialUpdate
     */
    readonly patchedUserUpdateProfile?: PatchedUserUpdateProfile
}

/**
 * Request parameters for sellerSellerInfoCreate operation in UserApi.
 * @export
 * @interface UserApiSellerSellerInfoCreateRequest
 */
export interface UserApiSellerSellerInfoCreateRequest {
    /**
     * 
     * @type {SellerInfoCreate}
     * @memberof UserApiSellerSellerInfoCreate
     */
    readonly sellerInfoCreate: SellerInfoCreate
}

/**
 * Request parameters for userPartialUpdate operation in UserApi.
 * @export
 * @interface UserApiUserPartialUpdateRequest
 */
export interface UserApiUserPartialUpdateRequest {
    /**
     * 
     * @type {PatchedUserUpdateProfile}
     * @memberof UserApiUserPartialUpdate
     */
    readonly patchedUserUpdateProfile?: PatchedUserUpdateProfile
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Deletes the user from the system, making their account inactive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sellerDeleteDestroy(options?: any) {
        return UserApiFp(this.configuration).sellerDeleteDestroy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user profile details
     * @param {UserApiSellerProfilePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sellerProfilePartialUpdate(requestParameters: UserApiSellerProfilePartialUpdateRequest = {}, options?: any) {
        return UserApiFp(this.configuration).sellerProfilePartialUpdate(requestParameters.patchedUserUpdateProfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the users profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sellerProfileRetrieve(options?: any) {
        return UserApiFp(this.configuration).sellerProfileRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the sellers info details, returning the created data
     * @summary Create/Update the sellers info
     * @param {UserApiSellerSellerInfoCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sellerSellerInfoCreate(requestParameters: UserApiSellerSellerInfoCreateRequest, options?: any) {
        return UserApiFp(this.configuration).sellerSellerInfoCreate(requestParameters.sellerInfoCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the user from the system, making their account inactive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDestroy(options?: any) {
        return UserApiFp(this.configuration).userDestroy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user profile details
     * @param {UserApiUserPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPartialUpdate(requestParameters: UserApiUserPartialUpdateRequest = {}, options?: any) {
        return UserApiFp(this.configuration).userPartialUpdate(requestParameters.patchedUserUpdateProfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the users profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userRetrieve(options?: any) {
        return UserApiFp(this.configuration).userRetrieve(options).then((request) => request(this.axios, this.basePath));
    }
}
